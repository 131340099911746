import React, { FC, useContext, useEffect } from 'react';
import { StyledCurrentLocation } from './CurrentLocation.styles';
import { SyntheticEvent } from 'react';
import { getCurrentLocation } from '../../../utils/helper';
import { ContentContext } from '../../../index.contexts';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

export interface CurrentLocationProps {
  currentLocationHiddenFieldHandler: (data: Record<string, string>) => void;
  inputFieldHandler: (data: string) => void;
}

const CurrentLocation: FC<CurrentLocationProps> = ({ currentLocationHiddenFieldHandler, inputFieldHandler }) => {
  const { currentLocationLabel } = useContext(ContentContext);

  const clickHandler = (e: SyntheticEvent): void => {
    e.preventDefault();
    const locationHandler = (data: Record<string, string>): void => {
      const currentLocationInfo = data;
      // update the hidden input fields and destination input field
      currentLocationHiddenFieldHandler(currentLocationInfo);
      inputFieldHandler(currentLocationInfo['address']);
    };
    getCurrentLocation(locationHandler);
  };

  const { GOOGLE_MAP_API_KEY } = process.env;

  useEffect(() => {
    if (!window.google || window.google === undefined) {
      appendMapFile();
    }
    function appendMapFile(): void {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
      script.defer = true;

      document.head.appendChild(script);

      const script1 = document.createElement('script');
      script1.src = `https://unpkg.com/@googlemaps/markerwithlabel@1.0.3/dist/index.min.js`;
      script1.defer = true;

      document.head.appendChild(script1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledCurrentLocation>
      <a
        href="#"
        onTouchStart={(event: SyntheticEvent): void => clickHandler(event)}
        onClick={(event: SyntheticEvent): void => clickHandler(event)}
        className="t-subtitle-m"
      >
        <span className="icon-nearby">{currentLocationLabel}</span>
      </a>
    </StyledCurrentLocation>
  );
};

export default CurrentLocation;
