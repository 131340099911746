/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useEffect, useState, useRef } from 'react';
import { useApolloClient } from '@apollo/client';

import MapClusterComponent from '../MapClusterComponent';
import {
  LEISURE_REGION_LIST,
  SELECTED_REGION,
  STATE_COUNTRY_LIST,
  SHOW_PAGE_LEVEL_ALERT,
  DEFAULT_ZOOM_LEVEL,
  TRACKING_CONST,
  SHOW_REGION_CARD_ERROR,
  TOTAL_NO_OF_HOTELS_WORLDWIDE,
  AFRICA_CUSTOM_LABEL,
  ASIA_CUSTOM_LABEL,
  EUROPE_CUSTOM_LABEL,
  MIDDLE_EAST_CUSTOM_LABEL,
  SOUTH_AMERICA_CUSTOM_LABEL,
  CARIBBEAN_CUSTOM_LABEL,
  CENTRAL_AMERICA_CUSTOM_LABEL,
  AUSTRALIA_CUSTOM_LABEL,
  USA_CUSTOM_LABEL,
  MIDDLE_EAST_CODE,
  SOUTH_AMERICA_CODE,
  CENTRAL_AMERICA_CODE,
  AUSTRALIA_CODE,
  USA_CODE,
} from '../../../modules/constants/HotelDirectory.constants';
import { useHotelDirectoryStore } from '../../../modules/store/hotelDirectoryStore';
import {
  customSort,
  extractFiltersData,
  extractMarkerData,
  searchCounterValue,
  updateSessionProperties,
  getRegionDataToStore,
  convertToDTTResponse,
} from './HotelDirectoryMapHelper';
import { StyledHotelDirectoryMap } from './HotelDirectoryMap.styles';
import { phoenixShopHotelDirectoryData, phoenixShopSearchPropertiesByLocationInput } from '../queries';
import { HotelDirectoryDataList, HotelDirectoryMapProps, RegionItems, UXLPayload } from './HotelDirectoryMap.types';
import { SearchFilterPanel } from '../../../molecules/SearchFilterPanel';
import { useHotelDirectoryStaticDataContext } from '../../../modules/context/HotelDirectoryStaticDataContext';
import { SearchFacetsList } from '../../../constants/Filters.constant';
import { formatNumberByLocale } from '../../../utils/src/utils';
import { usePageContext } from '../../../context';
import { hotelDirectoryMockUXL } from '../__mock__/hotelDirectoryMockUXL.';
import { SkeletonLoader } from '../../../molecules/SkeletonLoader';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}
export const HotelDirectoryMap: FC<HotelDirectoryMapProps> = (props: HotelDirectoryMapProps) => {
  const {
    model,
    isDesktopView,
    setTotalCountOnHeader,
    filtersCount,
    filtersSessionVal,
    filtersPayload,
    setFiltersPayload,
    setCenter,
    center,
    setPillsData,
    isAmenitiesSelected,
    isBrandsSelected,
    setFiltersData,
    popupOpenState,
    setPopupOpenState,
    filterButtonClicked,
    amenitiesData,
    brandsData,
    setFilterButtonClicked,
    filtersData,
    setFiltersCount,
    setIsDataLoaded,
    isSessionUpdateWithQueryFilter,
    isStoryBook,
    isAuthorMode = false,
  } = props;
  const regionName = model?.mapRegion ? JSON.parse(model?.mapRegion) : [];
  const subRegionName = model?.subRegion ? JSON.parse(model?.subRegion) : {};
  const labelForChina = model?.chinaDisplayLabel;
  const [totalCountOfHotels, setTotalCountOfHotels] = useState(0);
  const {
    headersData,
    currentLocale,
    sessionData,
    SESSION_APP_GET_CALL_URL,
    SESSION_APP_POST_CALL_URL,
    NEXT_PUBLIC_PREFIX,
    resolvedUrl,
    query,
    datalayerParams,
    datalayerProperties,
    requestId,
    isDTT,
    dttHeaders,
  } = usePageContext();

  const setHotelDirectoryState = useHotelDirectoryStore(
    (state: { setHotelDirectoryState: any }) => state.setHotelDirectoryState
  );
  const selectedRegion = useHotelDirectoryStore(
    (state: { selectedRegion: any }) => state.selectedRegion
  ).selectedRegion;
  const stateORCountryList = useHotelDirectoryStore(
    (state: { stateORCountryList: any }) => state.stateORCountryList
  ).stateORCountryList;

  const { HOTEL_DIRECTORY, ALL_FILTERS, AMENITIES_FILTERS, BRANDS_FILTERS } = TRACKING_CONST;

  const [openInfoWindow, setOpenInfoWindow] = useState(false);
  const [markers, setMarkers] = useState<HotelDirectoryDataList>([]);
  const [infoWindowData, setInfoWindowData] = useState<any>({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState<number>(DEFAULT_ZOOM_LEVEL);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [disableFilters, setDisableFilters] = useState<boolean>(false);

  const isFiltersApplied = filtersCount > 0 ? 'true' : 'false';

  const [applyBtnClicked, setApplyBtnClicked] = useState<boolean>(false);
  const client = useApolloClient();
  const clustererRef = useRef<any>();
  const selectedRegionRef = useRef<any>();
  const filtersPayloadRef = useRef<any>();
  const {
    applyLabel,
    clearLabel,
    filtersOrderList,
    brandCategories,
    filterLabel,
    brandsLabel,
    amenitiesLabel,
    showMoreLabel,
    showLessLabel,
  } = useHotelDirectoryStaticDataContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const bodyRef = useRef<HTMLBodyElement | null>(null);

  useEffect(() => {
    bodyRef.current = document.body as HTMLBodyElement;
  }, []);

  // function to update the URL parameters based on the number of filters selected
  function updateURLParams() {
    // case where >=1 filter(s) have been selected ("?filtersApplied=true" has to be set)
    const params = new URLSearchParams(window?.location?.search ?? '');
    if (filtersCount > 0) {
      params.set('filtersApplied', 'true');
    }
    // case where 0 filters have been selected (filtersApplied param has to be removed)
    else {
      if (params.has('filtersApplied')) {
        params.delete('filtersApplied');
      }
    }
    const counterValue = sessionStorage.getItem('hotelDirectoryCounter');
    const correctURL = filtersCount > 0 ? `?${params}/#${counterValue}/` : `?${params}#${counterValue}/`;
    window.history.replaceState({}, '', decodeURIComponent(correctURL));
  }

  // function to update the exit point URLs
  function updateExitPointLink() {
    sessionStorage.setItem('isFiltersApplied', isFiltersApplied);
  }

  useEffect(() => {
    if (disableFilters) {
      setHotelDirectoryState([SHOW_PAGE_LEVEL_ALERT], {
        [SHOW_PAGE_LEVEL_ALERT]: { [SHOW_PAGE_LEVEL_ALERT]: true },
      });
    } else {
      setHotelDirectoryState([SHOW_PAGE_LEVEL_ALERT], {
        [SHOW_PAGE_LEVEL_ALERT]: { [SHOW_PAGE_LEVEL_ALERT]: false },
      });
    }
  }, [disableFilters]);

  useEffect(() => {
    //initializing store with region labels with ZERO count in case UXL fails to return the count and labels
    const regionDataToStore: HotelDirectoryDataList = [];
    regionName?.forEach((item: RegionItems) => {
      let customLabel = '';
      if (item.regionCode === AFRICA_CUSTOM_LABEL) {
        customLabel = AFRICA_CUSTOM_LABEL;
      } else if (item.regionCode === ASIA_CUSTOM_LABEL) {
        customLabel = ASIA_CUSTOM_LABEL;
      } else if (item.regionCode === EUROPE_CUSTOM_LABEL) {
        customLabel = EUROPE_CUSTOM_LABEL;
      } else if (item.regionCode === MIDDLE_EAST_CODE) {
        customLabel = MIDDLE_EAST_CUSTOM_LABEL;
      } else if (item.regionCode === SOUTH_AMERICA_CODE) {
        customLabel = SOUTH_AMERICA_CUSTOM_LABEL;
      } else if (item.regionCode === CARIBBEAN_CUSTOM_LABEL) {
        customLabel = CARIBBEAN_CUSTOM_LABEL;
      } else if (item.regionCode === CENTRAL_AMERICA_CODE) {
        customLabel = CENTRAL_AMERICA_CUSTOM_LABEL;
      } else if (item.regionCode === AUSTRALIA_CODE) {
        customLabel = AUSTRALIA_CUSTOM_LABEL;
      } else if (item.regionCode === USA_CODE) {
        customLabel = USA_CUSTOM_LABEL;
      }
      regionDataToStore.push({
        label: item.regionTagTitle,
        count: 0,
        code: item.regionCode,
        latitude: Number(item.regionLatitude),
        longitude: Number(item.regionLongitude),
        customLabel: customLabel,
      });
    });
    regionDataToStore.sort(customSort(currentLocale));

    setHotelDirectoryState([LEISURE_REGION_LIST, SELECTED_REGION], {
      [LEISURE_REGION_LIST]: {
        [LEISURE_REGION_LIST]: regionDataToStore ? regionDataToStore : [],
      },
      [SELECTED_REGION]: { [SELECTED_REGION]: regionDataToStore[0] },
    });
    const inputValues: UXLPayload = {
      options: {
        countsOnly: true,
      },
      facets: {
        terms: [
          {
            type: SearchFacetsList?.brands,
            dimensions:
              filtersSessionVal && filtersSessionVal?.brands && Object.keys(filtersSessionVal?.brands)?.length > 0
                ? filtersSessionVal.brands
                : [],
          },
          {
            type: SearchFacetsList?.amenities,
            dimensions:
              filtersSessionVal && filtersSessionVal?.amenities && Object.keys(filtersSessionVal?.amenities)?.length > 0
                ? filtersSessionVal.amenities
                : [],
          },
          {
            type: SearchFacetsList?.propertyType,
            dimensions:
              filtersSessionVal &&
              filtersSessionVal?.propertyType &&
              Object.keys(filtersSessionVal?.propertyType)?.length > 0
                ? filtersSessionVal.propertyType
                : [],
          },
          {
            type: SearchFacetsList?.activities,
            dimensions:
              filtersSessionVal &&
              filtersSessionVal?.activities &&
              Object.keys(filtersSessionVal?.activities)?.length > 0
                ? filtersSessionVal.activities
                : [],
          },
          {
            type: SearchFacetsList?.state,
            dimensions:
              filtersSessionVal && filtersSessionVal?.states && Object.keys(filtersSessionVal?.states)?.length > 0
                ? filtersSessionVal.states
                : [],
          },
          {
            type: SearchFacetsList?.country,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.events,
            dimensions:
              filtersSessionVal && filtersSessionVal?.events && Object.keys(filtersSessionVal?.events)?.length > 0
                ? filtersSessionVal.events
                : [],
          },
          {
            type: SearchFacetsList?.transportation,
            dimensions:
              filtersSessionVal &&
              filtersSessionVal?.transportationTypes &&
              Object.keys(filtersSessionVal?.transportationTypes)?.length > 0
                ? filtersSessionVal.transportationTypes
                : [],
          },
          {
            type: SearchFacetsList?.leisureRegion,
            dimensions: [],
          },
        ],
      },
    };
    if (!isDTT) {
      inputValues.destination = '';
    }
    getInitialRegionCount({
      variables: {
        search: inputValues,
      },
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': currentLocale?.replace('_', '-'),
          ...dttHeaders,
        },
      },
    }).catch(() => {
      setDisableFilters(true);
    });
    // call datalayer on page load to add/remove some datalayer attributes specific for HD page
    !isStoryBook && getLatestSessionData();
  }, []);

  const [initialResponse, setInitialResponse] = useState<any>();

  useEffect(() => {
    const inputValues: UXLPayload = {
      options: {
        countsOnly: true,
      },
      facets: {
        terms: [
          {
            type: SearchFacetsList?.brands,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.amenities,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.propertyType,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.activities,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.state,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.country,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.events,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.transportation,
            dimensions: [],
          },
          {
            type: SearchFacetsList?.leisureRegion,
            dimensions: [],
          },
        ],
      },
    };
    if (!isDTT) {
      inputValues.destination = '';
    }
    getInitialResponse({
      variables: {
        search: inputValues,
      },
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': currentLocale?.replace('_', '-'),
          ...dttHeaders,
        },
      },
    }).catch(() => {
      setDisableFilters(true);
    });
  }, []);

  // get the full response containing all filters
  async function getInitialResponse(inputValues: any) {
    const data = await fetchHDData(inputValues);
    if (initialResponse === undefined) {
      setInitialResponse(extractFiltersData(data, brandsLabel));
    }
  }

  useEffect(() => {
    if (clustererRef.current && clustererRef.current.repaint) {
      clustererRef.current.repaint();
    }
    setMarkers(stateORCountryList);
  }, [stateORCountryList]);
  useEffect(() => {
    if (clustererRef.current && clustererRef.current.repaint) {
      clustererRef.current.repaint();
    }
  }, [markers]);

  useEffect(() => {
    const newFiltersPayload = filtersPayload?.map((fp: any) => {
      const returnValue = { ...fp };
      return returnValue;
    });

    setFiltersPayload(newFiltersPayload);

    setCenter({
      lat: selectedRegion?.latitude,
      lng: selectedRegion?.longitude,
    });
  }, [selectedRegion]);

  useEffect(() => {
    selectedRegionRef.current = selectedRegion;
    filtersPayloadRef.current = filtersPayload;

    const inputValues: UXLPayload = {
      options: {
        countsOnly: true,
      },
      facets: {
        terms: filtersPayload,
      },
    };
    if (!isDTT) {
      inputValues.destination = '';
    }

    getRegionAndFiltersData({
      variables: {
        search: inputValues,
      },
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': currentLocale?.replace('_', '-'),
          ...dttHeaders,
        },
      },
    }).catch(() => {
      setMapZoom(DEFAULT_ZOOM_LEVEL);
      setCenter({
        lat: selectedRegion?.latitude,
        lng: selectedRegion?.longitude,
      });
      setDisableFilters(true);
    });
  }, [filtersPayload]);

  useEffect(() => {
    //enabling session update for MapAndRegionCard component with filters in query on mount
    //HotelDirectoryMap component useeffect trigger first before MapAndRegionCard useffect
    if (applyBtnClicked || isSessionUpdateWithQueryFilter) {
      const filtersPayloadWithEmptyLeisureRegion = filtersPayload?.map((fp: any) => {
        const returnValue = { ...fp };

        if (fp?.type === 'LEISURE_REGIONS') {
          returnValue.dimensions = [];
        }

        return returnValue;
      });
      const inputData: UXLPayload = {
        options: {
          countsOnly: true,
        },
        facets: {
          terms: filtersPayloadWithEmptyLeisureRegion,
        },
      };
      if (!isDTT) {
        inputData.destination = '';
      }
      getRegionCountOnApply({
        variables: {
          search: inputData,
        },
        context: {
          headers: {
            'x-request-id': requestId,
            'accept-language': currentLocale?.replace('_', '-'),
            ...dttHeaders,
          },
        },
      });
      updateURLParams();
      !isStoryBook && getLatestSessionData();
      updateExitPointLink();
      // update pills selection on click of apply button
      setPillsData([
        {
          label: filterLabel,
          value: 'allFilters',
          isActive: filtersCount > 0,
          showIcon: filtersCount === 0,
          iconClass: 'icon-dining-filter',
          count: filtersCount,
          custom_click_track_value: `${HOTEL_DIRECTORY} | ${ALL_FILTERS} | internal`,
          buttonClassName: 'd-flex align-items-center all-filters-button',
          id: 'allFilters',
        },
        {
          label: amenitiesLabel,
          value: 'amenities',
          isActive: isAmenitiesSelected,
          custom_click_track_value: `${HOTEL_DIRECTORY} | ${AMENITIES_FILTERS} | internal`,
          className: totalCountOfHotels === 0 ? 'd-none' : '',
          id: 'amenities',
        },
        {
          label: brandsLabel,
          value: 'brands',
          isActive: isBrandsSelected,
          custom_click_track_value: `${HOTEL_DIRECTORY} | ${BRANDS_FILTERS} | internal`,
          id: 'brands',
        },
      ]);
    }
    applyBtnClicked && setIsLoading(true);
  }, [applyBtnClicked, isSessionUpdateWithQueryFilter]);

  // add below useEffect to stop the background scroll when the filter popup is open
  useEffect(() => {
    if (!popupOpenState && bodyRef.current) {
      bodyRef.current.style.overflow = 'auto';
    } else {
      if (bodyRef.current) {
        bodyRef.current.style.overflow = 'hidden';
      }
    }
  }, [popupOpenState]);

  // add class to position full screen view icon properly on map
  // This QuerySelector can't be removed, as this class is from Google maps library.
  const fullViewElement = document.querySelector('.gm-fullscreen-control');
  if (fullViewElement) {
    fullViewElement?.parentElement?.classList?.add('map-full-view-container');
  }

  const fetchHDData = async (inputValues: any) => {
    const { data } = isAuthorMode
      ? hotelDirectoryMockUXL
      : await client.query({
          query: isDTT ? phoenixShopSearchPropertiesByLocationInput : phoenixShopHotelDirectoryData,
          ...inputValues,
        });
    return isDTT ? data : convertToDTTResponse(data);
  };

  const prevSelectedRegion = selectedRegionRef.current;
  const prevFiltersPayload = filtersPayloadRef.current;

  async function getInitialRegionCount(inputValues: any) {
    const data = await fetchHDData(inputValues);

    if (data?.search?.properties?.searchByLocation?.facets?.length === 0) {
      setDisableFilters(true);
    }
    if (data?.search?.properties?.searchByLocation?.facets?.length) {
      setTotalCountOnHeader(
        formatNumberByLocale(data?.search?.properties?.searchByLocation?.total, currentLocale?.replace('_', '-'))
      );
      setTotalCountOfHotels(data?.search?.properties?.searchByLocation?.total);
      setHotelDirectoryState([TOTAL_NO_OF_HOTELS_WORLDWIDE], {
        [TOTAL_NO_OF_HOTELS_WORLDWIDE]: {
          [TOTAL_NO_OF_HOTELS_WORLDWIDE]: data?.search?.properties?.searchByLocation?.total,
        },
      });

      const regionDataToStore: HotelDirectoryDataList = getRegionDataToStore(
        regionName,
        subRegionName,
        data,
        model.mexicoLabel,
        model.canadaLabel
      );
      regionDataToStore.sort(customSort(currentLocale));

      setHotelDirectoryState([LEISURE_REGION_LIST, SELECTED_REGION], {
        [LEISURE_REGION_LIST]: {
          [LEISURE_REGION_LIST]: regionDataToStore ? regionDataToStore : [],
        },
        [SELECTED_REGION]: { [SELECTED_REGION]: regionDataToStore[0] },
      });

      const filtersDataFromResponse: any = extractFiltersData(data, brandsLabel);
      setFiltersData(filtersDataFromResponse);
      if (filtersDataFromResponse.length) {
        setHotelDirectoryState([SHOW_REGION_CARD_ERROR], {
          [SHOW_REGION_CARD_ERROR]: { [SHOW_REGION_CARD_ERROR]: true },
        });
      }
    }
  }

  // get the region and filters data on selection
  async function getRegionAndFiltersData(inputValues: any) {
    const data = await fetchHDData(inputValues);

    setMapZoom(DEFAULT_ZOOM_LEVEL);
    if (data) {
      setIsDataLoaded(true);
      setIsLoading(false);
      setTotalCountOfHotels(data?.search?.properties?.searchByLocation?.total);

      if (prevSelectedRegion !== selectedRegion || applyBtnClicked) {
        const subRegionDataFromResponse = extractMarkerData(
          data,
          selectedRegion,
          subRegionName?.subRegion,
          labelForChina,
          model.australiaPILabel,
          model.mexicoLabel,
          model.canadaLabel,
          model.australiaAndOceaniaLabel
        );
        setHotelDirectoryState([STATE_COUNTRY_LIST], {
          [STATE_COUNTRY_LIST]: { [STATE_COUNTRY_LIST]: subRegionDataFromResponse ? subRegionDataFromResponse : [] },
        });
      }

      // set filter response for dyncamic filter changes
      if (prevFiltersPayload !== filtersPayload && data?.search?.properties?.searchByLocation?.facets?.length > 0) {
        const filtersDataFromResponse: any = extractFiltersData(data, brandsLabel);
        setFiltersData(filtersDataFromResponse);
      }
    } else {
      setIsLoading(true);
      setIsDataLoaded(false);
      setHotelDirectoryState([STATE_COUNTRY_LIST], {
        [STATE_COUNTRY_LIST]: { [STATE_COUNTRY_LIST]: [] },
      });
    }
  }

  // get the region related details on click of apply button on modal
  async function getRegionCountOnApply(inputValues: any) {
    const data = await fetchHDData(inputValues);

    if (data) {
      setTotalCountOnHeader(
        formatNumberByLocale(data?.search?.properties?.searchByLocation?.total, currentLocale?.replace('_', '-'))
      );
      setIsLoading(false);
      setIsDataLoaded(true);
      setCenter({
        lat: selectedRegion?.latitude,
        lng: selectedRegion?.longitude,
      });

      const subRegionDataFromResponse = extractMarkerData(
        data,
        selectedRegion,
        subRegionName?.subRegion,
        labelForChina,
        model.australiaPILabel,
        model.mexicoLabel,
        model.canadaLabel,
        model.australiaAndOceaniaLabel
      );
      setHotelDirectoryState([STATE_COUNTRY_LIST], {
        [STATE_COUNTRY_LIST]: { [STATE_COUNTRY_LIST]: subRegionDataFromResponse ? subRegionDataFromResponse : [] },
      });
      const regionDataToStore: HotelDirectoryDataList = getRegionDataToStore(
        regionName,
        subRegionName,
        data,

        model.mexicoLabel,
        model.canadaLabel
      );
      regionDataToStore.sort(customSort(currentLocale));

      setHotelDirectoryState([LEISURE_REGION_LIST, SELECTED_REGION], {
        [LEISURE_REGION_LIST]: {
          [LEISURE_REGION_LIST]: regionDataToStore ? regionDataToStore : [],
        },
        [SELECTED_REGION]: { [SELECTED_REGION]: regionDataToStore[0] },
      });

      // store the flter response on click of apply button when filter response is empty
      if (data?.search?.properties?.searchByLocation?.facets?.length === 0 && applyBtnClicked) {
        const filtersDataFromResponse: any = extractFiltersData(data, brandsLabel);
        setFiltersData(filtersDataFromResponse);
        setDisableFilters(true);
      } else if (data?.search?.properties?.searchByLocation?.facets?.length > 0 && applyBtnClicked) {
        setDisableFilters(false);
      }
    } else {
      setIsLoading(true);
      setIsDataLoaded(false);
    }
    setApplyBtnClicked(false);
  }

  // Asynchronous function to fetch and update the latest session data
  const getLatestSessionData = async () => {
    await updateSessionProperties(
      sessionData?.sessionToken,
      SESSION_APP_GET_CALL_URL,
      SESSION_APP_POST_CALL_URL,
      NEXT_PUBLIC_PREFIX,
      filtersPayload,
      resolvedUrl,
      headersData,
      query,
      currentLocale,
      datalayerParams,
      datalayerProperties
    );
  };

  return (
    <StyledHotelDirectoryMap>
      {popupOpenState && (
        <SearchFilterPanel
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          filtersData={
            filterButtonClicked?.['allFilters']
              ? filtersData
              : filterButtonClicked?.['amenities']
              ? amenitiesData
              : brandsData
          }
          primaryButtonLabel={applyLabel}
          secondaryButtonLabel={clearLabel}
          filterOrder={filtersOrderList}
          filtersPayload={filtersPayload}
          setFiltersPayload={setFiltersPayload}
          dynamicFiltersEnabled={true}
          setFiltersCount={setFiltersCount}
          setApplyBtnClicked={setApplyBtnClicked}
          brandCategories={brandCategories}
          clickTrackingLoc={HOTEL_DIRECTORY}
          filtersHeaderLabel={
            filterButtonClicked?.['allFilters']
              ? filterLabel
              : filterButtonClicked?.['amenities']
              ? amenitiesLabel
              : brandsLabel
          }
          onApplyButtonClicked={() => {
            searchCounterValue(headersData, props.hotelDirectoryCounter, props.setHotelDirectoryCounter);
          }}
          filterButtonClicked={filterButtonClicked}
          setFilterButtonClicked={setFilterButtonClicked}
          initialResponse={initialResponse}
          showMoreLabel={showMoreLabel}
          showLessLabel={showLessLabel}
        />
      )}
      {!isMapLoaded || isLoading ? <SkeletonLoader></SkeletonLoader> : <span></span>}

      {!isLoading && (
        <MapClusterComponent
          center={center}
          markers={markers}
          mapHeight={500}
          zoom={mapZoom}
          onZoomChange={setMapZoom}
          isMapLoaded={isMapLoaded}
          setIsMapLoaded={setIsMapLoaded}
          clustererRef={clustererRef}
          isDesktopView={isDesktopView}
          selectedRegion={selectedRegion}
          stateORCountryList={stateORCountryList}
          isFiltersApplied={isFiltersApplied}
          infoWindowData={infoWindowData}
          openInfoWindow={openInfoWindow}
          setOpenInfoWindow={setOpenInfoWindow}
          setInfoWindowData={setInfoWindowData}
        />
      )}
    </StyledHotelDirectoryMap>
  );
};

export default HotelDirectoryMap;
