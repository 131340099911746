// Imports for external libraries go here.
import React, { lazy, Suspense } from 'react';

import { TabbedPropertiesProps } from './TabbedProperties.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const TabbedPropertiesWrapperConfig = {
  emptyLabel: 'TabbedProperties',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/tabbedproperties`,
};
const TabbedPropertiesWrapper = lazy(() => import('./TabbedPropertiesWrapper'));
export const TabbedPropertiesComp: React.FC<TabbedPropertiesProps> = props => {
  return (
    <div className="tabbed-properties-list-wrapper">
      <Suspense fallback={<></>}>
        <TabbedPropertiesWrapper {...props} />
      </Suspense>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TabbedProperties = (props: any) => (
  <div data-testid="offers-TabbedPropertiesList" data-component-name="o-offers-TabbedPropertiesList">
    <EditableComponent config={TabbedPropertiesWrapperConfig} {...props}>
      <TabbedPropertiesComp {...props} />
    </EditableComponent>
  </div>
);
