import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledSkeletonLoaderContainer = styled.div`
  ${rtl`
    display: flex;
    flex-direction: column;
    @media ${theme.mediaQuery.largeMobile} {
      padding-right: 0;
      padding-left: 0;
    }
  `}
`;

export const StyledSkeletonLoaderDiv = styled.div`
  ${rtl`
    border-radius: ${toRem(14)};
    margin: ${toRem(10)} 0;
    font-family: ${theme.fonts['fontFamilySwiss']}; 
    display:flex;
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    background-color: inherit;
      @media ${theme.mediaQuery.allMobiles} {
        flex-flow:nowrap;
         height: auto;
      }
    .container-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
    }
    .skeleton-loader-composite {
      background-color: ${theme.color['light-gray-1']};
      color: white;
      height: 451px;
      width: 358px;
      position: relative;
      border-radius: 0.875rem;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow: hidden;
      max-width: 100%;
    }
  `}
`;
