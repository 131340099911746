export const BUTTON_CLICK_TRACK_VALUE = 'AncillaryOffer|Book Now CTA|External';
export const ANCILLARY_PRODUCT_OFFER = 'ancillary-product-offer';
export const SINGLE_PROPERTY_OFFER = 'single-property-offer';
export const API_TIMEOUT = 2500;
export const DEFAULT_API_ACCEPT_LANGUAGE = 'en-US';
export const API_SSL_CHECK_FALSE = 'FALSE';
export const ARROW_UP = 'icon-arrow-up';
export const ARROW_DOWN = 'icon-arrow-down';
export const DETAILS_SHOWN = 'details-visible';
export const DETAILS_HIDDEN = 'details-hidden';
export const OFFER_DETAILS_OPERATION_SIGNATURE = '';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const OFFER_DETAILS_OPERATION_NAME = 'phoenixOfferDetails';
export const OFFER_SEARCH_CLIENT_NAME = 'phoenix_offers';
export const OFFER_DETAILS_PREVIEW_OPERATION_NAME = '';
export const SBO_MPO_OFFER = 'single-brand-multi-property-offer';
export const MBO_MPO_OFFER = 'multi-brand-multi-property-offer';
export const OFFER_IMAGE_CACHE_DOMAIN = 'https://cache.marriott.com';
export const OUTLET_FALLBACK_SQ =
  '/content/dam/marriott-digital/marriott-rnb/global/en_us/fallback/photo/unlimited/assets/pdt-Fallback-image-square-853428133784326.jpg';
export const OUTLET_FALLBACK_HOR = '/is/image/marriotts7prod/pdt-Fallback-image-square-853428133784326:Wide-Hor';
export const OFFERS_SEARCH_FALLBACK_IMAGE =
  'https://cache.marriott.com/marriottassets/marriott/Stock/stock-woman-sunset-1069700-hor-wide.jpg';
export const NON_STAY_BASED_OFFERS = 'Non Stay Based Offer';
export const PRODUCT_AND_LOYALTY_OFFER = 'Product and Loyalty Offer';
export const NON_STAY_BASED_PRODUCT_AND_LOYALTY_OFFER = 'NON_STAY_BASED_PRODUCT_AND_LOYALTY_OFFER';
export const NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER = 'NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER';
export const STAY_BASED_ALL = 'STAY_BASED_ALL';
