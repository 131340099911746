import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledOutletOfferResult, StyledOutletOfferResultPreview } from './OutletOfferResult.styles';
import { OutletOfferResultInput } from './OutletOfferResult.types';
import { OutletOfferResultContainer } from './OutletOfferResultContainer';

export const OutletOfferResultWrapperConfig = {
  emptyLabel: 'OutletOfferResult',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/outletofferresult`,
};

export const OutletOfferResultWrapper: React.FC<OutletOfferResultInput> = props => {
  return <OutletOfferResultContainer {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OutletOfferResult = (props: any) => (
  <EditableComponent config={OutletOfferResultWrapperConfig} {...props}>
    <div data-component-name="o-offers-outletofferresults" data-testid="outletofferresults">
      {props?.isPreview === 'true' ? (
        <StyledOutletOfferResultPreview>
          <OutletOfferResultContainer {...props} />
        </StyledOutletOfferResultPreview>
      ) : (
        <StyledOutletOfferResult>
          <OutletOfferResultContainer {...props} />
        </StyledOutletOfferResult>
      )}
    </div>
  </EditableComponent>
);
