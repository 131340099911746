// Styles for OfferDetails go here.
import styled from 'styled-components';

export const StyledOfferDetails = styled.div``;
export const StyledOfferDetailsPreviewMode = styled.div`
  pointer-events: none;
  .m-details-page-TnC,
  .popup-close {
    pointer-events: auto;
  }
`;
