import React, { FC, useContext } from 'react';
import { PopupHeaderProps } from './PopupHeader.types';
import { StyledPopupHeaderDiv } from './PopupHeader.styles';
import { ContentContext, ViewportContext } from '../../../index.contexts';

const PopupHeader: FC<PopupHeaderProps> = ({
  className,
  children,
  cancelClickHandler,
  mainText,
  labelText,
  labelIcon,
  inputId,
  isMPO = false,
}) => {
  const isMobileView = useContext(ViewportContext);
  const LabelTag = inputId ? 'label' : 'span';
  const { mobilePlaceholderText, desktopPlaceholderText, cancel } = useContext(ContentContext);
  // click anywhere on the wrapper will move focus to input
  const withInputClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    // if click happened on the element itself not its children
    if (e.target === e.currentTarget && inputId) {
      const inputElement: HTMLElement | null = document.getElementById(inputId);
      inputElement?.focus();
    }
  };
  return (
    <StyledPopupHeaderDiv className={className}>
      {!isMobileView && inputId ? (
        <div className="with-input" onClick={withInputClickHandler}>
          <div className="dashed-border">
            <label
              htmlFor={inputId}
              id={`${inputId}-label`}
              className={`${labelIcon} t-label-xs`}
              aria-label={labelText}
            >
              {labelText}
              <span className="sr-only" aria-hidden={!isMPO}>
                {desktopPlaceholderText as string}
              </span>
            </label>
            {children}
          </div>
        </div>
      ) : (
        <>
          {/* id is needed to be consumed by the <ul> element of autocomplete options for aria-labelledby attribute */}
          <LabelTag
            htmlFor={inputId}
            className={`${labelIcon !== undefined ? labelIcon : ''} ${
              isMobileView ? 't-label-inverse-xs' : 't-label-xs'
            }`}
            id={inputId ? `${inputId}-label` : ''}
          >
            {labelText}
            <span className="sr-only">{mobilePlaceholderText as string}</span>
          </LabelTag>
          <div className={`${labelIcon ? 't-title-inverse-s' : 't-subtitle-inverse-l'}`}>
            {children ?? <div>{mainText}</div>}
            {cancelClickHandler ? (
              <button className="link-white" onClick={cancelClickHandler}>
                {cancel}
              </button>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </StyledPopupHeaderDiv>
  );
};

export default PopupHeader;
