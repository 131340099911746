import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { StyledSearchMobile } from './component/organisms/SearchMobile/SearchMobile.styles';
import { baseVariables } from '@marriott/mi-ui-library';
import { mediaQuery } from '../../utils/styles';

export const StyledSearchContainer = styled.div`
  .priviewMode {
    pointer-events: none;
  }
  display: inline-block !important;
  width: 100%;
  &.offers-spo {
    height: auto;
    position: absolute;
    width: 100%;
    z-index: 5;
    margin-top: ${toRem(-136)};
    left: 0;
  }
  @media ${mediaQuery.mobileOnly} {
    &.offers-spo {
      padding: 0 ${toRem(16)};
      margin-top: ${toRem(-74)};
    }
    .offers-mpo {
      padding: 0 ${toRem(16)};
    }
  }
  @media ${theme.mediaQuery.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
  .h-r-center-item {
    @media ${theme.mediaQuery.allTablets} {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
  }
  .container {
    @media ${theme.mediaQuery.allTablets} {
      max-width: 100%;
      z-index: 1;
    }
  }
  .enable-overlap {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.25rem 1.25rem 0px;
    @media ${theme.mediaQuery.mobileTablet} {
      position: relative;
      bottom: 1rem;
    }
  }
  .enable-overlap {
    @media ${theme.mediaQuery.allTablets} {
      position: relative;
      top: 22px;
    }
  }
  .enable-overlap {
    @media ${theme.mediaQuery.desktop} {
      position: relative;
      top: 22px;
    }
  }
  .search-secondary {
    max-width: 100%;
    @media ${theme.mediaQuery.allTablets} {
      margin: -0.685rem 0 0;
      margin-left: auto;
      margin-right: auto;
      height: ${toRem(58)};
    }
  }
  .offers-mpo {
    form {
      @media ${theme.mediaQuery.allTablets} {
        padding: 0;
      }
      @media (min-width: 821px) and (max-width: 1024px) {
        margin-left: 2.5rem;
      }
    }
  }
  .offers-spo {
    form {
      @media ${theme.mediaQuery.allTablets} {
        padding: 0 ${toRem(48)};
      }
    }
  }
  form {
    position: relative;
    @media ${theme.mediaQuery.desktop} {
      margin-left: auto;
      margin-right: auto;
      width: ${toRem(1125)};
    }
    @media ${theme.mediaQuery.allTablets} {
      padding: 0 ${toRem(50)};
    }
  }
  &.sticky {
    background-color: ${theme.color.white};
    width: 100%;
    z-index: 5;
    box-shadow: ${theme.color['container-box-shadow']};
    & > div {
      box-shadow: none;
    }
    form {
      padding: 0;
      max-width: unset;
      @media ${theme.mediaQuery.desktop} {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
    .search-primary-container {
      box-shadow: ${theme.color['container-box-shadow']};
      & > div > div {
        box-shadow: none;
        & > div > button,
        .with-input,
        .search-dates-popup,
        .hover-60 {
          padding-top: ${toRem(20)};
          @media ${theme.mediaQuery.desktop} {
            padding-top: ${toRem(16)};
          }
        }
        .find-btn {
          @media ${theme.mediaQuery.tablet} {
            right: ${toRem(36)};
          }
          @media ${theme.mediaQuery.desktop} {
            right: ${toRem(32)};
          }
        }
      }
      & + div {
        margin: -${toRem(14)} auto 0;
        background-color: inherit;
        display: none;
      }
    }
    .find-btn {
      top: ${toRem(18)};
      @media ${theme.mediaQuery.desktop} {
        top: ${toRem(16)};
      }
    }
    .with-input {
      @media ${theme.mediaQuery.mobileTablet} {
        padding-left: ${toRem(40)};
      }
    }
    .show-popup {
      @media ${theme.mediaQuery.mobileTablet} {
        left: ${toRem(16)};
        .with-input {
          padding-left: ${toRem(24)};
        }
      }
    }
    ${StyledSearchMobile} {
      padding: ${toRem(16)} ${toRem(32)} ${toRem(20)};
      border-radius: 0;
      button {
        align-items: center;
        flex-direction: row;
      }
      @media ${theme.mediaQuery.smallMobile} {
        padding-left: ${toRem(24)};
        padding-right: ${toRem(24)};
        .h-r-form-field-txt {
          font-size: ${toRem(16)};
        }
      }
      span {
        &:first-child {
          display: inline;
          font-size: ${toRem(14)};
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .mobile-only-show {
    @media ${theme.mediaQuery.tablet} {
      display: none;
    }
  }
  .mobile-only-hide {
    @media ${theme.mediaQuery.mobileOnly} {
      display: none;
    }
  }
  .hover-60 {
    @media ${theme.mediaQuery.tablet} {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .link-white {
    color: white;
  }
  .search-primary {
    @media ${theme.mediaQuery.desktop} {
      height: ${toRem(92)};
    }
    @media ${theme.mediaQuery.tabletMax} {
      height: ${toRem(90)};
    }
  }
  .offers-mpo .find-btn {
    top: ${toRem(33)};
    color: ${theme.color.white};
    @media ${theme.mediaQuery.desktop} {
      right: calc(50% - ${toRem(488)});
      top: ${toRem(30)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      right: calc(50% - ${toRem(570)});
      top: ${toRem(30)};
    }
    @media (min-width: 768px) and (max-width: 820px) {
      top: ${toRem(40)};
      right: calc(50% - 350px);
    }
    @media (min-width: 821px) and (max-width: 1025px) {
      top: ${toRem(43)};
      right: calc(42% - ${toRem(350)});
    }
  }
  .offers-mpo .focused.desktop-input {
    border-bottom: 2px solid ${theme.color.black};
  }
  .offers-mpo .focused .MuiFormControl-root .MuiAutocomplete-inputRoot.MuiInputBase-formControl {
    padding-right: ${toRem(25)};
  }
  .offers-mpo
    .MuiFormControl-root
    .MuiAutocomplete-inputRoot.MuiInputBase-formControl
    input[type='text'].MuiInputBase-input {
    font-family: ${theme.fontFamily.title};
    font-weight: 500;
    color: ${theme.color['dark-gray-14']};
    @media ${theme.mediaQuery.tablet} {
      padding-bottom: ${toRem(12.67)} !important;
    }
    @media ${theme.mediaQuery.desktop} {
      padding-bottom: ${toRem(12.67)} !important;
    }
  }
  .offers-mpo .dashed-border {
    &:after {
      height: calc(100% - ${toRem(52)});
      right: ${toRem(-24)};
      @media ${theme.mediaQuery.desktop} {
        right: ${toRem(-35)};
      }
    }
  }
  .offers-mpo .h-r-form-field-txt {
    font-weight: 500;
  }
  .offers-mpo {
    @media ${theme.mediaQuery.tablet} {
      background-color: white;
      margin-left: ${toRem(8)};
    }
    .desktop-input {
      border-bottom: 2px solid ${theme.color['dark-gray-6']};
      .MuiInputBase-input {
        font-family: ${theme.fontFamily.swiss};
        font-size: ${toRem(18)};
        color: ${theme.color['dark-gray-4']};
      }
    }
    .search-primary {
      box-shadow: none;
    }
    .search-primary-destination {
      padding: 0;
      @media ${theme.mediaQuery.desktop} {
        margin-left: -40px;
      }
    }
    .desktop-calendar-btn {
      border-bottom: 2px solid ${theme.color['dark-gray-6']};
      outline: none;
      @media ${theme.mediaQuery.desktop} {
        padding-bottom: ${toRem(10)};
        margin-left: ${toRem(62)};
        padding-left: 0;
      }
      &:focus-visible {
        outline: 2px auto ${theme.color.black} !important;
      }
    }
    @media ${theme.mediaQuery.allTablets} {
      .offers-mpo {
        margin: 0 ${toRem(32)};
        padding-bottom: ${toRem(22)};
      }
      .search-primary-destination {
        .show-popup {
          width: ${toRem(288)};
          .with-input {
            padding-left: ${toRem(24)};
          }
        }
      }
      .mpo-search-title {
        width: unset;
        margin: ${toRem(62)} auto ${toRem(12)};
        p {
          font-size: ${toRem(22)};
          line-height: ${toRem(26)};
          font-family: ${theme.fontFamily.title};
        }
      }
      button.find-btn {
        font-size: ${toRem(16)};
        line-height: ${toRem(22)};
        font-weight: 500;
        font-family: ${theme.fontFamily.title};
        height: ${toRem(40)} !important;
        color: ${theme.color.white};
        padding: 0;
      }
      .search-primary-container {
        font-family: ${theme.fontFamily.title};
        .with-input {
          padding-left: 0;
          label {
            font-weight: 500;
            font-family: ${theme.fontFamily.title};
            padding-bottom: ${toRem(4)};
          }
          input[type='text'].MuiInputBase-input {
            line-height: ${toRem(26)};
          }
        }
        .dashed-border::after {
          right: ${toRem(-20)};
        }
        .desktop-calendar-btn {
          padding-bottom: ${toRem(10)};
          margin-left: ${toRem(39)};
          .search__calendar-label {
            font-weight: 500;
            padding-bottom: ${toRem(4)};
          }
          .h-r-form-field-txt {
            line-height: ${toRem(26)};
          }
        }
      }
    }
    .search-secondary {
      box-shadow: none;
    }
    .search-secondary > div:first-child {
      padding-left: ${toRem(30)};
    }
  }
  .mpo-search-title {
    margin-bottom: ${toRem(18)};
    margin-top: ${toRem(62)};
    margin-left: auto;
    margin-right: auto;
    width: ${toRem(1147.4)};
    p {
      color: ${baseVariables.color.merch30};
      font-family: ${theme.fontFamily.swiss};
      font-size: ${toRem(30)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${toRem(32)};
    }
    @media ${theme.mediaQuery.mobileOnly} {
      padding-top: ${toRem(40)};
      margin-top: 0;
      margin-bottom: ${toRem(16)};
      width: unset;
      p {
        font-size: ${toRem(22)};
      }
    }
  }
  .offers-spo .MuiFormControl-root .MuiInputBase-formControl input[type='text'].MuiInputBase-input {
    border-bottom-color: transparent;
  }
  .offers-mpo .color-scheme1.find-btn {
    background: ${theme.color.black};
    &:hover {
      background: #494949;
    }
  }
  .preview-cta {
    pointer-events: none;
  }
  .desktop-calendar-btn {
    outline: none !important;
    &:focus-visible {
      outline: 2px auto ${theme.color.black} !important;
    }
  }
  /* spo calendar brand imlplementation */
  .offers-spo
    .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected:not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ):not(.DayPicker-Day--outside)
    .DayPicker-Day-value,
  .offers-mpo
    .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected:not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ):not(.DayPicker-Day--outside)
    .DayPicker-Day-value {
    background-color: var(--t-input-fields-bg-color) !important;
    color: var(--t-input-fields-fg-color) !important;
  }
  /* spo calendar brand imlplementation */
  .offers-spo
    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ),
  .offers-mpo
    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ) {
    background: var(--t-input-fields-bg-color) !important;
    color: var(--t-input-fields-fg-color);
  }
  /* spo calendar brand imlplementation */
  .offers-spo .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside),
  .offers-spo .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside),
  .offers-mpo .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside),
  .offers-mpo .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside) {
    background: var(--t-input-fields-bg-color) !important;
  }
  /* spo calendar brand imlplementation */
  .offers-spo
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside)
    .DayPicker-Day-value,
  .offers-spo
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--disabled)
    .DayPicker-Day-value,
  .offers-mpo
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside)
    .DayPicker-Day-value,
  .offers-mpo
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--disabled)
    .DayPicker-Day-value {
    background: var(--t-input-fields-bg-color) !important;
    color: var(--t-input-fields-fg-color) !important;
  }
  .search-primary-container .DayPicker-Weekday abbr {
    font-weight: 500;
  }
  &.sticky-search-container {
    position: fixed;
    width: 100%;
    top: 0 !important;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 1001;
    padding: ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding: 0 !important;
    }
  }
  &.search-container-top {
    padding-top: ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding-top: ${toRem(32)} !important;
    }
  }
  &.sticky-search-top {
    position: fixed;
    width: 100%;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 30;
    transition: top 0.4s ease 0s;
    padding: ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding: 0 !important;
    }
  }
`;
