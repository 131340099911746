import React, { useContext, useEffect, useRef } from 'react';
import { StyledRoomsAndGuestDiv } from './RoomsAndGuests.styles';
import Row from '../../atoms/RoomsAndGuestsRow/RoomsAndGuestsRow';
import { ChildrenAgeCollectionType, RoomsAndGuestsProps } from './RoomsAndGuests.types';
import { ViewportContext, ContentContext } from '../../../index.contexts';
import Popup from '../../atoms/Popup/Popup';
import PopupMain from '../../atoms/PopupMain/PopupMain';
import PopupHeader from '../../atoms/PopupHeader/PopupHeader';
import { FormValuesContext } from '../../../index.contexts';
import { setRoomsAndGuests } from '../../../store/store.actions';
import { ROOMS_AND_GUESTS } from '../../../constants/StoreConstants';
import { useTranslation } from 'react-i18next';
import PopupFooter from '../../atoms/PopupFooter/PopupFooter';
import { CustomSlider } from '@marriott/mi-ui-library-shop';
import CommonUtils from '../../../utils/CommonUtils';

const RoomsAndGuests: React.FC<RoomsAndGuestsProps> = ({
  onCancel,
  onChange: changeMobileState,
  isPublishUserSelection = false,
}) => {
  const compRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isMobileView = useContext(ViewportContext);
  const {
    maxChildAge,
    maxGuestsMsg,
    maxGuestPerRoom,
    roomListItems,
    roomsGuestsHead,
    rooms,
    room,
    guests,
    guest,
    adults,
    children,
    ageBasedRatesLabel,
    childAgeRequired,
    reset,
    lessThanLabel,
    ageLabel,
    applyLabel,
  } = useContext(ContentContext);
  const [popupOpenState, setPopupOpenState] = React.useState(false);

  const arrayInitializer = (length: number, key: string, incrementValue = 0): Array<Record<string, string>> =>
    Array.from({ length }, (_, i) => {
      return { [key]: `${i + incrementValue}` };
    });
  const roomsList = roomListItems as Array<{ roomQuantity: string }>;
  const roomsMinValue: { roomQuantity: string } = { ...roomsList?.[0] };
  const roomsMaxValue: { roomQuantity: string } = { ...roomsList?.[roomsList.length - 1] };

  const adultsList = arrayInitializer(+(maxGuestPerRoom as string), 'adultsQuantity', 1);
  const adultsMinValue = { ...adultsList[0] };

  const childrenList = arrayInitializer(+(maxGuestPerRoom as string), 'childrenQuantity');
  const childrenMinValue = { ...childrenList[0] };

  const childAgeList = arrayInitializer(+(maxChildAge as string) + 1, 'val', 0);
  childAgeList[0] = { val: lessThanLabel?.toLowerCase() };
  const childrenAgeMinValue = { ...childAgeList[0] };
  const childrenAgeMaxValue = { ...childAgeList[childAgeList.length - 1] };
  const totalGuests = +(maxGuestPerRoom as string);

  // get the initial rooms and guests values by checking the formValues
  const { formValues, setFormValues } = useContext(FormValuesContext);

  const roomsGuestsSavedData = formValues?.[ROOMS_AND_GUESTS];
  const roomsCountSaved = roomsGuestsSavedData?.roomsCount;
  const adultsCountSaved = roomsGuestsSavedData?.adultsCount;
  const childrenCountSaved = roomsGuestsSavedData?.childrenCount;
  const childrenAgesSaved = roomsGuestsSavedData?.childrenAges;
  const childrenAgesCollectionSaved = childrenAgesSaved?.map((val, index) => {
    const value = isMobileView && val !== '0' ? val : childAgeList[parseInt(val, 10)]?.['val'];
    return { id: index, value, isValueSet: value !== '-1' };
  });

  const [roomsCount, setRoomsCount] = React.useState<Record<string, string>>(
    roomsCountSaved ? { roomQuantity: roomsCountSaved.toString() } : roomsMinValue
  );
  const [adultsCount, setAdultsCount] = React.useState<Record<string, string>>(
    adultsCountSaved ? { adultsQuantity: adultsCountSaved.toString() } : adultsMinValue
  );
  const [childrenCount, setChildrenCount] = React.useState<Record<string, string>>(
    childrenCountSaved ? { childrenQuantity: childrenCountSaved.toString() } : childrenMinValue
  );

  const [childrenAgeCollection, setChildrenAgeCollection] = React.useState<Array<ChildrenAgeCollectionType>>(
    childrenAgesCollectionSaved ? childrenAgesCollectionSaved : []
  );

  // id for div element which describes popup on desktop
  const popupLabelId = CommonUtils.getElementId();

  useEffect(() => {
    if (popupOpenState) {
      setFocusOnFirstBtn();
    } else {
      if (!isMobileView) {
        applyBtnHandler();
      }
    }

    if (isPublishUserSelection && !isMobileView) {
      applyBtnHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOpenState, roomsCount]);

  useEffect(() => {
    if (!isMobileView) {
      applyBtnHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsCount, adultsCount, childrenAgeCollection, childrenCount]);

  useEffect(() => {
    // To focus the first interactive element
    if (isMobileView) {
      CommonUtils.focusFirstTabbable(compRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFocusOnFirstBtn = (): void => {
    const popupElemBtn = compRef.current?.querySelector('.rg-popup button') as HTMLElement;
    popupElemBtn?.focus();
  };

  //To update the children collection based on children row
  const updateChildrenCollection = (count: number): void => {
    if (childrenAgeCollection.length !== count) {
      const childrenAgeCollectionClone: Array<ChildrenAgeCollectionType> = childrenAgeCollection.map(element => {
        return { ...element };
      });

      //To handle decrement button click
      if (childrenAgeCollectionClone.length > count) {
        childrenAgeCollectionClone.pop();
      }
      //To handle increment button click
      if (childrenAgeCollectionClone.length < count) {
        const childToAdd = {
          id: 0,
          value: '-1', // set default value to -1 before user interaction
          isValueSet: false, // set this to true on first user interaction
        };
        if (childrenAgeCollectionClone.length > 0) {
          childToAdd.id = childrenAgeCollectionClone[childrenAgeCollectionClone.length - 1].id + 1;
        }
        childrenAgeCollectionClone.push(childToAdd);
      }
      setChildrenAgeCollection(childrenAgeCollectionClone);
    }
  };

  //To reset the values in all the rows
  const resetHandler = (e?: React.MouseEvent): void => {
    e?.preventDefault();
    setFocusOnFirstBtn();
    setRoomsCount(roomsMinValue);
    setAdultsCount(adultsMinValue);
    setChildrenCount(childrenMinValue);
    setChildrenAgeCollection([]);
  };

  //To handle rooms state count change
  const roomsChangeHandler = (type: string): void => {
    const i = roomsList.findIndex(ele => ele['roomQuantity'] === roomsCount['roomQuantity']);
    if (type === 'inc') {
      setRoomsCount({ ...roomsList[i + 1] });
    } else {
      setRoomsCount({ ...roomsList[i - 1] });
    }
  };

  //To handle adults state count change
  const adultsChangeHandler = (type: string): void => {
    const i = adultsList.findIndex(ele => ele['adultsQuantity'] === adultsCount['adultsQuantity']);
    if (type === 'inc') {
      setAdultsCount({ ...adultsList[i + 1] });
    } else {
      setAdultsCount({ ...adultsList[i - 1] });
    }
  };

  //To handle children state count change
  const childrenChangeHandler = (type: string): void => {
    const i = childrenList.findIndex(ele => ele['childrenQuantity'] === childrenCount['childrenQuantity']);
    if (type === 'inc') {
      setChildrenCount({ ...childrenList[i + 1] });
      updateChildrenCollection(parseInt(childrenList[i + 1]['childrenQuantity'], 10));
    } else {
      setChildrenCount({ ...childrenList[i - 1] });
      updateChildrenCollection(parseInt(childrenList[i - 1]['childrenQuantity'], 10));
    }
  };

  //To handle each child state count change
  const childAgeChangeHandler = (index: number, type?: string, age?: number): void => {
    const childrenAgeCollectionClone: Array<ChildrenAgeCollectionType> = childrenAgeCollection.map(element => {
      return { ...element };
    });
    // if value is not already set then set the value to minimum age value
    if (!childrenAgeCollectionClone[index].isValueSet) {
      childrenAgeCollectionClone[index].isValueSet = true;
      childrenAgeCollectionClone[index].value = childrenAgeMinValue['val'];
    } else {
      const i = childAgeList.findIndex(ele => ele['val'] === childrenAgeCollectionClone[index].value);
      if (type) {
        if (type === 'inc') {
          childrenAgeCollectionClone[index].value = childAgeList[i + 1]['val'];
        } else {
          childrenAgeCollectionClone[index].value = childAgeList[i - 1]['val'];
        }
      }
    }
    if (typeof age !== 'undefined') {
      // store child's age in the clone array
      childrenAgeCollectionClone[index].value = childAgeList[age]['val'];
    }
    // update the chidlren age collection
    setChildrenAgeCollection(childrenAgeCollectionClone);
  };

  // To handle adults and following rows hide
  const checkHideRows = (): boolean => {
    if (roomsList?.findIndex(ele => ele.roomQuantity === roomsCount['roomQuantity']) >= 4) {
      return false;
    }
    return true;
  };

  // to show popup on desktop on button click
  const showPopup = (): void => {
    setPopupOpenState(true);
  };

  /**
   * returns the string format of rooms and guest count for display
   * @param roomsCount
   * @param adultsCount
   * @param childrenCount
   */
  const getRoomsAndGuestCount = (roomsCount: string, adultsCount: number, childrenCount: number): string => {
    const guestCount = adultsCount + childrenCount;
    const guestText = guestCount > 1 ? guests : guest;
    const roomsText = roomsCount !== '1' ? rooms : room;
    if (checkHideRows()) {
      return `${roomsCount} ${roomsText}, ${guestCount} ${guestText}`;
    } else {
      return `${roomsCount} ${roomsText}`;
    }
  };

  // To check total guests count (adults + children)
  const totalGuestsCheck = (): boolean => {
    return parseInt(adultsCount['adultsQuantity'], 10) + parseInt(childrenCount['childrenQuantity'], 10) >= totalGuests;
  };

  // To hide reset handler when initial values are set
  const resetHideCheck = (): boolean => {
    return (
      roomsCount['roomQuantity'] !== roomsMinValue.roomQuantity ||
      adultsCount['adultsQuantity'] !== adultsMinValue['adultsQuantity'] ||
      childrenCount['childrenQuantity'] !== childrenMinValue['childrenQuantity']
    );
  };

  const applyBtnHandler = (): void => {
    // when Apply button is clicked, update the FormValues accordingly
    setFormValues(
      setRoomsAndGuests({
        ...(formValues?.[ROOMS_AND_GUESTS] ?? {}),
        roomsCount: roomsCount['roomQuantity'],
        adultsCount: parseInt(adultsCount['adultsQuantity'], 10),
        childrenCount: parseInt(childrenCount['childrenQuantity'], 10),
        childrenAges: childrenAgeCollection.map(obj => obj.value),
      })
    );
    // when Apply button is clicked, update mobile state
    if (changeMobileState) {
      changeMobileState();
    }
  };

  const keyHandler = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (!event.shiftKey && event.keyCode === 9) {
      setPopupOpenState(false);
    }
  };
  const tabKeyFlag =
    roomsMinValue.roomQuantity === roomsCount['roomQuantity'] &&
    adultsMinValue['adultsQuantity'] === adultsCount['adultsQuantity'] &&
    childrenMinValue['childrenQuantity'] === childrenCount['childrenQuantity']
      ? 'true'
      : 'false';
  return (
    <StyledRoomsAndGuestDiv ref={compRef} className="browser-outline">
      {isMobileView ? (
        ''
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          <button type="button" className="search__rates t-font-s" onClick={showPopup} aria-expanded={popupOpenState}>
            {getRoomsAndGuestCount(
              roomsCount['roomQuantity'],
              +adultsCount['adultsQuantity'],
              +childrenCount['childrenQuantity']
            )}
            <span className="icon-dropdown-down"></span>
          </button>
        </>
      )}
      {isMobileView || popupOpenState ? (
        <Popup
          className="rg-popup custom-scrollbar"
          show={true}
          handleBlur={!isMobileView}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          role={!isMobileView ? 'dialog' : ''}
          labelledBy={!isMobileView ? popupLabelId : ''}
        >
          {!isMobileView ? (
            <div className="sr-only" id={popupLabelId}>
              {`${t('selectLabel')} ${roomsGuestsHead}`}
            </div>
          ) : (
            <PopupHeader
              cancelClickHandler={onCancel}
              mainText={getRoomsAndGuestCount(
                roomsCount['roomQuantity'],
                +adultsCount['adultsQuantity'],
                +childrenCount['childrenQuantity']
              )}
              labelText={roomsGuestsHead}
            />
          )}
          <PopupMain className="rg-main">
            <Row
              title={rooms}
              showCurrentValue={true}
              currentValue={roomsCount['roomQuantity']}
              incrementHandler={(): void => roomsChangeHandler('inc')}
              decrementHandler={(): void => roomsChangeHandler('dec')}
              incrementDisable={roomsMaxValue.roomQuantity === roomsCount['roomQuantity']}
              decrementDisable={roomsMinValue.roomQuantity === roomsCount['roomQuantity']}
              setPopupOpenState={setPopupOpenState}
            />
            {checkHideRows() ? (
              <>
                <Row
                  title={adults}
                  subTitle={`(${maxGuestsMsg?.replace('{x}', maxGuestPerRoom as string)})`}
                  showCurrentValue={true}
                  currentValue={adultsCount['adultsQuantity']}
                  incrementHandler={(): void => adultsChangeHandler('inc')}
                  decrementHandler={(): void => adultsChangeHandler('dec')}
                  incrementDisable={totalGuestsCheck()}
                  decrementDisable={adultsMinValue['adultsQuantity'] === adultsCount['adultsQuantity']}
                />
                <Row
                  title={children}
                  subTitle={`(${maxGuestsMsg?.replace('{x}', maxGuestPerRoom as string)})`}
                  showCurrentValue={true}
                  currentValue={childrenCount['childrenQuantity']}
                  incrementHandler={(): void => childrenChangeHandler('inc')}
                  decrementHandler={(): void => childrenChangeHandler('dec')}
                  incrementDisable={totalGuestsCheck()}
                  decrementDisable={childrenMinValue['childrenQuantity'] === childrenCount['childrenQuantity']}
                  isNotUpdated={tabKeyFlag}
                />
                {childrenAgeCollection.length > 0 ? (
                  <div className="children-age-heading t-font-s">({ageBasedRatesLabel})</div>
                ) : (
                  ''
                )}
                {childrenAgeCollection.map((element: ChildrenAgeCollectionType, index: number) => (
                  <div key={element.id} className="children-age-row">
                    {!isMobileView ? (
                      <>
                        <span>{childAgeRequired?.replace('{x}', (index + 1).toString())}</span>
                        <Row
                          title={element.isValueSet ? `${element.value}` : ageLabel}
                          showCurrentValue={false}
                          childrenFlag={true}
                          currentValue={element.value}
                          incrementHandler={(): void => childAgeChangeHandler(index, 'inc')}
                          decrementHandler={(): void => childAgeChangeHandler(index, 'dec')}
                          incrementDisable={childrenAgeMaxValue['val'] === element.value}
                          decrementDisable={!element.isValueSet || childrenAgeMinValue['val'] === element.value}
                        />
                      </>
                    ) : (
                      <>
                        <span>
                          {childAgeRequired?.replace('{x}', (index + 1).toString()) +
                            ' ' +
                            (element.isValueSet ? `${element.value}` : ageLabel)}
                        </span>
                        <CustomSlider
                          minValue={0}
                          maxValue={childAgeList.length - 1}
                          minLabel={element.isValueSet ? childrenAgeMinValue['val'] : ageLabel}
                          maxLabel={childrenAgeMaxValue['val']}
                          value={parseInt(element.value, 10) || 0}
                          valueChangeHandler={(value: number): void => childAgeChangeHandler(index, undefined, value)}
                        />
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : null}
          </PopupMain>
          {isMobileView ? (
            resetHideCheck() ? (
              <PopupFooter
                primaryBtnLabel={applyLabel}
                resetLabel={reset}
                resetClickHandler={resetHandler}
                primaryBtnClickHandler={applyBtnHandler}
              />
            ) : (
              <PopupFooter primaryBtnLabel={applyLabel} primaryBtnClickHandler={applyBtnHandler} />
            )
          ) : resetHideCheck() ? (
            <button
              type="reset"
              onClick={resetHandler}
              // this classname will helps in compensating the bottom margin
              // for IE and edge browser
              onKeyDown={keyHandler}
              className={`${Number(childrenCount['childrenQuantity']) > 1 ? 'addBtmMargin' : ''} t-text-color`}
            >
              {reset}
            </button>
          ) : null}
        </Popup>
      ) : (
        ''
      )}
    </StyledRoomsAndGuestDiv>
  );
};

export default RoomsAndGuests;
