import { Types } from '@marriott/mi-ui-library';
import { trackingDetailArr, config } from '../constants/ApplicationConstants';
import { CookieUtils } from '@marriott/mi-ui-library-shop';
declare global {
  interface Window {
    impressionArr: string[];
    impressionTrack: (value: string) => Record<string, string>;
    atCArray: Array<string>;
    atMArray: Array<string>;
    dataLayer?: Record<string, unknown>;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getClickTrackValue = (linkType: string, trackingProperties?: Types.TrackingPropsInterface, ref?: any) => {
  let trackPosAndDesc = '';
  const {
    trackingContentPosition,
    isImpressionTrack,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    isCoBrand,
    isClickTrack,
    atCCeVar48,
    compName,
  } = ref?.current.dataset || {};
  const RefCurrent = ref?.current;
  if (isCoBrand === 'true') {
    if (atCCeVar48) {
      if (
        isClickTrack === 'true' &&
        isImpressionTrack === 'true' &&
        !RefCurrent?.classList.contains('no-merch-track')
      ) {
        // hero cta link tag needs to include custom attribute custom_click_track_value where the value is the atCTAclickTrackVal variable
        let atCTAclickTrackVal = compName === 'herobanner' ? `${config.HIO}|` : `${trackingContentPosition}|`;
        atCTAclickTrackVal += `${atCCeVar48?.split('-')[4] || ''}${
          atCCeVar48?.split('-')[5] || ''
        }|${linkType}|event167,event271,evar48=${atCCeVar48}`;
        RefCurrent?.setAttribute('data-custom_click_track_value', atCTAclickTrackVal);
      }
      // update href value
      RefCurrent?.setAttribute('href', getHref(RefCurrent.dataset, RefCurrent.getAttribute('href') ?? ''));
      if (!RefCurrent?.classList.contains('no-impression') && typeof window.impressionTrack !== 'undefined') {
        window.impressionTrack('event168|event270|evar48=' + atCCeVar48);
      }
    }
  } else {
    if (isClickTrack === 'true' && isImpressionTrack === 'true') {
      let trackPosAndDesc = compName === 'herobanner' ? `${config.HIO}|` : `${trackingContentPosition}|`;

      if (trackingDescription) {
        trackPosAndDesc += `${trackingDescription}|`;
      }
      RefCurrent?.setAttribute(
        'data-custom_click_track_value',
        `${trackPosAndDesc}${linkType}|event167,list3=${
          compName === 'herobanner' ? `hpHero` : `${trackingContentPosition}`
        }${trackingProperties?.additionalTrackingVariables ? getTrackingProps(trackingProperties) : ''}`
      );
    }
    if (isClickTrack === 'true' && isImpressionTrack === 'false') {
      if (trackingContentPosition) {
        trackPosAndDesc += `${trackingContentPosition}|`;
      }
      if (trackingDescription) {
        trackPosAndDesc += `${trackingDescription}|`;
      }
      RefCurrent?.setAttribute(
        'data-custom_click_track_value',
        `${trackPosAndDesc}${linkType}${
          trackingProperties?.additionalTrackingVariables ? getTrackingProps(trackingProperties) : ''
        }`
      );
    }
    if (
      !RefCurrent?.classList.contains('no-impression') &&
      isImpressionTrack === 'true' &&
      (trackingContentPosition || trackingDescription || trackingOfferType || trackingTag)
    ) {
      if (typeof window.atMArray !== 'object') {
        window.atMArray = [];
      }
      window.atMArray.push(
        `${trackingContentPosition},type=${trackingOfferType},msg=${trackingDescription},tag=${trackingTag}`
      );
    }
  }
  // update atCArray
  setMerchImpTrack(trackingProperties, RefCurrent);
};

export const getTrackingProps = (trackingProperties?: {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  isClickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
}) => {
  let trackingVar = ``;
  if (trackingProperties?.trackingOfferType) {
    trackingVar += `,type=${trackingProperties?.trackingOfferType},msg=${trackingProperties?.trackingDescription}`;
  }
  if (trackingProperties?.trackingTag) {
    trackingVar += `,tag=${trackingProperties?.trackingTag}`;
  }
  return trackingVar;
};

export const trackImpression = (
  trackingProperties: Types.TrackingPropsInterface,
  text: string,
  preTrackVal?: string
) => {
  if (trackingProperties?.isCoBrand) {
    return;
  }
  const tracking = getTrackingProperties(trackingProperties || {}, ',');
  const preTrackValue = preTrackVal ? preTrackVal + '|' : '';
  if (trackingProperties?.impressionTrack && !window.impressionArr?.includes(`${tracking.trackingString}${text}`)) {
    const ImpressionEventType = trackingProperties?.['impressionEventType'] || 'event168';
    if (window?.impressionTrack) {
      window.impressionTrack(
        `${preTrackValue}${ImpressionEventType}|${tracking?.payloadType}=${tracking.trackingString}`
      );
      if (typeof window.impressionArr === 'object') {
        window.impressionArr?.push(`${tracking.trackingString}${text}`);
      } else {
        window.impressionArr = [`${tracking.trackingString}${text}`];
      }
    }
  }
};

const getTrackingProperties = (trackingProperties: Types.TrackingPropsInterface, seperator: string) => {
  const trackingArr: Array<string> = [];
  trackingDetailArr.forEach(item => {
    if (item.text) {
      trackingArr.push(`${item.pre}${trackingProperties?.[item.text]}`);
    }
  });
  return {
    trackingString: trackingArr.join(seperator),
    payloadType: trackingProperties?.payloadType || 'list3',
  };
};

const getHref = (data: DOMStringMap, atCCctaUrl: string): string => {
  /* creates CTA dynamic URL value in variable atCCctaUrl, use this as the CTA button URL */
  const atCCaltID = window?.dataLayer?.['mr_id_alternate'] || '';
  const atCCmcvID = mtGCV(config.gcv) || '';
  let URL;
  if (data['atCCeVar48']?.split('-')[4] === config.code1 || data['atCCeVar48']?.split('-')[4] === config.code2) {
    URL =
      config.ctaURL +
      encodeURIComponent(
        atCCctaUrl + '&alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48']
      );
  } else if (atCCctaUrl?.includes('?')) {
    URL = atCCctaUrl + '&alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48'];
  } else {
    URL = atCCctaUrl + '?alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48'];
  }
  return URL;
};

const mtGCV = (cname: string): string => {
  const x: Array<string> = mtGC(cname).split('|');
  let i;
  for (i = 0; i < x.length; i++) {
    if (x[i] === config.cookie) {
      return x[i + 1];
    }
  }
  return '';
};

const mtGC = (cname: string): string => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const cnameValue = c.substring(name.length, c.length);
      try {
        return decodeURIComponent(cnameValue);
      } catch {
        return cnameValue;
      }
    }
  }
  return '';
};

const setMerchImpTrack = (data: Types.TrackingPropsInterface | undefined, ref?: HTMLElement): void => {
  // sets merchandising impression tracking
  if (
    data?.['trackingContentPosition'] &&
    data?.['trackingDescription'] &&
    (data?.['compName'] === 'herobanner' || !ref?.classList.contains('no-impression')) &&
    data?.['impressionCount']
  ) {
    const cname = config.cname;
    const ctemp = CookieUtils.getCookie(cname) || '';
    const cvalue = `${data?.['trackingContentPosition']}-${data?.['trackingDescription']}`;
    if (ctemp.includes(cvalue) !== true) {
      if (typeof window.atCArray !== 'object') {
        window.atCArray = [];
      }
      window.atCArray.push(cvalue);
    }
  }
};

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);
export function getCurrentLocation(callback: CallableFunction): void {
  let currentLocation: Record<string, string>;

  // fetching the latitude and longitude
  navigator.geolocation.getCurrentPosition(
    (location: GeolocationPosition) => {
      const lat = location.coords.latitude;
      const lng = location.coords.longitude;

      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };
        // using Goggle Maps API reverse geocoding service to convert latlng to location details
        geocoder.geocode(
          { location: latlng },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (results: any, status: any) => {
            if (status === 'OK') {
              if (results[0]) {
                // only store the data required for hidden fields and input field
                currentLocation = {
                  placeId: results[0].place_id,
                  address: results[0].formatted_address,
                  types: results[0].types.join(),
                };
                // using callback to pass the data
                callback(currentLocation);
              } else {
                window.alert('No location available.');
              }
            }
          }
        );
      }
    },
    (error: GeolocationPositionError) => {
      window.alert('Please enable geolocation in your browser settings.');
      return error;
    }
  );
}
