export const PANORAMIC_ASPECT_RATIO = 'PANO';
export const OFFER_IMAGE_CACHE_DOMAIN = 'https://cache.marriott.com';
export const ANCILLARY_PRODUCT_OFFER = 'Ancillary Product Offer';
export const SINGLE_PROPERTY_OFFER = 'single-property-offer';
export const BUTTON_CLICK_TRACK_VALUE = 'AncillaryOffer|Book Now|external';
export const SHOP_NOW = 'shopNow';
export const BOOK_NOW = 'Book Now';
export const API_TIMEOUT = 2500;
export const DEFAULT_API_ACCEPT_LANGUAGE = 'en-US';
export const API_SSL_CHECK_FALSE = 'FALSE';
export const ARROW_UP = 'icon-arrow-up';
export const ARROW_DOWN = 'icon-arrow-down';
export const DETAILS_SHOWN = 'details-visible';
export const DETAILS_HIDDEN = 'details-hidden';
export const OFFER_DETAILS_OPERATION_SIGNATURE = '';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const OFFER_DETAILS_OPERATION_NAME = 'phoenixOfferDetails';
export const OFFER_SEARCH_CLIENT_NAME = 'phoenix_offers';
export const OFFER_DETAILS_PREVIEW_OPERATION_NAME = '';

export const ASPECT_RATIO = {
  Wide: 'hor-wide',
  Feature: 'hor-feat',
  Classic: 'hor-clsc',
  Pano: 'hor-pano',
  Square: 'sq',
};
export const IMG_TYPE_JPG = '.jpg';
export const CLASSIC_ASPECT_RATIO = 'CLASSIC';
export const WIDE_ASPECT_RATIO = 'WIDE';
export const SQUARE_ASPECT_RATIO = 'SQUARE';
export const EXPLORE_THIS_OFFER = 'Explore this Offer';
export const LOCALE_SPECIFIC_MAPPING = {
  en_US: { url: 'https://www.marriott.com', hreflang: 'en-US' },
  it_IT: { url: 'https://www.marriott.it', hreflang: 'it-IT' },
  ru_RU: { url: 'https://www.marriott.com.ru', hreflang: 'ru-RU' },
  en_GB: { url: 'https://www.marriott.co.uk', hreflang: 'en-GB' },
  ko_KR: { url: 'https://www.marriott.co.kr', hreflang: 'ko-KR' },
  pt_BR: { url: 'https://www.marriott.com.br', hreflang: 'pt-BR' },
  zh_CN: { url: 'https://www.marriott.com.cn', hreflang: 'zh-CN' },
  fr_FR: { url: 'https://www.marriott.fr', hreflang: 'fr-FR' },
  ja_JP: { url: 'https://www.marriott.co.jp', hreflang: 'ja-JP' },
  de_DE: { url: 'https://www.marriott.de', hreflang: 'de-DE' },
  pt_PT: { url: 'https://www.marriott.pt', hreflang: 'pt-PT' },
  es_ES: { url: 'https://www.espanol.marriott.com', hreflang: 'es' },
};
export const SINGLE_BRAND_MULTI_PROP_OFFER = 'single-brand-multi-property-offer';
export const MULTI_BRAND_MULTI_PROP_OFFER = 'multi-brand-multi-property-offer';
export const MPO_SBO_BUTTON = 'mpo-sbo-button';
export const MPO_MBO_BUTTON = 'mpo-mbo-button';
export const MPO_MBO_STYLES = 'mpo-mbo-styles';
export const COLOR_SCHEME_7 = 'color-scheme7';
