// Styles for OfferCardHorizontalMini go here.
import styled from 'styled-components';

export const StyledOfferCardHorizontalMini = styled.div`
  .miniCardImage {
    height: unset;
  }
  .offers-card-hr-mini-img {
    padding: 16px 0 16px 16px !important;
  }
`;
