import styled from 'styled-components';
import { mediaQuery, font } from '../../../../../utils/styles';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${baseVariables.color.neutral20};
  margin-left: ${toRem(24)};
  margin-right: ${toRem(24)};
  @media ${mediaQuery.tablet} {
    margin-left: ${toRem(0)};
    margin-right: ${toRem(0)};
  }
  .row-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span:nth-child(2) {
      color: ${baseVariables.color.neutral40};
      font-size: ${toRem(12)};
      letter-spacing: 0;
      line-height: ${toRem(18)};
      font-family: ${font.Swiss721BTRegular};
    }
  }
`;
