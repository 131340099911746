import React, { FC } from 'react';
import { PopupFooterProps } from './PopupFooter.types';
import { StyledPopupFooterDiv } from './PopupFooter.styles';
import { Button } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import TertiaryCta from '../TertiaryCta/TertiaryCta';

const PopupFooter: FC<PopupFooterProps> = ({
  primaryBtnLabel,
  primaryBtnClickHandler,
  resetClickHandler,
  resetLabel,
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(primaryBtnLabel || resetLabel) && (
        <StyledPopupFooterDiv className="brand-css">
          {primaryBtnLabel && (
            <Button
              buttonCopy={primaryBtnLabel}
              type={ButtonTypeVariation.Button}
              id={'findHotelBtn'}
              className={clsx('m-button-l m-button-primary brand-css')}
              callback={primaryBtnClickHandler}
            />
          )}
          {resetLabel && (
            <TertiaryCta clickHandler={resetClickHandler} label={resetLabel} className="reset-btn t-text-color" />
          )}
        </StyledPopupFooterDiv>
      )}
    </>
  );
};

export default PopupFooter;
