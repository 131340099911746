import React, { FC, lazy, Suspense } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledOffersHero, StyledOffersHeroPreviewMode } from './OffersHero.styles';
import { OfferHeroProps } from './OffersHero.types';
import {
  ANCILLARY_PRODUCT_OFFER,
  MULTI_BRAND_MULTI_PROP_OFFER,
  SINGLE_BRAND_MULTI_PROP_OFFER,
  SINGLE_PROPERTY_OFFER,
} from '../../constants/OffersHeroConstants';

// Use named rather than default exports.
const OffersHeroReactWrapper: FC<OfferHeroProps> = props => {
  const { model, offersData } = props;
  const OffersHeroSingleProperty = lazy(() => import('./OffersHeroSingleProperty'));
  const OffersHeroMultiProperty = lazy(() => import('./OffersHeroMultiProperty'));

  const OfferHeroComponent: React.FC = () => {
    switch (model?.variation) {
      case SINGLE_BRAND_MULTI_PROP_OFFER:
        return (
          <Suspense fallback={<></>}>
            <OffersHeroMultiProperty
              modelObj={model}
              offersHeroProps={offersData}
              variation={SINGLE_BRAND_MULTI_PROP_OFFER}
            />
          </Suspense>
        );
      case MULTI_BRAND_MULTI_PROP_OFFER:
        return (
          <Suspense fallback={<></>}>
            <OffersHeroMultiProperty
              modelObj={model}
              offersHeroProps={offersData}
              variation={MULTI_BRAND_MULTI_PROP_OFFER}
            />
          </Suspense>
        );
      case SINGLE_PROPERTY_OFFER:
        return (
          <Suspense fallback={<></>}>
            <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} variation={SINGLE_PROPERTY_OFFER} />
          </Suspense>
        );
      case ANCILLARY_PRODUCT_OFFER:
        return (
          <Suspense fallback={<></>}>
            <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<></>}>
            <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} />
          </Suspense>
        );
    }
  };

  return offersData?.isPreview === 'true' ? (
    <StyledOffersHeroPreviewMode data-testid="offers-hero" data-component-name="o-offers-hero">
      <OfferHeroComponent />
    </StyledOffersHeroPreviewMode>
  ) : (
    <StyledOffersHero data-testid="offers-hero" data-component-name="o-offers-hero">
      <OfferHeroComponent />
    </StyledOffersHero>
  );
};

export const OffersHeroWrapperConfig = {
  emptyLabel: 'offersHero',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-hero`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersHero = (props: any) => (
  <EditableComponent config={OffersHeroWrapperConfig} {...props}>
    <div className="m-container-fullbleed">
      <OffersHeroReactWrapper {...props} />
    </div>
  </EditableComponent>
);
