import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledPopupFooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  bottom: 0;
  padding: ${toRem(22)} ${toRem(24)} ${toRem(35)};
  width: 100%;
  background-color: ${theme.color.white};
  border-top: ${toRem(1)} solid ${theme.color['light-gray-3']};

  .reset-btn {
    text-align: center;
    padding: 0;
    margin-top: ${toRem(11)};
    background: transparent;
    border: none;
  }

  @media ${theme.mediaQuery.tablet} {
    display: none;
  }
`;
