import { CustomOverlay } from '@uiw/react-baidu-map';
import { ClusterMarker } from './BaiduMap.styles';
import { ClusterBubbleProps } from './BaiduMap.types';

const ClusterBubble = ({ position, showRing = false, label, ...props }: ClusterBubbleProps) => {
  return (
    <CustomOverlay position={position}>
      <ClusterMarker showRing={showRing} {...props}>
        {label}
      </ClusterMarker>
    </CustomOverlay>
  );
};

export default ClusterBubble;
