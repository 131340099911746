import styled from 'styled-components';
import { theme, toRem } from '@marriott/mi-ui-library-shop';

export const StyledRecentViewAndSearch = styled.div`
  margin-top: ${toRem(12)};
  @media ${theme.mediaQuery.mobileOnly} {
    padding-bottom: ${toRem(23)};
    margin-top: 0;
    height: calc(100vh - ${toRem(109)});
    background-color: ${theme.color['white-smoke']};
    overflow: auto;
  }
  .clear-recent-search-btn {
    border: none;
    color: var(--t-link-text-color);
    margin-bottom: ${toRem(32)};
    @media ${theme.mediaQuery.tablet} {
      margin-left: ${toRem(24)};
    }
    @media ${theme.mediaQuery.desktop} {
      margin-left: ${toRem(32)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      margin-left: ${toRem(40)};
    }
  }
  ul li a span.icon-clock:before {
    color: var(--t-icon-color-decorative);
  }
`;
