import React, { lazy, Suspense } from 'react';

import { ExploreDestinationsProps } from './ExploreDestinations.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const ExploreDestinationsWrapperConfig = {
  emptyLabel: 'ExploreDestinations',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/exploredestinations`,
};
const ExploreDestinationsWrapper = lazy(() => import('./ExploreDestinationsWrapper'));
export const ExploreDestinationsComp: React.FC<ExploreDestinationsProps> = props => {
  return (
    <div className="explore-destinations-wrapper">
      <Suspense fallback={<></>}>
        <ExploreDestinationsWrapper {...props} />
      </Suspense>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExploreDestinations = (props: any) => (
  <div data-testid="offers-ExploreDestinations" data-component-name="o-offers-ExploreDestinations">
    <EditableComponent config={ExploreDestinationsWrapperConfig} {...props}>
      <ExploreDestinationsComp {...props} />
    </EditableComponent>
  </div>
);
