import React, { FC, useState, useEffect, ReactElement, useContext, useRef, RefObject } from 'react';
import TextField from '@material-ui/core/TextField';
import { ViewportContext, ContentContext, FormValuesContext, FocusContext } from '../../../index.contexts';
import { DestinationProps, SuggestedPlacesNode } from './Destination.types';
import { StyledDestination } from './Destination.styles';
import RecentViewAndSearch from '../RecentViewAndSearch/RecentViewAndSearch';
import Popup from '../../atoms/Popup/Popup';
import PopupHeader from '../../atoms/PopupHeader/PopupHeader';
import PopupMain from '../../atoms/PopupMain/PopupMain';
import { setDestination } from '../../../store/store.actions';
import { DESTINATION, DATES, DESTINATION_HIDDEN_FIELDS } from '../../../constants/StoreConstants';
import { VARIATION_DESKTOP } from '../../../constants/ApplicationConstants';
import CommonUtils from '../../../utils/CommonUtils';
import { Autocomplete } from '@material-ui/lab';
import { getUrlParam } from '../../../utils/OfferUtils';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { getMPOFormattedDate } from '../../../utils/DateUtils';
import { phoenixOfferSuggestedPlacesQuery } from '@marriott/mi-offers-graphql';
import { useLazyQuery } from '@apollo/client';
import { Button, Text, Types, ButtonTypeVariation } from '@marriott/mi-ui-library';
import { clsx } from 'clsx';
import { UXL_ERROR_POLICY } from '../../../../../api/apiConstants';
import { ALLOWED_TAGS_IN_DESCRIPTION } from '../../../../../constants/CommonConstants';
import DOMPurify from 'isomorphic-dompurify';

const DestinationMPO: FC<DestinationProps> = ({ onCancel, onChange: changeMobileState }) => {
  const { mobilePlaceholderText, desktopPlaceholderText, destination, destinationSearchResult } =
    useContext(ContentContext);
  const { formValues, setFormValues, setIsPopupOpen } = useContext(FormValuesContext);
  const hiddenFieldsValues = formValues?.[DESTINATION_HIDDEN_FIELDS];
  const { setFocusComp } = useContext(FocusContext);
  const searchText = formValues?.[DESTINATION]?.displayText;
  const isMobileView = useContext(ViewportContext);
  // flag for openning the popup
  const [openPopup, setOpenPopupState] = useState(false);
  // value of user typed in
  const [inputValue, setInputValue] = useState<string>('');
  const [optionsList, setOptionsList] = useState<SuggestedPlacesNode[] | string[]>([]);
  // const [optionsList, setOptionsList] = useState<string[]>([]);
  const [firstDestOptn, setFirstDestOptn] = useState({
    description: '',
    placeId: '',
  });
  // value of input field, from choosing the suggestion
  const [value, setValue] = useState<string | null>(searchText ? searchText : null);
  const [showRecentViewed, setRecentViewed] = useState(true);
  const [timerList, setTimerList] = useState<ReturnType<typeof setTimeout>[]>([]);
  // for unique id
  const timestamp = new Date().getTime();
  const formId = timestamp.toString() + '-search-destination';
  const compRef = useRef<HTMLDivElement>(null);
  // use ref to access the current inputValue during setTimeout call for fetching auto suggestions
  const inputValueRef = useRef(inputValue);
  const [destinationOnFocus, setDestinationOnFocus] = useState(false);
  const [variation, setVariation] = React.useState<string>(CommonUtils.getVariation());
  const isServer = !(typeof window != 'undefined' && window.document);
  const { requestId, acceptLang } = useContext(ContentContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processSuggestionListdata = (suggestionListData: any) => {
    const srElm = document.querySelector('.screen-reader-text');
    if (suggestionListData && suggestionListData.suggestedPlaces !== undefined) {
      if (suggestionListData.suggestedPlaces.total === 0) {
        setOptionsList([]);
        // if there's no search results, screen reader should announce
        // no results found.
        if (srElm) {
          setScreenReaderText(srElm, 'noResultFound');
        }
      } else if (suggestionListData.suggestedPlaces.total > 0 && inputValueRef.current) {
        // use valRef.current to check the current inputValue at the end of timeout
        // if inputValue is cleared, do not update optionsList
        // if there is auto-suggest, hide top destinations/search history
        setRecentPid(suggestionListData.suggestedPlaces.edges[0]?.node?.placeId);
        setRecentViewed(false);
        if (suggestionListData.suggestedPlaces.edges) {
          setOptionsList(suggestionListData.suggestedPlaces.edges);
          // screen reader will announce how many results are present
          if (srElm) {
            setScreenReaderText(
              srElm,
              destinationSearchResult?.replace('{x}', `${suggestionListData.suggestedPlaces.total}`)
            );
          }
        }
      }
    }
  };

  const [getSuggestionList, { data: _suggestionListData, error: _suggestionListError }] = useLazyQuery(
    phoenixOfferSuggestedPlacesQuery,
    {
      fetchPolicy: isServer ? 'network-only' : 'cache-first',
      errorPolicy: UXL_ERROR_POLICY,
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': acceptLang,
        },
      },
      onCompleted: processSuggestionListdata,
    }
  );
  /** Add dates value computation */
  // const datesValues = formValues?.[DATES] ?? {};
  // const { fromDate, toDate } = datesValues;
  // const dateFormat = (localDateFormat as string) ?? DATE_FORMAT_VALUE;
  // const fromDateValue = getValueFromDate(fromDate, dateFormat);
  // const toDateValue = getValueFromDate(toDate, dateFormat);
  // const fromToDateValue = toDateValue;
  //numberInParty is total number of persons.
  const childCount = formValues?.roomsAndGuests?.childrenCount;
  const numberInParty =
    (formValues?.roomsAndGuests?.adultsCount ? formValues.roomsAndGuests.adultsCount : 0) + (childCount ?? 0);

  const [recentPId, setRecentPid] = useState('');
  const [onloadInput, setOnloadInput] = useState<string>('');
  // Utility to set variation based on true value
  const setSuccessVariation = (flag: boolean, variation: string): void => {
    if (flag) {
      setVariation(variation);
    }
  };
  //get onloadInput value
  useEffect(() => {
    setOnloadInput(getUrlParam());
  }, []);

  //get onloadInput value
  useEffect(() => {
    setOnloadInput(getUrlParam());
  }, [0]);
  // update variation based on viewport size change
  useEffect(() => {
    // set viewport change event and return callback function to clear events
    const updateViewportHandler = CommonUtils.addRemoveResizeHandler(setSuccessVariation);

    return (): void => {
      // remove change event listner on component unmount
      updateViewportHandler();
    };
  }, []);

  //useEffect to set inputValue from url parameters.
  useEffect(() => {
    onloadInput && setInputValue(onloadInput);
  }, [onloadInput]);

  //deeplinking calling evenutil which inturns calls offerPropertiesService
  useEffect(() => {
    const searchFormData = {
      latitude: hiddenFieldsValues?.latitude ?? '',
      longitude: hiddenFieldsValues?.longitude ?? '',
      stateCode: hiddenFieldsValues?.state ?? '',
      countryCode: hiddenFieldsValues?.country ?? '',
      startDate: getMPOFormattedDate(),
      endDate: getMPOFormattedDate(),
      quantity: formValues?.roomsAndGuests?.roomsCount,
      numberInParty: numberInParty,
      childAges: formValues?.roomsAndGuests?.childrenAges,
    };
    //call eventutil only if the location data is available to pass over to properties service, as onloadInput will set to '' after execution.
    if (onloadInput && (hiddenFieldsValues?.country || hiddenFieldsValues?.placeId)) {
      eventUtil.dispatch('DeepLinkUtil', { searchFormData });
      setOnloadInput(''); // remove onloadInput value to call DeepLinkUtil only once.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.[DESTINATION_HIDDEN_FIELDS]?.country, formValues?.[DESTINATION_HIDDEN_FIELDS]?.placeId]); //function will execute when address from formValues is updated

  const setPopupOpen = (val: boolean): void => {
    setOpenPopupState(val);
    // to scroll search form to top
    setIsPopupOpen?.(val);
  };
  const clearTimer = (timers: ReturnType<typeof setTimeout>[]): void => {
    if (timers.length) {
      for (let i = timers.length - 1; i >= 0; i--) {
        clearTimeout(timers[i]);
      }
      setTimerList([]);
    }
  };

  /**
   * making an Aries API call to get auto-suggestion data based on user input value
   * return a callback function using response data
   * @param input: the current input value in text field;
   * @param Uuid: uuid generated when component is rendered
   * @param callback: callback function once there is response coming back from API call
   */

  useEffect(() => {
    const displayText = formValues?.[DESTINATION]?.displayText ?? null;
    if (displayText) {
      setValue(displayText);
    }
  }, [formValues]);

  // setting screen reader text and removing after 100ms
  // so that SR can announce updated result everytime
  const setScreenReaderText = (elm: Element, text: string): void => {
    if (elm) {
      // empty the element before inserting content to replicate innerHTML functionality
      elm.replaceChildren();
      elm.append(DOMPurify.sanitize(text, { RETURN_DOM_FRAGMENT: true, ALLOWED_TAGS: ALLOWED_TAGS_IN_DESCRIPTION }));
      setTimeout(() => {
        elm.replaceChildren();
      }, 100);
    }
  };

  useEffect(() => {
    // update current valRef with new inputValue
    inputValueRef.current = inputValue;

    // Delaying API call till the input value finishes updating
    if (inputValue) {
      clearTimer(timerList);
      const timer = setTimeout(() => {
        getSuggestionList({
          variables: {
            query: inputValue,
          },
        });
      }, 500);
      setTimerList([...timerList, timer]);
      setRecentViewed(false);
    } else {
      // cancel current fetch timeout tiemr if user delete the input value
      clearTimer(timerList);

      // clear options list and show recently viewed when input value is cleared
      setOptionsList([]);
      setRecentViewed(true);
    }
    //to add aria-attribute
    // document.querySelectorAll('.MuiAutocomplete-root.desktop-input')[0].setAttribute('aria-label', 'destination');
    // value is the val attribute of input field
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, value]);

  useEffect(() => {
    updateFormValues(inputValue, recentPId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentPId]);

  useEffect(() => {
    //to add first suggestion from list to recent search history, when no option is selected manually.
    if (optionsList.length) {
      const firstSuggestion = JSON.stringify(optionsList[0]);
      setFirstDestOptn(JSON.parse(firstSuggestion));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsList]);

  useEffect(() => {
    localStorage.setItem('recentSearchLabel', firstDestOptn.description);
    localStorage.setItem('recentPlaceId', firstDestOptn.placeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDestOptn.description]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stringifyOption = (option: SuggestedPlacesNode | Record<string, string> | any): string => {
    // const stringifyOption = (option: Record<string, string> | string): string => {
    try {
      return JSON.stringify(option?.node);
    } catch {
      return '';
    }
  };
  const optionDescription = (option: string): string => {
    try {
      const parsedOption = JSON.parse(option);
      // below check is required because JSON.parse("33").something
      // return undefined rather error
      if (typeof parsedOption === 'object') {
        return parsedOption.description;
      }
      throw new Error();
    } catch {
      return option;
    }
  };

  // update the input field with recent search value and close the popup
  const updateRecentValue = (recentValue: string | number): void => {
    if (typeof recentValue === 'string') {
      // shift focus to calendar after selection
      setFocusComp(DATES);
      // to remove "focused" class
      setDestinationOnFocus(false);
      setInputValue(recentValue);
      setPopupOpen(false);
      changeMobileState?.();
      // updateFormValues(recentValue, recentPId);
    }
  };

  const closePopup = (): void => {
    setPopupOpen(false);
  };

  const updateFormValues = (val: string, pid?: string): void => {
    setFormValues(
      setDestination({
        displayText: val,
        pid,
        clusterCode: formValues?.destination?.clusterCode ?? '',
      })
    );
  };

  // function to detect of destination component is on focus by checking where the click event happens
  const useFocusInsideComp = (ref: RefObject<HTMLDivElement>): void => {
    useEffect(() => {
      // const autoCompleteInput: HTMLElement | null = ref.current?.querySelector('input') as HTMLElement;
      const handleClickInside = (event: Event): void => {
        if (ref.current?.contains(event.target as Element)) {
          // if (isMobileView) {
          //   // sync focus behavior in mobile view
          //   autoCompleteInput?.focus();
          // }
          setDestinationOnFocus(true);
        } else {
          setDestinationOnFocus(false);
          // this state is needed for auto-scrolling search to the top
          // see index.tsx L109
          setPopupOpen(false);
        }
      };

      document.addEventListener('click', handleClickInside);
      return (): void => {
        document.removeEventListener('click', handleClickInside);
      };
    }, [ref]);
  };
  useFocusInsideComp(compRef);

  let selectedFromAutoComplete = false;
  const popUpHeaderContent = (): React.ReactNode => {
    return (
      <PopupHeader
        inputId={formId}
        labelIcon="icon-location"
        labelText={destination}
        cancelClickHandler={isMobileView && onCancel ? onCancel : undefined}
      >
        <Autocomplete
          id={formId}
          className={`${inputValue || value ? 'dirty' : ''} ${
            destinationOnFocus && openPopup ? 'focused' : ''
          } desktop-input`}
          options={optionsList?.map(stringifyOption)}
          filterOptions={(x): string[] => x}
          getOptionLabel={optionDescription}
          disablePortal={true}
          openOnFocus={true}
          clearOnBlur={false}
          forcePopupIcon={false}
          closeIcon={<span className="icon-cancel" />}
          // debug={true}
          // open={true}
          onOpen={(): void => {
            setPopupOpen(true);
          }}
          onClose={(): void => {
            // Secondary Tab Show functionality
            if (inputValue.trim() && !selectedFromAutoComplete) {
              updateFormValues(inputValue);
            }
            selectedFromAutoComplete = false;
          }}
          aria-expanded={openPopup}
          value={value}
          onChange={(_event, newValue: string | null, reason: string): void => {
            // onChange will be triggered when an auto-suggest option is chosen or a chosen option is deleted;
            // thus only call the following when newValue exists, aka an option is chosen
            if (newValue) {
              setValue(newValue);
              setPopupOpen(false);
              setOptionsList([]);
              selectedFromAutoComplete = true;
              const PID = JSON.parse(newValue).placeId;
              try {
                changeMobileState?.();
                updateFormValues(JSON.parse(newValue).description, PID);
              } catch {
                changeMobileState?.();
                updateFormValues(newValue, PID);
              }

              setDestinationOnFocus(false);
              // shift focus to calendar after selection
              setFocusComp(DATES);
              localStorage.setItem('recentSearchLabel', JSON.parse(newValue).description);
              localStorage.setItem('recentPlaceId', JSON.parse(newValue).placeId);
            }
            // reset once textfield is cleared up from clear button
            if (reason === 'clear') {
              setValue(null);
              setInputValue('');
            }
          }}
          onInputChange={(event, newInputValue: string): void => {
            // only to catch the event triggered by typing whose type is 'change';
            // exclude the input change from choosing an option from dropdown
            if (event?.type === 'change') {
              const trimmedNewInput = newInputValue.trim();
              setPopupOpen(true);
              setInputValue(trimmedNewInput);
            }

            // reset once textfield is cleared up from keyboard
            if (newInputValue === '') {
              setValue(null);
              setInputValue('');
              // clear optionsList when inputValue is empty
              setOptionsList([]);
              //show recent search
              setRecentViewed(true);
              if (!isMobileView) {
                updateFormValues('', ''); // setting input text and PID empty (for Hidden fields)
              }
            }
          }}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              name="destinationAddress.destination"
              placeholder={
                variation !== VARIATION_DESKTOP ? (mobilePlaceholderText as string) : (desktopPlaceholderText as string)
              }
              variant="standard"
              fullWidth
              value={value}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={isMobileView ? true : false}
              onFocus={(): void => {
                setDestinationOnFocus(true);
                setPopupOpen(true);

                // when focus on empty text field, show recent viewed in popup
                if (value) {
                  setRecentViewed(false);
                  setInputValue(value);
                } else {
                  setRecentViewed(true);
                }

                // reset focus when destination is invoked
                setFocusComp('');
              }}
            />
          )}
          renderOption={(option): ReactElement => {
            const candidate = JSON.parse(option);
            return (
              <p className="t-font-m">
                {candidate.primaryDescription}
                <Text
                  copyText={candidate.secondaryDescription ? ', ' + candidate.secondaryDescription : ''}
                  fontSize={Types.size.medium}
                  element={Types.tags.span}
                />
              </p>
            );
          }}
        />
        {isMobileView ? <Button type={ButtonTypeVariation.Submit} className={clsx('d-none')} buttonCopy={'.'} /> : ''}
      </PopupHeader>
    );
  };

  return (
    // detect when click outside the component and close the popup
    <StyledDestination ref={compRef}>
      <>
        {/* <input type="hidden" name="searchCriteriaVO.destination" value={value ?? ''} /> */}
        <Popup
          handleBlur={!isMobileView}
          show={openPopup || isMobileView}
          className={`${openPopup || isMobileView ? 'show-popup' : ''} dest-dropdown`}
          popupOpenState={openPopup}
          setPopupOpenState={setOpenPopupState}
        >
          {isMobileView ? (
            <form
              action=""
              method="get"
              onSubmit={(event: React.SyntheticEvent): void => {
                event.preventDefault();
                if (inputValue !== '') {
                  setInputValue(inputValue);
                  updateFormValues(inputValue);
                }
                changeMobileState?.();
              }}
            >
              {popUpHeaderContent()}
            </form>
          ) : (
            popUpHeaderContent()
          )}
          <div aria-live="assertive" className="screen-reader-text sr-only" />
          {showRecentViewed && openPopup && (
            <PopupMain className="show-popup__scrollable custom-scrollbar">
              <RecentViewAndSearch updateInputValue={updateRecentValue} closePopup={closePopup} />
            </PopupMain>
          )}
        </Popup>
      </>
    </StyledDestination>
  );
};

export default DestinationMPO;
