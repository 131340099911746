import { StyledSkeletonLoaderDiv, StyledSkeletonLoaderContainer } from './SkeletonOffersSearchResultsLoader.styles';

export const SkeletonOffersSearchResultsLoader = () => {
  return (
    <StyledSkeletonLoaderContainer className="container skeleton-loader-container">
      <StyledSkeletonLoaderDiv className="container-wrapper mt-3 pt-3">
        {[...Array(15)].map(ind => (
          <div className={'col-xs-12 col-md-6 col-lg-4 mb-4'} key={ind}>
            <div className="skeleton-loader skeleton-loader-composite mb-1"></div>
          </div>
        ))}
      </StyledSkeletonLoaderDiv>
    </StyledSkeletonLoaderContainer>
  );
};
