import React, { useRef } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { Badge } from '../../atoms/Badge';
import { size, BadgeVariation, headingType, ButtonStyle } from '../../utils/enums/enums';

import { CardVerticalVariations, CardVerticalProps } from './CardVertical.types';
import { StyledCardVertical, StyledCardVerticalContainer } from './CardVertical.styles';
import { RichText } from '../RichText';

export const CardVertical: React.FC<CardVerticalProps> = props => {
  const {
    openInNewTab = false,
    icon,
    eyebrow,
    badgeIcon,
    badgeText,
    ctaLink,
    header,
    headerTag,
    description,
    ctaLinkText,
    ctaType,
    iconText,
    iconFontValue,
    cardVerticalVariations,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId,
    custom_click_track_value,
  } = props;

  const renditions = dynamicMedia?.renditions;

  const CardVerticalRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      default:
        return '';
    }
  };

  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);

  const innerContent = (
    <div className={'card-vertical'} data-variation={cardVerticalVariations} data-testid="card-vertical">
      <Image
        altText={dynamicMedia?.altText}
        renditions={renditions}
        dynamic={dynamicMedia?.dynamic}
        defaultImageURL={dynamicMedia?.assetPath}
      />
      <div
        className={clsx(
          `card-vertical__body ${
            cardVerticalVariations === (CardVerticalVariations.Tall1x1 as CardVerticalVariations)
              ? 'align-items-center'
              : cardVerticalVariations === (CardVerticalVariations.Tall16x9 as CardVerticalVariations)
              ? `align-items-center card-vertical__tall16x9 d-flex flex-grow-1 flex-column`
              : ''
          }`
        )}
      >
        <div className={clsx('card-vertical_overlay overlay')}>
          {badgeText && cardVerticalVariations !== (CardVerticalVariations.Tall1x1 as CardVerticalVariations) && (
            <Badge
              badgeVariation={BadgeVariation.Overlay}
              badgeText={badgeText ? badgeText : ''}
              badgeIcon={badgeIcon?.split(':')[1] || badgeIcon}
              customClass="card-vertical_badge"
            />
          )}
          {icon && (
            <Button
              className={clsx('card-vertical_socialicon', 'm-icon-button-primary')}
              children={<Icon iconClass={`icon-${icon} icon-l`} />}
            />
          )}
        </div>
        <div
          className={clsx(
            'text-center text-center-card-vertical',
            `${
              cardVerticalVariations === (CardVerticalVariations.Tall16x9 as CardVerticalVariations)
                ? 'text-container'
                : ''
            }`
          )}
        >
          {eyebrow && cardVerticalVariations !== CardVerticalVariations.Standard && (
            <div className="truncate-text m-auto card-vertical_eyebrow">
              <Eyebrow text={eyebrow} />
            </div>
          )}
          <Heading
            element={headerTag}
            variation={headingType.subtitle}
            fontSize={
              cardVerticalVariations === (CardVerticalVariations.Standard as CardVerticalVariations)
                ? size.medium
                : size.large
            }
            titleText={header || ''}
            customClass={clsx(
              'truncate-text',
              'card-vertical_heading',
              'm-ellipsis-2lines',
              `${
                cardVerticalVariations === CardVerticalVariations.Tall16x9
                  ? description
                    ? ''
                    : eyebrow
                    ? 'pt-2 mx-auto'
                    : 'm-0'
                  : cardVerticalVariations === CardVerticalVariations.Tall1x1
                  ? 'mx-auto'
                  : description
                  ? ''
                  : 'mb-0'
              }`
            )}
          />
          {cardVerticalVariations === (CardVerticalVariations.Tall1x1 as CardVerticalVariations) && (
            <span className={'seperator t-horizontal-vertical-rule'}></span>
          )}
          {cardVerticalVariations === (CardVerticalVariations.Tall16x9 as CardVerticalVariations) && (
            <div className="card-vertical-detailLabel">
              <Icon iconClass={`card-vertical-icon mr-1 ${iconFontValue} icon-l`}></Icon>
              <span className={`card-vertical-iconfont t-font-xs m-ellipsis-1line`}>{iconText}</span>
            </div>
          )}
          <div
            className={`${
              cardVerticalVariations === (CardVerticalVariations.Tall1x1 as CardVerticalVariations)
                ? description
                  ? 'card-vertical_description'
                  : 'no-description'
                : 'card-vertical_detail'
            }`}
          >
            {description && (
              <RichText
                text={description}
                customClass={clsx(
                  `${
                    cardVerticalVariations === (CardVerticalVariations.Standard as CardVerticalVariations)
                      ? 'card-vertical_description m-ellipsis-4lines t-font-alt-s'
                      : cardVerticalVariations === (CardVerticalVariations.Tall16x9 as CardVerticalVariations)
                      ? 'card-vertical-description m-ellipsis-3lines t-font-s'
                      : 'description-height mx-auto m-ellipsis-1line'
                  }`,
                  'truncate-text'
                )}
                componentId={componentId + '-DescriptionText'}
              />
            )}
            {cardVerticalVariations === (CardVerticalVariations.Standard as CardVerticalVariations) ? (
              <span className="col-1" />
            ) : cardVerticalVariations !== (CardVerticalVariations.Tall16x9 as CardVerticalVariations) ? (
              ctaLinkText && (
                <span className={`${openInNewTab ? 'm-link-tertiary-button-external' : 'm-link-tertiary-button'}`}>
                  {ctaLinkText}
                </span>
              )
            ) : null}
          </div>
        </div>
        {cardVerticalVariations === 'Tall16:9' && ctaLinkText && (
          <div className="p-0 d-flex justify-content-center">
            <Button
              isLink
              href={ctaLink}
              className={`${buttonTypeClasses} m-button-s anchor-button`}
              target={openInNewTab === true ? '_blank' : ''}
              trackingProperties={trackingProperties}
              ref={CardVerticalRef}
              custom_click_track_value={custom_click_track_value}
            >
              {ctaLinkText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <StyledCardVerticalContainer
      data-component-name="m-ui-library-CardVertical"
      data-testid="ui-library-CardVertical"
      cardVerticalVariation={cardVerticalVariations}
    >
      <StyledCardVertical
        className={clsx(`p-0 card-vertical-id_${componentId}`, `${styleclass ? styleclass : ''}`)}
        {...customAttributes}
        cardVerticalVariation={cardVerticalVariations}
      >
        {(cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'standard') && (
          <Button
            isLink
            href={ctaLink}
            target={openInNewTab ? '_blank' : '_self'}
            aria-label={ctaLink}
            trackingProperties={trackingProperties}
            rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
            ref={CardVerticalRef}
            className={clsx(
              `${
                cardVerticalVariations === CardVerticalVariations.Standard
                  ? `card-vertical_chevron ${openInNewTab === true ? 'icon-arrow-right-external' : 'icon-arrow-right'}`
                  : ''
              }`
            )}
            custom_click_track_value={custom_click_track_value}
          >
            {innerContent}
          </Button>
        )}
        {cardVerticalVariations === 'Tall16:9' && innerContent}
      </StyledCardVertical>
    </StyledCardVerticalContainer>
  );
};
