// Imports for external libraries go here.
import React, { useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { errorTypes } from '../../constants/CommonConstants';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { StyledOffersAemText } from './OffersAemText.styles';

export const aemTextConfig = {
  emptyLabel: 'text',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/text`,
};

type ErrorMessage = {
  [key: string]: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersAemText = (props: any) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errors: ErrorMessage = {};

    errorTypes.forEach(type => {
      errors[type] = params.get(type) === 'true';
    });

    setErrorMessage(errors);
  }, []);

  const appliedCssClassNames = props?.model?.appliedCssClassNames?.split(' ');
  let isErrorWithFlag = false;

  if (appliedCssClassNames) {
    for (let i = 0; i < appliedCssClassNames.length; i++) {
      const className = appliedCssClassNames[i];
      if (errorMessage[className] === true) {
        isErrorWithFlag = true;
        break;
      }
    }
  }

  const errorCssClassNames = [
    props?.model?.appliedCssClassNames,
    isErrorWithFlag ? 'icon-alert withIcon d-flex form-error' : '',
  ].join(' ');

  return (
    <EditableComponent config={aemTextConfig} {...props}>
      <StyledOffersAemText
        data-component-name="o-offers-text"
        data-testid="text"
        className={errorCssClassNames || 'standard'}
        dangerouslySetInnerHTML={{ __html: ` ${props?.model?.text}` }}
      />
    </EditableComponent>
  );
};
