/* eslint-disable @nx/enforce-module-boundaries */
import {
  DestinationDetails,
  DestinationNode,
  DestinationNodeType,
  DistanceMatrixResponseType,
  LocationCoords,
  NearByDestinationsInfoProps,
} from '../NearByDestinations.types';

export const getNearByDestinationsVariables = (
  latitude: number,
  longitude: number,
  minDistance: string | number,
  maxDistance: string | number
) => {
  const obj = {
    input: {
      queries: [
        {
          id: 'latitude',
          values: [`${latitude}`],
        },
        {
          id: 'longitude',
          values: [`${longitude}`],
        },
      ],
      facets: {
        terms: [
          {
            type: 'DISTANCE_RANGE',
            dimensions: [minDistance, maxDistance],
          },
          {
            type: 'CITIES',
            dimensions: [],
          },
        ],
      },
    },
    sort: { field: 'OFFER_DISTANCE' },
  };
  return obj;
};

// getting destinations which has more offers than maxOffersPerCity
// and display cities equal to numCitiesToDisplay
export const filterDestinations = (
  edges: Array<DestinationNode>,
  minOffersPerCity: string | number,
  numCitiesToDisplay: string | number
): Array<DestinationNodeType> => {
  const destinations = new Map();
  const filteredEdges = edges.filter(item => item.node.participatingProperties.city);
  const edgeLength = filteredEdges.length;
  for (let i = 0; i < edgeLength; i++) {
    const city = filteredEdges[i].node.participatingProperties.city;
    if (!destinations.has(city)) {
      destinations.set(city, { nodes: [filteredEdges[i].node], offerCount: 1 });
    } else {
      const cityData = destinations.get(city);
      cityData.nodes.push(filteredEdges[i].node);
      cityData.offerCount += 1;
    }
    if (destinations.size > Number(numCitiesToDisplay)) {
      break;
    }
  }
  const destinationArray: Array<DestinationNodeType> = [];
  destinations.forEach(city => {
    if (city.offerCount >= minOffersPerCity && destinationArray.length < Number(numCitiesToDisplay)) {
      destinationArray.push(city.nodes[0]);
    }
  });
  return destinationArray;
};

export const getDistanceMatrixForDestinations = async (
  origins: LocationCoords,
  destinationsCoordinates: Array<LocationCoords>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const service = new google.maps.DistanceMatrixService();

  const request = {
    origins: [origins],
    destinations: destinationsCoordinates,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  try {
    const response = await service.getDistanceMatrix(request);
    return response;
  } catch (error) {
    console.error('Error occurred:', error);
    throw error; // optional, rethrow the error for handling elsewhere if needed
  }
};

export const destinationListResponse = (
  resp: DistanceMatrixResponseType,
  destinations: Array<DestinationNodeType>
): NearByDestinationsInfoProps => {
  // creating inital object to push destinations under destinationList key
  const data: NearByDestinationsInfoProps = { destinationList: [] };
  for (let i = 0; i < destinations.length; i++) {
    data.destinationList.push({
      destination: `${destinations[i].participatingProperties.city}, ${destinations[i].participatingProperties.country}`,
      distance: resp.rows[0].elements[i].distance.text,
      duration: convertInHours(resp.rows[0].elements[i].duration.value),
      placeId: '',
      address: `${destinations[i].participatingProperties.city}, ${destinations[i].participatingProperties.country}`,
      city: destinations[i].participatingProperties.city,
      country: destinations[i].participatingProperties.countryCode,
      latitude: `${destinations[i].participatingProperties.properties[0].basicInformation.latitude}`,
      longitude: `${destinations[i].participatingProperties.properties[0].basicInformation.longitude}`,
      state: destinations[i].participatingProperties.stateProvince,
      seconds: resp.rows[0].elements[i].distance.value,
      hoursAwayText: resp.rows[0].elements[i].duration.text,
    });
  }
  return { ...data, destinationList: data.destinationList.sort((a, b) => a.duration - b.duration) };
};

export const returnValidNumber = (num: string): number => {
  const number = Number(num);
  return isNaN(number) ? 0 : number;
};

export const convertInHours = (sec: number): number => {
  let hours = returnValidNumber(`${sec}`);
  hours = Math.round(sec / 3600);
  return hours;
};

export const constructOfferSearchURL = (baseURL: string, destinationData: DestinationDetails): string => {
  return (
    baseURL +
    '?destinationAddress.placeId=' +
    destinationData?.placeId +
    '&destinationAddress.latitude=' +
    destinationData?.latitude +
    '&isInternalSearch=true' +
    '&destinationAddress.country=' +
    destinationData?.country +
    '&vsInitialRequest=false' +
    '&searchType=InCity' +
    '&destinationAddress.address=' +
    destinationData?.address +
    '&destinationAddress.city=' +
    destinationData?.city +
    '&destinationAddress.mainText=' +
    destinationData?.city +
    '&destinationAddress.countryName=' +
    destinationData?.country +
    '&destinationAddress.longitude=' +
    destinationData?.longitude +
    '&destinationAddress.stateProvince=' +
    destinationData?.state +
    '&destinationAddress.initialRequest=false' +
    '&destinationAddress.singleSearchAutoSuggest=Unmatched' +
    '&destinationAddress.destination=' +
    destinationData?.destination
  );
};

export const generateCardIndexArray = (length: number): string[] => {
  return Array.from({ length }, (_, index) => `card-${index}`);
};

export const getHoursAwayText = (
  duration: number,
  hourText: string | undefined,
  hoursText: string | undefined
): string => {
  return `${duration} ${duration > 1 ? hoursText : hourText}`;
};
