import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { theme as fontTheme } from '../../../styles/theme';
import { mediaQuery } from '../../../../../utils/styles';

export const StyledAddDatesDiv = styled.div`
  position: relative;
  height: 100%;
  background-color: ${theme.color['white']};
  @media ${theme.mediaQuery.desktop} {
    .DayPicker-Month {
      display: flex;
      flex-direction: column;
    }
  }
  @media ${theme.mediaQuery.tablet} {
    height: auto;
    flex: 1;
    margin-right: 30%;
    padding: 0;
  }
  .h-r-cta-secondary-white {
    background-color: ${theme.color['white']};
  }
  .search__calendar-label {
    display: block;
    text-transform: uppercase;
    padding-bottom: ${toRem(6)};
    color: ${theme.color['dark-gray-4']};
    letter-spacing: ${toRem(1.3)};
    ::before {
      color: var(--t-icon-color-decorative);
      padding-right: ${toRem(8)};
      font-size: ${theme.fonts['fontM']};
    }
    @media ${theme.mediaQuery.desktop} {
      padding-bottom: ${toRem(4)};
    }
  }

  .search__calendar-value {
    white-space: nowrap;
  }
  .search__calendar-value.lowerCaseDate {
    text-transform: lowercase !important;
  }

  .h-r-form-field-txt {
    color: ${theme.color['black']};
    font-family: ${fontTheme.fontFamily.title};
    font-size: ${toRem(18)};
    @media ${mediaQuery.desktop} {
      font-size: ${toRem(22)};
    }
  }
  .search__calendar-value-empty {
    color: ${theme.color['dark-gray-4']};
  }

  > button {
    border: 0;
    background-color: transparent;
    text-align: left;
    width: 100%;
    padding: 0;

    @media ${theme.mediaQuery.tablet} {
      padding: ${toRem(21)} ${toRem(5)} ${toRem(20)} ${toRem(5)};
      &:focus {
        // outline: ${toRem(2)} solid ${theme.color['outline-blue']} !important;
      }
    }
    @media ${theme.mediaQuery.desktop} {
      padding: ${toRem(22)} ${toRem(32)} ${toRem(19)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      padding: ${toRem(22)} ${toRem(40)} ${toRem(19)};
    }
  }

  .search-dates-popup {
    height: 100%;
    background-color: ${theme.color['white']};
    @media ${theme.mediaQuery.tablet} {
      height: auto;
      width: ${toRem(484)};
      left: -${toRem(1)};
      padding: ${toRem(20)} 0 ${toRem(32)};
    }
    @media ${theme.mediaQuery.desktop} {
      width: ${toRem(634)};
      padding: ${toRem(22)} 0 ${toRem(32)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      width: ${toRem(754)};
      padding: ${toRem(22)} 0 ${toRem(32)};
    }
    button:focus-visible {
      outline: ${theme.color.black} auto ${toRem(2)};
    }
  }

  .search-dates-popup__header .h-r-form-field-txt {
    font-size: ${toRem(22)};
    line-height: ${toRem(28)};
    letter-spacing: -${toRem(0.5)};
  }
  .search-dates-popup__main {
    @media ${theme.mediaQuery.mobileOnly} {
      overflow: hidden;
      height: 100%;
      padding-bottom: ${toRem(105)};
      flex-direction: column;
      > div {
        &:first-child {
          flex: 1;
          height: calc(100% - ${toRem(50)});
          overflow-y: auto;
        }
        &:nth-child(2) {
          height: ${toRem(50)};
          padding: ${toRem(16)} ${toRem(24)};
          width: 100%;
          box-shadow: 0 ${toRem(2)} ${toRem(2)} 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    /* START: IE11 fix for styled div as flex:1 not supported */

    @media ${theme.mediaQuery.mobileOnly && theme.mediaQuery.ie11Only} {
      > div {
        &:first-child {
          width: 100%;
        }
      }
    }

    /* END: IE11 fix for styled div as flex:1 not supported */
  }
`;

export const StyledDatesFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${toRem(30)};
  button {
    min-width: ${toRem(99)};
  }

  @media ${theme.mediaQuery.desktop} {
    padding: 0 ${toRem(43)} 0 ${toRem(36)};
  }
`;
