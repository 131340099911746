import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';

// TODO: remove lines 20 to 25 after GS is fixed
export const StyledPopupHeaderDiv = styled.div`
  label {
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    margin-bottom: 0;
    ::before {
      color: var(--t-icon-color-decorative);
      padding-right: ${toRem(8)};
      font-size: ${toRem(13)};
    }
    @media ${theme.mediaQuery.tablet} {
      color: ${theme.color['dark-gray-4']};
      display: block;
    }
  }
  .link-white {
    background: none;
    border: none;
    color: ${baseVariables.color.base20};
    font-family: ${baseVariables.font.fontSwiss};
    font-size: ${toRem(13)};
    font-weight: 700;
    line-height: ${toRem(16)};
  }
  .with-input {
    cursor: pointer;
    label {
      padding-bottom: ${toRem(6)};
      @media ${theme.mediaQuery.desktop} {
        padding-bottom: ${toRem(4)};
      }
    }

    @media ${theme.mediaQuery.tablet} {
      padding: ${toRem(20)} 0 ${toRem(24)} ${toRem(16)};
    }

    @media ${theme.mediaQuery.desktop} {
      padding: ${toRem(22)} 0 ${toRem(24)} ${toRem(24)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      padding: ${toRem(22)} 0 ${toRem(24)} ${toRem(32)};
    }
  }
  @media ${theme.mediaQuery.mobileOnly} {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    top: 0;
    background-color: ${theme.color['dark-gray-1']};
    padding: ${toRem(26)} ${toRem(16)} ${toRem(25)};
    width: 100%;
    color: ${theme.color.white};
    height: ${toRem(109)};

    > span,
    > label,
    .h-r-form-field-txt {
      color: ${theme.color['white']};
      ::before {
        color: ${theme.color['light-orange-2']};
        padding-right: ${toRem(8)};
      }
    }

    > span {
      text-transform: uppercase;
      letter-spacing: ${toRem(1.3)};
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      &.t-title-inverse-s {
        padding-top: ${toRem(15)};
      }
      &.t-subtitle-inverse-l {
        padding-top: ${toRem(18)};
      }
      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }
`;
