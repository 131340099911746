import React, { FC, useContext } from 'react';
import { StyledFlexibleDateCheckBox } from './FlexibleDate.styles';
import { FlexibleDateType } from './FlexibleDate.types';
import { ContentContext } from '../../../index.contexts';

const FlexibleDate: FC<FlexibleDateType> = ({ onChange, checked }) => {
  const { myDatesFlexibleLabel } = useContext(ContentContext);

  const timestamp = new Date().getTime();
  const checkboxId = timestamp.toString() + '-flexible-date-checkbox';
  return (
    <StyledFlexibleDateCheckBox
      className={'search__flexible_date'}
      variation={'simple'}
      checkboxLabel={myDatesFlexibleLabel}
      checkboxId={checkboxId}
      checkboxName="flexibleDateSearch"
      onChange={onChange}
      // 19 line no commented
      // onKeyPress={checkboxKeyHandler}
      checked={checked}
      size={18}
      value="on"
    />
  );
};

export default FlexibleDate;
