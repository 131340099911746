import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { Checkbox } from '@marriott/mi-ui-library-shop';

export const StyledFlexibleDateCheckBox = styled(Checkbox)`
  font-family: ${theme.fontFamily.swiss};
  line-height: ${toRem(18)};
  font-size: ${toRem(14)};
  font-weight: 700;
  input[type='checkbox'] + span {
    color: ${theme.color['black']};
  }
  label {
    color: ${theme.color['black']};
  }
  input[type='checkbox']:focus + label:before,
  input[type='checkbox']:checked + label:before {
    outline: none;
  }
  input[type='checkbox']:focus-visible + label:before {
    outline: ${toRem(1)} auto -webkit-focus-ring-color;
  }

  @media ${theme.mediaQuery.tablet} {
    font-size: ${toRem(14)};
    font-family: ${theme.fontFamily.swiss};
    line-height: ${toRem(18)};
    font-weight: 500;
  }
`;
