/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC, useCallback, useEffect, useState } from 'react';
import cssClass from 'classnames';
import { LoadingSkeleton as Skeleton } from '../../atoms';
import { usePageContext, pageStoreComponentID } from '../PageContext';
// import { LoadingSkeleton as Skeleton, usePageContext, pageStoreComponentID } from '@marriott/mi-book-components';
import { BrandedHotelHeader } from './BrandedHotelHeader';

import { findSavedProperty, parseHotelHeaderData, recentlyViewed } from './hotelHeaderHelper';
import { SessionDataMap } from './HotelHeader.schema';
import { HotelHeaderProps, ComponentProps } from './HotelHeader.types';
import { StyledComp, StyledLoadingSection } from './HotelHeader.styles';

import { parseContactDetails, isMobileViewPort, transformResponse, eventUtil, getWindowSession } from '../../utils';
import {
  constants,
  GQL_OPT_NAME,
  URL_CONSTANTS,
  LOADER_CONFIGS,
  GQL_OPT_SIGN,
  CUSTOM_EVENTS_CONSTANTS,
  VITAL_UXL_CALLS,
} from '../../constants';
import { PhoenixBookHotelHeaderData, PhoenixBookHotelHeaderDataAuth, PhoenixBookUpdateSaveProperties } from './Queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';

const { HotelHeaderImageLoadingConfig, HotelHeaderDetailsLoadingConfig } = LOADER_CONFIGS;
export const HotelHeaderLoader = (props: { inModal: boolean }) => {
  return props.inModal ? (
    <div data-component-name="o-book-HotelHeader-Loading">
      <Skeleton count={1} height={isMobileViewPort() ? 152 : 126} />
    </div>
  ) : (
    <StyledLoadingSection
      data-component-name="o-book-HotelHeader-Loading"
      className="d-flex align-items-center container"
    >
      <div className="mr-3 icon-loader">
        <Skeleton customSkeletonConfig={HotelHeaderImageLoadingConfig} style={{ borderRadius: '25px' }} />
      </div>
      <div>
        <Skeleton customSkeletonConfig={HotelHeaderDetailsLoadingConfig} style={{ borderRadius: '25px' }} />
      </div>
    </StyledLoadingSection>
  );
};

export const HotelHeaderComponent: FC<ComponentProps> = props => {
  const initialState = {
    country: '',
    city: '',
    phone: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    stateProvince: '',
    brandCode: '',
    hotelName: '',
    seoNickname: '',
    userData: {},
    imageUrl: '',
    dacLogo: '',
  };

  const {
    isInEditor,
    inModal,
    reviews,
    componentId,
    fileReference,
    hotelDetailsLabel,
    pageType = 'rlm',
    savedLabel,
    saveLabel,
    phoneText,
    starsOutOfText,
    reviewUrl,
    overridePropertyCountry,
    overridePropertyCountryText,
    millionLabel,
    thousandLabel,
  } = props.model;
  const { enableHQVLink, acceptLanguage, isBulgariDomain, isMBOP = false } = props;
  const { REMEMBERED_STATE, RLM_PAGE, ACCESS_TYPE, REMEMBER_ME_STATE, UPCOMING_RESERVATION_PAGE, ADF_PAGE } = constants;
  const { PROPERTY_URL, PROPERTY_LOCATION_URL, HOTEL_MAP_POST_URL } = URL_CONSTANTS;
  const [hotelHeaderData, setHotelHeaderData] = useState<HotelHeaderProps>(initialState);
  const [uniqueHotelLogo, setUniqueHotelLogo] = useState('');
  const acceptLang = acceptLanguage ? acceptLanguage?.replace('_', '-') : 'en-US';

  // const processenv = getProcessEnvs();
  const { hotelHeaderAuth, hotelHeader } = GQL_OPT_NAME;

  // const NEXT_PUBLIC_GRAPHQL_URL = processenv.NEXT_PUBLIC_GRAPHQL_URL ?? process.env['NEXT_PUBLIC_GRAPHQL_URL'];
  // const NEXT_PUBLIC_PREFIX = processenv.NEXT_PUBLIC_PREFIX ?? process.env['NEXT_PUBLIC_PREFIX'];
  const saveOperationName = GQL_OPT_NAME.customerSavedProperties;
  const saveOperationSign = GQL_OPT_SIGN[saveOperationName as keyof typeof GQL_OPT_SIGN];

  const { updateComponentState = () => '' } = usePageContext(state => state.hotelHeader);

  const sessionObject = getWindowSession();
  const isAuth = sessionObject?.authenticated;
  const isRlmPage = pageType === RLM_PAGE || pageType === '';
  const isRlmOrUpcomingResPage = isRlmPage || pageType === UPCOMING_RESERVATION_PAGE || pageType === ADF_PAGE;

  const updateHotelHeaderContext = useCallback(
    (data: Record<string, string | number | boolean>) => {
      if (isRlmPage) {
        sessionStorage.removeItem('insuranceData');
        sessionStorage.removeItem('insurancePurchased');
        sessionStorage.removeItem('insuranceShown');
      }
      updateComponentState({
        componentName: pageStoreComponentID.HOTEL_HEADER,
        newState: {
          data,
        },
      });
    },
    [updateComponentState]
  );

  const { tripsXRequestedByHeader, propertyId, locale, consumerID, userState, isMax } = transformResponse(
    sessionObject,
    SessionDataMap
  );
  const isRememberedUser = userState === REMEMBERED_STATE;
  const headerOperationName = (isAuth || isRememberedUser) && isRlmOrUpcomingResPage ? hotelHeaderAuth : hotelHeader;
  const additionalHeaders = isRememberedUser ? { [ACCESS_TYPE]: REMEMBER_ME_STATE } : {};
  const headerOperationSign = GQL_OPT_SIGN[headerOperationName as keyof typeof GQL_OPT_SIGN];
  const [getHotelHeaderData, { loading, data, refetch }] = useLazyQuery(
    (isAuth || isRememberedUser) && isRlmOrUpcomingResPage
      ? PhoenixBookHotelHeaderDataAuth
      : PhoenixBookHotelHeaderData,
    {
      context: {
        isBatchEnabled: false,
        headers: {
          'accept-language': acceptLang,
          'graphql-operation-name': `${headerOperationName}`,
          'graphql-operation-signature': `${headerOperationSign}`,
          'x-requested-by': tripsXRequestedByHeader ?? '',
          ...additionalHeaders,
        },
        //Commented for future purpose
        // uri: `${NEXT_PUBLIC_PREFIX}${NEXT_PUBLIC_GRAPHQL_URL}/${headerOperationName}`,
        // ...(isDev && {
        //   uri: `${NEXT_PUBLIC_GRAPHQL_URL}/${headerOperationName}`,
        // }),
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
      //Commented for future purpose
      // onCompleted: data => {
      //   console.log('data', data);
      // },
      onError: () => {
        if (VITAL_UXL_CALLS.includes(headerOperationName)) {
          if (
            headerOperationName === GQL_OPT_NAME.propertyPaymentInfo ||
            headerOperationName === GQL_OPT_NAME.customerPaymentInfo
          ) {
            window.parent.postMessage({ graphQlError: true }, window.location.origin);
          } else {
            eventUtil.dispatch(CUSTOM_EVENTS_CONSTANTS.ON_GRAPHQL_ERROR, {});
          }
        }
      },
    }
  );

  const [savePropertyToProfile] = useMutation(PhoenixBookUpdateSaveProperties, {
    context: {
      headers: {
        'graphql-operation-name': `${saveOperationName}`,
        'graphql-operation-signature': `${saveOperationSign}`,
        'x-requested-by': '',
        ...additionalHeaders,
      },
      //Commented for future purpose
      // uri: `${NEXT_PUBLIC_PREFIX}${NEXT_PUBLIC_GRAPHQL_URL}/${saveOperationName}`,
      // ...(isDev && {
      //   uri: `${processenv['PROXY_HOST']}${NEXT_PUBLIC_PREFIX}${NEXT_PUBLIC_GRAPHQL_URL}/${saveOperationName}`,
      // }),
    },
    errorPolicy: 'none',
    onError: () => {
      if (VITAL_UXL_CALLS.includes(saveOperationName)) {
        eventUtil.dispatch(CUSTOM_EVENTS_CONSTANTS.ON_GRAPHQL_ERROR, {});
      }
    },
  });

  let isLoading = loading || !data;

  if (isInEditor) {
    isLoading = false;
  }

  const isSession = sessionObject?.cacheData?.data?.AriesReservation;
  const showSaveProperty = isAuth || isRememberedUser;
  const containerClassName = cssClass({ hide: isLoading });

  const {
    brandCode,
    hotelName,
    city,
    seoNickname,
    phone,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    latitude,
    longitude,
    stars,
    hotelReviews,
    isJPLocale,
    suppressReviews,
    showReviews,
    userData,
    imageUrl,
    dacLogo,
    currency,
  } = hotelHeaderData;

  const hotelAddress = parseContactDetails({
    city,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    isJPLocale,
    inModal,
    overridePropertyCountry,
    overridePropertyCountryText,
  });

  useEffect(() => {
    if (isSession) {
      getHotelHeaderData({
        variables: {
          propertyId: propertyId,
          customerId: consumerID ?? '',
        },
      });
    }
  }, [isSession, propertyId, getHotelHeaderData]);

  useEffect(() => {
    if (data?.property) {
      parseHotelHeaderData({ data }, setHotelHeaderData, locale);
      //Dispatching the data whenever we receive the hotel header data from uxl
      eventUtil.dispatch('hotelHeaderDataBasicInformation', data?.property?.basicInformation);
      updateHotelHeaderContext(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Method to update unique hotel logo in Property Header
  useEffect(() => {
    if (propertyId && !inModal) {
      setUniqueHotelLogo(dacLogo ? dacLogo : '');
    }
  }, [propertyId, inModal, dacLogo]);

  const sessionData = transformResponse(sessionObject, SessionDataMap);

  const recentlyViewData = {
    name: hotelName || '',
    address: hotelAddress || '',
    marshaCode: sessionData?.propertyId || '',
    imageUrl: imageUrl || '',
    latitude: latitude || '',
    longitude: longitude || '',
    marshaBrandCode: brandCode || '',
    numberOfReviewsCount: hotelReviews?.['count'] || '',
    starsCount: stars?.['count'] || '',
    postalAddress: postalCode || '',
    state: stateProvince || '',
    country: country || '',
    createdAt: new Date().toISOString(),
  };

  useEffect(() => {
    if (isRlmPage) {
      recentlyViewed(recentlyViewData, sessionData);
    }
  }, [recentlyViewData]);

  const handleSaveHotel = async (
    propertyList: { [id: string]: string }[],
    setHotelSave: React.Dispatch<React.SetStateAction<{ addClass: string; showSavedLabel: boolean }>>,
    hotelSave: Record<string, string | boolean>
  ) => {
    if (userData?.savedProperties && propertyList?.length <= 20) {
      const index = findSavedProperty(propertyId, propertyList);
      index > -1 ? propertyList.splice(index, 1) : propertyList.push({ id: propertyId.toUpperCase() });
      let status = constants.ERROR;
      const variables = {
        input: {
          id: consumerID,
          savedProperties: propertyList,
          revisionToken: userData?.revisionToken,
        },
      };
      status = await savePropertyToProfile({
        variables: variables,
      })
        .then(result => {
          return result.data.updateCustomerSavedProperties.status[0].code;
        })
        .catch(err => console.log(err, 'Error while updating properties'));
      if (status?.toLowerCase() === constants.SUCCESS) {
        refetch({
          variables: {
            propertyId: propertyId,
            customerId: consumerID ?? '',
          },
        });
        if (index > -1) {
          setHotelSave({ ...hotelSave, addClass: 'icon-heart-outline', showSavedLabel: false });
        } else {
          setHotelSave({ ...hotelSave, addClass: 'icon-heart-fill', showSavedLabel: true });
        }
      }
    }
  };

  const brandedHeaderData = {
    componentId,
    seoNickname,
    hotelName,
    fileReference,
    latitude,
    longitude,
    hotelAddress,
    phone,
    showReviews,
    suppressReviews,
    stars,
    hotelReviews,
    reviews,
    hotelDetailsLabel,
    pageType,
    savedLabel,
    saveLabel,
    brandCode,
    propertyId,
    userData,
    showSaveProperty,
    consumerID,
    imageUrl,
    phoneText,
    starsOutOfText,
    reviewUrl,
    isMax,
    enableHQVLink,
    locale,
    millionLabel,
    thousandLabel,
    currency,
  };

  return (
    <>
      {isLoading && <HotelHeaderLoader inModal={Boolean(inModal)} />}
      {inModal ? (
        <StyledComp data-component-name="o-book-HotelHeader" data-testid="HotelHeader" className={containerClassName}>
          <span className={`t-brand-logo brand-logo-${brandCode} hotelLogo t-brand-logo-s`}></span>
          <div className="headerContent">
            <a href={`${PROPERTY_URL}${seoNickname}`} aria-label={`${PROPERTY_URL}${seoNickname}`}>
              <span className="title">{hotelName}</span>
            </a>
            {/*  Show hotel header data only when hotel header data is received */}
            {hotelName && (
              <div className="addressWrapper">
                <a
                  className="icon-location icon-margin"
                  href={`${PROPERTY_LOCATION_URL}${seoNickname}?${HOTEL_MAP_POST_URL}${seoNickname}`}
                  aria-label={hotelAddress}
                />
                <p className="address">{hotelAddress}</p>
              </div>
            )}
          </div>
        </StyledComp>
      ) : (
        !isLoading && (
          <BrandedHotelHeader
            {...brandedHeaderData}
            uniqueHotelLogo={uniqueHotelLogo}
            isLoading={isLoading}
            handleClick={handleSaveHotel}
            isBulgariDomain={isBulgariDomain}
            isMBOP={isMBOP}
          />
        )
      )}
    </>
  );
};

export const HotelHeaderComponentConfig = {
  emptyLabel: 'HotelHeaderComponent',
  isEmpty: false,
  resourceType: 'mcom-book-spa/components/prebooking/hotelheader',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HotelHeaderComponentEditable = (props: any) => (
  <EditableComponent config={HotelHeaderComponentConfig} {...props}>
    <HotelHeaderComponent {...props} />
  </EditableComponent>
);
