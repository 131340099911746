// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const OffersHeroV2Wrapper = dynamic(() => import('./OffersHeroV2').then(mod => mod.OffersHeroV2Wrapper));

export const OffersHeroV2WrapperConfig = {
  emptyLabel: 'OffersHeroBannerV2',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/hero`,
};

export const OffersHeroV2 = (props: any) => (
  <EditableComponent config={OffersHeroV2WrapperConfig} {...props}>
    <div className="m-container-fullbleed" data-testid="hero">
      <OffersHeroV2Wrapper {...props} />
    </div>
  </EditableComponent>
);
