/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import clsx from 'clsx';
import {
  AVG_TAB,
  DESKTOP_CURRENCY_LENGTH,
  LOWEST_AVERAGE_RATE,
  MOBILE_CURRENCY_LENGTH,
  POINTS_PER_QUANTITY,
  TABLET_CURRENCY_LENGTH,
  TOTAL_RATE,
} from '../../constants/Calendar.constant';
import { getCompactFormat, formatNumberByLocale } from '../../utils/src/utils';
import { getCurrencySymbol } from '../../utils/src/currencySymbolGenerator';
import {
  currencyLocales,
  DATE_FORMAT_DEFAULT,
  DEFAULT_FALLBACK_CURRENCY,
  MIN_TAB_SIZE,
} from '../../constants/lib/constants';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}
export const CustomizeRenderDay = (
  day: Date,
  isMobile: boolean,
  data: any,
  isDataLoading: boolean | undefined,
  conversionFactorRate: number,
  isViewportL: boolean,
  currentLocale: string,
  smallerDevicesCheck: boolean,
  isPointsSelected: boolean,
  isRedemption: boolean,
  lowPriceIndicator: string,
  compactFormatLiteral: any,
  currentCurrency: string,
  mandatoryFeesValue: number,
  numberOfDays: number
) => {
  const adfData = data?.adfData?.node;
  const selectedTab = data?.selectedTab;
  const isLowest = data?.isLowest;
  const rateBlock =
    isPointsSelected || isRedemption ? POINTS_PER_QUANTITY : selectedTab === AVG_TAB ? LOWEST_AVERAGE_RATE : TOTAL_RATE;

  const specificRate = fetchSpecificRates(
    adfData?.rateModes,
    rateBlock,
    conversionFactorRate,
    rateBlock === LOWEST_AVERAGE_RATE ? mandatoryFeesValue / numberOfDays : mandatoryFeesValue
  );
  const rateToDisplay = getCommaSeparators(specificRate, isMobile, isViewportL, currentLocale, compactFormatLiteral);
  // Variable containing last bookable date. Variable naming based on story AC.
  const kDate = data?.inventoryDateData;

  const showCurrencySymbol = () => {
    const showSymbol = currencyLocales.find(item => {
      return item.currency?.toLocaleUpperCase() === currentCurrency?.toLocaleUpperCase();
    });
    if (isPointsSelected || isRedemption || !showSymbol) {
      return '';
    } else {
      if (
        (window && window?.screen?.width > MIN_TAB_SIZE) ||
        (window && window?.screen?.width < MIN_TAB_SIZE && currentCurrency?.toUpperCase() === DEFAULT_FALLBACK_CURRENCY)
      ) {
        return getCurrencySymbol(currentCurrency);
      } else {
        return '';
      }
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        'daypicker-cell-custom d-flex justify-content-center pt-md-0',
        `${(!adfData && smallerDevicesCheck) || adfData ? 'custom_click_track' : ''}`
      )}
      {...{
        custom_click_track_value: `ADFCalendar|${
          !adfData
            ? moment(day).isAfter(kDate)
              ? data['rateUnavailableText']
              : data['notAvailableForCheckInText']
            : 'Checkin'
        } clicked|internal`,
      }}
      aria-label={
        moment(day).isAfter(kDate)
          ? !isMobile
            ? '-'
            : data['rateUnavailableText']
          : !adfData
          ? !isMobile
            ? '-'
            : data['notAvailableForCheckInText']
          : showCurrencySymbol() +
            rateToDisplay +
            `for ${moment(day).format(DATE_FORMAT_DEFAULT)} ${isLowest && !isDataLoading ? lowPriceIndicator : ''}`
      }
    >
      <div
        className={clsx(
          'DayPicker-Day-Cell-Wrapper',
          'px-0',
          'px-md-2',
          adfData ? '' : 'rate-unavailable',
          'pt-2',
          'pt-md-4',
          'pb-4',
          'pb-md-4'
        )}
      >
        <span className={clsx('DayPicker-Day-value', isMobile ? 't-subtitle-l' : 't-font-s"')}>{day?.getDate()}</span>
        <input className="DayPicker-Day-month-value" type="hidden" value={day?.getMonth()} />
        <input className="DayPicker-Day-year-value" type="hidden" value={day?.getFullYear()} />
        <div className="DayPicker-Day-custom-data">
          {!moment(day).utc().isBefore(moment().utc(), 'day') ? (
            isDataLoading ? (
              <>
                <div className="skeleton-loader-composite"></div>
                <div className="low-placeholder"></div>
              </>
            ) : (
              <div
                className={clsx(
                  !isMobile || !adfData || moment(day).isAfter(kDate) ? 't-font-alt-xs' : 't-font-alt-m ',
                  'price-section',
                  isLowest ? (isMobile ? 'm-price-saving' : 'mobile-price-low-rates') : ''
                )}
              >
                {moment(day).isAfter(kDate)
                  ? !isMobile
                    ? '-'
                    : data['rateUnavailableText']
                  : !adfData
                  ? !isMobile
                    ? '-'
                    : data['notAvailableForCheckInText']
                  : showCurrencySymbol() + rateToDisplay}
              </div>
            )
          ) : (
            ''
          )}
          {isLowest && !isDataLoading ? (
            <div className={clsx('t-font-alt-xs', 'low-label-adf', currentLocale === 'de-DE' ? 'de-related-font' : '')}>
              {lowPriceIndicator}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

//used to fetch rates based on rate type required
export const fetchSpecificRates = (
  rateObject: any,
  rateBlock: string,
  conversionFactorRate: number,
  mandatoryFeesValue?: number
) => {
  const manFees = mandatoryFeesValue ?? 0;
  const rateToDisplay =
    rateBlock === POINTS_PER_QUANTITY
      ? rateObject?.pointsPerQuantity
      : rateBlock === LOWEST_AVERAGE_RATE
      ? rateObject?.lowestAverageRate
      : rateObject?.totalRate;

  if (rateBlock === POINTS_PER_QUANTITY) {
    return rateToDisplay?.points;
  } else {
    return Math.round(
      conversionFactorRate
        ? (manFees ? manFees : 0) +
            (rateToDisplay?.amount?.amount / Math.pow(10, rateToDisplay?.amount?.valueDecimalPoint)) *
              conversionFactorRate
        : (manFees ? manFees : 0) +
            rateToDisplay?.amount?.amount / Math.pow(10, rateToDisplay?.amount?.valueDecimalPoint)
    );
  }
};
export const setLanguageLocalization = (
  monthsShortValue: Array<string>,
  monthsValue: Array<string>,
  weekdaysLongName: Array<string>,
  weeksdaysShort: Array<string>
): void => {
  moment.locale('locale', {
    months: monthsValue,
    monthsShort: monthsShortValue,
    weekdays: weekdaysLongName,
    weekdaysShort: weeksdaysShort,
  });
};

/**
 * Function to format the Price on locale based and also on viewPort specific
 * based on the length of the price we recieve
 */

export const PriceDisplay = (
  price: number,
  isViewportM: boolean,
  isViewportL: boolean,
  currentLocale: string,
  compactFormatLiteral: any
) => {
  let formattedPrice = '';

  /**
   * Check if the 'price' is a string or a number,
   * and assign the appropriate value to the 'number' variable.
   */
  const number = typeof price === 'string' ? parseFloat(price) : price;
  /**
   * Convert the price to string so as to calculate the length to format it based on the viewPorts
   */
  const numberAsString = number?.toString();
  const currencyLength = numberAsString?.length;
  if (
    !isNaN(number) &&
    ((currencyLength > DESKTOP_CURRENCY_LENGTH && isViewportL) ||
      (currencyLength > TABLET_CURRENCY_LENGTH && isViewportM && !isViewportL) ||
      (currencyLength > MOBILE_CURRENCY_LENGTH && !isViewportM))
  ) {
    formattedPrice = getCompactFormat(number, currentLocale, compactFormatLiteral);
  } else {
    formattedPrice = formatNumberByLocale(price, currentLocale);
  }
  return formattedPrice;
};

/**
 * Function to add the comma seperator for the formatted Price
 * on locale based and also on viewPort based
 */
export const getCommaSeparators = (
  price: number,
  isViewportM: boolean,
  isViewportL: boolean,
  currentLocale: string,
  compactFormatLiteral: any
): string => {
  let formattedNumber = '';
  if (price !== undefined) {
    formattedNumber = PriceDisplay(price, isViewportM, isViewportL, currentLocale, compactFormatLiteral);
  }
  return formattedNumber;
};
