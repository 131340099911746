// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { OffersSearchForm } from './OffersSearchForm';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const OffersSearchFormWrapperConfig = {
  emptyLabel: 'OffersSearchForm',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-searchform`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersSearchFormWrapper = (props: any) => (
  <EditableComponent config={OffersSearchFormWrapperConfig} {...props}>
    <OffersSearchForm {...props} />
  </EditableComponent> // Props contains data from AEM json and Datalayer.
  // Props can be passed to respective components through props.model
);
