import React, { FC, useContext, useRef } from 'react';
import { StyledFindBtn } from './FindBtn.styles';
import { ContentContext, FormValuesContext } from '../../../index.contexts';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { useTranslation } from 'react-i18next';
import { DATES, DESTINATION_HIDDEN_FIELDS } from '../../../constants/StoreConstants';
import { DATE_FORMAT_VALUE, RECENT_SEARCH_LIST_LIMIT } from '../../../constants/ApplicationConstants';
import { getSearchFormattedDate, getValueFromDate } from '../../../utils/DateUtils';
import { recentSearchObj } from '../../../index.types';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '@marriott/mi-ui-library';
import { INTERNAL_CTA_TYPE } from '../../../../../constants/CommonConstants';

declare module 'react' {
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}

const FindBtn: FC<{ isPublishUserSelection?: boolean; isMpo?: boolean; isSPO?: boolean }> = ({
  isPublishUserSelection = false,
  isMpo,
  isSPO,
}) => {
  const { ctaLabel, localDateFormat } = useContext(ContentContext);
  const { id, trackingProperties } = useContext(ContentContext);
  const { formValues } = useContext(FormValuesContext);
  const hiddenFieldsValues = formValues?.[DESTINATION_HIDDEN_FIELDS];
  const { t } = useTranslation();
  const eventDispatch = (): void => eventUtil.dispatch('searchFormValues', { formValues });
  const getRooms = formValues?.roomsAndGuests?.roomsCount;
  const trackValue = `Storefront Properties List | Search Form Component - Find Hotels - Check In Date= Rooms=${
    formValues?.roomsAndGuests?.roomsCount
  }, Adults=${formValues?.roomsAndGuests?.adultsCount}, Children=${formValues?.roomsAndGuests?.childrenCount}, ${
    formValues?.roomsAndGuests?.childrenAges?.length
      ? `Children age=${formValues?.roomsAndGuests?.childrenAges?.join(', ')}, `
      : ''
  } | Internal`;

  const offersTrackValue = `${id}|${ctaLabel}|${INTERNAL_CTA_TYPE}`;
  let customTrackValue = '';
  if (isSPO || isMpo) {
    customTrackValue = offersTrackValue;
  } else if (isPublishUserSelection && !isSPO) {
    customTrackValue = trackValue;
  }
  const OffersSearchFindBtnRef = useRef(null);

  /** Add dates value computation */
  const datesValues = formValues?.[DATES] ?? {};
  const { fromDate, toDate } = datesValues;
  const dateFormat = (localDateFormat as string) ?? DATE_FORMAT_VALUE;
  const fromDateValue = getValueFromDate(fromDate, dateFormat);
  const toDateValue = getValueFromDate(toDate, dateFormat);
  // const toDateValue = getValueFromDate(toDate, dateFormat);
  // const fromToDateValue = toDateValue;

  const findBtn = (event: React.SyntheticEvent): void => {
    if (!isMpo || getRooms === '10-25' || getRooms === '26+' || getRooms === '4-9') {
      if (isPublishUserSelection) {
        if (getRooms === '4-9') {
          eventUtil.dispatch('bannerMessage', { displayBanner: true });
          document.dispatchEvent(
            new CustomEvent('showBannerMessage', {
              detail: { displayBanner: true, bannerMessage: `${t('storefrontPropertySearchWidgetErrorMessageRooms')}` },
            })
          );
        } else {
          eventUtil.dispatch('bannerMessage', { displayBanner: false });
          document.dispatchEvent(
            new CustomEvent('showBannerMessage', {
              detail: {
                displayBanner: false,
                bannerMessage: `${t('storefrontPropertySearchWidgetErrorMessageRooms')}`,
              },
            })
          );
        }
      }
      if (isPublishUserSelection && getRooms !== '10-25' && getRooms !== '26+') {
        eventDispatch();
      }
    } else {
      event.preventDefault();
      eventUtil.dispatch('childAgeErrFlag', false);
      eventUtil.dispatch('stayDateMissingErrFlag', false);
      const childCount = formValues?.roomsAndGuests?.childrenCount;
      const childAges = formValues?.roomsAndGuests?.childrenAges;

      if (!fromDateValue) {
        eventUtil.dispatch('stayDateMissingErrFlag', true);
        return;
      }
      if (childAges?.length) {
        if (childAges?.includes('-1')) {
          eventUtil.dispatch('childAgeErrFlag', true);
          return;
        }
      }
      //numberInParty is total number of persons.
      const numberInParty =
        (formValues?.roomsAndGuests?.adultsCount ? formValues.roomsAndGuests.adultsCount : 0) + (childCount ?? 0);

      const searchFormData = {
        latitude: hiddenFieldsValues?.latitude ?? '',
        longitude: hiddenFieldsValues?.longitude ?? '',
        stateCode: hiddenFieldsValues?.state ?? '',
        countryCode: hiddenFieldsValues?.country ?? '',
        startDate: getSearchFormattedDate(fromDateValue),
        endDate: getSearchFormattedDate(toDateValue),
        quantity: formValues?.roomsAndGuests?.roomsCount,
        numberInParty: numberInParty,
        childAges: formValues?.roomsAndGuests?.childrenAges,
      };
      //setting localStorage
      const localSearch = localStorage.getItem('miRecentSearch');
      const recentSearches: recentSearchObj[] = JSON.parse(localSearch ? localSearch : '[]');

      const isDuplicate =
        recentSearches?.length && recentSearches.find(item => item.label === localStorage.getItem('recentSearchLabel'));
      if (!isDuplicate && localStorage.getItem('recentSearchLabel')) {
        const obj = {
          label: localStorage.getItem('recentSearchLabel'),
          state: searchFormData.stateCode,
          country: searchFormData.countryCode,
          value: localStorage.getItem('recentSearchLabel'),
          geocode: `${searchFormData.latitude},${searchFormData.longitude}`,
          placeId: localStorage.getItem('recentPlaceId'),
          latitude: searchFormData.latitude,
          longitude: searchFormData.longitude,
        };

        if (recentSearches && recentSearches.length >= 0) {
          if (recentSearches.length === RECENT_SEARCH_LIST_LIMIT) {
            recentSearches.pop();
          }
          recentSearches.unshift(obj);
        }
        localStorage.setItem('miRecentSearch', JSON.stringify(recentSearches));
      }
      localStorage.removeItem('recentSearchLabel');
      localStorage.removeItem('recentPlaceId');
      eventUtil.dispatch('MPOSearchFormData', { searchFormData });
    }
  };

  return (
    <StyledFindBtn className="brand-css">
      <Button
        buttonCopy={ctaLabel}
        type={
          isPublishUserSelection && getRooms !== '4-9' && getRooms !== '10-25' && getRooms !== '26+'
            ? ButtonTypeVariation.Button
            : ButtonTypeVariation.Submit
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback={(event: any): void => findBtn(event)}
        className={clsx('find-btn m-button-l m-button-primary custom_click_track brand-css')}
        custom_click_track_value={customTrackValue}
        trackingProperties={trackingProperties}
        ref={OffersSearchFindBtnRef}
      />
    </StyledFindBtn>
  );
};

export default FindBtn;
