import { useControl } from '@uiw/react-baidu-map';
import { useCallback, useEffect, useState } from 'react';
import { BaiduButton } from './BaiduMap.styles';
import { Icon } from '../Icon';
import { FullScreenProps } from './BaiduMap.types';

const FullScreenControl = ({ container, anchor = BMAP_ANCHOR_TOP_RIGHT }: FullScreenProps) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const { ControlPortal } = useControl({ anchor });

  useEffect(() => {
    document.onfullscreenchange = () => setFullScreen(!!document.fullscreenElement);
  }, []);

  const onFullScreen = useCallback(() => {
    if (isFullScreen) document.exitFullscreen?.();
    else container?.current?.requestFullscreen?.();
  }, [isFullScreen, container]);

  return (
    <ControlPortal key="full-screen-control">
      <BaiduButton onClick={onFullScreen}>
        <Icon iconClass={isFullScreen ? 'icon-clear' : 'icon-expand-white'} />
      </BaiduButton>
    </ControlPortal>
  );
};

export default FullScreenControl;
