import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledDestination = styled.div`
  position: relative;
  flex: 1;
  input {
    padding: 0;
    border: 0;
    height: auto;
    flex-grow: 1;
    text-overflow: ellipsis;
  }
  button {
    @media ${theme.mediaQuery.tablet} {
      background-color: transparent;
      padding: 0;
      text-align: left;
    }
    @media ${theme.mediaQuery.mobileOnly} {
      background-color: transparent;
      padding: 0;
      text-align: left;
      border: none;
      padding-left: ${toRem(16)};
      padding-top: ${toRem(4)};
      color: white;
    }
  }

  /* Autocomplete input style  */
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    padding: 0;
    @media ${theme.mediaQuery.ie11Only} {
      @media ${theme.mediaQuery.tablet} {
        padding-right: ${toRem(56)};
      }
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
    padding: 0;
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }

  .MuiAutocomplete-endAdornment {
    right: ${toRem(24)};
    top: calc(50% - 10px);
    .icon-cancel::before {
      color: ${theme.color['base-10']};
    }
  }

  .MuiAutocomplete-clearIndicator {
    padding: ${toRem(2)};
  }

  .MuiAutocomplete-root.dirty .MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }
  .MuiAutocomplete-root.dirty.focused .MuiAutocomplete-clearIndicator,
  .MuiAutocomplete-root.dirty.focused:hover .MuiAutocomplete-clearIndicator {
    visibility: visible;
  }

  .MuiAutocomplete-root.dirty:not(.focused):hover .MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }

  .MuiInput-underline::before {
    transition: none;
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: 0;
  }
  .MuiInput-underline:after {
    transition: none;
    border-bottom: 0;
  }
  .MuiInput-underline.Mui-focused::after {
    transform: scaleX(0);
  }
  legend {
    display: none;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] input[type='text'].MuiInputBase-input {
    box-sizing: border-box;
    padding: 0;
    border: none;
    height: auto;
    line-height: ${theme.fonts['lineHeightXxl']};
    caret-color: ${theme.color['light-orange-2']};
    font-family: ${theme.fontFamily.swiss};
    font-size: ${toRem(18)};
    color: ${theme.color['dark-gray-4']};
    @media ${theme.mediaQuery.mobileOnly} {
      color: ${theme.color['white']};
    }
    @media ${theme.mediaQuery.tablet} {
      font-size: ${theme.fonts['fontL']};
      line-height: ${theme.fonts['lineHeightL']};
      padding-bottom: 1rem;
    }
    @media ${theme.mediaQuery.desktop} {
      font-size: ${toRem(18)};
      line-height: ${theme.fonts['lineHeightXxl']};
      padding-bottom: 1rem;
    }
    &:focus {
      border-bottom: 0;
    }
  }

  .MuiIconButton-root,
  .MuiIconButton-root:hover {
    color: ${theme.color.white};
  }
  .MuiSvgIcon-root {
    height: ${toRem(18)};
    width: ${toRem(18)};
  }

  .dashed-border {
    &:after {
      content: '';
      border-right: ${toRem(1)} dashed ${theme.color['dark-gray-6']};
      height: calc(100% - ${toRem(48)});
      position: absolute;
      right: ${toRem(-13)};
      top: ${toRem(20)};
      @media ${theme.mediaQuery.desktop} {
        top: ${toRem(23)};
      }
    }
  }

  .show-popup {
    border-right: 0;
    margin: 0;
    padding: 0;
    @media ${theme.mediaQuery.tablet} {
      padding-bottom: 0;
      overflow: hidden;
      & > div:first-child {
        position: sticky;
      }
      &__scrollable {
        overflow: auto;
        max-height: ${toRem(480)};
      }
    }
    @media ${theme.mediaQuery.desktop} {
      padding: 0;
    }
    .with-input {
      cursor: auto;
      @media ${theme.mediaQuery.tablet} {
        padding: ${toRem(20)} ${toRem(24)} 0;
      }
      @media ${theme.mediaQuery.desktop} {
        padding: ${toRem(22)} ${toRem(32)} 0;
      }
      @media ${theme.mediaQuery.largeDesktop} {
        padding: ${toRem(22)} ${toRem(40)} 0;
      }
      &:hover {
        opacity: 1;
      }
      > div {
        border: 0;
      }

      label {
        @media ${theme.mediaQuery.tablet} {
          color: ${theme.color['light-gray-4']};
        }
        @media ${theme.mediaQuery.desktop} {
          padding-bottom: ${toRem(4)};
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 0;
      top: calc(50% - 20px);
    }
    .MuiInput-underline::after {
      transform: scaleX(1);
    }

    /* autocomplete popper style */
    .MuiAutocomplete-popperDisablePortal {
      position: relative;
    }
    .MuiAutocomplete-noOptions {
      display: none;
    }
    .MuiAutocomplete-popper {
      background: transparent;
      .MuiAutocomplete-listbox {
        overflow: visible;
        max-height: none;
        padding: 0;
        .MuiAutocomplete-option {
          p {
            color: ${baseVariables.color.base10};
            line-height: ${baseVariables.font.lineHeightM};
            font-weight: ${baseVariables.font.fontWeightMedium};
          }
          span {
            color: ${baseVariables.color.neutral40};
            line-height: ${baseVariables.font.lineHeightM};
            font-weight: ${baseVariables.font.fontWeightMedium};
          }
        }
      }
      .MuiAutocomplete-paper {
        margin: ${toRem(0)} ${toRem(-24)};
        overflow: visible;
        @media ${theme.mediaQuery.desktop} {
          margin-left: ${toRem(-32)};
          margin-right: ${toRem(-32)};
        }
        @media ${theme.mediaQuery.largeDesktop} {
          margin-left: ${toRem(-40)};
          margin-right: ${toRem(-40)};
        }
        @media ${theme.mediaQuery.mobileOnly} {
          margin-top: ${toRem(20)};
          margin-bottom: ${toRem(4)};
          background: transparent;
        }
      }
      .MuiAutocomplete-option {
        padding: ${toRem(12)} ${toRem(40)} ${toRem(12)} ${toRem(24)};
        overflow-wrap: break-word;
        display: block;
        &:first-child {
          margin-top: ${toRem(16)};
        }
        &:last-child {
          margin-bottom: ${toRem(16)};
        }
        @media ${theme.mediaQuery.mobileOnly} {
          white-space: normal;
          padding: ${toRem(16)} ${toRem(16)};
        }
        @media ${theme.mediaQuery.desktop} {
          padding-left: ${toRem(32)};
          padding-right: ${toRem(32)};
        }
        @media ${theme.mediaQuery.largeDesktop} {
          padding-left: ${toRem(40)};
          padding-right: ${toRem(40)};
        }
        font-family: ${theme.fontFamily.swiss};
        font-size: ${toRem(16)};
        line-height: ${toRem(20)};
        p {
          margin: 0;
        }
        span {
          color: ${theme.color['dark-gray-3']};
        }
      }
      > div {
        box-shadow: none;
      }
    }

    /* mobile black sticky bar style */
    @media ${theme.mediaQuery.mobileOnly} {
      .MuiInput-underline::after {
        border: 0;
      }

      .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
        padding-bottom: 0;
        padding-right: ${toRem(40)};
        color: ${theme.color.white};
      }

      .MuiAutocomplete-popper {
        padding: 0 ${toRem(24)};
        width: 100% !important;
        position: absolute;
        top: ${toRem(109)};
        left: 0;
      }
      .MuiAutocomplete-endAdornment {
        right: ${toRem(6)};
        top: calc(50% - 11px);
      }
      .MuiIconButton-root,
      .MuiIconButton-root:hover {
        color: ${theme.color['dark-gray-1']};
        padding: 0;
        span {
          &:before {
            font-size: ${toRem(15)};
            color: ${theme.color['white']};
          }
        }
      }
      .MuiSvgIcon-root {
        height: ${toRem(13)};
        width: ${toRem(13)};
      }
    }
  }
  .desktop-input {
    input {
      font-size: ${toRem(22)};
      border-bottom-color: transparent;
      @media ${theme.mediaQuery.allTablets} {
        font-size: ${toRem(18)};
      }
    }
  }

  .Mui-disabled {
    color: #707070;
  }
  .destination-label {
    padding: 0;
    padding-bottom: 0 !important;
    color: transparent;
    height: 0;
  }
  .dest-dropdown {
    z-index: 99;
  }
`;
