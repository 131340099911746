import React, { useContext } from 'react';
import { serverData } from '../HotelOverview';
import clsx from 'clsx';
import { clickTrackingLoc } from '../constants/ApplicationConstants';
import { usePageContext } from '../../../../context/PageContext';
import { hardCodedAllLocale, MILESTOKILOMETERS } from '../../../../../../mi-shop-components/src/constants';
import { addSubDirectoryPrefix } from '../../../../utils/src/helper';
import { formatNumberByLocale } from '../../../../utils/src/utils';
import { airportFeeDataTypes, feeDetailTypes } from './index.types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Accordion = (airportInfo: any): any => {
  const accordionData = airportInfo?.aeroData;
  const { jsonContent } = useContext(serverData);
  const { currentLocale } = usePageContext();

  const airportPhoneNumber =
    accordionData?.contactNumber?.number ?? accordionData?.contactNumber?.phoneNumber?.original;

  const formatPhoneNumber = (phoneNumberString: string): string => {
    let returnedNumber = '';
    if (phoneNumberString?.includes('+')) {
      if (phoneNumberString.replaceAll(' ', '').replaceAll('-', '')?.split('')?.length > 12) {
        returnedNumber = phoneNumberString
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replace(/(\d{2})(\d{3})(\d{4})/, '$1 $2 $3');
      } else {
        returnedNumber = phoneNumberString
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
      }
    }
    return returnedNumber;
  };

  // This logic is implemented to apply a New Logic for Airport Shuttle
  const getAirportAccordionLabel = (airportsData: airportFeeDataTypes) => {
    const shuttleFeeVal = airportsData?.fees?.find((item: feeDetailTypes) => item?.type?.code === 'shuttleFee');
    if (airportsData?.complimentaryShuttle) {
      return jsonContent?.['shuttleServiceLabel'];
    } else if (!airportsData?.complimentaryShuttle && !shuttleFeeVal) {
      return jsonContent?.['noShuttleServiceLabel'];
    } else if (!airportsData?.complimentaryShuttle && shuttleFeeVal) {
      const shuttleFeeAvailableLabel =
        jsonContent?.['shuttleFeeAvailableLabel'].indexOf('{X}') !== -1
          ? jsonContent?.['shuttleFeeAvailableLabel']
              .replace('{X}', shuttleFeeVal?.details?.amount?.amount)
              .replace('{currency}', shuttleFeeVal?.details?.amount?.currency)
          : '';
      return shuttleFeeAvailableLabel;
    } else {
      return null;
    }
  };

  // get distance value in kilometers
  const getDistanceValue = (distance: string): number | string => {
    const trimmedValue = distance ? +distance?.split(' ')[0] : 0;
    const distanceValInKilometers = trimmedValue
      ? formatNumberByLocale(parseFloat((trimmedValue * MILESTOKILOMETERS)?.toFixed(2)), currentLocale)
      : 0;

    return distanceValInKilometers;
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header location-box-header" id={accordionData.name}>
        <button
          tabIndex={0}
          className={clsx(
            'accordion-button',
            'location-box__accordian',
            'custom_click_track',
            airportInfo.active ? '' : 'show-border collapsed'
          )}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionData.name}${airportInfo.accIndex}`}
          aria-expanded={airportInfo.active ? 'true' : 'false'}
          aria-controls={`${accordionData.name}${airportInfo.accIndex}`}
          onClick={(e): void => {
            airportInfo.accordionClick(e, airportInfo.accIndex);
          }}
          onKeyDown={(e): void => {
            airportInfo.accordionClick(e, airportInfo.accIndex);
          }}
          {...{
            custom_click_track_value: `${clickTrackingLoc}| Airport Accordian: ${accordionData.name} |internal`,
          }}
        >
          <div className={clsx('m-accordion-title', 'airplane-icon-name', 'accordion-element')}>
            <span className={clsx('icon-s', 'mr-2', 'icon-airplane', 'icon-decorative')}></span>
            <span className={clsx('t-font-m', 'location-box__location-name')}>{accordionData.name}</span>
            <span
              className={clsx(
                'accordion-arrow-icon',
                airportInfo.active ? 'ml-3 icon-dropdown-up' : 'ml-3 icon-dropdown-down'
              )}
            ></span>
          </div>
        </button>
      </h2>
      {airportInfo.active && (
        <div
          id={`${accordionData.name}${airportInfo.accIndex}`}
          className={clsx(
            'accordion-collapse',
            'collapse panel',
            'accordion-content',
            airportInfo.active ? ' show' : ''
          )}
          aria-labelledby={accordionData.name}
          data-bs-parent="#accordionExample1"
        >
          <p className="accordion-body">
            <p className="t-font-s">
              {jsonContent?.['hotelDirectionLabel']}:{' '}
              {currentLocale === hardCodedAllLocale?.en
                ? accordionData?.distanceDetails?.description
                : `${getDistanceValue(accordionData?.distanceDetails?.description)} ${jsonContent?.['kmLabel']} ${
                    accordionData?.distanceDetails?.description?.split(' ')?.[2] ?? ''
                  }`}
            </p>
            {airportPhoneNumber && (
              <a href={`tel:` + airportPhoneNumber} className="t-font-s">
                <span>
                  {jsonContent?.['airportPhoneLabel']}:&nbsp;
                  <span dir="ltr" className="phone-number">
                    {formatPhoneNumber(airportPhoneNumber)}
                  </span>
                </span>
              </a>
            )}
            <p> </p>
            <a
              href={addSubDirectoryPrefix(accordionData.url)}
              className={clsx('hotel-website', 'custom_click_track')}
              target="_blank"
              rel="noreferrer"
              {...{ custom_click_track_value: `${clickTrackingLoc}| ${accordionData.name} website link |internal` }}
            >
              <span className="website-link">
                <p className={clsx('m-link-tertiary-button-external', 'underline-content')}>
                  {jsonContent?.['visitLabel']} {accordionData.id} {jsonContent?.['airportWebsiteLabel']}
                </p>
              </span>
              <span aria-label="Opens in a new browser window."></span>
            </a>
            {getAirportAccordionLabel(accordionData) && (
              <p className="t-font-s">{getAirportAccordionLabel(accordionData)}</p>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
