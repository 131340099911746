// Imports for external libraries go here.
import React from 'react';

import { OffersSearchResultsProps } from './OffersSearchResults.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersSearchResultsWrapper } from './OffersSearchResultsWrapper';

export const OffersSearchResultsWrapperConfig = {
  emptyLabel: 'OffersSearchResults',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offerssearchresults`,
};

export const OffersSearchResultsComp: React.FC<OffersSearchResultsProps> = props => {
  return <OffersSearchResultsWrapper data-component-name="o-offers-OffersSearchResults" {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersSearchResults = (props: any) => (
  <div data-testid="offers-search-result" data-component-name="o-offers-offerssearchresults">
    <EditableComponent config={OffersSearchResultsWrapperConfig} {...props}>
      <OffersSearchResultsComp {...props} />
    </EditableComponent>
  </div>
);
