/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { generateSeoFriendlyText, shouldUseMockData } from '../../utils/CommonUtils';
import { phoenixOfferDetailsForOutlets, phoenixOfferDetailsPreviewForOutlets } from '@marriott/mi-offers-graphql';
import { CardVertical, CardHorizontalFeature, Types } from '@marriott/mi-ui-library';
import { Button } from '@marriott/mi-ui-library';
import { Modal, Text } from '@marriott/mi-ui-library';
/* eslint-disable @nx/enforce-module-boundaries */
import { Pagination } from '@marriott/mi-ui-library';
import {
  OutletOfferResultInput,
  OutletOfferResultCardProps,
  facetListProps,
  OutletPayLoad,
} from './OutletOfferResult.types';

import { useOutletFilterStore } from '../../modules/store/outletFilterStore';
import { OUTLET_CUISINE, OUTLET_CITIES, OUTLET_DATA, OUTLET_COUNTRY } from '../../constants/OutletFilterConstants';
import {
  OUTLET_FALLBACK_SQ,
  OFFER_IMAGE_CACHE_DOMAIN,
  OUTLET_FALLBACK_HOR,
} from '../../constants/OfferDetailsConstants';
import { processAcceptLang } from '../../utils/OfferUtils';
import { EXTERNAL_CTA_TYPE, INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';
import AlertNotification from 'libs/mi-ui-library/src/atoms/Alerts';

const OutletOfferResultUXLMockJson = async (isAuthorMode: boolean) => {
  if (shouldUseMockData(isAuthorMode)) {
    const { OutletOfferResultUXLMockJson } = await import('./__mock__/OutletOfferResult.uxl'); //Load mock model dynamically only for dev mode
    return OutletOfferResultUXLMockJson;
  }
  return {};
};

export const OutletOfferResultContainer: React.FC<OutletOfferResultInput> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId, offerId } = props;
  const isPreview = props?.isPreview === 'true' ? true : false;
  const acceptLang = processAcceptLang(acceptLanguage ?? '')?.toLowerCase();
  const isServer = !(typeof window != 'undefined' && window.document);
  const offerIdArray: string[] = [];
  offerIdArray.push(offerId);
  //const { NEXT_PUBLIC_DEFAULT_OUTLET_IMAGE } = process.env;
  const [outletResult, setOutletResult] = useState<OutletOfferResultCardProps[]>();
  const [cardCount, setCardCount] = useState(0);
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<facetListProps[]>([]);
  const [newArray, setNewArray] = useState<facetListProps[]>([]);
  const [_uniqueItems, setUniqueItems] = useState<facetListProps[]>([]);
  const [_selectedButton, setSelectedButton] = useState<string>('');
  const [_currentSelectionArray, setCurrentSelectionArray] = useState<facetListProps[]>([]);
  const outletStoreData = useOutletFilterStore((state: any) => state);
  const [originalStoreData, setOriginalStoreData] = useState(outletStoreData);
  const setOutletFilterState = useOutletFilterStore((state: any) => state.setOutletFilterState);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedCuisine, setSelectedCuisine] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const PageSize = 9; //this can be changed
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setstartIndex] = useState(0);
  const [endIndex, setendIndex] = useState(startIndex + PageSize);
  const [clearBtn, setClearBtn] = useState<boolean>(false);
  const [totalFilterApplied, setTotalFilterApplied] = useState<number>(0);
  const [isApplyFilter, setIsApplyFilter] = useState(-1);
  const [queryData, setQueryData] = useState({});

  const TotalPages = Math.ceil(cardCount / PageSize);

  const [payloadObj, _setPayloadObj] = useState<OutletPayLoad>({
    input: {
      queries: [
        {
          id: 'offers',
          values: offerIdArray,
        },
      ],
      facets: {
        terms: [
          {
            type: 'OUTLET_CUISINE',
            dimensions: [],
          },
          {
            type: 'OUTLET_TYPE',
            dimensions: ['Dining'],
          },
          {
            type: 'CITIES',
            dimensions: [],
          },
          {
            type: 'COUNTRIES',
            dimensions: [],
          },
        ],
      },
    },
    sort: {
      field: 'PROPERTY_NAME',
    },
    offset: Number(PageSize) * (currentPage - 1), //pageNumber === 1 ? 0 : (pageNumber - 1) * PageSize,
    limit: PageSize,
  });

  const requestVariablesPreview = {
    offerPreviewId: offerId,
  };

  const uxlQuery = isPreview ? phoenixOfferDetailsPreviewForOutlets : phoenixOfferDetailsForOutlets;
  const {
    data: queryResultData,
    error: queryError,
    refetch: refetchData,
  } = useQuery(uxlQuery, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: 'ignore',
    variables: isPreview ? requestVariablesPreview : payloadObj,

    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLang,
      },
    },
    skip: isAuthorMode,
  });

  useEffect(() => {
    if (isApplyFilter !== 0) setQueryData(queryResultData);
  }, [isApplyFilter, queryResultData]);

  const checkBoxList =
    queryResultData && queryResultData.offersSearch && queryResultData.offersSearch.facets.length > 0
      ? queryResultData?.offersSearch?.facets.filter((x: any) => x.type.description)
      : '';

  const sortedCheckBoxList = [...checkBoxList]
    .sort((a: any, b: any) => b.type.description.localeCompare(a.type.description))
    .filter(item => item?.type?.description !== 'outlet-type');

  const reformPreviewResponse = (response: any) => {
    return {
      offersSearch: {
        edges: [
          {
            node: response?.offerPreview?.offer,
          },
        ],
      },
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkDataAvailable = (queryData: any) => {
    if (isPreview) {
      if (!queryData) return false;
      else return true;
    } else {
      if (queryData?.offersSearch?.edges?.length) return true;
      else return false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processUxlData = async (uxlData: any) => {
    let outlets: OutletOfferResultCardProps[] = [];
    const getOutletOfferResultUXLMockJson = await OutletOfferResultUXLMockJson(isAuthorMode);
    const response = isAuthorMode ? getOutletOfferResultUXLMockJson : uxlData || [];
    const outletCount = response?.offersSearch?.edges[0]?.node?.numberOfOutlets;
    setCardCount(outletCount);

    response?.offersSearch?.facets?.forEach((facetBucket: any) => {
      const filterData = filterName;
      filterData.push(facetBucket.type.description);
      setFilterName(filterData);
      facetBucket?.buckets?.map((facetItem: any) => {
        const filterData: facetListProps = {
          ...facetItem,
          additionalProperty: facetBucket.type.description,
        };
        newArray.push(filterData);
        setNewArray(newArray);
        const uniqueIds = new Set<string>();
        const uniqueItems = newArray.filter(item => {
          if (uniqueIds.has(item.additionalProperty)) {
            return false;
          } else {
            uniqueIds.add(item.additionalProperty);
            return true;
          }
        });
        setUniqueItems(uniqueItems);
      });
    });

    response?.offersSearch?.edges[0]?.node?.associatedOutlets?.forEach((associatedOutletItem: any) => {
      const outletData: OutletOfferResultCardProps[] = associatedOutletItem?.outlets?.map(
        (outletItem: any, index: number) => {
          const defaultImage = OFFER_IMAGE_CACHE_DOMAIN + OUTLET_FALLBACK_SQ;
          const defaultImageHorizontal = OFFER_IMAGE_CACHE_DOMAIN + OUTLET_FALLBACK_HOR;
          const marshaCode = associatedOutletItem?.property?.id;
          const propertyName = associatedOutletItem?.property?.basicInformation?.name;
          const outletName = outletItem?.name;
          const outletId = outletItem?.id;
          const marshaSeo = generateSeoFriendlyText(marshaCode);
          const propertyNameSeo = generateSeoFriendlyText(propertyName);
          const outletNameSeo = generateSeoFriendlyText(outletName);
          const outletCta = `/${acceptLang}/dining/restaurant-bar/${marshaSeo}-${propertyNameSeo}/${outletId}-${outletNameSeo}.mi`;
          const outletCtaFormat = outletCta.replace(/-{2,}/g, '-').replace(/-(?=\.[^.]*$)/g, '');
          const imageNodePrimary = outletItem.media?.primaryArchitecturalImage;
          const imageNodeSecondary = outletItem.media?.primaryServiceImage?.edges[0]?.node?.imageUrls?.square;
          const imageNodeHorizontalPrimary =
            outletItem.media?.primaryArchitecturalImage?.edges[0]?.node?.imageUrls?.classicHorizontal;
          const imageNodeHorizontalSecondary =
            outletItem.media?.primaryServiceImage?.edges[0]?.node?.imageUrls?.classicHorizontal;

          const image =
            outletCount && outletCount > 1
              ? imageNodePrimary
                ? OFFER_IMAGE_CACHE_DOMAIN +
                  outletItem.media?.primaryArchitecturalImage?.edges[0]?.node?.imageUrls?.square
                : imageNodeSecondary
                ? OFFER_IMAGE_CACHE_DOMAIN + outletItem.media?.primaryServiceImage?.edges[0]?.node?.imageUrls?.square
                : defaultImage
              : imageNodeHorizontalPrimary
              ? OFFER_IMAGE_CACHE_DOMAIN +
                outletItem.media?.primaryArchitecturalImage?.edges[0]?.node?.imageUrls?.classicHorizontal
              : imageNodeHorizontalSecondary
              ? OFFER_IMAGE_CACHE_DOMAIN +
                outletItem.media?.primaryServiceImage?.edges[0]?.node?.imageUrls?.classicHorizontal
              : defaultImageHorizontal;

          const cardLocDetails = model?.trackingProperties?.location
            ? `${model.trackingProperties.location}-card-${index}`
            : `card-${index}`;
          const merchandCat = model?.trackingProperties?.merchandisingCategory
            ? model.trackingProperties.merchandisingCategory
            : 'nonCobrand';

          return {
            cardId: outletId,
            cardProps: {
              headerTag: 'h4',
              description: outletItem?.description,
              cardVerticalVariations: 'Tall1:1',
              dynamicMedia: {
                altText: 'Sunrise over the clouds',
                assetPath: image,
                dynamic: true,
                renditions: [
                  {
                    renditionPath: `${image}??downsize=364px:*`,
                    mediaValue: '768px',
                    width: 0,
                    dynamic: true,
                    damPath:
                      '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                    mediaQuery: 'min-width',
                    height: 0,
                  },
                  {
                    renditionPath: `${image}?downsize=364px:*`,
                    mediaValue: '576px',
                    width: 0,
                    dynamic: true,
                    damPath:
                      '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                    mediaQuery: 'min-width',
                    height: 0,
                  },
                  {
                    renditionPath: `${image}?downsize=332px:*`,
                    mediaValue: '576px',
                    width: 0,
                    dynamic: true,
                    damPath:
                      '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                    mediaQuery: 'max-width',
                    height: 0,
                  },
                ],
                damPath:
                  '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
              },
              ctaType: 'tertiaryButton',
              ':type': 'mi-aem-common-spa/components/content/cardvertical',
              badgeIcon: 'icon-globe',
              imgAltText: 'Sunrise over the clouds',
              fileReferenceImage:
                '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
              ctaLinkText: model?.cardLearnMoreLabel,
              ctaLink: outletCtaFormat,
              linkText: model?.cardLearnMoreLabel,
              linkUrl: outletCtaFormat,
              imageAlignment: 'left',
              contentBlockAlignment: 'center',
              trackingProperties: {
                atCCeVar48: model?.trackingProperties?.atCCeVar48 ?? null,
                trackingDescription: outletItem?.description,
                clickTrack: model?.trackingProperties?.clickTrack,
                impressionTrack: model?.trackingProperties?.impressionTrack,
                location: model?.trackingProperties?.location,
                trackingContentPosition: cardLocDetails,
                merchandisingCategory: merchandCat,
                impressionCount: model?.trackingProperties?.impressionCount,
                trackingTag: model?.trackingProperties?.trackingTag,
                trackingOfferType: model?.trackingProperties?.trackingOfferType ?? null,
              },
              //iconFontValue: 'icon-location',
              eyebrow: propertyName,
              eyebrowtext: propertyName,
              styleclass: 'standard t-subtitle-xl',
              header: outletName,
              openInNewTab: true,
            },
          };
        }
      );

      outlets = outlets.concat(outletData);
    });
    setOutletFilterState(
      [OUTLET_DATA],
      {
        [OUTLET_DATA]: {
          allOutlets: outlets,
        },
      },
      true
    );
    setOutletResult(outlets);
  };

  useEffect(() => {
    if (queryData && !isAuthorMode) {
      if (checkDataAvailable(queryData)) {
        processUxlData(isPreview ? reformPreviewResponse(queryData) : queryData);
      }
    } else if (isAuthorMode) {
      (async () => {
        const getPropertiesListUXLMockJson = await OutletOfferResultUXLMockJson(isAuthorMode);
        processUxlData(getPropertiesListUXLMockJson);
      })();
    }
  }, [queryData, queryError]);

  const emptyPayloadObj: OutletPayLoad = {
    input: {
      queries: [
        {
          id: 'offers',
          values: offerIdArray,
        },
      ],
      facets: {
        terms: [
          {
            type: 'OUTLET_CUISINE',
            dimensions: [],
          },
          {
            type: 'OUTLET_TYPE',
            dimensions: ['Dining'],
          },
          {
            type: 'CITIES',
            dimensions: [],
          },
          {
            type: 'COUNTRIES',
            dimensions: [],
          },
        ],
      },
    },
    sort: {
      field: 'PROPERTY_NAME',
    },
    offset: 0,
    limit: PageSize,
  };

  const toggleModal = (event: any) => {
    setSelectedItems([]);
    setPopupOpenState(!popupOpenState);
    const currentButtonName = event.currentTarget.id;
    setSelectedButton(currentButtonName);
  };

  const clearAll = () => {
    setClearBtn(false);
    setOutletFilterState(
      [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
      {
        [OUTLET_CUISINE]: {
          selectedCuisine: [],
        },
        [OUTLET_CITIES]: {
          selectedCities: [],
        },
        [OUTLET_COUNTRY]: {
          selectedCountry: [],
        },
      },
      true
    );
    setIsApplyFilter(-1);
    setSelectedCities([]);
    setSelectedCuisine([]);
    setSelectedCountry([]);
    setCurrentPage(1);
    setendIndex(0 + PageSize);
    setstartIndex(0);
    setOriginalStoreData({
      cities: {
        selectedCities: [],
      },
      cuisine: {
        selectedCuisine: [],
      },
      country: {
        selectedCountry: [],
      },
    });
    refetchData(emptyPayloadObj);
    setTotalFilterApplied(0);
  };

  const closeModal = () => {
    if (
      (originalStoreData && originalStoreData.cuisine && originalStoreData.cuisine.selectedCuisine) ||
      (originalStoreData && originalStoreData.cities && originalStoreData.cities.selectedCities)
    ) {
      setOutletFilterState(
        [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
        {
          [OUTLET_CUISINE]: {
            selectedCuisine: [...originalStoreData.cuisine.selectedCuisine],
          },
          [OUTLET_CITIES]: {
            selectedCities: [...originalStoreData.cities.selectedCities],
          },
          [OUTLET_COUNTRY]: {
            selectedCountry: [...originalStoreData.country.selectedCountry],
          },
        },
        true
      );
      setSelectedCities([...originalStoreData.cities.selectedCities]);
      setSelectedCuisine([...originalStoreData.cuisine.selectedCuisine]);
      setSelectedCountry([...originalStoreData.country.selectedCountry]);
    }
    if (isApplyFilter !== 1) {
      refetchData(emptyPayloadObj);
    }

    setPopupOpenState(!popupOpenState);

    setCurrentSelectionArray([]);
    const classToRemove = document.getElementsByClassName('outlet-filter-cta');
    for (let i = 0; i < classToRemove.length; i++) {
      classToRemove[i].classList.remove('active');
    }
  };

  const updateCheckStatus = (event: any, description: string, code: string) => {
    if (event.target.checked) {
      selectedItems.push(description);
      if (event.target.value === 'outlet-cuisine') {
        selectedCuisine.push(description);
      } else if (event.target.value === 'cities') {
        selectedCities.push(code);
      }
    } else {
      selectedItems.splice(selectedItems.indexOf(description), 1);
      if (event.target.value === 'cities') {
        selectedCities.splice(selectedCities.indexOf(code), 1);
      }
      if (event.target.value === 'outlet-cuisine') {
        selectedCuisine.splice(selectedCuisine.indexOf(description), 1);
      }
    }
    setOutletFilterState(
      [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
      {
        [OUTLET_CUISINE]: {
          selectedCuisine: [...selectedCuisine],
        },
        [OUTLET_CITIES]: {
          selectedCities: [...selectedCities],
        },
        [OUTLET_COUNTRY]: {
          selectedCountry: [...selectedCountry],
        },
      },
      true
    );
    selectFilter();
  };

  const unSelectAll = () => {
    document.querySelectorAll('input[type=checkbox]').forEach(el => ((el as HTMLInputElement).checked = false));
    setSelectedItems([]);
    setOutletFilterState(
      [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
      {
        [OUTLET_CUISINE]: {
          selectedCuisine: [],
        },
        [OUTLET_CITIES]: {
          selectedCities: [],
        },
        [OUTLET_COUNTRY]: {
          selectedCountry: [],
        },
      },
      true
    );
    setIsApplyFilter(0);
    setSelectedCities([]);
    setSelectedCuisine([]);
    setSelectedCountry([]);
    refetchData(emptyPayloadObj);
  };
  const onPageItemClick = (page: number): void => {
    setCurrentPage(page);
    calculateIndex(page);
    window.scrollTo({ top: 800, left: 0, behavior: 'smooth' });
    const emptyPayloadObjPagination: OutletPayLoad = {
      input: {
        queries: [
          {
            id: 'offers',
            values: offerIdArray,
          },
        ],
        facets: {
          terms: [
            {
              type: 'OUTLET_CUISINE',
              dimensions: [],
            },
            {
              type: 'OUTLET_TYPE',
              dimensions: ['Dining'],
            },
            {
              type: 'CITIES',
              dimensions: [],
            },
            {
              type: 'COUNTRIES',
              dimensions: [],
            },
          ],
        },
      },
      sort: {
        field: 'PROPERTY_NAME',
      },
      offset: Number(PageSize) * (page - 1),
      limit: PageSize,
    };
    emptyPayloadObjPagination.input.facets.terms[0].dimensions.push(...selectedCuisine);
    emptyPayloadObjPagination.input.facets.terms[1].dimensions.push('Dining');
    emptyPayloadObjPagination.input.facets.terms[2].dimensions.push(...selectedCities);
    emptyPayloadObjPagination.input.facets.terms[3].dimensions.push(...selectedCountry);
    setIsApplyFilter(1);
    refetchData(emptyPayloadObjPagination);
  };
  const calculateIndex = (page: number) => {
    const startInd = (page - 1) * PageSize;
    const endInd = startInd + PageSize;
    setstartIndex(startInd);
    setendIndex(endInd);
    if (page === TotalPages) {
      setendIndex(cardCount);
    }
  };

  const applyFilter = () => {
    payloadObj.input.facets.terms.filter(item => {
      if (item.dimensions.length !== 0) {
        item.dimensions.length = 0;
      }
      return payloadObj;
    });
    const tempObj = payloadObj;
    tempObj.input.facets.terms[0].dimensions.push(...selectedCuisine);
    tempObj.input.facets.terms[1].dimensions.push('Dining');
    tempObj.input.facets.terms[2].dimensions.push(...selectedCities);
    setOutletFilterState(
      [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
      {
        [OUTLET_CUISINE]: {
          selectedCuisine: [...selectedCuisine],
        },
        [OUTLET_CITIES]: {
          selectedCities: [...selectedCities],
        },
        [OUTLET_COUNTRY]: {
          selectedCountry: [...selectedCountry],
        },
      },
      true
    );
    setOriginalStoreData(outletStoreData);
    setIsApplyFilter(1);
    setCurrentPage(1);
    setendIndex(0 + PageSize);
    setstartIndex(0);
    refetchData(tempObj);
    setTotalFilterApplied(
      selectedItemsFromStore && selectedItemsFromStore?.length ? selectedItemsFromStore?.length : 0
    );
    setClearBtn(selectedItemsFromStore && selectedItemsFromStore?.length ? true : false);
  };

  useEffect(() => {
    if (selectedItemsFromStore && selectedItemsFromStore?.length !== 0)
      setTotalFilterApplied(selectedItemsFromStore?.length);
  }, [totalFilterApplied]);

  const selectFilter = () => {
    payloadObj.input.facets.terms.filter(item => {
      if (item.dimensions.length !== 0) {
        item.dimensions.length = 0;
      }
      return payloadObj;
    });
    const tempObj = payloadObj;
    tempObj.input.facets.terms[0].dimensions.push(...selectedCuisine);
    tempObj.input.facets.terms[1].dimensions.push('Dining');
    tempObj.input.facets.terms[2].dimensions.push(...selectedCities);
    setOutletFilterState(
      [OUTLET_CUISINE, OUTLET_CITIES, OUTLET_COUNTRY],
      {
        [OUTLET_CUISINE]: {
          selectedCuisine: [...selectedCuisine],
        },
        [OUTLET_CITIES]: {
          selectedCities: [...selectedCities],
        },
        [OUTLET_COUNTRY]: {
          selectedCountry: [...selectedCountry],
        },
      },
      true
    );
    setIsApplyFilter(0);
    refetchData(tempObj);
  };

  const selectedItemsFromStore = outletStoreData?.cities?.selectedCities
    .concat(outletStoreData?.cuisine?.selectedCuisine)
    .concat(outletStoreData?.country?.selectedCountry);

  return (
    <div className={`container ${model?.appliedCssClassNames}`}>
      <div className="outlet-properties-title" id={`${model?.componentId}`}>
        <Text copyText={model?.headerText} fontSize={Types.size.medium} element={Types.tags.paragraph} />
      </div>
      {outletResult && outletResult.length === 0 ? (
        <AlertNotification errorMessage={model?.errorMessage} className={'error-message alert'} />
      ) : null}
      {cardCount ? (
        <div className="filter-btns-wrapper">
          {(outletResult && outletResult.length > 1) || isApplyFilter !== -1 ? (
            <Button
              id="all-filters"
              callback={toggleModal}
              className={clsx(
                `button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m outlet-filter-cta`
              )}
              custom_click_track_value={`${model?.id}|${model?.allFilters}|${INTERNAL_CTA_TYPE}`}
            >
              {totalFilterApplied !== 0 ? <span className="icon-s all-filter-badge">{totalFilterApplied}</span> : null}
              <span className="icon-s icon-dining-filter">{model?.allFilters}</span>
            </Button>
          ) : (
            ''
          )}
          <Button
            id="all-clear"
            callback={clearAll}
            className={clsx(
              `button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m outlet-filter-cta-clear
            ${clearBtn ? 'display' : ''}`
            )}
            custom_click_track_value={`${model?.id}|${model?.clear}|${INTERNAL_CTA_TYPE}`}
            testId="all-filters-test-id"
          >
            <span className="icon-s icon-clear">{model?.clear}</span>
          </Button>
        </div>
      ) : (
        ''
      )}
      {cardCount && cardCount > PageSize ? (
        <div className={clsx('pageResultNumbers')}>
          <p className={clsx('pb-1', 'mb-0')}>
            Showing {startIndex + 1}-{endIndex} of {cardCount ? cardCount : ''}
          </p>
        </div>
      ) : (
        ''
      )}
      <div className={clsx(cardCount > 0 ? '' : 'hideCarousel', 'cardWrapper')}>
        {cardCount > 1 &&
          outletResult?.map(card => (
            <div className="col-xs-12 col-md-6 col-lg-4 card-vertical-outlets">
              <CardVertical
                {...card?.cardProps}
                custom_click_track_value={`${model?.id}|${card?.cardId}-${card?.cardProps?.header}|${EXTERNAL_CTA_TYPE}`}
              />
            </div>
          ))}
        {cardCount === 1 &&
          outletResult?.map(card => (
            <div>
              <CardHorizontalFeature
                assetVariation="image"
                {...card?.cardProps}
                custom_click_track_value={`${model?.id}|${card?.cardId}-${model?.cardLearnMoreLabel}|${EXTERNAL_CTA_TYPE}`}
              />
            </div>
          ))}
      </div>
      {cardCount && cardCount > PageSize ? (
        <div className="pagination-container mt-5">
          <Pagination
            currentPage={currentPage}
            totalCount={cardCount}
            pageSize={PageSize}
            onPageChange={(page: number): void => onPageItemClick(page)}
          />
        </div>
      ) : (
        ''
      )}
      {popupOpenState && (
        <Modal
          className={`custom-scrollbar modal-main-container ${popupOpenState && 'close-popup-header'}`}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          allFilterPopUp={true}
          onClose={(): void => {
            setPopupOpenState(false);
            closeModal();
          }}
        >
          <Modal.Header
            className="modal-header t-subtitle-xl"
            labelText="Filters"
            popupHeaderOnCLoseFunc={closeModal}
          />
          <Modal.Body className="all-filters custom-scrollbar">
            <div className="facet-wrapper">
              {sortedCheckBoxList &&
              sortedCheckBoxList[0] &&
              sortedCheckBoxList[0].buckets &&
              sortedCheckBoxList[0].buckets.length > 0
                ? sortedCheckBoxList.map((x: any) => (
                    <div id={x.type.description} className="checkbox-wrap">
                      <h5>
                        {x.type.description === 'cities'
                          ? JSON.stringify(model?.cityFilterLabel).replace(/['"]+/g, '')
                          : x.type.description === 'outlet-cuisine'
                          ? JSON.stringify(model?.cuisineFilterLabel).replace(/['"]+/g, '')
                          : x.type.description === 'country'
                          ? JSON.stringify(model?.countryFilterLabel).replace(/['"]+/g, '')
                          : ''}
                      </h5>
                      {x.type.description !== 'outlet-type' &&
                        x.buckets?.map(
                          (
                            filterSetName: {
                              checked: boolean | undefined;
                              code: string;
                              description: string;
                              count: number;
                            },
                            index: number | null | undefined
                          ) => (
                            <div className="col-md-6 checkbox-items">
                              <input
                                key={index}
                                value={x.type.description}
                                type="checkbox"
                                id={`box_${index}_${x.type.description}`}
                                checked={
                                  selectedItemsFromStore && selectedItemsFromStore.length
                                    ? selectedItemsFromStore.filter(
                                        (sName: string) =>
                                          filterSetName.description === sName || filterSetName.code === sName
                                      ).length > 0
                                      ? true
                                      : false
                                    : false
                                }
                                onChange={e => updateCheckStatus(e, filterSetName.description, filterSetName.code)}
                                className="checkbox-input"
                              />
                              <label
                                htmlFor={`box_${index}_${x.type.description}`}
                                className="l-margin-top checkbox-label custom_click_track track-completed"
                              >
                                {filterSetName.description} ({filterSetName.count})
                              </label>
                            </div>
                          )
                        )}
                    </div>
                  ))
                : ''}
            </div>
          </Modal.Body>
          <Modal.Footer
            clearClickHandler={unSelectAll}
            setPopUpOpenState={setPopupOpenState}
            applyBtnClickHandler={() => {
              applyFilter();
            }}
            clearButtonLabel={model?.clear}
            applyLabel={model?.applyLabel}
            className="modal-footer"
            clickTrackingLoc={'SEARCH_FILTER_MODAL'}
          />
        </Modal>
      )}
    </div>
  );
};

export default OutletOfferResultContainer;
