import styled from 'styled-components';
import { mediaQuery } from '../../utils/styles';

import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const CardsListingStyle = `
.feature-header{
  line-height:1;
}
.content
.text-left {
  align-items: flex-start;
  padding: 3.9375rem 3.5rem 5.4375rem;
}
.list{
  &-header{
  text-align:center;
  margin-bottom:${toRem(60)};
   @media ${mediaQuery.tabletMax} {
      margin-bottom:${toRem(50)};
   }
   @media ${mediaQuery.mobileOnly} {
     margin-bottom:${toRem(30)};
   }

    h2{
    color: ${baseVariables.color.black}
    font-size: ${toRem(40)};
    font-weight: 500;
    letter-spacing: 0;
    line-height: ${toRem(44)};
    margin:0;

      @media ${mediaQuery.tabletMax} {
      line-height: ${toRem(32)};
      font-size: ${toRem(30)};
      }

      @media ${mediaQuery.mobileOnly} {
        line-height: ${toRem(32)};
        font-size: ${toRem(30)};
      }
    }
  }
  &-body{
    .offer-card{
      margin: ${toRem(64)} 1rem 0;
      @media ${mediaQuery.tabletMax} {
        width: calc(100% - 2rem);
        margin: ${toRem(48)} 1rem 0;
      }
      @media ${mediaQuery.mobileOnly} {
      width: calc(100% - 2rem);
      margin: ${toRem(32)} 1rem 0;
      }

      &-btn{
        padding: ${toRem(10.45)} ${toRem(23.71)} ${toRem(12.2)} ${toRem(23.71)};
      }
      &-btn-icon{
        display: none;
      }
    }
.cardstylehidden{
  display:flex;
  justify-content:flex-end;
  flex-direction:row;
  display:none;

}
.show-more-button-center-align{
  display:flex;
  align-items:center;
  justify-content:center;
  font-style: ${baseVariables.font.fontFamilyPl};
}
.cardstylevisible{
  @media ${mediaQuery.desktop} {
    display:flex;
    flex-direction:row;
  }
  @media ${mediaQuery.allTablets} {
    display:flex;
    flex-direction:row;
  }
}
.cardstylevisible:nth-child(odd){
  @media ${mediaQuery.desktop} {
    flex-direction:row-reverse;
  }
  @media ${mediaQuery.allTablets} {
    flex-direction:row-reverse;
  }
}


    .show-more-section{
      text-align: center;
      margin-top: ${toRem(64)};
      @media ${mediaQuery.tabletMax} {
        margin-top:${toRem(48)};
      }
      @media ${mediaQuery.mobileOnly} {
        margin-top:${toRem(32)};
      }

      .offer-card-show-more-btn{
        display:flex;
        align-items:center;
        justify-items:center;
        width: fit-content;
        max-width: 100%;
        height: 2rem;
        border-radius: 1.25rem;
        padding: ${toRem(5)} ${toRem(20.48)} ${toRem(7)};
        text-align: center;
        text-decoration: none;
        border-radius: ${toRem(26)};
        cursor: pointer;
        font-family: ${baseVariables.font.fontFamilyPl};
        @media ${mediaQuery.tabletMax} {
          font-size: ${toRem(13)};
        }
        @media ${mediaQuery.mobileOnly}{
          padding: ${toRem(4)} ${toRem(21.84)} ${toRem(10)};
        }
        &:focus{
          outline: none;
        }
      }
    }
  }
}
`;

export const OfferCardsListingStyled = styled.div`
  .cmp-offers-cards-listing {
    width: 100%;

    .offers-container {
      width: 100%;
      margin: 0 auto;
      max-width: ${toRem(1124)};
      padding-bottom: ${toRem(64)};
      padding-top: ${toRem(44)};

      @media ${mediaQuery.tabletMax} {
        max-width: ${toRem(704)};
        padding-bottom: ${toRem(64)};
        padding-top: ${toRem(39)};
      }

      @media ${mediaQuery.mobileOnly} {
        max-width: ${toRem(332)};
        padding-top: ${toRem(24)};
        padding-bottom: ${toRem(64)};
      }
      ${CardsListingStyle}
    }
  }
`;
export const StyledOfferCardsDiv = styled.div``;
export const StyledOfferCardsDivPreviewMode = styled.div`
  pointer-events: none;
  .glide__arrow--right {
    display: none;
  }
  .center-align {
    display: none !important;
  }
`;
