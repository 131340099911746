// Styles for OffersAemText go here.
import styled from 'styled-components';

export const StyledOffersAemText = styled.div`
  &.invalidDealMsg,
  &.invalidUserMsg,
  &.invalidPreviewOffer,
  &.notEligible {
    display: none;
    border: solid 1px #d0021b;
    border-radius: 8px;
    padding: 12px 20px;
    p {
      color: #d0021b;
      margin-bottom: 0;
      margin-left: 0.625rem;
    }
  }
`;
