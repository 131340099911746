// Styles for OffersHero go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { mediaQuery, font } from '../../utils/styles';

export const StyledOffersHero = styled.div``;
export const StyledOffersHeroPreviewMode = styled.div`
  pointer-events: none;
`;

// Styles for single prop hero
export const StyledOffersHeroSingleProperty = styled.div`
  .hero-img-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: ${baseVariables.color.neutral00};
    .hero-img-container {
      width: 100%;
      height: ${toRem(359)};
      @media ${mediaQuery.tablet} {
        height: ${toRem(256)};
      }
      @media ${mediaQuery.desktop} {
        height: ${toRem(480)};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .container {
      padding: 0;
    }
    @media (min-width: 819px) {
      .container {
        margin-left: 0.3rem;
      }
    }
    @media (min-width: 767px) and (max-width: 799px) {
      .container {
        margin-left: 0rem;
      }
    }
    @media ${mediaQuery.mobileOnly} {
      .container {
        padding: 0;
      }
    }
    @media ${mediaQuery.mobile} {
      .container {
        padding: 0rem 0rem;
      }
    }
    .left-section-wrapper {
      @media ${mediaQuery.desktop} {
        max-width: ${toRem(1140)};
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        transition: all 1s;
        padding: 0 ${toRem(8)};
      }
      .hero-left-section {
        position: absolute;
        z-index: 1;
        bottom: ${toRem(105)};
        color: ${baseVariables.color.base20};
        margin-left: ${toRem(16)};
        top: auto;
        @media ${mediaQuery.tablet} {
          bottom: 0;
          width: ${toRem(344)};
          margin-left: ${toRem(42)};
          top: ${toRem(56)};
        }
        @media ${mediaQuery.desktop} {
          top: ${toRem(145)};
          width: ${toRem(551)};
          margin-left: 0;
          bottom: auto;
        }
        @media (min-width: 1023px) and (max-width: 1099px) {
          margin-left: 2.5rem;
        }
        @media (min-width: 799px) and (max-width: 818px) {
          margin-left: 0;
        }
        @media ${mediaQuery.mobileOnly} {
          width: ${toRem(332)};
          margin-left: 0;
        }
        @media ${mediaQuery.mobile} {
          margin-left: 1rem;
        }
        h2 {
          font-weight: 500;
          letter-spacing: 0;
          line-height: ${toRem(32)};
          margin-bottom: ${toRem(8)};
          color: ${baseVariables.color.base20};
          text-shadow: 0 0 ${toRem(2)} ${baseVariables.color.neutral00};
          @media ${mediaQuery.desktop} {
            line-height: ${toRem(44)};
          }
        }
        .m-details-page-teaser {
          font-family: ${baseVariables.font.fontFamilyPl};
          font-size: ${toRem(14)};
          letter-spacing: 0;
          line-height: ${toRem(20)};
          text-shadow: 0 0 ${toRem(2)} ${baseVariables.color.neutral00};
          @media ${mediaQuery.tablet} {
            font-size: ${toRem(16)};
            line-height: ${toRem(24)};
          }
          p {
            margin-top: 0;
            margin-bottom: 0;
            font-family: ${font.Swiss721BTRegular};
          }
        }
        .m-primary-btn-wrapper {
          margin-top: ${toRem(24)};
          .hero-img-button {
            padding-top: ${toRem(8)};
            background: ${baseVariables.color.alert10};
            color: ${baseVariables.color.merch30};
            &:active {
              box-shadow: inset ${toRem(9999)} ${toRem(9999)} rgba(28, 28, 28, 0.2);
              border-color: rgba(28, 28, 28, 0.2);
            }
            &:hover {
              @media ${mediaQuery.desktop} {
                box-shadow: inset ${toRem(9999)} ${toRem(9999)} rgba(28, 28, 28, 0.2);
                border-color: rgba(28, 28, 28, 0.2);
              }
            }
          }
        }
      }
      .t-color-white {
        color: ${baseVariables.color.alert10};
      }
      .t-font-swiss721 {
        font-family: ${baseVariables.font.fontFamilyPl};
      }
      .t-font-brand-style {
        font-family: var(--t-font-family);
      }
      .t-font-brand-style {
        font-family: var(--t-font-family);
      }
    }
    .hero-img-gradient {
      position: absolute;
      height: 100%;
      width: 65%;
      left: 0;
      top: 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 55.04%, rgba(0, 0, 0, 0.62) 100%);
      @media ${mediaQuery.mobileOnly} {
        width: 100%;
        height: ${toRem(359)};
      }
    }
  }
`;

// Styles for multi prop hero
export const OffersHeroMultiPropertyStyled = styled.div`
  .section-wrapper {
    @media ${mediaQuery.desktop} {
      min-height: ${toRem(715)};
    }

    display: flex;
    img {
      height: 100%;
      object-fit: cover;
    }
    @media ${mediaQuery.mobileOnly} {
      flex-direction: column;
    }
    @media ${mediaQuery.tabletOnly} {
      min-height: ${toRem(336)};
    }
    .image-section {
      width: 62%;
      position: relative;
      @media ${mediaQuery.tabletOnly} {
        width: 54%;
      }
      @media ${mediaQuery.mobileOnly} {
        width: 100%;
        height: ${toRem(291)};
        img {
          height: ${toRem(291)};
        }
      }
    }

    .details-section {
      width: 38%;
      padding-bottom: ${toRem(0)};
      @media ${mediaQuery.tabletOnly} {
        width: 46%;
      }
      @media ${mediaQuery.mobileOnly} {
        width: 100%;
        min-height: ${toRem(291)};
      }
      .t-font-swiss721 {
        font-family: ${baseVariables.font.fontFamilyPl};
      }

      .details {
        padding: ${toRem(244)} ${toRem(48)} ${toRem(65)};
        .logo.t-brand-logo-s {
          margin-bottom: ${toRem(24)};
          .brand-logo-WH:before{
            font-size: ${toRem(26)};
          }
        }
        @media ${mediaQuery.tabletOnly} {
          padding: ${toRem(96)} ${toRem(32)} ${toRem(55)};
          .logo.t-brand-logo-s {
            margin-bottom: ${toRem(12)};
          }
        }
        @media ${mediaQuery.mobileOnly} {
          padding: ${toRem(66)} ${toRem(40)} ${toRem(45)};
          .logo.t-brand-logo-s {
            margin-bottom: ${toRem(10)};
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          text-align: center;
          margin-bottom: ${toRem(8)};
        }

        .desc {
          margin-bottom: ${toRem(22)};
          text-align: center;
          p {
            font-family: ${baseVariables.font.fontFamilyPl};
            font-size: ${toRem(16)};
            letter-spacing: 0;
            line-height: ${toRem(24)};
            text-align: center;
            margin: 0;
          }
        }
        @media ${mediaQuery.mobileTablet} {
          .desc {
            p {
              font-size: ${toRem(14)};
              letter-spacing: 0;
              line-height: ${toRem(20)};
            }
          }
        }

        .navigation-btns {
            &.mpo-sbo-button{
              button:focus{
                outline:2px solid transparent;
              }
              
            }
          margin-top: ${toRem(8)};
          a.m-button-primary {
            border: none;
            height: ${toRem(40)};
            border-radius: ${toRem(20)};
            padding: ${toRem(8.34)} ${toRem(25.67)} ${toRem(12.34)} !important;
            text-decoration: none;
            font-family: ${baseVariables.font.fontFamilyPl};
            &:hover {
              border: none;
            }
          }
          a {
            font-size: ${toRem(16)};
            font-weight: 500;
          }

          @media ${mediaQuery.tabletOnly} {
            a.m-button-primary {
              font-size: ${toRem(13)};
              line-height: ${toRem(16)};
              font-weight: 500;
              height: ${toRem(32)};
            }
          }
        }
      }

      &.multi-brand-multi-property-offer {
        background-color: ${baseVariables.color.merch30};
        .details {
          padding-top: ${toRem(273)};
          @media ${mediaQuery.tabletOnly} {
            padding-top: ${toRem(104)};
          }
          @media ${mediaQuery.mobileOnly} {
            padding-top: ${toRem(83)};
          }
          .title {
            .mpo-hero-title {
              font-size: 2.5rem;
            }
            .t-title-m {
              color: ${baseVariables.color.merch50};
            }
          }
          .desc p {
            color: ${baseVariables.color.merch50};
          }

          .navigation-btns {
            a.m-button-primary {
              background-color: ${baseVariables.color.merch50};
              color: ${baseVariables.color.merch30};
              @media ${mediaQuery.mobileOnly} {
                font-size: ${toRem(13)};
                line-height: ${toRem(16)};
                height: ${toRem(32)};
              }

              &:hover {
                background-color: ${baseVariables.color.merch30};
                color: ${baseVariables.color.merch50};
              }
            }
          }
        }
      }
    }
    &.mpo-mbo-styles {
      .details{
        padding-top: ${toRem(273)};
        @media ${mediaQuery.tabletOnly} {
          padding-top: ${toRem(104)};
        }
        @media ${mediaQuery.mobileOnly} {
          padding-top: ${toRem(83)};
        }
        .title {
          .mpo-hero-title {
            font-size: 2.5rem;
          }
          .t-title-m {
            color: ${baseVariables.color.merch50};
            font-weight:500;
            display:inline;
            text-transform:none;
          }
        }
       .desc p {
        color: ${baseVariables.color.merch50};
        font-size:${toRem(14)};
        font-weight:400;
      }
      .navigation-btns {
        &.mpo-mbo-button {
          button {
            font-size: ${toRem(16)};
                font-weight:500;
 background-color: ${baseVariables.color.merch50};
              color: ${baseVariables.color.merch30};
              @media ${mediaQuery.tabletOnly} {
                font-size: ${toRem(13)};
               }
              @media ${mediaQuery.mobileOnly} {
                background-color: ${baseVariables.color.merch50};
                font-size: ${toRem(13)};
                font-weight:500;
                line-height: ${toRem(16)};
                height: ${toRem(32)};
              }
              &:hover {
                box-shadow: inset 9999px 9999px hsla(0,0%,100%,.2);
                background-color: ${baseVariables.color.merch30};
                color: ${baseVariables.color.merch50};
              }
              &:focus {
               outline:2px solid transparent;
               
              }
          }
        }
      }
    }
  }
  img {
    width: 100%;
  }
`;
