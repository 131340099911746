import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const SearchDesktopSecondary = styled.div`
  margin: 0;
  display: flex;
  background-color: ${theme.color.white};
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 ${toRem(14)} ${toRem(14)};

  margin-top: -${toRem(14)};

  @media ${theme.mediaQuery.tablet} {
    padding-top: ${toRem(14)} !important;
    box-shadow: rgb(0 0 0 / 7%) 0px 0.25rem 1.25rem 0px;
  }
  @media ${theme.mediaQuery.desktop} {
    padding-top: ${toRem(14)} !important;
    padding-left: ${toRem(4)} !important;
    box-shadow: rgb(0 0 0 / 7%) 0px 0.25rem 1.25rem 0px;
  }

  > div {
    > button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      span {
        margin-left: ${toRem(10)};
      }
    }
    padding: ${toRem(16)} ${toRem(24)} ${toRem(13.5)};
    @media ${theme.mediaQuery.tablet} {
      padding: ${toRem(16)} ${toRem(24)} ${toRem(18)} ${toRem(24)};
    }
    @media ${theme.mediaQuery.desktop} {
      padding: ${toRem(16)} ${toRem(20)} ${toRem(18)} ${toRem(36)};
    }
  }
  input[type='checkbox'] + label {
    top: 0;
    font-family: ${theme.fontFamily.swiss};
  }

  .search__container-hide-destination {
    .search-secondary {
      box-shadow: 0 2px 20px 0 rgb(0 0 0 / 7%);
    }
  }
`;
