import React, { FC, useContext, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { ViewportContext, ContentContext, FormValuesContext } from '../../../index.contexts';
import { DestinationProps } from './Destination.types';
import { StyledDestination } from './Destination.styles';

import { VARIATION_DESKTOP } from '../../../constants/ApplicationConstants';
import CommonUtils from '../../../utils/CommonUtils';
import { DESTINATION } from '../../../constants/StoreConstants';
import PopupHeader from '../../atoms/PopupHeader/PopupHeader';
import { useTranslation } from 'react-i18next';
import PopupFooter from '../../atoms/PopupFooter/PopupFooter';

const Destination: FC<DestinationProps> = ({ onCancel, onChange: changeMobileState, isMPO }) => {
  const { t } = useTranslation();
  const { mobilePlaceholderText, desktopPlaceholderText, destination } = useContext(ContentContext);
  const { formValues } = useContext(FormValuesContext);
  const searchText = formValues?.[DESTINATION]?.displayText;
  const isMobileView = useContext(ViewportContext);
  const compRef = useRef<HTMLDivElement>(null);
  const variation = CommonUtils.getVariation();
  const value = searchText ?? '';

  const timestamp = new Date().getTime();
  const formId = timestamp.toString() + '-offer-search-destination';
  // const value = "Offer Name";

  // const [value, setValue] = useState<string | null>(searchText ? searchText : null);

  const changeMobileStateOnDone = (): void => {
    // setPopupOpen(false);
    // setValue(value);
    changeMobileState?.();
  };

  const popUpHeaderContent = (): React.ReactNode => {
    return (
      <StyledDestination>
        <PopupHeader
          inputId={formId}
          labelIcon="icon-location"
          labelText={destination}
          cancelClickHandler={isMobileView && onCancel ? onCancel : undefined}
          isMPO={isMPO}
        >
          <label htmlFor="destination-property" className="destination-label" aria-hidden="true">
            .
          </label>
          <TextField
            placeholder={
              variation !== VARIATION_DESKTOP ? (mobilePlaceholderText as string) : (desktopPlaceholderText as string)
            }
            variant="standard"
            fullWidth
            value={value}
            // value = 'Sheraton Grande Tokyo Bay Hotel'
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={isMobileView ? true : false}
            disabled={true}
            className="desktop-input"
            id="destination-property"
            role="textbox"
            aria-label={value}
          />
          {/* <input type="hidden" name="searchCriteriaVO.destination" value={value ?? ''} /> */}
          {isMobileView ? (
            <button className={value} type="submit" style={{ display: 'none' }}>
              .
            </button>
          ) : (
            ''
          )}
        </PopupHeader>

        {isMobileView ? (
          <PopupFooter primaryBtnLabel={t('done')} primaryBtnClickHandler={changeMobileStateOnDone} />
        ) : (
          ''
        )}
      </StyledDestination>
    );
  };

  return (
    <StyledDestination ref={compRef}>
      <>{popUpHeaderContent()}</>
    </StyledDestination>
  );
};

export default Destination;
