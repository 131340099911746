/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Container } from '@adobe/aem-react-editable-components';
import { MegaMenu } from './MegaMenu';
import { SubNavigation } from './SubNavigation';
import { NavigationUtilityLink } from './NavigationUtilityLink';
import { BrandRibbon } from '../../organisms/BrandRibbon';
import Link from 'next/link';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import {
  PrimaryNavEnum,
  PrimaryNavItemProps,
  PrimaryNavItemWrapperProps,
  AemContainerConfig,
  ComponentTypeEnum,
} from './Navigation.types';
import { constants, sortAEMModelItemsByOrder } from '@marriott/mi-ui-library';
import { AEMModel } from '@marriott/mi-ui-library';
import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';
import { BrandribbonVariation } from '../../organisms/BrandRibbon/BrandRibbon.types';

export const PrimaryNavItem = (props: PrimaryNavItemWrapperProps) => {
  const {
    cqPath = '',
    isAuthorMode,
    primaryNavigationItem = {},
    index,
    changeNavOverFlowStyle,
    activeMainNav,
    setActiveMainNav,
    primaryNavRefs,
    isFocused,
    setIsFocused,
    utilityNavigation,
    setIsMegaMenuActive,
    megaMenuCloseButtonLabel,
  } = props;

  const { MEGA_MENU, BRAND_RIBBON } = ComponentTypeEnum;

  const primaryNavItem: PrimaryNavItemProps =
    primaryNavigationItem?.[':type'] === MEGA_MENU ? primaryNavigationItem?.resourceProperties : primaryNavigationItem;
  const megaMenuItems: AEMModel = primaryNavigationItem?.[':items']
    ? primaryNavigationItem?.[':items']['megaMenuItems']
    : {};
  const dynamicData = primaryNavigationItem['backgroundMedia'] ? primaryNavigationItem['backgroundMedia'] : {};

  const subNavItems: any = sortAEMModelItemsByOrder(megaMenuItems) || [];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const subNavRefs: React.MutableRefObject<HTMLElement | null>[] = subNavItems?.map((_: any) => useRef(null)) || [];

  const trackingProperties = primaryNavigationItem?.trackingProperties;

  const cardArticleData = primaryNavigationItem?.['resourceProperties']
    ? primaryNavigationItem?.['resourceProperties']
    : {};

  const isArticleCardEnabled =
    cardArticleData &&
    cardArticleData.ctalink &&
    cardArticleData.ctalabel &&
    cardArticleData.descriptiontext &&
    cardArticleData.titletext &&
    dynamicData;

  const articleCardRef = useRef<HTMLButtonElement | null>(null);
  if (isArticleCardEnabled) {
    subNavRefs.push(articleCardRef);
  }

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  subNavRefs.push(closeButtonRef);

  const handleMegaMenuKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { key } = event;
    const itemsLength = subNavRefs.length;
    const currentIndex = subNavRefs.findIndex(linkRef => linkRef.current === document.activeElement);

    if (key === 'Escape') {
      return handleMegaMenuClose();
    }
    if (key === 'ArrowUp' || key === 'ArrowLeft' || (event.shiftKey && key === 'Tab')) {
      if (currentIndex === 0) {
        primaryNavRefs.current[index].focus();
      }
      event.preventDefault();
      subNavRefs[currentIndex - 1]?.current?.focus();
    } else if (key === 'ArrowDown' || key === 'ArrowRight' || key === 'Tab') {
      event.preventDefault();
      if (currentIndex < itemsLength - 1) {
        subNavRefs[currentIndex + 1]?.current?.focus();
      } else if (currentIndex === itemsLength - 1) {
        primaryNavRefs.current[index + 1].focus();
      }
    }
  };

  const { PANEL_TITLE, PANEL_URL } = PrimaryNavEnum;
  const { TARGET_BLANK } = constants;
  const isSubNavAvailable = !!(!primaryNavItem?.[PANEL_URL] && megaMenuItems && megaMenuItems[':itemsOrder']?.length);
  const isBrandRibbonAvailable = primaryNavigationItem?.[':type'] === BRAND_RIBBON;
  const isContentAvailable = isSubNavAvailable || isBrandRibbonAvailable;

  const PrimaryMenuItemConfig: AemContainerConfig = {
    emptyLabel: 'Marriott Primary Navitem Container',
    isEmpty: () => false,
    resourceType: primaryNavigationItem?.[':type'] || '',
    cqPath: cqPath,
    isInEditor: !!isAuthorMode,
  };

  const handleMainNavKeyDown = (index: number, event: React.KeyboardEvent<HTMLAnchorElement>, url: string) => {
    const { key } = event;

    if (key === 'ArrowDown' && subNavItems.length > 0) {
      if (activeMainNav !== index) {
        handleMainNavClick(index, event, url);
      }
      setTimeout(() => subNavRefs[0]?.current?.focus());
    }

    if (key === 'ArrowLeft' || (event.shiftKey && key === 'Tab')) {
      if (activeMainNav === index - 1) {
        event.preventDefault();
        document.getElementById('close-megamenu-button')?.focus();
      } else if (index > 0) {
        event.preventDefault();
        primaryNavRefs.current[index - 1].focus();
      }
    } else if (key === 'ArrowRight' || key === 'Tab') {
      if (activeMainNav === index) {
        event.preventDefault();
        subNavRefs[0]?.current?.focus();
      } else if (index < primaryNavRefs.current.length - 1) {
        event.preventDefault();
        primaryNavRefs.current[index + 1].focus();
      }
    }
    if (key === 'Enter' || key === ' ') {
      handleMainNavClick(index, event, url);
    }
  };

  //shows mega-menu when primary nav link is clicked
  const handleMainNavClick = (index: number, event: any, url?: string) => {
    if (!url) event.preventDefault();
    if (activeMainNav === index) {
      handleMegaMenuClose();
      return;
    }
    setIsMegaMenuActive(true);
    setActiveMainNav && setActiveMainNav(index);
  };

  //closes mega menu when close or back button is clicked
  const handleMegaMenuClose = () => {
    setIsMegaMenuActive(false);
    setActiveMainNav && setActiveMainNav(null);
    primaryNavRefs.current[index].focus();
  };

  const trackingValue = getClickTrackValue({
    trackingProperties,
    url: null,
    fallbacks: {
      position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
      description:
        primaryNavItem?.[PANEL_TITLE] || primaryNavItem?.cqPanelTitle || DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  });

  const primaryNavItemNode = (
    <li className="main-nav__nav-item" key={index} role="presentation">
      <Link
        href={primaryNavItem?.[PANEL_URL] || ''}
        className={clsx('m-nav-link custom_click_track', { focused: isFocused }, { active: activeMainNav === index })}
        role="menuitem"
        aria-current={isFocused ? 'page' : undefined}
        aria-label={primaryNavItem?.[PANEL_TITLE] || ''}
        onFocus={() => setIsFocused && setIsFocused(true)}
        onBlur={() => setIsFocused && setIsFocused(false)}
        tabIndex={0}
        ref={element => element && (primaryNavRefs.current[index] = element)}
        target={primaryNavItem?.['openInaNewTab'] ? TARGET_BLANK : '_self'}
        onKeyDown={event => handleMainNavKeyDown(index, event, primaryNavItem?.[PANEL_URL] || '')}
        onClick={event => handleMainNavClick(index, event, primaryNavItem?.[PANEL_URL])}
        data-custom_click_track_value={trackingValue}
      >
        {primaryNavItem?.[PANEL_TITLE] || primaryNavItem?.cqPanelTitle}
        {isContentAvailable && (
          <span className="icon-arrow-right main-nav__nav-link-arrow d-lg-none" data-testid="menu-right-arrow"></span>
        )}
      </Link>

      {activeMainNav === index && isContentAvailable && (
        <MegaMenu
          handleCloseButtonClick={handleMegaMenuClose}
          changeNavOverFlowStyle={changeNavOverFlowStyle}
          currentMainNav={primaryNavItem[PANEL_TITLE] || primaryNavItem?.cqPanelTitle || ''}
          megaMenuCloseButtonLabel={megaMenuCloseButtonLabel}
          handleKeyDown={handleMegaMenuKeyDown}
          ref={closeButtonRef}
        >
          {isSubNavAvailable && (
            <SubNavigation
              megamenuitems={megaMenuItems}
              maxItemsPerColumn={primaryNavItem?.maxColumnItems}
              subNavItems={subNavItems}
              // TODO: i18nOpensInNewTab value should be replaced with the value coming from AEM during integration story
              i18nOpensInNewTab="Opens in a New Tab"
              // TODO: isArticleCardEnabled value should be replaced with the value coming from AEM during Article card story: https://jira.marriott.com/browse/FSD-77628
              cardArticleData={cardArticleData}
              dynamicData={dynamicData}
              handleMegaMenuClose={handleMegaMenuClose}
              isArticleCardEnabled={isArticleCardEnabled}
              forwardedRefs={subNavRefs}
              primaryParentLabel={primaryNavItem?.[PANEL_TITLE] || primaryNavItem?.cqPanelTitle}
            />
          )}
          {isBrandRibbonAvailable && (
            <>
              <BrandRibbon
                variations={BrandribbonVariation.PortfolioBrands}
                categorylists={primaryNavItem?.categorylists || []}
              />
              <Button
                className={[
                  'exlpore-all m-link-tertiary-button mb-3',
                  primaryNavItem?.['openInaNewTab'] === 'true' ? 'm-link-tertiary-button-external' : '',
                ]}
                isLink={true}
                target={primaryNavItem?.['openInaNewTab'] === 'true' ? TARGET_BLANK : '_self'}
                href={primaryNavItem?.cqPanelUrl}
              >
                {primaryNavItem?.exploreLabel}
              </Button>
            </>
          )}
          {utilityNavigation?.[':items'] && (
            <div className="sub-nav-utility-container d-lg-none">
              <NavigationUtilityLink utilityNavigation={utilityNavigation} isRenderedInsideOverlay={true} />
            </div>
          )}
        </MegaMenu>
      )}
    </li>
  );

  return (
    <Container {...PrimaryMenuItemConfig} className={clsx({ 'py-0': isAuthorMode })} childPages={primaryNavItemNode} />
  );
};
