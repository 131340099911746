// Styles for OffersSearchForm go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { theme } from '@marriott/mi-ui-library-shop';

export const StyledOffersSearchForm = styled.div`
  .error-message {
    border: ${toRem(1)} solid ${baseVariables.color.alert50};
    border-radius: ${toRem(8)};
    background-color: rgb(252, 235, 237);
    margin-top: ${toRem(16)};
    margin-bottom: ${toRem(10)};
    .m-message-inline.col-10 {
      flex: unset;
      max-width: 100%;
      border: none;
      background-color: rgb(252, 235, 237);
      padding: ${toRem(12)} ${toRem(20)};
    }
    .m-message-content-wrap {
      &:before {
        color: ${baseVariables.color.alert50};
      }
      color: ${baseVariables.color.alert50};
    }
  }
  .search-form-wrapper {
    box-shadow: none;
    border-radius: ${toRem(16)};
    background: none;
    label {
      margin-bottom: 0;
    }
  }
  &.sticky-search-container {
    position: fixed;
    width: 100%;
    top: 52px !important;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 1001;
    padding: 0 !important;
    @media ${theme.mediaQuery.allMobiles} {
      top: 34px !important;
    }
    .offers-spo {
      @media ${theme.mediaQuery.allMobiles} {
        padding: 0 !important;
      }
    }
    .search-primary {
      box-shadow: none;
    }
    .search-secondary {
      display: none;
    }
  }
  &.search-container-top {
    padding-top: ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding-top: ${toRem(32)} !important;
    }
  }
  &.sticky-search-top {
    position: fixed;
    width: 100%;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 30;
    transition: top 0.4s ease 0s;
    padding: 0 !important;
    top: 52px !important;
    @media ${theme.mediaQuery.allMobiles} {
      top: 34px !important;
    }
    .offers-spo {
      @media ${theme.mediaQuery.allMobiles} {
        padding: 0 !important;
      }
    .search-primary {
      box-shadow: none;
    }
    .search-secondary {
      display: none !important;
    }
  }
`;
