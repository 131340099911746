import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { StyledPopupProps } from './Popup.types';

export const StyledPopupDiv = styled.div<StyledPopupProps>(({ show }) =>
  show
    ? `
    overflow: visible;
    width: 100%;
    position: relative;
    @media ${theme.mediaQuery.tablet} {
        position: absolute;
        top: 0;
        left: ${toRem(-8)};
        width: auto;
        width: ${toRem(352)};
        height: auto;
        overflow: hidden;
        overflow-y: auto;
        max-height: ${toRem(570)};
        background: ${theme.color.white};
        box-shadow: ${theme.color['cta-banner-shadow']};
        border-radius: ${toRem(14)};
        z-index: 2;
        padding: ${toRem(20)} ${toRem(24)};
    }

    @media ${theme.mediaQuery.desktop} {
        padding: ${toRem(23)} ${toRem(32)};
        width: ${toRem(386)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
        padding: ${toRem(23)} ${toRem(40)};
        width: ${toRem(402)};
    }
    ::-webkit-scrollbar {
        width: 0.3125rem;
    }
    ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 0.125rem;
        margin: 0.75rem 0;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 0.125rem;
    }
`
    : ''
);
