/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { logger } from '../logger';
import { DataLayerAttributes, DataLayerResponse } from './index.types';
import { getDataLayer } from './index';
import {
  COOKIE_KEYS,
  PI_DATA,
  DATA_LAYER_IGNORE_LIST as dataLayerIgnoreList,
  DATA_LAYER_CAN_BE_NULL_LIST as dataLayerCanBeNullList,
} from '../../lib/constants';
import {
  GlobalPageProps,
  PersistentDatalayerAttributes,
  SessionData,
  DatalayerValueGenerators as ValueGenerators,
} from '../session/types';
import {
  getAkamaiLocationAttrs,
  getAriesErrorObj,
  getCookiePersonalizationGuid,
  getCurrentDateTime,
  getCurrentLocale,
  getDatalayerAttrList,
  getEnvServerId,
  getEnvSiteId,
  getPageUrlPathBranch,
  getPreviousPageUrl,
  getPromoSpecialOfferId,
  propertyResolver,
} from './datalayerHelpers';
import { populatePIdataLayer } from '../helpers';

export const getDatalayerResponse = async (req: any, ignoreMVPOffers = false) => {
  global.loggerInstance = logger({
    sessionID: req?.cookies?.sessionID,
    requestID: req.headers['x-request-id'],
  }) as CallableFunction;
  const { log } = global.loggerInstance('getDatalayerResponse');
  const requestId = req.headers['x-request-id'];
  const currentLocale = req.headers['accept-language'];
  const locale = currentLocale?.split(',')[0]?.replace('-', '_');
  log.debug(`getDataLayer request headers Accept-Language: ${req.headers['accept-language']}`);
  log.debug(
    `getDataLayer request headers requestId: ${req.headers['x-request-id']} and host: ${req.headers['x-host']}`
  );
  log.debug(`getDataLayer resolvedUrl passed ${requestId?.split('.mi')[0]}`);
  log.debug(`getDataLayer locale passed ${locale}`);

  try {
    // Fetch data layer response
    const datalayerResp = (await getDataLayer(
      req,
      requestId?.split('.mi')[0],
      locale,
      req.query,
      ignoreMVPOffers
    )) as DataLayerResponse;

    const dataLayerObj = datalayerResp?.data[0] || {};
    const mvpOffers =
      datalayerResp?.mvpOffersData && datalayerResp?.mvpOffersData !== 'null' ? datalayerResp?.mvpOffersData : '{}';

    // Extract the keys from PI_DATA to filter out
    const dataLayerFilterList = new Set(Object.keys(PI_DATA));

    // filter datalayer object without PI_DATA
    const filteredDataLayer = Object.keys(dataLayerObj).reduce<Record<string, any>>((obj, key) => {
      if (!dataLayerFilterList.has(key)) {
        obj[key] = dataLayerObj[key];
      }
      return obj;
    }, {});

    // Convert filtered object back to JSON string
    const dataLayer = JSON.stringify(filteredDataLayer);

    // Return the filtered JSON string of dataLayer and mvpOffers
    return { dataLayer, mvpOffers };
  } catch (error) {
    log.error(`Error in getDatalayerResponse: ${error}`);
    throw error;
  }
};

export function normalizeDataAttributes(attributes?: DataLayerAttributes[]) {
  return attributes?.reduce((acc: Record<string, string>, attr: DataLayerAttributes) => {
    if (attr.value && attr.value !== 'undefined' && attr.value !== null) {
      acc[attr.key as string] = attr.value as string;
    }
    return acc;
  }, {});
}

/**
 *
 * @param sessionData
 * @returns and object that contains list of commonly used dataLayer keys
 * 1. cookie_merchViewed value for this key is same as 'merchViewed' cookie
 * 2. promo_special_offer_id, if url.pathname contains '/search/default'
 *    then value  is promo_special_offer_id of sessionData.AriesCommon object
 *    else marOfferId of AriesSearch
 * 3. cookie_personalization_guid, its encoded values based on Personalization cookie please do refer that function
 * 4. env_api_tile_error_info, it will be populated when ther are Aries Errors in sessionData
 * 5. env_site_name, if pageURI contains rfp then it will be RITZ domain else xHost in sessionData
 * 6. page_domain_name, if pageURI doesnt contain rfp then value should be xHost of sessionData, else empty
 * 7. page_type, its value is combination of env_site_name & pageURI
 * 8. page_url_path_branch, if pageURI contains 'search' then value should be
 *    'search/findHotels.mi' else either pageURI or '/offers'
 * 9. request_id, this refers to either sessionData.x-request-id or requestId in pageProps
 */
const getCommonKeys = (sessionData: SessionData, pageProps: GlobalPageProps) => {
  const pageURI = pageProps?.resolvedUrl;
  const xHost = sessionData?.data?.xHost;
  return {
    cookie_merchViewed: propertyResolver(Cookies.get(COOKIE_KEYS?.merchViewed)),
    promo_special_offer_id: getPromoSpecialOfferId(sessionData),
    cookie_personalization_guid: getCookiePersonalizationGuid(),
    env_api_tile_error_info: getAriesErrorObj(sessionData),
    env_site_name: xHost,
    page_domain_name: propertyResolver(!pageURI?.includes('rfp') ? xHost : ''),
    page_type: propertyResolver(xHost + pageURI),
    page_url_path_branch: getPageUrlPathBranch(pageProps),
    request_id: propertyResolver(sessionData?.data?.['x-request-id'] ?? pageProps?.requestId),
  };
};

const datalayerValueGenerator: ValueGenerators = {
  browser_akamai_loc_country: ({ akamaiLocationAttrs }) => propertyResolver(akamaiLocationAttrs?.country_code),
  browser_akamai_loc_lat: ({ akamaiLocationAttrs }) => propertyResolver(akamaiLocationAttrs?.lat),
  browser_akamai_loc_long: ({ akamaiLocationAttrs }) => propertyResolver(akamaiLocationAttrs?.long),
  browser_akamai_loc_city: ({ akamaiLocationAttrs }) => propertyResolver(akamaiLocationAttrs?.city?.toLowerCase()),
  browser_akamai_loc_state: ({ akamaiLocationAttrs }) =>
    propertyResolver(akamaiLocationAttrs?.region_code?.toLowerCase()),
  cookie_mi_site: ({ sessionData }) => propertyResolver(sessionData?.data?.xHost),
  device_language_preferred: ({ pageProps }) => getCurrentLocale(pageProps)?.replace('-', '_'),
  env_date_time: () => getCurrentDateTime(),
  env_server_id: () => getEnvServerId(),
  env_site_id: ({ pageProps }) => getEnvSiteId(pageProps),
  env_language: () => '', // check its value??
  page_requested_uri: () => propertyResolver(window.location.pathname.replace('.mi', '')),
  page_url_query_string: () => propertyResolver(window.location.search?.split('?')?.[1]),
  site_id: ({ pageProps }) => getEnvSiteId(pageProps),
  previous_page: () => getPreviousPageUrl(),
};

// Reading the datalayer attributes from master list and populating them from value generator function
// if some key is present is master list but in generator function, picking up default value from master list
const getFilteredDataLayerValues = (
  datalayerAttrList: PersistentDatalayerAttributes,
  valueGenerator: ValueGenerators,
  sessionData: SessionData,
  pageProps: GlobalPageProps,
  includePiData: boolean
) => {
  const akamaiLocationAttrs = getAkamaiLocationAttrs(sessionData?.data?.akamaiHeader);
  let datalayerObj = Object.entries(datalayerAttrList).reduce((acc: Record<string, string>, [key, defaultValue]) => {
    const value = valueGenerator[key]
      ? valueGenerator[key]({ sessionData, akamaiLocationAttrs, pageProps })
      : defaultValue;
    // remove empty values except the onces which can be empty in datalayer
    if (dataLayerCanBeNullList.includes(key) || value !== '') {
      acc[key as string] = value as string;
    }
    return acc;
  }, {});
  // adding PI data to acdl when includePiData is true
  if (includePiData) {
    const piDataLayerObj = populatePIdataLayer(sessionData);
    datalayerObj = { ...datalayerObj, ...piDataLayerObj };
  }

  // adding common datalayer keys and removing keys with empty values
  const commonKeysObj = Object.fromEntries(
    Object.entries(getCommonKeys(sessionData, pageProps))?.filter(([_, value]) => value !== '' && value != null)
  );

  return { ...datalayerObj, ...commonKeysObj };
};

const addMissingDataLayerElements = (
  newDataLayer: { [k: string]: string | number | boolean | undefined },
  options: DataLayerConstructionOptions
) => {
  if (!options.fillMissingElements) {
    return newDataLayer;
  }
  const oldDataLayer = JSON.parse(window.sessionStorage.getItem('oldDataLayer') || '{}') as {
    [k: string]: string | number | boolean | undefined;
  };
  Object.keys(oldDataLayer).forEach(key => {
    if (
      !newDataLayer[key] &&
      ['undefined', 'string'].indexOf(typeof newDataLayer[key]) !== -1 &&
      dataLayerIgnoreList.indexOf(key) === -1
    ) {
      newDataLayer[key] = oldDataLayer[key];
    }
  });
  return newDataLayer;
};

export type DataLayerConstructionOptions = {
  includeStaticElements: boolean;
  fillMissingElements: boolean;
};

export const getDatalayerObject = (
  sessionData: SessionData,
  pageProps: GlobalPageProps,
  includePiData: boolean,
  options: DataLayerConstructionOptions = {
    includeStaticElements: true,
    fillMissingElements: true,
  }
) => {
  const skipOldDatalayer = new URLSearchParams(window?.location?.search)?.get('skipOldDatalayer');
  // this is to identify list of datalayer keys that are migrated to ACDL object
  return addMissingDataLayerElements(
    getFilteredDataLayerValues(
      getDatalayerAttrList(pageProps),
      datalayerValueGenerator,
      sessionData,
      pageProps,
      includePiData
    ),
    { ...options, fillMissingElements: skipOldDatalayer === 'true' ? false : true }
  );
};
