import { DATES, DESTINATION, ROOMS_AND_GUESTS, RESET, DESTINATION_HIDDEN_FIELDS } from '../constants/StoreConstants';

import {
  DestinationFieldType,
  mobileDestinationFieldType,
  RoomsAndGuestsFieldType,
  DatesFieldType,
  ActionType,
  DestinationHiddenFieldsType,
} from './store.types';

export const setDestination = (payload: DestinationFieldType): ActionType => ({
  type: DESTINATION,
  payload,
});

export const setMobileDestination = (payload: mobileDestinationFieldType): ActionType => ({
  type: DESTINATION,
  payload,
});

export const setDates = (payload: DatesFieldType): ActionType => ({
  type: DATES,
  payload,
});

export const setRoomsAndGuests = (payload: RoomsAndGuestsFieldType): ActionType => ({
  type: ROOMS_AND_GUESTS,
  payload,
});

export const resetForm = (): ActionType => ({
  type: RESET,
});

export const setDestinationHiddenFields = (payload: DestinationHiddenFieldsType): ActionType => ({
  type: DESTINATION_HIDDEN_FIELDS,
  payload,
});
