import React, { FC, useContext } from 'react';
import { RecentList } from '@marriott/mi-ui-library-shop';
import CurrentLocation from '../CurrentLocation/CurrentLocation';
import { setDestinationHiddenFields, setMobileDestination } from '../../../store/store.actions';
import { ContentContext, FormValuesContext } from '../../../index.contexts';
import { RecentViewAndSearchProps } from './RecentViewAndSearch.types';
import TertiaryCta from '../../atoms/TertiaryCta/TertiaryCta';
import { StyledRecentViewAndSearch } from './RecentViewAndSearch.styles';

const RecentViewAndSearch: FC<RecentViewAndSearchProps> = ({ updateInputValue, closePopup }) => {
  const {
    // topDestinationslistItems,
    // placeholderTextPopularDestination,
    orderingRecentlySearchedAndViewed,
    disableRecentlyViewed,
    disableRecentlySearched,
    recentSearchesHeading,
    recentlyViewedHeading,
    clearRecentLabel,
    // disableTopDestination,
  } = useContext(ContentContext);
  const { setFormValues } = useContext(FormValuesContext);

  // const topDestinationsList = topDestinationslistItems as Array<{ topDestinationField: string }>;
  const firstList = orderingRecentlySearchedAndViewed as string;
  const hideRecentSearches = disableRecentlySearched as string;
  const hideRecentlyViewed = disableRecentlyViewed as string;
  // const hideTopDestinations = disableTopDestination as string;
  // const PopularDestinationHeading = placeholderTextPopularDestination as string;

  const getRecentSearchList: string | null = localStorage.getItem('miRecentSearch');
  const getRecentViewList: string | null = localStorage.getItem('miRecentlyViewedProperties');
  const recentlyViewedAll: Array<Record<string, string>> =
    getRecentViewList && JSON.parse(getRecentViewList)?.recentlyViewedProperties;

  const recentSearches: Array<Record<string, string>> = getRecentSearchList && JSON.parse(getRecentSearchList);
  const recentlyViewed: Array<Record<string, string>> = recentlyViewedAll?.slice(0, 5);

  let showRecentSearches = false;
  let showRecentlyViewed = false;
  // let showTopDestinations = false;

  let recentSearchesData;
  let recentlyViewedData;

  if (recentSearches && hideRecentSearches === 'false') {
    showRecentSearches = true;
    recentSearchesData = { heading: recentSearchesHeading, listArray: recentSearches, nameKey: 'label' };
  }

  if (recentlyViewed && hideRecentlyViewed === 'false') {
    showRecentlyViewed = true;
    recentlyViewedData = { heading: recentlyViewedHeading, listArray: recentlyViewed, nameKey: 'name' };
  }

  // if (topDestinationsList && hideTopDestinations === 'false') {
  //   showTopDestinations = true;
  // }

  const clearRecent = (): void => {
    localStorage.removeItem('miRecentSearch');
    localStorage.removeItem('miRecentlyViewedProperties');
    closePopup();
  };
  const keyHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.keyCode === 9) {
      closePopup();
    }
  };

  const recentListConfig: Array<configType | undefined> = [];

  interface configType {
    heading: string;
    listArray: Array<Record<string, string>>;
    nameKey: string;
  }

  if (firstList !== 'recentSearch') {
    if (recentlyViewedData) {
      recentListConfig.push(recentlyViewedData);
    }
    if (recentSearchesData) {
      recentListConfig.push(recentSearchesData);
    }
  } else {
    if (recentSearchesData) {
      recentListConfig.push(recentSearchesData);
    }
    if (recentlyViewedData) {
      recentListConfig.push(recentlyViewedData);
    }
  }

  const hiddenFieldsHandler = (data: Record<string, string | number>): void => {
    setFormValues(setDestinationHiddenFields(data));
    setFormValues(
      setMobileDestination({
        displayText: data?.['label'] ?? data?.['address'],
        pid: data?.['placeId'],
      })
    );
  };

  return (
    <StyledRecentViewAndSearch>
      <CurrentLocation currentLocationHiddenFieldHandler={hiddenFieldsHandler} inputFieldHandler={updateInputValue} />
      {/* {!showRecentSearches && !showRecentlyViewed && showTopDestinations && (
        <RecentList
          heading={PopularDestinationHeading}
          listArray={topDestinationsList}
          listIcon={'location'}
          nameKey={'topDestinationField'}
          valueKey={'topDestinationField'}
          clickHandler={updateInputValue}
        />
      )} */}

      {recentListConfig.length
        ? recentListConfig.map(
            item =>
              item !== undefined && (
                <RecentList
                  key={item.heading}
                  heading={item.heading}
                  listArray={item.listArray}
                  listIcon={'clock'}
                  nameKey={item.nameKey}
                  clickHandler={updateInputValue}
                  hiddenFieldsHandler={hiddenFieldsHandler}
                />
              )
          )
        : null}

      {(showRecentlyViewed || showRecentSearches) && (
        <TertiaryCta
          clickHandler={clearRecent}
          keyHandler={keyHandler}
          label={clearRecentLabel}
          className="clear-recent-search-btn"
        />
      )}
    </StyledRecentViewAndSearch>
  );
};

export default RecentViewAndSearch;
