import { PreloadFontsLinkProps } from './index.types';

/* Preload web fonts to prevent layout shift*/
export const PreloadFontsLink = ({
  preloadSwissRegular = true,
  preloadSwissMedium = true,
  preloadSwissBold = true,
  preloadMIIcons = true,
  preloadBrandLogoIcons = true,
  preloadPortfolioLogoIcons = true,
}: PreloadFontsLinkProps) => {
  return (
    <>
      {preloadSwissRegular && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/MarriottDigital/swiss/Swiss721BT-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
      {preloadSwissMedium && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/MarriottDigital/swiss/Swiss721BT-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
      {preloadSwissBold && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/MarriottDigital/swiss/Swiss721BT-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
      {preloadMIIcons && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/mi-icons/mi-icons.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
      {preloadBrandLogoIcons && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/mi-brand-logo-icons/mi-brand-logo-icons.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
      {preloadPortfolioLogoIcons && (
        <link
          rel="preload"
          href="https://cache.marriott.com/aka-fonts/mi-portfolio-logo-icons/mi-portfolio-logo-icons.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      )}
    </>
  );
};
