import { generateRandomNumber } from '@marriott/shared/mi-helper-utils';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
  VARIATION_DESKTOP,
  VARIATION_TABLET,
  VARIATION_VERTICAL,
} from '../constants/ApplicationConstants';

export default class CommonUtils {
  /**
   * dispatchCustomEvent
   * Dispatches custom event with the provided eventname and data
   * on the provided target element
   *
   * @param eventName
   * @param data
   * @param target
   */
  static dispatchCustomEvent(eventName: string, data: Record<string, unknown>, target = document): void {
    let evt;
    /* Custom Event */
    if (typeof window.CustomEvent === 'function') {
      /* For browsers that supports Event Constructor */
      evt = new CustomEvent(eventName, {
        bubbles: true,
        detail: data,
      });
    } else {
      /* For IE */
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(eventName, true, false, data);
    }

    target.dispatchEvent(evt);
  }

  /**
   * Verify if its mobile viewport
   */
  static isOnlyMobileView(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    // On windows machine its was breaking on 767 to fix this added extra 0.5
    return window.matchMedia(`(max-width: ${BREAKPOINT_TABLET + 0.5}px)`).matches;
  }

  /**
   * Verify if its tablet viewport
   */
  static isTabletView(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return window.matchMedia(`(max-width: ${BREAKPOINT_DESKTOP}px) and (min-width: ${BREAKPOINT_TABLET}px)`).matches;
  }

  /**
   * Fail safe method of parsing json string to json objects
   * @param jsonString
   */
  static parseConfig(jsonString: string | undefined): Record<string, unknown> {
    try {
      if (jsonString) {
        return JSON.parse(jsonString);
      }
      return {};
    } catch (_) {
      return {};
    }
  }

  /**
   * To create random string for element ID (uuidv4)
   */
  static getElementId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (generateRandomNumber() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static getVariation(): string {
    if (CommonUtils.isOnlyMobileView()) {
      return VARIATION_VERTICAL;
    }
    if (CommonUtils.isTabletView()) {
      return VARIATION_TABLET;
    }
    return VARIATION_DESKTOP;
  }

  static injectDependency(assets: { path: string; preload: [][] }): void {
    const filesList = assets?.preload ?? [];
    filesList.forEach(v => {
      // const script = document.createElement('script');
      // script.src = `${assets.path}${v}`;
      // document.body.appendChild(script);
      const preloadLink = document.createElement('link');
      preloadLink.href = `${assets.path}${v}`;
      preloadLink.rel = 'preload';
      preloadLink.as = 'script';
      document.head.appendChild(preloadLink);
    });
  }

  static focusFirstTabbable(ref: React.RefObject<HTMLElement>): void {
    // focus first interactive element
    const focusable = ref.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ) as NodeListOf<HTMLElement>;
    focusable[0]?.focus();
  }

  // remove event listner
  static removeViewportChangeEventListner(
    tabletQuery: MediaQueryList,
    desktopQuery: MediaQueryList,
    onTabletChangeHandler: (event: MediaQueryListEvent) => void,
    onDesktopChangeHandler: (event: MediaQueryListEvent) => void
  ): void {
    if ('removeEventListener' in desktopQuery) {
      desktopQuery.removeEventListener('change', onDesktopChangeHandler);
    }
    if ('removeEventListener' in tabletQuery) {
      tabletQuery.removeEventListener('change', onTabletChangeHandler);
    }
  }

  // set variation  browser resize
  static addRemoveResizeHandler(setSuccessVariation: (flag: boolean, variation: string) => void): () => void {
    const tabletQuery: MediaQueryList = window.matchMedia(
      `(max-width: ${BREAKPOINT_DESKTOP}px) and (min-width: ${BREAKPOINT_TABLET + 1}px)`
    );
    const desktopQuery: MediaQueryList = window.matchMedia(`(min-width: ${BREAKPOINT_DESKTOP + 1}px)`);
    const onTabletChangeHandler = (event: MediaQueryListEvent): void =>
      setSuccessVariation(event.matches, VARIATION_TABLET);
    const onDesktopChangeHandler = (event: MediaQueryListEvent): void =>
      setSuccessVariation(event.matches, VARIATION_DESKTOP);

    if ('addEventListener' in desktopQuery) {
      desktopQuery.addEventListener('change', onDesktopChangeHandler);
    }

    if ('addEventListener' in tabletQuery) {
      tabletQuery.addEventListener('change', onTabletChangeHandler);
    }

    return CommonUtils.removeViewportChangeEventListner.bind(
      null,
      tabletQuery,
      desktopQuery,
      onTabletChangeHandler,
      onDesktopChangeHandler
    );
  }

  // inject script tag in document
  static injectScript(url: string | null): void {
    if (!url) {
      return;
    }
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
    return;
  }

  /**
   *
   * @param fromDate
   * @param toDate
   */
  static getNumberOfNights(fromDate: moment.Moment | undefined, toDate: moment.Moment | undefined): number {
    fromDate = fromDate?.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    toDate = toDate?.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    return toDate && fromDate ? Math.round(toDate.diff(fromDate, 'days', true)) : 0;
  }
}
