import React, { FC } from 'react';
// import ErrorBoundary from '../../atoms/errorBoundary';
import SearchSecondaryBar from './SearchSecondaryBar';

// const SearchSecondaryBar = React.lazy(() => import('./searchSecondaryBar'));

const SearchSecondaryBarLazy: FC<{ isPublishUserSelection: boolean }> = ({ isPublishUserSelection = false }) => {
  return (
    //<ErrorBoundary>
    // <Suspense fallback={''}>
    <SearchSecondaryBar isPublishUserSelection={isPublishUserSelection} />
    // {/* </Suspense> */}
    // {/* </ErrorBoundary> */}
  );
};

export default SearchSecondaryBarLazy;
