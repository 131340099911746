import React, { useEffect, useState } from 'react';
import { CountDownTimerProps } from './OffersMBEHero.types';
import { calculateTimeLeft } from '../../utils/OfferUtils';
import { StyledOffersMBEHero } from './OffersMBEHero.styles';

export const CountDownTimer: React.FC<CountDownTimerProps> = props => {
  const { startDate, isAuthorMode, model } = props;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate, isAuthorMode));
  const [timezoneOffset, setTimezoneOffset] = useState(new Date().getTimezoneOffset());

  useEffect(() => {
    const updateTime = () => {
      const newTimeLeft = calculateTimeLeft(startDate, isAuthorMode);
      setTimeLeft(newTimeLeft);

      const currentOffset = new Date().getTimezoneOffset();
      if (currentOffset !== timezoneOffset) {
        setTimezoneOffset(currentOffset);
      }
    };

    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, [startDate, isAuthorMode, timezoneOffset]);

  const timeLabels = [model.daysLabel, model.hoursLabel, model.minutesLabel, model.secondsLabel];
  const timeEntries = Object.entries(timeLeft);

  return (
    <StyledOffersMBEHero>
      <div className="countdownWrapper">
        {timeEntries.map(([key, value], index) => (
          <React.Fragment key={key}>
            {index > 0 && <div className="separator" />} {/* Separator between tiles */}
            <div className="countdownUnit">
              <div className="countdownTile">
                <span className="countdownNumber">
                  {value !== undefined ? value.toString().padStart(2, '0') : '00'}
                </span>
              </div>
              <div className="countdownLabelsWrapper">
                <span className="countdownLabel">{timeLabels[index]}</span>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </StyledOffersMBEHero>
  );
};
export default CountDownTimer;
