/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import '@marriott/global-styles/dist/marriot.global.css';
import '../styles/common.scss';
import {
  CustomModelClient,
  getEnvProp,
  getProcessEnvs,
  populatePIdataLayer,
  UserSessionProvider,
} from '@marriott/mi-headless-utils';
import { envVarKeys } from '../_constants/envVarKeys';
import { defineComponentMapping } from '../import-components';
import { canUseDOM } from '@marriott/mi-offers-components';
import path from 'path';
import { setOffersSubDirectoryPrefix, setSessionKey } from '@marriott/mi-offers-components';
import { setSubDirectoryPrefix } from '@marriott/mi-shop-components/utils';
import { useClientEnvVarsStore, usePersistentGlobalStore } from '@marriott/mi-store-utils';
path.resolve('./next.config.js');

declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);
if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const { model, isSeoDisable, apolloEnvVars } = pageProps;
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['GOOGLE_MAP_API_KEY'] = process.env['GOOGLE_MAP_API_KEY'];
  envVars['NEXT_PUBLIC_ENABLE_WEBSDK'] =
    getEnvProp(envVarKeys.NEXT_PUBLIC_ENABLE_WEBSDK) === 'true' && model?.enableWebSDK === 'true';
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);

  const {
    NEXT_PUBLIC_SUBMIT_OSERP_URL,
    NEXT_PUBLIC_SUBMIT_SEARCH_URL,
    SESSION_GET_CALL_URL_CLIENT,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    NEXT_PUBLIC_PREFIX,
  } = getProcessEnvs();

  let subDirectoryContextPath = '';
  if (model?.subDirectoryContextPath) {
    subDirectoryContextPath = model.subDirectoryContextPath ? model.subDirectoryContextPath : '';
    //from shop
    setSubDirectoryPrefix(subDirectoryContextPath);
  }

  if (pageProps?.resolvedUrl === '/offers/findOffers') {
    envVars['NEXT_PUBLIC_SUBMIT_SEARCH_URL'] =
      NEXT_PUBLIC_SUBMIT_OSERP_URL ?? process.env['NEXT_PUBLIC_SUBMIT_OSERP_URL'];
  } else {
    envVars['NEXT_PUBLIC_SUBMIT_SEARCH_URL'] =
      NEXT_PUBLIC_SUBMIT_OSERP_URL ?? process.env['NEXT_PUBLIC_SUBMIT_OSERP_URL'];
  }

  envVars['SESSION_GET_CALL_URL_CLIENT'] = SESSION_GET_CALL_URL_CLIENT ?? process.env['SESSION_GET_CALL_URL_CLIENT'];
  envVars['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'] =
    NEXT_PUBLIC_SUBMIT_OSERP_URL ?? process.env['NEXT_PUBLIC_SUBMIT_OSERP_URL'];
  envVars['NEXT_PUBLIC_PREFIX'] = NEXT_PUBLIC_PREFIX ?? process.env['NEXT_PUBLIC_PREFIX'];
  setEnvVars(envVars);
  if (model?.subDirectoryContextPath) {
    setOffersSubDirectoryPrefix(model.subDirectoryContextPath);
    setSessionKey('SUBDIRECTORYCONTEXTPATH', model?.subDirectoryContextPath || '');
  }

  useEffect(() => {
    updateGlobalData('sessionData', pageProps?.sessionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (canUseDOM) {
    populatePIdataLayer();
  }
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;

  defineComponentMapping(allowedComponents, pageProps, isSeoDisable);

  return (
    <UserSessionProvider pageProps={pageProps} includePiData={true}>
      <Component {...pageProps} />
    </UserSessionProvider>
  );
};

export default App;
