// Styles for OutletOfferResult go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { font, mediaQuery } from '../../utils/styles';

export const StyledOutletOfferResult = styled.div`
  .outlet-properties-title {
    margin-bottom: ${toRem(40)};
    margin-top: ${toRem(62)};
    margin-left: auto;
    margin-right: auto;
    width: ${toRem(1147.4)};
    p {
      color: ${baseVariables.color.merch30};
      font-family: ${font.Swiss721BTRegular};
      font-size: ${toRem(30)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${toRem(32)};
    }
    @media ${mediaQuery.mobileOnly} {
      padding-top: ${toRem(40)};
      margin-top: 0;
      margin-bottom: ${toRem(16)};
      width: unset;
      p {
        font-size: ${toRem(22)};
      }
    }
  }
  .filter-btns-wrapper {
    margin: 0 0 2rem;
    .outlet-filter-cta {
      border: 1px solid #ccc;
      border-radius: 11px;
      margin: 0 0.5rem 0 0;
    }
    .outlet-filter-cta-clear {
      border: 1px solid #ccc;
      margin: 0 0.5rem 0 0;
      background-color: #c4c4c4;
      display: none;
      &.display {
        display: inline-block;
      }
    }
    button {
      &.active {
        background-color: #c4c4c4;
      }
      span {
        & :: before {
          padding-right: 0.5rem;
        }
      }
    }
  }

  .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }

  .facet-wrapper {
    display: block;
    .checkbox-wrap {
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin: 0;
      }
      h5 {
        margin-bottom: 1rem;
      }
      margin: 0 0 2rem 0;
      border-bottom: 1px solid ${baseVariables.color['neutral20']};
      padding-bottom: 1rem;
      .checkbox-items {
        display: inline-block;
      }
    }
  }

  .all-filters form input {
    clip: unset;
    height: 1rem;
    width: 1rem;
    margin: 0 5px;
    position: relative;
  }
  .pageResultNumbers {
    font-size: ${toRem(14)};
    font-weight: 700;
    color: ${baseVariables.color['base10']};
    font-family: ${font.Swiss721BTRegular};
    margin-left: ${toRem(6)};
    .pb-1,
    .py-1 {
      padding-bottom: 0.25rem !important;
    }
    .mb-0,
    .my-0 {
      margin-bottom: 0 !important;
    }
  }
  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    .card-vertical_badge {
      display: none;
    }
    div:nth-child(1) {
      margin-top: 0px !important;
    }
  }
  .card-vertical-outlets {
    margin: 0 0 1rem 0;
    .t-overline-normal {
      line-height: 1rem !important;
      display: block;
      margin-bottom: 0.75rem;
    }
    .description-height {
      font: normal normal 500 0.875rem/1.25rem Swiss-721;
    }
  }
  .all-filter-badge {
    height: 1.25rem;
    border-radius: 50%;
    background-color: rgb(28, 28, 28);
    display: inline-flex;
    -webkit-box-pack: center;
    place-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 0.3125rem;
    width: max-content;
    margin-right: 3px;
  }
`;

export const StyledOutletOfferResultPreview = styled.div`
  pointer-events: none;

  .outlet-properties-title {
    margin-bottom: ${toRem(40)};
    margin-top: ${toRem(62)};
    margin-left: auto;
    margin-right: auto;
    width: ${toRem(1147.4)};
    p {
      color: ${baseVariables.color.merch30};
      font-family: ${font.Swiss721BTRegular};
      font-size: ${toRem(30)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${toRem(32)};
    }
    @media ${mediaQuery.mobileOnly} {
      padding-top: ${toRem(40)};
      margin-top: 0;
      margin-bottom: ${toRem(16)};
      width: unset;
      p {
        font-size: ${toRem(22)};
      }
    }
  }
  .filter-btns-wrapper {
    margin: 0 0 2rem;
    .outlet-filter-cta {
      border: 1px solid #ccc;
      border-radius: 11px;
      margin: 0 0.5rem 0 0;
    }
    .outlet-filter-cta-clear {
      border: 1px solid #ccc;
      margin: 0 0.5rem 0 0;
      background-color: #c4c4c4;
      display: none;
      &.display {
        display: inline-block;
      }
    }
    button {
      &.active {
        background-color: #c4c4c4;
      }
      span {
        & :: before {
          padding-right: 0.5rem;
        }
      }
    }
  }

  .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }

  .facet-wrapper {
    display: block;
    .checkbox-wrap {
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin: 0;
      }
      h5 {
        margin-bottom: 1rem;
      }
      margin: 0 0 2rem 0;
      border-bottom: 1px solid ${baseVariables.color['neutral20']};
      padding-bottom: 1rem;
      .checkbox-items {
        display: inline-block;
      }
    }
  }

  .all-filters form input {
    clip: unset;
    height: 1rem;
    width: 1rem;
    margin: 0 5px;
    position: relative;
  }
  .pageResultNumbers {
    font-size: ${toRem(14)};
    font-weight: 700;
    color: ${baseVariables.color['base10']};
    font-family: ${font.Swiss721BTRegular};
    margin-left: ${toRem(6)};
    .pb-1,
    .py-1 {
      padding-bottom: 0.25rem !important;
    }
    .mb-0,
    .my-0 {
      margin-bottom: 0 !important;
    }
  }
  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    .card-vertical_badge {
      display: none;
    }
  }
  .card-vertical-outlets {
    margin: 0 0 1rem 0;
    .t-overline-normal {
      line-height: 1rem !important;
      display: block;
      margin-bottom: 0.75rem;
    }
    .description-height {
      font: normal normal 500 0.875rem/1.25rem Swiss-721;
    }
  }
`;
