/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';

// Use named rather than default exports.
export const PreviewContainerReactWrapper = (props: any) => {
  if (props?.isPreview === 'true' || props.isAuthorMode) {
    return <ResponsiveGrid isInEditor={true} {...props} />;
  } else {
    return <></>;
  }
};

export const PreviewContainerWrapperConfig = {
  emptyLabel: 'PreviewContainer',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/previewcontainer`,
};

export const PreviewContainer = (props: any) => (
  <EditableComponent config={PreviewContainerWrapperConfig} {...props}>
    <div
      className="m-container-fullbleed"
      data-testid="previewcontainer"
      data-component-name="o-offers-previewcontainer"
    >
      <PreviewContainerReactWrapper {...props} />
    </div>
  </EditableComponent>
);
