import { Suspense, lazy } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledOfferCardTiles, StyledOfferCardsDivPreviewMode } from './OfferCards.styles';
import { OfferCardsProps } from './OfferCards.types';
// import { CardTiles } from './CardTiles';

export const OfferCardWrapperConfig = {
  emptyLabel: 'OfferCardTiles',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offerscardstiles`,
};

export const OfferCardWrapper: React.FC<OfferCardsProps> = props => {
  const CardTiles = lazy(() => import('./CardTiles'));
  return (
    <Suspense fallback={<></>}>
      <CardTiles {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferCards = (props: any) => (
  <div>
    <EditableComponent config={OfferCardWrapperConfig} {...props}>
      {props?.offersData?.isPreview === 'true' ? (
        <StyledOfferCardsDivPreviewMode data-component-name="o-offers-cards" data-testid="offers-cards">
          <OfferCardWrapper {...props} />
        </StyledOfferCardsDivPreviewMode>
      ) : (
        <StyledOfferCardTiles data-component-name="o-offers-cards" data-testid="offers-cards">
          <OfferCardWrapper {...props} />
        </StyledOfferCardTiles>
      )}
    </EditableComponent>
  </div>
);
