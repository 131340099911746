import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.section`
  display: flex;
  box-sizing: border-box;
  border-radius: ${toRem(8)};
  margin-bottom: ${toRem(10)};
  padding: ${toRem(12)} ${toRem(20)};
  background-color: ${theme.colors.white};
  &.alert {
    color: ${theme.colors['base-10']};
    border: ${toRem(1)} solid ${theme.colors['close-silver']};
  }
  &.success {
    color: ${theme.colors['merch-green']};
    border: ${toRem(1)} solid ${theme.colors['merch-green']};
  }
  &.success-message,
  &.cancel-success-msg,
  &.success-msg {
    color: ${theme.colors['merch-green']};
    border: ${toRem(1)} solid ${theme.colors['merch-green']};
    margin-bottom: 0;
    margin-top: ${toRem(32)};
    .message {
      color: ${theme.colors['base-10']};
    }
  }
  @media ${theme.mediaQuery.allMobiles} {
    &.success-message {
      margin-top: ${toRem(24)};
    }
  }
  &.cancel-success-msg {
    padding: ${toRem(13)} ${toRem(16)};
    align-items: center;
    .message {
      line-height: ${theme.fonts['lineHeightM']};
    }
  }
  &.success-msg {
    margin: ${toRem(24)} 0 ${toRem(4)};
    padding: ${toRem(13)} ${toRem(16)};
  }
  &.alert-error {
    color: ${theme.colors['rose-of-sharon-2']}; //Using Color code as it is not available in global styles.
    border: ${toRem(1)} solid ${theme.colors['rose-of-sharon-2']};
  }
  &.no-redemption-error {
    background-color: ${theme.colors['carousel-pink']};
    color: ${theme.colors.monza};
    border: ${toRem(1)} solid ${theme.colors.monza};
    .message {
      color: ${theme.colors['base-10']};
      & a {
        text-decoration: underline;
      }
    }
    margin-top: ${toRem(32)};
    @media ${theme.mediaQuery.mobileOnly} {
      margin-top: ${toRem(24)};
    }
  }
  &.is-adult-error {
    background-color: ${theme.colors['carousel-pink']};
    color: ${theme.colors.monza};
    border: ${toRem(1)} solid ${theme.colors.monza};
    .message {
      color: ${theme.colors['base-10']};
    }
    margin-top: ${toRem(32)};
    margin-bottom: ${toRem(0)};
    @media ${theme.mediaQuery.tabletOnly} {
      margin-top: ${toRem(32)};
    }
    @media ${theme.mediaQuery.mobileOnly} {
      margin-top: ${toRem(24)};
    }
  }
  &.accessibility-alert {
    background-color: transparent;
    padding: 0;
    margin: ${toRem(0)} ${toRem(27)} ${toRem(16)} ${toRem(0)};
    .message {
      line-height: ${theme.fonts['fontXl']};
    }
    .icon-accessibility {
      &::before {
        height: ${toRem(14)};
        width: ${toRem(11)};
        font-size: ${theme.fonts['fonts']};
      }
    }
  }
  .message {
    margin: 0;
    font-weight: normal;
    font-size: ${theme.fonts['fontS']};
    line-height: ${theme.fonts['fontXxl']};
    & > p {
      margin-bottom: 0;
      & > a {
        color: ${theme.colors['rose-of-sharon-2']};
        text-decoration: underline;
      }
    }
  }
  &.error,
  &.ocj-error,
  &.redemption-error-msg {
    color: ${theme.colors['rose-of-sharon']};
    border: ${toRem(1)} solid ${theme.colors['rose-of-sharon']};
    .message {
      & a {
        color: ${theme.colors['rose-of-sharon']};
      }
    }
  }
  &.ocj-error {
    margin-top: ${toRem(32)};
    margin-bottom: ${toRem(0)};
  }
  &.redemption-error-msg {
    margin: 0;
    .message {
      line-height: ${theme.fonts['fontXl']};
    }
  }
  &.hotel-message {
    color: ${theme.colors['base-10']};
    background-color: transparent;
    border: none;
    border-radius: ${toRem(0)};
    padding: 0;
    .hide-icon {
      display: none;
      margin: 0;
    }
    .message {
      font-size: ${theme.fonts['fontM']};
      line-height: ${theme.fonts['fontXxxl']};
      margin-bottom: 0;
    }
  }
  &.modify-restrict,
  &.pref-error {
    color: ${theme.colors['rose-of-sharon']};
    border: ${toRem(1)} solid ${theme.colors['rose-of-sharon']};
    margin: ${toRem(23)} ${toRem(16)} ${toRem(24)};
    padding: ${toRem(13)} ${toRem(16)};
    .message {
      color: ${theme.colors['base-10']};
    }
    .icon-warning {
      height: ${toRem(20)};
      &::before {
        font-weight: ${theme.fonts['fontWeightMedium']};
        font-size: ${theme.fonts['fontL']};
      }
    }
  }
  &.modify-restrict {
    margin: ${toRem(24)} 0 0;
    .message {
      line-height: ${toRem(20)};
    }
  }
  &.failure-message {
    color: ${theme.colors.monza};
    border: ${toRem(1)} solid ${theme.colors.monza};
    margin: ${toRem(32)} 0 0;
    padding: ${toRem(13)} ${toRem(19)};
    height: ${toRem(46)};
    display: flex;
    align-items: center;
    .message {
      color: ${theme.colors['base-10']};
      line-height: ${toRem(20)};
    }
    .icon-warning {
      width: ${toRem(15)};
    }
  }
  &.warning-msg {
    color: ${theme.colors['rose-of-sharon']};
    border: ${toRem(1)} solid ${theme.colors['rose-of-sharon']};
    margin: ${toRem(32)} 0 0;
    .message {
      color: ${theme.colors['base-10']};
      line-height: ${toRem(20)};
    }
  }
  .linktitle {
    border-bottom: ${toRem(1)} solid;
  }
  .icon-external-arrow {
    &::before {
      height: ${toRem(17)};
      width: ${toRem(17)};
    }
  }
  &.upcomingres-alert {
    margin-bottom: ${toRem(16)};
  }
  &.credit-card-alert {
    .message {
      line-height: ${toRem(20)};
    }
    .icon-alert::before {
      line-height: ${toRem(20)};
    }
  }

  @media ${theme.mediaQuery.tablet} {
    &.pref-error {
      margin: 0;
      margin-bottom: ${toRem(32)};
    }
    &.modify-restrict {
      margin: ${toRem(32)} 0 ${toRem(-24)};
    }
  }

  @media ${theme.mediaQuery.desktop} {
    &.success-message {
      align-items: center;
    }
  }
  @media ${theme.mediaQuery.allMobiles} {
    &.cancel-success-msg,
    &.failure-message {
      margin: 0;
      margin-top: ${toRem(24)};
      align-items: center;
      height: auto;
    }
    &.warning-msg {
      margin-top: ${toRem(24)};
    }
    &.upcomingres-alert {
      margin-bottom: ${toRem(12)};
    }
    &.ocj-error {
      margin-top: ${toRem(24)};
    }
  }
`;

export const StyledIcon = styled.span`
  &:dir(rtl) {
    margin-left: 10px;
  }
  &:dir(ltr) {
    margin-right: 10px;
  }
`;

export const ImageContainer = styled.span`
  margin-right: ${toRem(24)};
  .image-view {
    height: ${toRem(49)};
    width: ${toRem(80)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    margin-right: 0;
    .image-view {
      display: none;
    }
  }
`;
