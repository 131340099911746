/* eslint-disable @typescript-eslint/no-unused-vars */
// Imports for external libraries go here.
import React, { useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersMerchandisingHeroBannerProps } from './OffersMerchandisingHeroBanner.types';
import { StyledOffersMerchandisingHeroBannerWrapper } from './OffersMerchandisingHeroBanner.styles';
import clsx from 'clsx';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MerchandisingHeroBanner } from '../../../../mi-merch-components/src/organisms/MerchandisingHeroBanner/MerchandisingHeroBanner';
import { getMediaData, getMediaSource, getMediaDataSecondary, getImageWithDomain } from '../../utils/OfferUtils';
import { shouldUseMockData } from '../../utils/CommonUtils';
import { ASSET_VARIATION, PRIMARY_MEDIA, SECONDARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersMerchandisingheroBannerWrapperConfig = {
  emptyLabel: 'OffersMerchandisingheroBannerWrapper',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/merchandisingherobanner`,
};

export const OffersMerchandisingHeroBannerWrapper: React.FC<OffersMerchandisingHeroBannerProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mockdataInAuthorMode, setMockDataInAuthorMode] = useState<any>({});
  const { merchOffersHeroBanner } = ImageRenditionsData;
  // Load mock model dynamically only for dev mode
  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const offersMerchandisingHeroBannerMockDataWithPointsVersion = await import(
        './__mock__/OffersMerchandisingHeroBanner.mock'
      );
      return offersMerchandisingHeroBannerMockDataWithPointsVersion;
    }
    return {};
  };
  useEffect(() => {
    const fetchMockData = async () => {
      const mockData = await GetMockData(isAuthorMode);
      setMockDataInAuthorMode(mockData);
    };
    fetchMockData();
  }, [isAuthorMode]);
  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockdataInAuthorMode?.OffersMerchandisingHeroBannerMock?.offersData?.responseObject?.edges[0];
  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        merchOffersHeroBanner?.aspectRatio?.backgroundMedia
      );
  const isMediaFromDAC = mediaSource === PRIMARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        merchOffersHeroBanner?.backgroundMedia,
        mediaSource,
        PRIMARY_MEDIA
      );
  const logoMediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        SECONDARY_MEDIA,
        merchOffersHeroBanner?.aspectRatio?.dynamicMediaLogo
      );
  const isLogoMediaFromDAC = logoMediaSource === SECONDARY_MEDIA;
  const logoMedia = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaDataSecondary(offersData?.responseObject?.edges[0]?.node?.media, model?.image, logoMediaSource);
  const fallbackLogoMedia = {
    altText: model?.logo?.altText,
    imageSrc: getImageWithDomain(model?.logo?.imageSrc),
  };
  const appliedCssClassNames = isAuthorMode ? '' : props?.model?.appliedCssClassNames;
  const offerDataObj = {
    image: isMediaFromDAC ? media : model?.image,
    mathLabel1: offersDataNode?.merchandisingNode?.mathLabel1,
    mathLabel2: offersDataNode?.merchandisingNode?.mathLabel2,
    mathLabel3: offersDataNode?.merchandisingNode?.mathLabel3,
    mathType: offersDataNode?.merchandisingNode?.mathType[0],
    mathValue: offersDataNode?.merchandisingNode?.mathValue,
    headerMedium: offersDataNode?.node?.title,
    descriptionLong: offersDataNode?.node?.description,
    callToActionText1: offersDataNode?.node?.callToActionLabel,
    destinationUrl1: offersDataNode?.node?.callToActionLink,
    callToActionText2: offersDataNode?.merchandisingNode?.secondaryCallToActionLabel,
    destinationUrl2: offersDataNode?.merchandisingNode?.secondaryCallToActionLink,
    caption: offersDataNode?.merchandisingNode?.caption,
    captionUrl: offersDataNode?.merchandisingNode?.captionUrl,
    fileReferenceBackgroundImagedesktop: isMediaFromDAC
      ? media?.fileReferenceBackgroundImage
      : model?.fileReferenceBackgroundImagedesktop,
    fileReferenceBackgroundImagemobile: isMediaFromDAC
      ? media?.fileReferenceBackgroundImage
      : model?.fileReferenceBackgroundImagemobile,
    logo: isLogoMediaFromDAC ? logoMedia : fallbackLogoMedia,
    assetsVariation: model?.assetsVariation ?? ASSET_VARIATION?.IMAGE,
    appliedCssClassNames: appliedCssClassNames,
    eyebrowText: offersDataNode?.node?.descriptionTeaser,
  };
  const mergedDataObj = { ...props?.model, ...offerDataObj };
  const offersMerchModelObj = {
    model: { ...mergedDataObj },
  };

  const isTargetEnabled = props.model.appliedCssClassNames?.includes('enable-target') && !props.isAuthorMode;
  return (
    <StyledOffersMerchandisingHeroBannerWrapper>
      <div
        data-testid="offers-OffersMerchandisingheroBanner"
        className={clsx('offersherobanner-fullbleed', {
          [`target-background herobanner-ratio-${props.model.heroSize}`]: isTargetEnabled,
        })}
      >
        <MerchandisingHeroBanner {...offersMerchModelObj?.model} />
      </div>
    </StyledOffersMerchandisingHeroBannerWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersMerchandisingHeroBanner = (props: any) => (
  <div data-component-name="o-offers-OffersMerchandisingheroBanner" data-testid="cobrand-card-horizontal-flexible">
    <EditableComponent config={OffersMerchandisingheroBannerWrapperConfig} {...props}>
      <OffersMerchandisingHeroBannerWrapper {...props} />
    </EditableComponent>
  </div>
);
