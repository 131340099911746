import { DATES, DESTINATION, ROOMS_AND_GUESTS, RESET, DESTINATION_HIDDEN_FIELDS } from '../constants/StoreConstants';
import { StoreValuesType, ActionType, PayloadType } from './store.types';
import { getCurrentDateObject, getDateObject, getNextDateObject } from '../utils/DateUtils';
import { DATE_FORMAT_VALUE } from '../constants/ApplicationConstants';

const setField = (state: StoreValuesType, fieldName: string, fieldData?: PayloadType): StoreValuesType => {
  return {
    ...state,
    [fieldName]: {
      ...fieldData,
    },
  };
};

const currentDate = getCurrentDateObject();
const nextDate = getNextDateObject(currentDate);

let initialStoreValues: StoreValuesType = {
  [DESTINATION]: {
    displayText: '',
    pid: '',
    propCode: '',
    clusterCode: '',
  },
  [ROOMS_AND_GUESTS]: {
    roomsCount: '1',
    adultsCount: 1,
    childrenCount: 0,
    childrenAges: [],
  },
  [DATES]: {
    lastDefaultDate: '',
    fromDate: currentDate,
    toDate: nextDate,
  },
  [DESTINATION_HIDDEN_FIELDS]: {
    latitude: '',
    longitude: '',
  },
};

export const getInitialStoreValues = (): StoreValuesType => initialStoreValues;

export const setInitialStoreValues = (values: StoreValuesType): void => {
  const dates = values?.[DATES];
  let { fromDate, toDate } = dates ?? {};
  const { checkin, checkout } = dates ?? {};

  if (typeof fromDate === 'string') {
    fromDate = checkin ? getDateObject(checkin, DATE_FORMAT_VALUE) : getDateObject(fromDate);
  }
  if (typeof toDate === 'string') {
    toDate = checkout ? getDateObject(checkout, DATE_FORMAT_VALUE) : getDateObject(toDate);
  }
  initialStoreValues = {
    ...initialStoreValues,
    ...values,
    [DATES]: {
      ...dates,
      fromDate,
      toDate,
    },
  };
};

export const storeReducer = (state: StoreValuesType, action: ActionType): StoreValuesType => {
  switch (action.type) {
    case RESET:
      return initialStoreValues;
    case DESTINATION:
      return setField(state, DESTINATION, action.payload);
    case ROOMS_AND_GUESTS:
      return setField(state, ROOMS_AND_GUESTS, action.payload);
    case DATES:
      return setField(state, DATES, action.payload);
    case DESTINATION_HIDDEN_FIELDS:
      return setField(state, DESTINATION_HIDDEN_FIELDS, action.payload);
    default:
      return state;
  }
};
