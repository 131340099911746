import { Suspense, lazy } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OfferCardListingProps } from './OfferCardListing.types';
import {
  OfferCardsListingStyled,
  StyledOfferCardsDiv,
  StyledOfferCardsDivPreviewMode,
} from './OfferCardListing.styles';

export const OfferCardListingWrapperConfig = {
  emptyLabel: 'OfferCardListing',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-cards-list`,
};

export const OfferCardListingWrapper: React.FC<OfferCardListingProps> = props => {
  const CardList = lazy(() => import('../OfferCardListing/CardList'));
  return (
    <OfferCardsListingStyled data-component-name="o-offers-cards-list" data-testid="offers-cards-list">
      <div className={`cmp-offers-cards-listing ${props?.model?.appliedCssClassNames ?? ''}`}>
        <div className="offers-container">
          <Suspense fallback={<></>}>
            <CardList {...props} />
          </Suspense>
        </div>
      </div>
    </OfferCardsListingStyled>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferCardListing = (props: any) => (
  <EditableComponent config={OfferCardListingWrapperConfig} {...props}>
    {props?.offersData?.isPreview === 'true' ? (
      <StyledOfferCardsDivPreviewMode>
        <OfferCardListingWrapper {...props} />
      </StyledOfferCardsDivPreviewMode>
    ) : (
      <StyledOfferCardsDiv>
        <OfferCardListingWrapper {...props} />
      </StyledOfferCardsDiv>
    )}
  </EditableComponent>
);
