import { FC, useState, useEffect } from 'react';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { getLocalTimeEST, calculateTimeLeft, checkCampaignStatus } from '../../utils/OfferUtils';
import { shouldUseMockData } from '../../utils/CommonUtils';
import { OnOffContainerProps } from './OnOffContainer.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnOffContainerWrapper = (props: any) => {
  const { isAuthorMode, offersData, model } = props;
  const { variation } = model;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [offersDataMock, setoffersDataMock] = useState<any>({});
  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const mockData = await import('./__mock__/OnOffContainerMock.mock');
      return mockData.offersDataMock;
    }
    return {};
  };

  useEffect(() => {
    const fetchMockData = async () => {
      const mockData = await GetMockData(isAuthorMode);
      setoffersDataMock(mockData);
    };

    fetchMockData();
  }, [isAuthorMode]);

  const { campaignStartDate, campaignEndDate } = !isAuthorMode ? offersData : offersDataMock;
  const [localTimezone, setLocalTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [utilityState, setUtilityState] = useState<string>('inactive');

  const [timeLeft, setTimeLeft] = useState<{ days: number }>({ days: 0 });

  const updateStatus = () => {
    const currentLocalTimeEST = getLocalTimeEST();

    setTimeLeft(calculateTimeLeft(campaignStartDate, isAuthorMode));
    setUtilityState(checkCampaignStatus(currentLocalTimeEST, campaignStartDate, campaignEndDate));
  };

  useEffect(() => {
    updateStatus();

    if (timeLeft.days > 1) return;
    const interval = setInterval(() => {
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (currentTimezone !== localTimezone) {
        setLocalTimezone(currentTimezone);
        updateStatus();
      }
    }, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps
  }, [localTimezone, variation, campaignStartDate, campaignEndDate, timeLeft.days]);

  if (variation === utilityState || isAuthorMode) {
    return <ResponsiveGrid isInEditor={true} {...props} />;
  }

  return <></>;
};

export const OnOffContainerWrapperConfig = {
  emptyLabel: 'OnOffContainer',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/onoffcontainer`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnOffContainer: FC<OnOffContainerProps> = (props: any) => {
  return (
    <EditableComponent config={OnOffContainerWrapperConfig} {...props}>
      <div data-testid="offers-OnOffContainer" data-component-name="o-offers-OnOffContainer">
        <OnOffContainerWrapper {...props} />
      </div>
    </EditableComponent>
  );
};
