import { FC, useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { MerchandisingSmallBannerComp } from '@marriott/mi-merch-components';
import { useDatalayer } from '@marriott/mi-headless-utils';
import { getMediaDataSecondary } from '../../utils/OfferUtils';
import { shouldUseMockData } from '../../utils/CommonUtils';
import { SECONDARY_MEDIA } from '../../constants/CommonConstants';
import { OffersMerchandisingSmallBannerProps } from './OffersMerchandisingSmallBanner.types';
import { StyledOffersMerchandisingSmallBanner } from './OffersMerchandisingSmallBanner.styles';

export const OffersMerchandisingSmallBannerWrapperConfig = {
  emptyLabel: 'OffersMerchandisingSmallBannerWrapper',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/merchandisingsmallbanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersMerchandisingSmallBannerWrapper: FC<OffersMerchandisingSmallBannerProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mockdataInAuthorMode, setMockDataInAuthorMode] = useState<any>({});

  const { datalayer } = useDatalayer();
  const preview_prop_currency_type = datalayer?.['prop_currency_type'] || 'USD';

  // Load mock model dynamically only for dev mode
  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const mockModule = await import('./__mock__/OffersMerchandisingSmallBanner.mock');
      return mockModule.OffersMerchandisingSmallBannerMockDataWithPointsVersion;
    }
    return {};
  };

  useEffect(() => {
    const fetchMockData = async () => {
      const mockData = await GetMockData(isAuthorMode);
      setMockDataInAuthorMode(mockData);
    };

    fetchMockData();
  }, [isAuthorMode]);

  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockdataInAuthorMode?.offersData?.responseObject?.edges[0];
  const merchandisingNode = offersDataNode?.merchandisingNode;
  const node = offersDataNode?.node;

  const mediaSource = isAuthorMode ? 'model' : SECONDARY_MEDIA;
  const media = isAuthorMode ? node?.media : getMediaDataSecondary(node?.media, model?.image, mediaSource);
  const mathType = merchandisingNode?.mathType?.[0];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requiredProps: any = {
    openInNewTab: model?.openInNewTab,
    ctaType: model?.ctaType,
    headerLong: merchandisingNode?.headerLong ?? model?.headerLong,
    descriptionLong: node?.description ?? model?.descriptionLong,
    descriptionLongMobile: merchandisingNode?.descriptionShort,
    callToActionText1: node?.callToActionLabel,
    destinationUrl1: node?.callToActionLink,
    mathLabel1: merchandisingNode?.mathLabel1,
    mathLabel2: merchandisingNode?.mathLabel2,
    mathLabel3: merchandisingNode?.mathLabel3,
    mathTitle: mathType,
    mathValue: merchandisingNode?.mathValue,
    legalLong: node?.termsAndConditions,
    mathType: mathType,
    image: media,
    styleclass: 'standard',
    mboxParameter: offersData?.mboxParameter ?? false,
    displayEvergreenContent: 'true',
    trackingProperties: model?.trackingProperties,
    preview_prop_currency_type: preview_prop_currency_type,
  };

  return (
    <StyledOffersMerchandisingSmallBanner
      data-testid="offers-OffersMerchandisingSmallBanner"
      data-component-name="o-offers-OffersMerchandisingSmallBanner"
    >
      <MerchandisingSmallBannerComp {...requiredProps} />
    </StyledOffersMerchandisingSmallBanner>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersMerchandisingSmallBanner = (props: any) => (
  <div
    data-component-name="o-offers-OffersMerchandisingSmallBanner"
    data-testid="offers-OffersMerchandisingSmallBanner"
  >
    <EditableComponent config={OffersMerchandisingSmallBannerWrapperConfig} {...props}>
      <OffersMerchandisingSmallBannerWrapper {...props} />
    </EditableComponent>
  </div>
);
