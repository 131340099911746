import styled from 'styled-components';
import { toRem, baseVariables as theme } from '@marriott/mi-ui-library';
import { theme as styledTheme } from '@marriott/mi-ui-library-shop';
export const StyledDatesHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 ${toRem(24)} ${toRem(19)};
  @media ${theme.mediaQuery.tablet} {
    padding: 0 ${toRem(24)} ${toRem(20)};
  }
  @media ${theme.mediaQuery.desktop} {
    padding: 0 ${toRem(32)} ${toRem(20)};
  }
  @media ${theme.mediaQuery.largeDesktop} {
    padding: 0 ${toRem(40)} ${toRem(20)};
  }
`;

export const StyledDateBoxDiv = styled.div<{ focused: boolean }>(
  ({ focused }) => `
    width: ${toRem(175)};
    position: relative;
    @media ${theme.mediaQuery.desktop} {
        width: ${toRem(218)};
    }

    &:hover{
        opacity: 0.6;
    }

    &:first-child {
        margin-right: ${toRem(40)};
        @media ${theme.mediaQuery.desktop} {
            margin-right: ${toRem(32)};
        }
         @media ${theme.mediaQuery.largeDesktop} {
            margin-right: ${toRem(40)};
        }
    }

    &:nth-child(2) {
        width: ${toRem(199)};
        @media ${theme.mediaQuery.desktop} {
           width: ${toRem(258)};
        }
        padding-left: ${toRem(24)};
        border-left: ${toRem(1)} dashed ${styledTheme.color['dark-gray-6']};
        @media ${theme.mediaQuery.desktop} {
            padding-left: ${toRem(32)};
        }
        @media ${theme.mediaQuery.largeDesktop} {
            padding-left: ${toRem(40)};
        }
    }
    
    button {
        outline: none;            
        cursor: pointer;
        background: transparent;
        padding: 0;
        border: none;

        &:first-child {
            outline: none;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            padding-bottom: ${toRem(14)};
            border-bottom: ${
              focused
                ? `${toRem(3)} solid ${styledTheme.color.black}`
                : `${toRem(1)} solid ${styledTheme.color['light-gray-6']}`
            };
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            text-transform: capitalize;
            
            span {
                &.gray-shade {
                    color: ${styledTheme.color['dark-gray-4']};
                }

                &:first-child {
                    padding-bottom: ${toRem(6)};
                    text-transform: uppercase;
                    ${focused ? `color: ${styledTheme.color.black};` : ''}
                    @media ${theme.mediaQuery.desktop} {
                        padding-bottom: ${toRem(4)};
                    }
                }
            }
        }
        &:nth-child(2){
            outline: none;
            font-size: ${toRem(24)};
            line-height: ${toRem(20)};
            position: absolute;
            bottom: ${toRem(19)};
            right: 0;
            padding: 0;
            text-transform: capitalize;
            ::before {
                color: ${styledTheme.color['base-10']};
            }
        }
    }
`
);
