/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import {
  OUTLET_CUISINE,
  OUTLET_TYPE,
  OUTLET_CITIES,
  OUTLET_DATA,
  OUTLET_COUNTRY,
} from '../../constants/OutletFilterConstants';

interface OutletFilterState {
  cuisine: any;
  type: any;
  cities: any;
  allOutlets: any;
  country: any;
  setOutletFilterState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) => any;
}
const initialState: OutletFilterState = {
  [OUTLET_CUISINE]: {
    selectedCuisine: [],
  },
  [OUTLET_TYPE]: {
    [OUTLET_TYPE]: ['Dining'],
  },
  [OUTLET_CITIES]: {
    selectedCities: [],
  },
  [OUTLET_DATA]: {
    allOutlets: [],
  },
  [OUTLET_COUNTRY]: {
    selectedCountry: [],
  },
  setOutletFilterState: () => {},
};

// To be moved to utils also needs change according to outlet
function outletFilterHelperFunction(
  state: Record<string, any>,
  fieldNames: Array<string>,
  fieldsData: Record<string, any>,
  reset?: boolean
): object {
  const updatedState = state;
  //to reset everything inside the given field , this will not reset the whole store but only reset the given fieldname
  if (reset) {
    fieldNames.forEach((name: string) => {
      updatedState[name] = {};
    });
  }
  fieldNames.forEach((name: string) => {
    updatedState[name] = { ...updatedState[name], ...fieldsData[name] };
  });
  return updatedState;
}

const OutletFilterStore: StateCreator<OutletFilterState> = set => {
  return {
    ...initialState,

    //Outlet Filter context setter method
    setOutletFilterState: (fieldNamesArray: Array<string>, fieldsData: any, reset?: boolean) =>
      set(state => {
        const updatedState = outletFilterHelperFunction(state, fieldNamesArray, fieldsData, reset);
        return updatedState;
      }),
  };
};

export const useOutletFilterStore = createAppStore(OutletFilterStore, {
  usePersistentStore: false,
});
