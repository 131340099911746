import React, { FC, Suspense } from 'react';
import ErrorBoundary from '../../atoms/ErrorBoundary';

const HiddenFields = React.lazy(() => import('./HiddenFields'));

const HiddenFieldsLazy: FC<{ isPublishUserSelection?: boolean; acceptLanguage?: string; isMPO?: boolean }> = ({
  isPublishUserSelection = false,
  acceptLanguage,
  isMPO = false,
}) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={''}>
        <HiddenFields isPublishUserSelection={isPublishUserSelection} acceptLanguage={acceptLanguage} isMPO={isMPO} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default HiddenFieldsLazy;
