import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledTertiaryCta = styled.button`
  font-family: ${theme.fontFamily.swiss};
  font-weight: ${theme.fonts['fontWeightMedium']};
  font-size: ${toRem(13)};
  line-height: ${toRem(16)};
  color: ${theme.color['base-10']};
  :hover {
    cursor: pointer;
  }
`;
