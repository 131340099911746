import styled from 'styled-components';
import { theme, toRem } from '@marriott/mi-ui-library-shop';
import { StyledPopupDiv } from '../../atoms/Popup/Popup.styles';
import { StyledPopupHeaderDiv } from '../../atoms/PopupHeader/PopupHeader.styles';
import { StyledPopupMain } from '../../atoms/PopupMain/PopupMain.styles';
import { StyledTextFieldDiv } from '@marriott/mi-ui-library-shop';

export const StyledSpecialRatesDiv = styled.div`
  position: relative;
  color: ${theme.color['dark-gray-2']};
  font-weight: ${theme.fonts['fontWeightMedium']};
  font-family: ${theme.fontFamily.swiss} !important;
  @media ${theme.mediaQuery.mobileOnly} {
    height: calc(100% - 132px);
    overflow-y: auto;
  }
  .crop-promo-code {
    position: relative;
    input {
      font-weight: ${theme.fonts['fontWeightMedium']};
    }
    button {
      display: inline-block;
      position: absolute;
      &[class*='m-button'] {
        bottom: ${toRem(8)};
        right: ${toRem(40)};
        @media ${theme.mediaQuery.tablet} {
          right: ${toRem(0)};
        }
      }
    }
  }
  fieldset {
    padding: 0;
    border: none;
    margin: 0;
    legend {
      margin-bottom: 0;
    }
  }
  ${StyledPopupDiv} {
    padding-top: 0;
    @media ${theme.mediaQuery.tablet} {
      padding-top: ${toRem(24)};
      max-height: ${toRem(506)};
      padding-bottom: ${toRem(32)};
      width: ${toRem(386)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      width: ${toRem(402)};
    }
  }
  ${StyledTextFieldDiv} {
    margin-top: ${toRem(16)};
    @media ${theme.mediaQuery.mobileOnly} {
      margin-left: ${toRem(20)};
      margin-right: ${toRem(20)};
    }
  }
  ${StyledPopupMain} {
    @media ${theme.mediaQuery.mobileOnly} {
      padding-top: ${toRem(133)};
      .h-r-label-form-field.spl-rates-heading {
        padding: ${toRem(0)} 0 0 ${toRem(16)};
        color: ${theme.color['dark-gray-4']};
      }
      @media ${theme.mediaQuery.tablet} {
        max-height: ${toRem(421)};
        padding-bottom: ${toRem(32)};
      }
    }
  }
  ${StyledPopupHeaderDiv} {
    span {
      letter-spacing: ${toRem(1.3)};
    }
    .h-r-label-form-field {
      margin: ${toRem(24)} ${toRem(24)} 0 0;
      @media ${theme.mediaQuery.mobileOnly} {
        margin: 0;
      }
    }
  }
  [class^='icon-'] {
    color: ${theme.color['dark-gray-1']};
  }
  .cta-reset {
    font-size: ${toRem(17.6)};
    line-height: ${toRem(20)};
    position: absolute;
    bottom: ${toRem(14)};
    right: ${toRem(100)};
    @media ${theme.mediaQuery.mobileOnly} {
      right: ${toRem(20)};
    }
    padding: 0;
    &:before {
      color: ${theme.color['light-gray-6']};
    }
  }
  .corp-code-edit {
    position: relative;
    label:first-of-type {
      position: relative;
      top: ${toRem(-7)};
      &:before {
        top: ${toRem(7)};
      }
      &:after {
        top: ${toRem(14)};
      }
    }
  }
  .corp-code-container {
    span {
      position: absolute;
      bottom: ${toRem(15)};
      left: ${toRem(52)};
      @media ${theme.mediaQuery.tablet} {
        bottom: ${toRem(47)};
        left: ${toRem(60)};
      }
      @media ${theme.mediaQuery.desktop} {
        left: ${toRem(72)};
      }
      @media ${theme.mediaQuery.largeDesktop} {
        left: ${toRem(76)};
      }
      font-size: ${toRem(14)};
      line-height: ${toRem(16)};
      color: ${theme.color['dark-gray-4']};
    }
    .cta-link {
      position: absolute;
      bottom: ${toRem(26)};
      right: ${toRem(20)};
      @media ${theme.mediaQuery.tablet} {
        bottom: ${toRem(60)};
      }
      @media ${theme.mediaQuery.desktop} {
        right: ${toRem(32)};
      }
      color: ${theme.color['black']};
      font-size: ${toRem(13)};
      line-height: ${toRem(16)};
      font-weight: ${theme.fonts['fontWeightMedium']};
      background: none;
      border: none;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;
