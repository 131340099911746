export const DESTINATION = 'destination';
export const ROOMS_AND_GUESTS = 'roomsAndGuests';
export const DATES = 'dates';
export const SPECIAL_RATES = 'specialRates';
export const USE_POINTS = 'usePoints';
export const RESET = 'reset';
export const DESTINATION_HIDDEN_FIELDS = 'destinationHiddenFields';
export const LATITUDE_AND_LONGITUDE = 'latitudeAndLongitude';
export const HOME_PAGE_HEADER_CONST_HEIGHT = 80;
export const HOME_PAGE_HEADER_HEIGHT = 112;
export const ALERT_BANNER_H = 16;
export const DESKTOP_SCROLL_HEIGHT = 530;
export const MOBILE_SCROLL_HEIGHT = 432;
