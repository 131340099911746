export default {
  FALLBACK_IMG_URI: process.env['FALLBACK_IMG_URI'] ?? '/marriottassets/marriott/Stock/stock-woman-sunset-1069700',
  playServicePath: process.env['NODE_ENV'] === 'development' ? process.env['PLAY_SERVICE_URL'] : '',
  autoSuggestionLocationDetailsAPI: '/aries-search/v2/autoComplete.comp',
  searchFormDataAPI: '/aries-search/v2/searchForm.comp',
  searchFormDefaultActionURLSPO: '/reservation/availabilitySearch.mi',
  searchFormDefaultActionURL: '/search/submitSearch.mi',
  searchFormGroupActionURL: '/meetings/search-hotels.mi?isTransient=true',
  searchFormMeetingURL: '/meetings-events/search/find-hotels.mi',
  MAX_OFFERS_COUNT: '10',
  FALLBACK_OFFERS_COUNT: '3',
  OFFER_IMAGE_CACHE_DOMAIN: process.env['OFFER_IMAGE_CACHE_DOMAIN'] ?? 'https://cache.marriott.com',
  API_TIMEOUT: 2500,
  OFFERS_BASE_URL: '/offers/submitOfferSearch.mi',
};
