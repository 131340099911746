/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react';
import { OffersAccordionContainerEnhancedProps } from './OffersAccordionContainerEnhanced.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const OffersAccordionContainerEnhancedConfig = {
  emptyLabel: 'OffersAccordionContainerEnhanced',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offersaccordioncontainerenhanced`,
};

export const OffersAccordionComp: React.FC<OffersAccordionContainerEnhancedProps> = props => {
  const OffersAccordionContainerWrapper = lazy(() => import('./OffersAccordionContainerWrapper'));
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Suspense fallback={<></>}>
      <OffersAccordionContainerWrapper {...props} />
    </Suspense>
  );
};
// Use named rather than default exports.
export const OffersAccordionContainerEnhanced = (props: any) => (
  <div id="offers-FAQsAccordion" data-testid="offers-OffersAccordion" data-component-name="o-offers-OffersAccordion">
    <EditableComponent config={OffersAccordionContainerEnhancedConfig} {...props}>
      <OffersAccordionComp {...props} />
    </EditableComponent>
  </div>
);
