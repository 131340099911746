// Styles for NearByDestinations go here.
import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledNearByDestinations = styled.div`
  .near-by-destinations-wrapper {
    .full-width-cards-slides {
      @media ${baseVariables.mediaQuery.sm} {
        width: 100% !important;
        li {
          width: inherit !important;
        }
      }
      @media ${baseVariables.mediaQuery.md} {
        ul {
          width: 100% !important;
          li {
            width: inherit !important;
          }
        }
      }
    }
  }
`;
