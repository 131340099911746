import { FC } from 'react';
import clsx from 'clsx';
import { StyledPagination } from './Pagination.styles';
import { PaginationProps } from './index';
import { useTranslation } from 'react-i18next';
import { usePagination, DOTS } from './usePagination';

export const TRACKING_CONSTANT = {
  SEARCH_RESULTS_PAGINATION: 'Search Results Pagination',
  PREVIOUS_BUTTON: 'Previous',
  NEXT_BUTTON: 'Next',
  PAGE_BUTTON: 'Page Number',
  INTERNAL_LINK: 'internal',
};
export const Pagination: FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  className,
  seoFriendlyPagination = false,
  queryParamName = '',
  classList,
  labels = { previous: 'Previous', next: 'Next' },
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });
  const { t } = useTranslation();

  const getUpdatedUrlwithParams = (param: string, newValue: string) => {
    const url = new URL(window.location.href, window.location.href);
    if (url.searchParams.has(param)) {
      if (newValue === '1') {
        url.searchParams.delete(param);
      } else {
        url.searchParams.set(param, newValue); // Update the parameter value
      }
    } else {
      if (newValue !== '1') url.searchParams.append(param, newValue); // Add it if it doesn't exist
    }
    return url.toString();
  };

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const getNextItem = () => {
    return (
      <li
        className={clsx(
          'pagination-arrow',
          't-font-s',
          currentPage === lastPage ? 'disabled' : '',
          'custom_click_track'
        )}
        onClick={seoFriendlyPagination ? () => {} : onNext}
        tabIndex={currentPage === lastPage ? -1 : 0}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        aria-label={t('NextPage')}
        data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${NEXT_BUTTON}|${INTERNAL_LINK}`}
        data-testid="pagination-next"
      >
        <div className="page-arrow-container">
          {seoFriendlyPagination && currentPage < (lastPage ?? totalCount / pageSize) ? (
            <a href={getUpdatedUrlwithParams(queryParamName, (currentPage + 1).toString())}>
              <span className="pagination-next-link t-font-xs ml-4 mr-2">{labels.next}</span>
              <span className="icon-arrow-right"></span>
            </a>
          ) : (
            <>
              <span className="pagination-next-link t-font-xs ml-4 mr-2">{labels.next}</span>
              <span className="icon-arrow-right"></span>
            </>
          )}
        </div>
      </li>
    );
  };
  const getPrevItem = () => {
    return (
      <li
        className={clsx(
          'pagination-arrow',
          'pagination-prev',
          't-font-s',
          currentPage === 1 ? 'disabled' : '',
          'custom_click_track'
        )}
        tabIndex={currentPage === 1 ? -1 : 0}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        onClick={seoFriendlyPagination ? () => {} : onPrevious}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        aria-label={t('PrevPage')}
        data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PREVIOUS_BUTTON}|${INTERNAL_LINK}`}
        data-testid="pagination-prev"
      >
        <div className="page-arrow-container">
          {seoFriendlyPagination && currentPage > 1 ? (
            <a href={getUpdatedUrlwithParams(queryParamName, (currentPage - 1).toString())}>
              <span className="icon-arrow-left"></span>
              <span className="pagination-prev-link t-font-xs mr-4 ml-2">{labels.previous}</span>
            </a>
          ) : (
            <>
              <span className="icon-arrow-left"></span>
              <span className="pagination-prev-link t-font-xs mr-4 ml-2">{labels.previous}</span>
            </>
          )}
        </div>
      </li>
    );
  };

  const getListItem = (pageNumber: number, ind: number) => {
    return (
      <li
        className={clsx(
          'pagination-item pl-2 pr-2 pb-1 pt-1',
          classList?.listItem,
          pageNumber === currentPage ? 'active t-label-s' : 't-font-s',
          'custom_click_track'
        )}
        key={`${ind}-list`}
        tabIndex={0}
        onClick={seoFriendlyPagination ? () => {} : (): void => onPageChange(pageNumber)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        aria-label={`${pageNumber}`}
        data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PAGE_BUTTON} ${pageNumber}|${INTERNAL_LINK}`}
        data-testid={`pagination-${pageNumber}`}
      >
        {seoFriendlyPagination ? (
          <a href={getUpdatedUrlwithParams(queryParamName, pageNumber.toString())}>{pageNumber}</a>
        ) : (
          pageNumber
        )}
      </li>
    );
  };
  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  const { SEARCH_RESULTS_PAGINATION, PREVIOUS_BUTTON, NEXT_BUTTON, PAGE_BUTTON, INTERNAL_LINK } = TRACKING_CONSTANT;

  return (
    <StyledPagination
      data-component-name="m-ui-library-Pagination"
      data-testid="PaginationPanel"
      className={clsx('pagination-container mt-5', className ?? className, classList?.container)}
    >
      <ul className={`pagination ${classList?.listParent}`}>
        {getPrevItem()}
        {paginationRange?.map((pageNumber, ind: number) =>
          pageNumber.toString() === DOTS ? (
            <li
              key={`${ind}-list`}
              className="pagination-dots custom_click_track"
              data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PAGE_BUTTON} ${pageNumber}|${INTERNAL_LINK}`}
            >
              &#8230;
            </li>
          ) : (
            getListItem(pageNumber, ind)
          )
        )}
        {getNextItem()}
      </ul>
    </StyledPagination>
  );
};
