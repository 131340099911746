// Styles for SearchFilterPanel go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { StyledBrandFilter } from '../BrandFilter/BrandFilter.styles';

export const StyledSearchFilterPanel = styled.div`
  ${rtl` 
    ${StyledBrandFilter} {
      padding: 0 !important;
      .brand-filter {
        padding-right: 0 !important;
      }

      .brand-filter__title {
        margin-top: ${toRem(5)} !important;
      }

      .brand-filter__category_label_btn:focus-visible {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }

      .brand-filter__category_label_title {
        letter-spacing: ${toRem(1.3)};
      }
    }
  `};

  .modal-footer {
    padding-top: ${toRem(20)};
    padding-bottom: ${toRem(20)};
    @media only ${baseVariables.mediaQuery.lg} {
      padding-top: ${toRem(22)};
      padding-bottom: ${toRem(22)};
    }
  }
  .modal-header {
    padding-left: ${toRem(24)};
    padding-right: ${toRem(24)};
    @media only ${baseVariables.mediaQuery.lg} {
      padding-left: ${toRem(32)};
      padding-right: ${toRem(32)};
    }
  }
  .all-filters {
    padding: ${toRem(15)} ${toRem(10)} ${toRem(15)} ${toRem(24)} !important;

    @media only ${baseVariables.mediaQuery.md} {
      padding: ${toRem(15)} ${toRem(10)} ${toRem(15)} ${toRem(24)} !important;
    }
  }

  .checkbox-controls-list {
    padding: 0;
    .filter-separator {
      margin-bottom: 0;
    }
    &:last-child {
      .filter-separator {
        display: none;
      }
    }
    .show-more-btn:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
    &__checkbox label {
      font-weight: ${baseVariables.font['fontWeightRegular']};
    }

    .row {
      margin-right: ${toRem(-8)};
      margin-left: ${toRem(-8)};
    }
  }

  @media only ${baseVariables.mediaQuery.tablet} {
    ${StyledBrandFilter} {
      .brand-filter__title {
        margin-top: ${toRem(16)} !important;
      }
    }
  }
`;
