import { MOCK_SESSION_ID } from '../constants/CommonConstants';
import { fetchOffersData } from '../api/offersService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOffersSpecificData = async (context: any, locale: string) => {
  const resolvedUrlString: string = context.resolvedUrl;
  const resolvedUrl = resolvedUrlString.split('?')[0];

  const req = context?.req;
  const resp = context?.res;

  const requestHeaders = {
    currentLocale: `${req.headers['accept-language']}`,
    requestId: req.headers['x-request-id'] ?? `${Date.now()}`,
    sessionToken: req?.cookies?.sessionID ?? MOCK_SESSION_ID,
    resolvedUrl: resolvedUrl,
    hostname: req?.headers['x-forwarded-host'],
    url: req?.url,
    referer: req?.headers?.referer ?? '',
  };
  console.log('[offersPrePageCall] req: ', req, 'resp: ', resp, 'requestHeaders: ', requestHeaders);
  const offersResponse = await fetchOffersData(requestHeaders, context, locale);
  return offersResponse;
};
