/* eslint-disable @nx/enforce-module-boundaries */
import React, { ReactNode } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { CalendarProps } from './index.types';
import { StyledCalendarDiv } from './index.styles';
import { getUserAgentType } from '@marriott/mi-ui-library';
import { useCalendar, VARIATION_DESKTOP, VARIATION_TABLET, VARIATION_VERTICAL } from './index.controller';

const Calendar: React.FC<CalendarProps> = props => {
  const {
    variation,
    dateRef,
    getVerticalMonths,
    hoverDate,
    numberOfNightsValue,
    selectedDaysModifier,
    selectedDaysRange,
    onDayMouseLeave,
    onDayClickHandler,
    fromMonth,
    toMonth,
    renderDay,
    shortWeekdays,
    monthsValue,
    weekdaysLongName,
    initialMonth,
    isDayDisabled,
    updateMouseEnterEvent,
    firstDayOfWeek,
    captionElement,
  } = useCalendar(props);

  return (
    <StyledCalendarDiv variation={variation} ref={dateRef} userAgentType={getUserAgentType()}>
      <DayPicker
        className="Selectable brand-css"
        selectedDays={selectedDaysRange}
        modifiers={selectedDaysModifier}
        month={initialMonth}
        fromMonth={fromMonth}
        toMonth={toMonth}
        weekdaysShort={shortWeekdays}
        weekdaysLong={weekdaysLongName}
        months={monthsValue}
        disabledDays={isDayDisabled}
        firstDayOfWeek={Number(firstDayOfWeek) || 0}
        numberOfMonths={variation === VARIATION_VERTICAL ? getVerticalMonths() : variation === VARIATION_TABLET ? 1 : 2}
        renderDay={(day, _modifier): ReactNode => {
          return renderDay(day);
        }}
        captionElement={captionElement}
        onDayClick={(day, modifiers: DayModifiers): void => {
          onDayClickHandler(day, modifiers);
        }}
        onDayMouseLeave={(_day: Date, modifiers: DayModifiers, _event: React.MouseEvent<HTMLDivElement>): void => {
          onDayMouseLeave(modifiers);
        }}
        onMonthChange={(_month: Date): void => updateMouseEnterEvent(true)}
      />
      {variation === VARIATION_DESKTOP && hoverDate ? numberOfNightsValue() : null}
    </StyledCalendarDiv>
  );
};

export default Calendar;
