import styled from 'styled-components';
import { toRem, baseVariables as theme } from '@marriott/mi-ui-library';
import { theme as styleTheme } from '@marriott/mi-ui-library-shop';

export const StyledFindBtn = styled.span`
  .find-btn {
    position: absolute;
    top: 1.125rem;
    width: ${toRem(160)};
    border: none !important;
    height: ${toRem(52)} !important;
    font-family: ${styleTheme.fontFamily.title};
    @media ${theme.mediaQuery.tablet} {
      // top: -7.5rem;
      right: calc(50% - 21rem);
      &:focus {
        outline: none;
        box-shadow: ${theme.color.white} 0 0 0 ${toRem(2)}, ${theme.color['outline-blue']} 0 0 0 ${toRem(4)};
      }
      // background:${theme.color['book-now-red']}
    }
    @media ${theme.mediaQuery.desktop} {
      // top: -8rem;
      right: calc(50% - 28rem);
      width: ${toRem(230)};
      // background:${theme.color['book-now-red']}
    }
    @media ${theme.mediaQuery.largeDesktop} {
      // top: -8.2rem;
      right: calc(50% - 33.125rem);
      width: ${toRem(275)};
      // background:${theme.color['book-now-red']}
    }
    &:active {
      background: #494949;
    }
  }
`;
