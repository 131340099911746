import React, { FC, ReactElement, useRef, useContext } from 'react';
import { DatesHeaderProps } from './DatesHeader.types';
import { StyledDatesHeaderDiv, StyledDateBoxDiv } from './DatesHeader.styles';
import { ContentContext } from '../../../index.contexts';

export const DatesHeader: FC<DatesHeaderProps> = ({
  checkInDate,
  checkOutDate,
  onClearCheckIn,
  onClearCheckOut,
  focusedCheckIn,
  onClickCheckIn,
  onClickCheckOut,
  keyHandlerCalendarheader,
}) => {
  const { checkInLabel, checkOutLabel, datesPlaceholderText, clear } = useContext(ContentContext);

  /**
   * Return react element to display check in/out date and clear button
   * @param label
   * @param date
   * @param onClearHandler
   * @param onClickHandler
   * @param focused
   */
  const DateBox = (
    label: string,
    date: string,
    onClearHandler: () => void,
    onClickHandler: () => void,
    focused: boolean,
    keyHandlerCalendarheader?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  ): ReactElement => {
    const mainBtn = useRef<HTMLButtonElement>(null);

    // handler for clear button click
    const clearClickHandler = (): void => {
      setTimeout(onClearHandler, 0);
      if (window.navigator?.platform?.indexOf('Win') > -1) {
        document.querySelector('.search-dates-popup button[aria-live=polite]')?.removeAttribute('aria-live');
        mainBtn.current?.setAttribute('aria-live', 'polite');
      }
      mainBtn.current?.focus();
    };
    return (
      <StyledDateBoxDiv focused={focused}>
        <button
          type="button"
          ref={mainBtn}
          aria-expanded={focused}
          onClick={onClickHandler}
          onKeyDown={keyHandlerCalendarheader}
        >
          <span className="t-label-xs">{label}</span>
          <span className={`h-r-form-field-txt ${!date ? 'gray-shade' : ''}`}>
            {date ? date : datesPlaceholderText}
          </span>
        </button>
        {focused && date ? (
          <button type="button" className="link-white icon-cancel" onClick={clearClickHandler}>
            <span className="sr-only">{clear}</span>
          </button>
        ) : (
          ''
        )}
      </StyledDateBoxDiv>
    );
  };

  return (
    <StyledDatesHeaderDiv>
      {DateBox(checkInLabel, checkInDate, onClearCheckIn, onClickCheckIn, focusedCheckIn, keyHandlerCalendarheader)}
      {DateBox(checkOutLabel, checkOutDate, onClearCheckOut, onClickCheckOut, !focusedCheckIn)}
    </StyledDatesHeaderDiv>
  );
};

// export default DatesHeader;
