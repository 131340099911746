/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import dynamic from 'next/dynamic';
import { OfferDetailsProps } from './OfferDetails.types';
import { StyledOfferDetails, StyledOfferDetailsPreviewMode } from './OfferDetails.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  ANCILLARY_PRODUCT_OFFER,
  MBO_MPO_OFFER,
  SBO_MPO_OFFER,
  SINGLE_PROPERTY_OFFER,
} from '../../constants/OfferDetailsConstants';

export const OfferDetailsWrapperConfig = {
  emptyLabel: 'offerDetails',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-details`,
};

export const OfferDetailsReactWrapper: FC<OfferDetailsProps> = props => {
  const OfferDetailsSingleProperty = dynamic(() => import('./OfferDetailsSingleProperty/OfferDetailsSingleProperty'));
  const OfferDetailsMultiProperty = dynamic(() => import('./OfferDetailsMultiProperty/OfferDetailsMultiProperty'));
  const isSpoVariation =
    props?.model.variation === ANCILLARY_PRODUCT_OFFER || props?.model.variation === SINGLE_PROPERTY_OFFER;
  const isMPOVariation = props?.model.variation === SBO_MPO_OFFER || props?.model.variation === MBO_MPO_OFFER;
  return props?.offersData?.isPreview === 'true' ? (
    <StyledOfferDetailsPreviewMode data-testid="offers-details" data-component-name="o-offers-details">
      {isSpoVariation && <OfferDetailsSingleProperty offerDetailsProp={props} />}
      {isMPOVariation && <OfferDetailsMultiProperty offerDetailsProp={props} />}
    </StyledOfferDetailsPreviewMode>
  ) : (
    <StyledOfferDetails data-testid="offers-details" data-component-name="o-offers-details">
      {isSpoVariation && <OfferDetailsSingleProperty offerDetailsProp={props} />}
      {isMPOVariation && <OfferDetailsMultiProperty offerDetailsProp={props} />}
    </StyledOfferDetails>
  );
};

export const OfferDetails = (props: any) => (
  <EditableComponent config={OfferDetailsWrapperConfig} {...props}>
    <div className="m-container-fullbleed">
      <OfferDetailsReactWrapper {...props} />
    </div>
  </EditableComponent>
);
