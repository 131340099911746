import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledForm = styled.form`
  .error-banner {
    color: #ffffff;
    background-color: #d0021b;
    border-radius: ${toRem(8)};
    flex-direction: row;
    margin-bottom: ${toRem(16)};
    .icon-alert {
      font-size: ${toRem(32)};
      padding: ${toRem(5)};
      &:before {
        color: #ffffff;
        margin: ${toRem(5)};
      }
    }
    .error-banner-message {
      margin: ${toRem(16)} ${toRem(20)} ${toRem(28)} ${toRem(0)};
      font-family: 'Swiss721-BT';
      font-size: ${toRem(14)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${toRem(20)};
    }
  }
  padding: ${toRem(42)} ${toRem(16)} ${toRem(48)};
  background: ${theme.color['base-10']};
  width: 100%;
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  .m-button-l {
    background-color: ${theme.color['base-20']};
    padding-top: ${toRem(13)};
    padding-bottom: ${toRem(13)};
    &:hover {
      box-shadow: none !important;
      color: ${theme.color['base-10']};
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .t-overline-inverse-normal {
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
  }
  .search-form-body {
    margin-bottom: auto;
  }

  button {
    &:first-child {
      border: none;
      background: transparent;
      width: ${toRem(18)};
      margin-bottom: ${toRem(30)};
      font-size: ${toRem(18)};
      padding: 0;
      &:before {
        color: ${theme.color['white']};
      }
    }
  }

  .h-r-form-field-btn {
    padding: 0;
    text-align: left;
    background: transparent;
    padding-bottom: ${toRem(16)};
    display: flex;
    flex-direction: column;
    border: 0;
    border-bottom: 2px solid ${theme.color['dark-gray-4']};
    color: ${theme.color['white']};
    margin-bottom: ${toRem(24)};

    .checkbox-switch-wrapper {
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-bottom: ${toRem(2)};
      .checkbox-switch {
        margin-right: 0;
      }

      .checkbox-slider {
        &:before {
          padding-right: 0;
          content: '';
        }
      }
    }

    span {
      padding-left: 0;
      &:first-child {
        padding-bottom: ${toRem(7)};
      }
      &:before {
        color: ${theme.color['light-orange-2']};
        padding-right: ${toRem(8)};
      }
    }

    .icon-location:before {
      font-size: ${toRem(12)};
      padding-right: ${toRem(8.58)};
    }

    .icon-nav---book:before {
      font-size: ${toRem(13)};
      padding-right: ${toRem(7)};
      top: ${toRem(2)};
    }

    .icon-arrow-right-cropped {
      position: relative;
      width: 100%;
      &:before {
        position: absolute;
        font-size: ${toRem(14)};
        color: ${theme.color['white']};
        font-weight: 500;
        top: ${toRem(2)};
        right: 0;
        padding-right: 0;
      }
    }
  }
`;
