import styled from 'styled-components';
import { theme, toRem } from '@marriott/mi-ui-library-shop';
import { StyledRowDiv } from '../../atoms/RoomsAndGuestsRow/RoomsAndGuestsRow.styles';
import { StyledPopupDiv } from '../../atoms/Popup/Popup.styles';

export const StyledRoomsAndGuestDiv = styled.div`
  position: relative;
  font-family: ${theme.fontFamily.swiss};
  font-weight: ${theme.fonts['fontWeightMedium']};

  button:focus-visible {
    outline: ${theme.color.black} auto 2px;
  }
  @media ${theme.mediaQuery.mobileOnly} {
    height: calc(100% - 132px);
    overflow-y: auto;
  }
  .rg-main {
    @media ${theme.mediaQuery.tablet} {
      padding-bottom: ${toRem(32)};
    }
  }
  ${StyledRowDiv} {
    height: ${toRem(72)};
  }
  .children-age-row {
    margin: 0 ${toRem(24)} ${toRem(32)};
    color: ${theme.color['gray']};
    font-family: ${theme.fontFamily.swiss};
    font-weight: ${theme.fonts['fontWeightMedium']};
    @media ${theme.mediaQuery.tablet} {
      height: ${toRem(77)};
      margin: 0 0 ${toRem(16)};
      &:last-child {
        margin-bottom: 0;
      }
    }
    > span {
      margin-bottom: ${toRem(14)};
      display: inline-block;
      font-size: ${theme.fonts['fontS']};
      &:first-child {
        margin-bottom: ${toRem(14)};
      }
    }
    ${StyledRowDiv} {
      height: auto;
      padding-bottom: ${toRem(14)};
    }
  }
  [class^='icon-'] {
    color: ${theme.color['dark-gray-1']};
  }
  button[type='reset'] {
    font-size: ${toRem(16)};
    font-weight: 700;
    font-family: ${theme.fontFamily.swiss};
    margin: ${toRem(-8)} 0 ${toRem(32)} auto;
    display: block;
    background: transparent;
    border: none;
    cursor: pointer;
    @media ${theme.mediaQuery.desktop} {
      margin-top: ${toRem(-4)};
    }
  }
  ${StyledPopupDiv} {
    padding-top: 0;
    @media ${theme.mediaQuery.tablet} {
      max-height: ${toRem(480)};
      padding-bottom: ${toRem(0)};
      left: ${toRem(0)};
      width: ${toRem(386)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      width: ${toRem(402)};
    }
    .addBtmMargin {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: ${toRem(32)};
      }
    }
  }
  .children-age-heading {
    color: ${theme.color['gray']};
    padding: ${toRem(24)} ${toRem(24)} ${toRem(8)};
    @media ${theme.mediaQuery.tablet} {
      padding: ${toRem(16)} 0 ${toRem(8)};
    }
  }
`;
