/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useEffect, useRef } from 'react';
import { DropDownModal, RadioButton, useCheckBreakpoint } from '@marriott/mi-ui-library';
import { useHotelDirectoryStore } from '../../../modules/store/hotelDirectoryStore';
import { StyledAllRegionList } from './AllRegionList.styles';
import { SELECTED_REGION } from '../../../modules/constants/HotelDirectory.constants';
import clsx from 'clsx';
import { formatNumberByLocale } from '../../../utils/src/utils';
import { usePageContext } from '../../../context';
import { RegionItemProps } from './AllRegionList.types';
import { changeRegionNames, searchCounterValue } from '../HotelDirectoryMap/HotelDirectoryMapHelper';
import { useHotelDirectoryStaticDataContext } from '../../../modules/context/HotelDirectoryStaticDataContext';

export const AllRegionList = ({
  openState,
  setOpenState,
  hotelDirectoryCounter,
  setHotelDirectoryCounter,
  custom_click_track_value,
  setCenter,
}: any) => {
  const compRef = useRef<HTMLDivElement>(null);
  const { hotelsLabel, unitedStatesLabel, australiaPILabel, hotelLabel } = useHotelDirectoryStaticDataContext();
  const { currentLocale, headersData } = usePageContext();
  const leisureRegionList = useHotelDirectoryStore(
    (state: { leisureRegionList: any }) => state.leisureRegionList
  ).leisureRegionList;
  const selectedRegion = useHotelDirectoryStore(
    (state: { selectedRegion: any }) => state.selectedRegion
  ).selectedRegion;
  const setHotelDirectoryState = useHotelDirectoryStore(
    (state: { setHotelDirectoryState: any }) => state.setHotelDirectoryState
  );
  const isTabletOrAbove = useCheckBreakpoint('viewportM');

  const onRadioChanged = (regionItem: RegionItemProps) => {
    searchCounterValue(headersData, hotelDirectoryCounter, setHotelDirectoryCounter);
    setOpenState(false);
    setCenter({
      lat: regionItem?.latitude,
      lng: regionItem?.longitude,
    });
    setHotelDirectoryState([SELECTED_REGION], {
      [SELECTED_REGION]: {
        [SELECTED_REGION]: {
          code: regionItem?.code,
          label: regionItem?.label,
          count: regionItem?.count,
          latitude: regionItem?.latitude,
          longitude: regionItem?.longitude,
          customLabel: regionItem?.customLabel,
        },
      },
    });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>, regionItem: RegionItemProps): void => {
    if (e.keyCode === 13) {
      onRadioChanged(regionItem);
    }
  };
  // Function to close dropdown when focus leaves both input and dropdown
  const handleBlur = (event: any) => {
    const relatedTarget = event.relatedTarget;
    if (relatedTarget && !compRef.current?.contains(relatedTarget)) {
      setOpenState(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click target is not within the popup or the button or the icons
      if (!event.target.closest('.all-region-dropdown')) {
        setOpenState(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <StyledAllRegionList ref={compRef} className="container">
      <DropDownModal
        mobileTakeOver={false}
        show={openState}
        className={clsx(
          'all-region-dropdown',
          'mt-4',
          isTabletOrAbove ? 'all-region-dropdown-right' : 'all-region-dropdown-left'
        )}
        mobileHeaderEnabled={false}
        callhandleBlur={handleBlur}
        // Important: The below variable needs to be set to false in the beginning.
        // If it is initially set to true, the click listener will be initialised and this will
        // cause the modal to not open at all
        dropdownModalOpenState={openState}
        scrollDownModalCloseState={true}
        setDropdownModalOpenState={setOpenState}
        handleBlur={true}
        role={'dialog'}
        contentLabel={'Region Dropdown'}
      >
        {/* Adding a custom styling class(custom-padding-top-class) for a padding top of 20px*/}
        <div className={clsx('pt-4', 'pb-4', 'radio-btn-outline-class')}>
          {leisureRegionList?.map((regionItem: any, index: number) => {
            return (
              <div className="pl-3 pr-3 d-flex justify-content-between radio-btn-wrapper">
                <RadioButton
                  radiobuttonId={`clusterCode${index}`}
                  radiobuttonName="clusterCode"
                  radiobuttonLabel={changeRegionNames(regionItem, unitedStatesLabel, australiaPILabel)}
                  customClickClass="custom_click_track"
                  checked={regionItem?.label === selectedRegion?.label}
                  onChange={() => {
                    onRadioChanged(regionItem);
                  }}
                  value={changeRegionNames(regionItem, unitedStatesLabel, australiaPILabel)}
                  custom_click_track_value={custom_click_track_value}
                  disabled={false}
                  setTabIndex={0}
                  labelClassName={'fontWeight'}
                  className={'m-radio-button-sm'}
                  onKeyDown={e => {
                    onKeyDown(e, regionItem);
                  }}
                  tabIndexForInput={-1}
                  ariaLabelForLink={
                    changeRegionNames(regionItem, unitedStatesLabel, australiaPILabel) +
                    ' ' +
                    formatNumberByLocale(regionItem?.count, currentLocale?.replace('_', '-'))
                  }
                  aria-label={changeRegionNames(regionItem, unitedStatesLabel, australiaPILabel)}
                />
                <div className="region-property-count t-font-alt-xs">
                  {/* TODO: Replace Hotels with AEM labels */}
                  {formatNumberByLocale(regionItem?.count, currentLocale?.replace('_', '-'))}{' '}
                  {regionItem?.count === 1 ? hotelLabel : hotelsLabel}
                </div>
              </div>
            );
          })}
        </div>
      </DropDownModal>
    </StyledAllRegionList>
  );
};

export default AllRegionList;
