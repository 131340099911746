import React from 'react';
import { useTranslation } from 'react-i18next';
import { RowProps } from './RoomsAndGuestsRow.types';
import { StyledRowDiv } from './RoomsAndGuestsRow.styles';
import { ariaConditionalAppend } from '@marriott/mi-ui-library-shop';
import { Increment } from '@marriott/mi-ui-library-shop';

const Row: React.FC<RowProps> = ({
  title,
  subTitle,
  showCurrentValue,
  childrenFlag,
  currentValue,
  incrementHandler,
  decrementHandler,
  incrementDisable,
  decrementDisable,
  // setPopupOpenState,
  // isNotUpdated
}) => {
  const { t } = useTranslation();
  return (
    <StyledRowDiv>
      <div className="row-title">
        <span {...ariaConditionalAppend('live', 'polite', childrenFlag ?? false)} className="t-subtitle-l">
          {title}
        </span>
        {subTitle ? <span>{subTitle}</span> : null}
      </div>
      <Increment
        incrementText={`${t('add')} ${title}`}
        decrementText={`${t('remove')} ${title}`}
        showCurrentValue={showCurrentValue}
        currentValue={currentValue}
        incrementHandler={incrementHandler}
        decrementHandler={decrementHandler}
        incrementDisable={incrementDisable}
        decrementDisable={decrementDisable}
        // setPopupOpenState={setPopupOpenState}
        // isNotUpdated={isNotUpdated}
      />
    </StyledRowDiv>
  );
};

export default Row;
