import React, { lazy, Suspense } from 'react';
import { BrandedLogoBarProps } from './BrandedLogoBar.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const BrandedLogoBarWrapperConfig = {
  emptyLabel: 'BrandedLogoBar',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/brandedlogobar`,
};
const BrandedLogoBarWrapper = lazy(() => import('./BrandedLogoBarWrapper'));
export const BrandedLogoBarComp: React.FC<BrandedLogoBarProps> = props => {
  return (
    <Suspense fallback={<></>}>
      <BrandedLogoBarWrapper {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BrandedLogoBar = (props: any) => (
  <div data-testid="offers-BrandedLogoBar" data-component-name="o-offers-BrandedLogoBar">
    <EditableComponent config={BrandedLogoBarWrapperConfig} {...props}>
      <BrandedLogoBarComp {...props} />
    </EditableComponent>
  </div>
);
