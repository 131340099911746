import React, { FC } from 'react';

import { StyledPopupMain } from './PopupMain.styles';
import { PopupMainProps } from './PopupMain.types';

const PopupMain: FC<PopupMainProps> = ({ className, children }) => {
  return <StyledPopupMain className={className}>{children}</StyledPopupMain>;
};

export default PopupMain;
