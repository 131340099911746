import React from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: unknown): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override componentDidCatch(error: unknown, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  override render(): JSX.Element {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>;
    }

    return <div>{this.props.children}</div>;
  }
}

export default ErrorBoundary;
