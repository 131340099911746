import React, { useEffect, useRef, FC } from 'react';
import { PopupProps } from './Popup.types';
import { StyledPopupDiv } from './Popup.styles';

const Popup: FC<PopupProps> = ({
  className,
  show,
  children,
  handleBlur,
  popupOpenState,
  setPopupOpenState,
  role,
  labelledBy,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // close popup on desktop if tab focus is out of popup or user clicks outside the popup
    if (handleBlur && popupOpenState && setPopupOpenState) {
      const popupElem = popupRef.current;
      if (popupElem && popupOpenState) {
        // click event handler
        const clickListener = (event: Event): void => {
          if (!popupElem.contains(event.target as Element) && !popupElem.contains(document.activeElement)) {
            setPopupOpenState(false);
          }
        };

        // focus out handler
        const focusoutListener = (event: FocusEvent): void => {
          if (
            event.relatedTarget &&
            !popupElem.contains(event.relatedTarget as Element) &&
            !popupElem.contains(document.activeElement) &&
            !popupElem.contains(event.target as Element)
          ) {
            setPopupOpenState(false);
          }
        };

        document.addEventListener('click', clickListener);
        popupElem.addEventListener('focusout', focusoutListener);

        return (): void => {
          document.removeEventListener('click', clickListener);
          popupElem.removeEventListener('focusout', focusoutListener);
        };
      }
    }
    return;
  }, [handleBlur, popupOpenState, setPopupOpenState]);

  return (
    <StyledPopupDiv className={className} show={show} ref={popupRef} role={role} aria-labelledby={labelledBy}>
      {children}
    </StyledPopupDiv>
  );
};

export default Popup;
