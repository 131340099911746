import React, { FC } from 'react';
// import ErrorBoundary from '../../atoms/errorBoundary';
import { SearchMobileModalProps } from './SearchMobile.types';
import SearchMobileModal from './SearchMobileModal';

// const SearchMobileModal = React.lazy(() => import('./searchMobileModal'));

const SearchMobileModalLazy: FC<SearchMobileModalProps> = props => {
  return (
    // <ErrorBoundary>
    //   <Suspense fallback={''}>
    <SearchMobileModal {...props} />
    //   {/* </Suspense>
    // </ErrorBoundary> */}
  );
};

export default SearchMobileModalLazy;
