import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledCurrentLocation = styled.div`
  margin: 0 ${toRem(16)} ${toRem(28)};
  border-bottom: ${toRem(1)} solid ${theme.color['light-gray-6']};
  a span.icon-nearby:before {
    color: var(--t-icon-color-decorative);
  }
  @media ${theme.mediaQuery.tablet} {
    margin: 0 ${toRem(24)} ${toRem(28)};
  }
  @media ${theme.mediaQuery.desktop} {
    margin: 0 ${toRem(32)} ${toRem(28)};
  }
  @media ${theme.mediaQuery.largeDesktop} {
    margin: 0 ${toRem(40)} ${toRem(28)};
  }
  a {
    position: relative;
    padding: ${toRem(12)} ${toRem(24)} ${toRem(12)} ${toRem(40)};
    margin: ${toRem(12)} ${toRem(-16)} ${toRem(16)};
    @media ${theme.mediaQuery.tablet} {
      padding-left: ${toRem(56)};
      margin: ${toRem(12)} ${toRem(-24)} ${toRem(16)};
    }
    @media ${theme.mediaQuery.desktop} {
      padding: ${toRem(12)} ${toRem(36)} ${toRem(12)} ${toRem(68)};
      margin: 0 ${toRem(-32)} ${toRem(16)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      padding: ${toRem(12)} ${toRem(36)} ${toRem(12)} ${toRem(68)};
      margin: 0 ${toRem(-40)} ${toRem(16)};
    }
    @media ${theme.mediaQuery.mobileOnly} {
      padding-top: ${toRem(24)};
      padding-bottom: ${toRem(16)};
      margin-top: 0;
    }
    display: block;
    text-decoration: none;
    [class^='icon-']::before {
      width: ${toRem(16)};
      height: ${toRem(16)};
      color: ${theme.color['light-orange-2']};
      position: absolute;
      left: ${toRem(-24)};
      @media ${theme.mediaQuery.tablet} {
        left: ${toRem(-32)};
      }
    }
    [class^='icon-'] {
      color: ${theme.color['dark-gray-1']};
    }
    span {
      position: relative;
    }
    :hover,
    :focus {
      background-color: ${theme.color['light-gray-3']};
      font-weight: ${theme.fonts['fontWeightMedium']};
    }
  }
`;
