import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledMapContainer = styled.div``;

export const BaiduButton = styled.button`
  width: ${toRem(22)};
  height: ${toRem(22)};
  border-radius: ${toRem(2)};
  padding: 0;
  font-size: ${toRem(18)};
  line-height: ${toRem(20)};
  color: ${baseVariables.cnColor['baiduButtonColor']};
  background-color: ${baseVariables.color['base20']};
  border: ${toRem(1)} solid ${baseVariables.color['neutral40']};
  &:focus {
    border: ${toRem(1)} solid ${baseVariables.color['neutral40']};
  }
  .icon-expand-white {
    padding-left: ${toRem(1)};
  }
`;

export const ClusterMarker = styled.span`
  display: inline-block;
  border-radius: 50%;
  font-size: ${toRem(14)};
  font-weight: ${baseVariables.font['fontWeightRegular']};
  color: ${baseVariables.color['neutral00']};
  width: ${({ showRing }: { showRing: boolean }): string => toRem(showRing ? 50 : 40)};
  height: ${({ showRing }: { showRing: boolean }): string => toRem(showRing ? 50 : 40)};
  line-height: ${({ showRing }: { showRing: boolean }): string => toRem(showRing ? 50 : 40)};
  text-align: center;
  cursor: pointer;
  background-color: ${baseVariables.color['base20']};
  outline: ${({ showRing }: { showRing: boolean }): string =>
    showRing ? `${toRem(12)} solid ${baseVariables.color['backgroundColorNavitemHover']}` : '0'};
  transform: translate(-50%, -50%);
`;
