import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const SearchDesktopPrimary = styled.div`
  box-shadow: ${theme.color['cta-box-shadow']};
  display: flex;
  align-items: flex-start;
  padding-right: 0;
  height: 100%;
  border-radius: ${toRem(14)};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${theme.color.white};
  position: relative;
  > button {
    align-self: center;
  }
  input:focus {
    border: none;
    outline: none;
  }
  button:focus {
    outline: 1px dotted ${theme.color.black};
  }

  .offers-mpo {
    .search-secondary {
      box-shadow: none;
    }
    .search-secondary > div:first-child {
      padding-left: ${toRem(10)};
    }
  }

  .search__container-hide-destination {
    .search-primary {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    }

    .search-primary-destination {
      display: none !important;
    }
    .offers-mpo .search-primary-destination {
      @media ${theme.mediaQuery.desktop} {
        margin-right: ${toRem(25)};
      }
      @media ${theme.mediaQuery.largeDesktop} {
        margin-right: ${toRem(25)};
      }
    }
  }
`;
