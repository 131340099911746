import styled from 'styled-components';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledSearchMobile = styled.div`
  box-shadow: ${theme.color['container-box-shadow']};
  background-color: ${theme.color.white};
  border-radius: ${toRem(6)};
  display: flex;
  &.m-search-container {
    padding: ${toRem(22)} ${toRem(20)} ${toRem(24)};
  }
  @media ${theme.mediaQuery.smallMobile} {
    padding-left: ${toRem(16)};
    padding-right: ${toRem(16)};
  }
  button {
    width: 50%;
    text-align: left;
    background: transparent;
    padding: 0;
    display: flex;
    border: none;
    flex-direction: column;
    &:first-child {
      border-right: ${toRem(1)} dashed ${theme.color['dark-gray-6']};
      padding-right: ${toRem(10)};
    }

    &:nth-child(2) {
      padding-left: ${toRem(21)};
    }

    &.no-padding {
      border-right: none;
      padding: 0;
      width: 100%;
    }
  }

  span {
    &:before {
      color: var(--t-icon-color-decorative);
      padding-right: ${toRem(8)};
      font-size: ${toRem(13)};
    }
    &:nth-child(1) {
      display: none;
      padding-bottom: 0;
      &:before {
        padding-right: ${toRem(11)};
      }
    }
    &:nth-child(2) {
      padding-bottom: ${toRem(6)};
      text-transform: uppercase;
      letter-spacing: ${toRem(1.3)};
      @media ${theme.mediaQuery.smallMobile} {
        letter-spacing: ${toRem(1)};
      }
    }
    &:nth-child(3) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      font-size: ${theme.fonts['fontL']};
      line-height: ${theme.fonts['lineHeightM']};
      color: ${baseVariables.color.neutral40};
    }
  }
  .search-date-label {
    color: ${theme.color['dark-gray-3']};
  }

  .search__container-hide-destination {
    .search-primary-destination {
      display: none !important;
    }
  }
`;
