import config from './config';
import { SuggestionDataTypes } from '../organisms/OffersSearchForm/index.types';
import { CookieUtils } from '@marriott/mi-ui-library-shop';
import { COOKIE_SESSION_ID } from '../organisms/OffersSearchForm/constants/ApplicationConstants';
import { StoreValuesType } from '../organisms/OffersSearchForm/store/store.types';
import { LOCALE_SUBDIRECTORY_MAPPING } from '../constants/CommonConstants';

export const showConfirmOnFormSubmit = (
  event: React.SyntheticEvent,
  roomsCount: string,
  flexibledateValue: string | undefined,
  flexibleDateMessage: string
): void => {
  if (parseInt(roomsCount, 10) > 3 && flexibledateValue) {
    // To show an alert for flexible date on submit
    window.alert(flexibleDateMessage);
    event.preventDefault();
    return;
  }
};
export async function getSearchFormPostData(UUID: string, PID: string): Promise<Record<string, string | number>> {
  const searchParams = new URLSearchParams({
    placeId: PID,
    uuid: UUID,
  });
  const searchURL = `${config.playServicePath}${config.autoSuggestionLocationDetailsAPI}?${searchParams.toString()}`;
  const response = await fetch(searchURL);
  const jsonResponse = await response.json();
  let locationDetails: SuggestionDataTypes;
  let locationHiddenFields: Record<string, string | number> = {};
  if (jsonResponse && jsonResponse.total > 0) {
    locationDetails = jsonResponse.suggestions[0];
    locationHiddenFields = flattenSuggestionData(locationDetails);
  }
  return locationHiddenFields;
}
export function flattenSuggestionData(data: SuggestionDataTypes): Record<string, string | number> {
  const details = data.details;
  const location = details?.location;
  const suggestionHiddenFields = {
    description: data.description,
    placeId: data.placeId,
    radius: details.distance,
    address: location.address,
    latitude: location.latitude,
    longitude: location.longitude,
    city: location.city,
    state: location.state,
    country: location.country,
    countryName: location.countryName,
    website: details.url,
    types: details.types?.join(),
  };
  return suggestionHiddenFields;
}
/**
 * Get data from Aries API for last successful search result of given sessionId
 * @param sessionID
 */
export async function getSearchFormData(sessionID?: string): Promise<StoreValuesType> {
  const sessionId = sessionID ?? CookieUtils.getCookie(COOKIE_SESSION_ID) ?? '';

  const credentialType: 'same-origin' | 'include' = 'same-origin';
  const url = `${config.playServicePath}${config.searchFormDataAPI}`;

  if (!sessionId) {
    return {};
  }

  try {
    const body = JSON.stringify({ sessionToken: sessionId });
    const config: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
      credentials: credentialType,
    };
    const response = await window.fetch(url, config);
    const responseData = await response.json();
    const { data } = responseData.component || {};
    // return parseFormData(data);
    return data;
  } catch (error) {
    // ignore
    return {};
  }
}

export const getSubDirectoryLocale = (currentLocale: string): string => {
  switch (currentLocale) {
    case 'ja-JP':
      return LOCALE_SUBDIRECTORY_MAPPING['ja-JP'];
    case 'pt-PT':
      return LOCALE_SUBDIRECTORY_MAPPING['ja-JP'];
    case 'en-GB':
      return LOCALE_SUBDIRECTORY_MAPPING['en-GB'];
    case 'it-IT':
      return LOCALE_SUBDIRECTORY_MAPPING['it-IT'];
    case 'fr-FR':
      return LOCALE_SUBDIRECTORY_MAPPING['fr-FR'];
    case 'ru-RU':
      return LOCALE_SUBDIRECTORY_MAPPING['ru-RU'];
    case 'pt-BR':
      return LOCALE_SUBDIRECTORY_MAPPING['pt-BR'];
    case 'de-DE':
      return LOCALE_SUBDIRECTORY_MAPPING['de-DE'];
    case 'es-ES':
      return LOCALE_SUBDIRECTORY_MAPPING['es-ES'];
    case 'ko-KR':
      return LOCALE_SUBDIRECTORY_MAPPING['ko-KR'];
    case 'zh-CN':
      return LOCALE_SUBDIRECTORY_MAPPING['zh-CN'];
    default:
      return '';
  }
};

// util.ts
interface Coordinates {
  latitude: number;
  longitude: number;
}

export const getLocation = (): Promise<Coordinates> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        error => {
          reject(error.message);
        }
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};
