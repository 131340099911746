import React, { lazy, Suspense, FC, useEffect } from 'react';
import { OfferPropertiesListProps } from './OfferPropertiesList.types';
import { StyledOfferPropertiesList } from './OfferPropertiesList.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const OfferDetailsWrapperConfig = {
  emptyLabel: 'offerDetails',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-properties-list`,
};

export const OfferPropertiesListWrapper: FC<OfferPropertiesListProps> = props => {
  const PropertiesListWrapper = lazy(() => import('./PropertiesListWrapper/PropertiesListWrapper'));
  useEffect(() => {
    const observer = new MutationObserver(mutationsList => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'childList' &&
          (mutation.target as Element).classList.contains(
            'PropertiesListFilterstyles__StyledSearchAllFilters-sc-bq5hrs-0'
          )
        ) {
          const childrenCount = (mutation.target as Element).children.length;
          if (childrenCount > 1) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
          } else {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
          }
        }
      }
    });

    const container = document.querySelector('.PropertiesListFilterstyles__StyledSearchAllFilters-sc-bq5hrs-0');
    if (container) {
      observer.observe(container, { childList: true });
    }

    return () => {
      observer.disconnect();
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  return (
    <StyledOfferPropertiesList data-testid="offers-properties-list" data-component-name="o-offers-properties-list">
      <Suspense fallback={<></>}>{<PropertiesListWrapper {...props} />}</Suspense>
    </StyledOfferPropertiesList>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferPropertiesList = (props: any) => (
  <EditableComponent config={OfferDetailsWrapperConfig} {...props}>
    <OfferPropertiesListWrapper {...props} />
  </EditableComponent>
);
