import React, { FC, useContext, useState, ReactElement, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { FormValuesContext } from '../../../../OffersSearchForm/index.contexts';
import Destination from '../../molecules/Destination/Destination';
import AddDates from '../../molecules/AddDates/AddDates';
import Form from '../../molecules/Form/Form';
import { SearchMobileModalProps } from './SearchMobile.types';
import RoomsAndGuests from '../../molecules/RoomsAndGuests/RoomsAndGuests';
import SpecialRates from '../../molecules/SpecialRates/SpecialRates';
import {
  CALENDAR_STATE,
  ROOM_GUEST_STATE,
  DESTINATION_STATE,
  FORM_STATE,
  SPECIAL_RATES_STATE,
  CLOSED_STATE,
} from '../../../../OffersSearchForm/index.constants';
import { setDates, setDestination, setRoomsAndGuests } from '../../../../OffersSearchForm/store/store.actions';
import { DATES, DESTINATION } from '../../../../OffersSearchForm/constants/StoreConstants';
import {
  DatesFieldType,
  DestinationFieldType,
  RoomsAndGuestsFieldType,
} from '../../../../OffersSearchForm/store/store.types';
import DestinationMPO from '../../molecules/Destination/Destination-MPO';

const SearchMobileModal: FC<SearchMobileModalProps> = ({
  dialogOpenState,
  setDialogOpenState,
  searchState,
  setSearchState,
  isHideDestinationProps = false,
  isPublishUserSelection = false,
  isHideFlexibleDates = false,
  isMPO = false,
  isSPO = false,
  acceptLanguage,
  colorScheme,
}) => {
  const { formValues, setFormValues } = useContext(FormValuesContext);

  /* states */
  const [lastState, setLastState] = useState('');
  const [cancelBackupState, setCancelBackupState] = useState<unknown>(null);
  const [lastActiveState, setLastActiveState] = useState(DESTINATION_STATE);

  /* event handlers */

  useEffect(() => {
    if (dialogOpenState) {
      setLastState(CLOSED_STATE);
    }
  }, [dialogOpenState]);

  // on click of cancel button in dialog
  const cancelHandler = (): void => {
    if (lastState === FORM_STATE) {
      // on cancel, revert dates in formvalues to last applied dates and destination
      if (searchState === CALENDAR_STATE) {
        setFormValues(setDates(cancelBackupState as DatesFieldType));
      } else if (searchState === DESTINATION_STATE) {
        setFormValues(setDestination(cancelBackupState as DestinationFieldType));
      }
      setSearchState(FORM_STATE);
    } else {
      backButtonHandler();
    }
  };

  // on selecting value in the dialog
  const changeValueHandler = (): void => {
    // if the first dialog state is destination then change to calendar or vice versa
    if (lastState === CLOSED_STATE) {
      if (searchState === DESTINATION_STATE) {
        setSearchState(CALENDAR_STATE);
      } else {
        if (isHideDestinationProps) {
          setSearchState(FORM_STATE);
        } else {
          // setSearchState(DESTINATION_STATE); // Commented this line only for SPO
          setSearchState(FORM_STATE);
        }
      }
      setLastState('');
    } else {
      // else change state to form
      setSearchState(FORM_STATE);
    }
  };

  // on click of back button in form state
  const backButtonHandler = (): void => {
    setSearchState(CLOSED_STATE);
    setDialogOpenState(false);
    // setFormValues(resetForm());
    setLastActiveState(DESTINATION_STATE);
  };

  const closePopupWithData = (): void => {
    setSearchState(CLOSED_STATE);
    setDialogOpenState(false);
    setFormValues(setDates(formValues?.dates as DatesFieldType));
    setFormValues(setDestination(formValues?.destination as DestinationFieldType));
    setFormValues(setRoomsAndGuests(formValues?.roomsAndGuests as RoomsAndGuestsFieldType));
    setLastActiveState(DESTINATION_STATE);
  };

  const fieldClickHandler = (newDialogState: string): void => {
    setLastState(searchState);
    // to persist the focus in the form
    setLastActiveState(newDialogState);
    setSearchState(newDialogState);
    // backing up dates and destination in case of cancel
    if (newDialogState === CALENDAR_STATE) {
      setCancelBackupState(formValues?.[DATES]);
    } else if (newDialogState === DESTINATION_STATE) {
      setCancelBackupState(formValues?.[DESTINATION]);
    }
  };

  // set dialog content based on search state
  const getDialogContent = (contentType: string): ReactElement | undefined => {
    const commonProps = {
      onCancel: cancelHandler,
      onChange: changeValueHandler,
    };
    const getDestinationType = (isMpo: boolean): ReactElement | undefined => {
      if (isMpo) {
        return <DestinationMPO {...commonProps} />;
      } else {
        return <Destination {...commonProps} />;
      }
    };
    switch (contentType) {
      case DESTINATION_STATE:
        return getDestinationType(isMPO);
      // { !isMPO && <Destination {...commonProps} /> }
      // { isMPO && <DestinationMPO {...commonProps} /> }
      // <Destination {...commonProps} />;
      case CALENDAR_STATE:
        return (
          <AddDates
            {...commonProps}
            isHideFlexibleDates={isHideFlexibleDates}
            acceptLanguage={acceptLanguage}
            isMPO={isMPO}
            colorScheme={colorScheme}
          />
        );
      case FORM_STATE:
        return (
          <FormValuesContext.Provider value={{ lastActiveState: lastActiveState, formValues, setFormValues }}>
            <Form
              onBack={backButtonHandler}
              onFieldClick={fieldClickHandler}
              isHideDestinationProps={isHideDestinationProps}
              isPublishUserSelection={isPublishUserSelection}
              closePopupWithData={closePopupWithData}
              isMPO={isMPO}
              isSPO={isSPO}
              acceptLanguage={acceptLanguage}
            />
          </FormValuesContext.Provider>
        );
      case ROOM_GUEST_STATE:
        return <RoomsAndGuests {...commonProps} />;
      case SPECIAL_RATES_STATE:
        return <SpecialRates {...commonProps} />;
    }
    return;
  };

  return (
    <Dialog fullScreen open={dialogOpenState}>
      {getDialogContent(searchState)}
    </Dialog>
  );
};

export default SearchMobileModal;
