import moment, { Moment } from 'moment';
import {
  CHN_DATE_FORMAT,
  DAY_DATE_MONTH,
  GERMAN_DATE_FORMAT,
  JAPNESE_DATE_FORMAT,
  KOREAN_DATE_FORMAT,
} from '../constants/ApplicationConstants';
import { LOCALES } from '../constants/ApplicationConstants';

export type DateObjectType = Moment;

export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
const DATE_FORMAT_DEFAULT = 'ddd, MMM DD';
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY';

export const getFormattedDate = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(moment(date).format(format));
};

export const getDurationBetweenDates = (startDate: string, endDate: string): number => {
  return moment.duration(getFormattedDate(endDate).diff(getFormattedDate(startDate), 'days'), 'days').asDays();
};

export const DiffBtDates = (val: string): number => {
  // Input Format should be YYYY-DD-MM
  const date1 = moment(new Date(val));
  const date2 = moment(new Date(new Date().toISOString().substr(0, 10)));
  const diffDays = moment.duration(date1.diff(date2)).asDays();
  return diffDays;
};

export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? 'MM/DD/YYYY') ?? '';
};

export const getMPOFormattedDate = (): { currMPODate: string; nextMPODate: string } => {
  const dateObj = {
    currMPODate: getDateFormatted(moment(new Date()), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    nextMPODate: getDateFormatted(moment(new Date()).add(1, 'days'), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
  };
  return dateObj;
};
export const getSearchFormattedDate = (date: string): string => {
  return getDateFormatted(moment(date), DATE_FORMAT_YEAR_MONTH_DAY).toString().split('/').join('-');
};

export const getCurrentDateObject = (): DateObjectType => {
  return moment();
};

export const getValueFromDate = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_VALUE) ?? '';
};

export const getNextDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(1, 'days');
};

export const getMinStayNextObject = (date: DateObjectType, minimumStay: number): DateObjectType => {
  return moment(date).add(minimumStay, 'days');
};

export const getPrevDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(-1, 'days');
};

export const getMinStayPrevObject = (date: DateObjectType, minimumStay: number): DateObjectType => {
  return moment(date).add(-minimumStay, 'days');
};

export const getDateObject = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(date, format).utc();
};

export const getDateObjectCurrent = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(date, format).utc(true);
};

// to get the dates based on the localization
export const getLocalizedDate = (date: DateObjectType | undefined, dateFormat: string): string => {
  moment.locale('locale');

  if (date) {
    //initially date will be in the english format we need to change that to the localized value
    const formattedDate = moment(date).format();
    return moment(formattedDate).format(dateFormat ?? DATE_FORMAT_DEFAULT);
  }
  return '';
};

export const getFormattedFromDate = (date: string): string => {
  if (moment(date) < moment(new Date())) {
    return getDateFormatted(moment(new Date()), DATE_FORMAT_VALUE).toString().split('-').join('/');
  } else {
    return getDateFormatted(moment(date), DATE_FORMAT_VALUE).toString().split('-').join('/');
  }
};

export const getFormattedToDate = (date: string, minimumStay: number): string => {
  return getDateFormatted(moment(date).add(minimumStay, 'days'), DATE_FORMAT_VALUE).toString().split('-').join('/');
};
export const setDateFormat = (acceptLanguage?: string): string => {
  switch (acceptLanguage) {
    case LOCALES.IT:
    case LOCALES.FR:
    case LOCALES.GB_PATTERN_HYPHEN:
    case LOCALES.ES:
    case LOCALES.RU:
      return DAY_DATE_MONTH;

    case LOCALES.DE:
      return GERMAN_DATE_FORMAT;

    case LOCALES.JP:
      return JAPNESE_DATE_FORMAT;

    case LOCALES.KR:
      return KOREAN_DATE_FORMAT;

    case LOCALES.CN:
      return CHN_DATE_FORMAT;

    default:
      return DATE_FORMAT_DEFAULT;
  }
};
export const getFormattedSearchDate = (date: string): string => {
  return getDateFormatted(moment(date), DATE_FORMAT_VALUE).toString().split('-').join('/');
};

export const removePeriods = (date: string, replaceAll?: boolean) => {
  if (date) {
    if (replaceAll) {
      return date?.replaceAll('.', '');
    } else {
      return date?.replace('.,', ',');
    }
  } else {
    return '';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCustomFormattedDate = (date: any, currentLocale: string) => {
  switch (currentLocale) {
    case LOCALES.FR:
      return removePeriods(date, true);
    case LOCALES.DE:
      return removePeriods(date, false);
    case LOCALES.ES:
    case LOCALES.PT_BR:
    case LOCALES.RU:
      if (date?.charAt(0) !== ' ') {
        return date?.charAt(0)?.toUpperCase() + date?.slice(1);
      } else {
        return date?.charAt(1)?.toUpperCase() + date?.slice(2);
      }

    default:
      return date;
  }
};
