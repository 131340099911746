import React from 'react';
import dynamic from 'next/dynamic';
import Skeleton from 'react-loading-skeleton';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';

import { usePageContext } from '../../../context';
import { MapContainerProps } from '../HotelDirectoryMap/HotelDirectoryMap.types';

const BaiduMapClusters = dynamic(() => import('./BaiduMapClusters'), {
  loading: () => <Skeleton containerTestId="baidu-map-skeleton" height="100%" enableAnimation />,
});

const GoogleMapClusters = dynamic(() => import('./GoogleMapClusters'), {
  loading: () => <Skeleton containerTestId="google-map-skeleton" height="100%" enableAnimation />,
});

const MapClusterComponent = ({ ...props }: MapContainerProps) => {
  const { currentLocale } = usePageContext();
  if (isCNLocale(currentLocale)) return <BaiduMapClusters {...props} />;
  return <GoogleMapClusters {...props} />;
};

export default MapClusterComponent;
