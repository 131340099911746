export const overviewMockData = {
  locationContentData: {
    property: {
      id: 'NYCES',
      basicInformation: {
        name: 'Courtyard New York Manhattan/Fifth Avenue',
        currency: 'USD',
        latitude: 40.752132,
        longitude: -73.981665,
        isAdultsOnly: false,
        isMax: false,
        brand: {
          id: 'CY',
        },
        openingDate: '2005-01-22',
        bookable: true,
        resort: false,
        descriptions: [
          {
            text: 'Take advantage of the modern amenities offered at Courtyard New York Manhattan/Fifth Avenue such as free Wi-Fi, fitness center and spacious accommodations. This hotel is near New York Public Library and Bryant Park.',
            type: {
              code: 'location',
              label: null,
              description: 'Location description which may not be in language',
            },
          },
        ],
        hasUniquePropertyLogo: false,
        nameInDefaultLanguage: 'Courtyard New York Manhattan/Fifth Avenue',
      },
      contactInformation: {
        address: {
          line1: '3 East 40th Street',
          city: 'New York',
          postalCode: '10016',
          stateProvince: {
            label: null,
            description: 'New York',
            code: 'NY',
          },
          country: {
            code: 'US',
            description: 'USA',
            label: null,
          },
        },
        contactNumbers: [
          {
            phoneNumber: {
              display: '+1 212 447 1500',
              original: '+12124471500',
            },
          },
        ],
      },
      airports: [
        {
          name: 'LaGuardia Airport (LGA)',
          distanceDetails: {
            description: '8.2 mi SW',
          },
          contactNumber: {
            phoneNumber: {
              original: '+1 718 2444444',
            },
          },
          url: 'http://www.panynj.gov/airports/laguardia.html',
          complimentaryShuttle: false,
          id: 'LGA',
        },
        {
          name: 'Newark Liberty International Airport (EWR)',
          distanceDetails: {
            description: '15.1 mi NE',
          },
          contactNumber: {
            phoneNumber: {
              original: '+1 718 2444444',
            },
          },
          url: 'http://www.panynj.gov/airports/newark-liberty.html',
          complimentaryShuttle: false,
          id: 'EWR',
        },
      ],
      otherTransportation: [
        {
          name: 'Hertz',
          contactInformation: {
            phones: ['+1 212 4865060'],
          },
          type: {
            description: 'Car Rental',
            code: 'Car Rental',
          },
        },
        {
          name: 'Av- W 41 St.',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Local Bus Stops',
            code: 'Local Bus Stops',
          },
        },
        {
          name: 'Grand Central Terminal Subway',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Subway Station',
            code: 'Subway Station',
          },
        },
        {
          name: 'Penn Station',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Subway Station',
            code: 'Subway Station',
          },
        },
        {
          name: 'Grand Central Station',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Train Station',
            code: 'Train Station',
          },
        },
        {
          name: 'Amtrak at Penn Station',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Train Station',
            code: 'Train Station',
          },
        },
        {
          name: 'The Port Authority Bus Terminal',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Bus Station',
            code: 'Bus Station',
          },
        },
        {
          name: 'NYC Bus Charters',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Bus Station',
            code: 'Bus Station',
          },
        },
        {
          name: 'New York City Passenger Ship Terminal',
          contactInformation: {
            phones: [],
          },
          type: {
            description: 'Cruise Port',
            code: 'Cruise Port',
          },
        },
      ],
      reviews: {
        stars: {
          count: 3.9,
        },
        numberOfReviews: {
          count: 1442,
        },
      },
      parking: [
        {
          fees: {
            fee: null,
            description: null,
          },
          description: 'Valet Parking is $70 for regular car and $80 for oversized car daily.',
        },
        {
          fees: {
            fee: 'Fee: 70.00 USD daily',
            description: 'Valet parking, fee: 70.00 USD daily',
          },
          description: null,
        },
        {
          fees: {
            fee: 'Fee: 50.00 USD daily',
            description: 'Off-site parking, fee: 50.00 USD daily',
          },
          description: null,
        },
      ],
      policies: {
        checkInTime: '16:00',
        checkOutTime: '11:00',
        smokefree: true,
        petsAllowed: false,
        petsPolicyDescription: 'No pets allowed-service animals only',
        localizedPetsPolicyDescription: {
          translatedText: 'No pets allowed-service animals only',
        },
        petsPolicyDetails: [],
      },
      seoNickname: 'nyces-courtyard-new-york-manhattan-fifth-avenue',
    },
  },
  textContentData: {
    property: {
      basicInformation: {
        descriptions: [
          {
            text: "Set off with confidence at Courtyard New York Manhattan/Fifth Avenue. Our hotel places you in the thick of Midtown, just walking distance from Times Square, Bryant Park, Rockefeller Center and the Theatre District. Take Grand Central Station to the Staten Island Ferry or explore Saks Fifth Avenue, Macy's and other shopping near the hotel. After a day in Bryant Park, visit our 24-hour fitness center with free weights and treadmills or return to your clean, inviting and space-savvy hotel room with ergonomic workstations. Each hotel room offers free Wi-Fi, safes and luxury bedding. Close the day with premium movies or HBO shows on a flat-screen TV. Your passion is our passion at Courtyard New York Manhattan/Fifth Avenue.",
            type: {
              description: 'Description which may not be in language',
              code: 'description',
            },
          },
          {
            text: 'Conveniently located in the heart of the city near popular attractions such as Grand Central Terminal, Bryant Park, Fifth Avenue and the New York Public Library, Courtyard New York Manhattan/Fifth Avenue is a great spot to explore the city from.',
            type: {
              description: 'Long Description which is always in language',
              code: 'longDescription',
            },
          },
          {
            text: 'Conveniently located in the heart of the city near popular attractions such as Grand Central Terminal, Bryant Park, Fifth Avenue and the New York Public Library, Courtyard New York Manhattan/Fifth Avenue is a great spot to explore the city from.',
            type: {
              description: 'Short Description which may not be in language',
              code: 'shortDescription',
            },
          },
          {
            text: 'Take advantage of the modern amenities offered at Courtyard New York Manhattan/Fifth Avenue such as free Wi-Fi, fitness center and spacious accommodations. This hotel is near New York Public Library and Bryant Park.',
            type: {
              description: 'Sales message which may not be in language',
              code: 'salesMessage',
            },
          },
          {
            text: 'Take advantage of the modern amenities offered at Courtyard New York Manhattan/Fifth Avenue such as free Wi-Fi, fitness center and spacious accommodations. This hotel is near New York Public Library and Bryant Park.',
            type: {
              description: 'Location description which may not be in language',
              code: 'location',
            },
          },
          {
            text: 'Move forward boldly in Bryant Park',
            type: {
              description: 'Header text for hotel website which may not be in language',
              code: 'headerMessage',
            },
          },
          {
            text: 'Modern hotel with free Wi-Fi and Fitness Center near Bryant Park and Fifth Avenue.',
            type: {
              description: 'Key selling point for hotel marketing which may not be in language',
              code: 'hotelMarketingCaption',
            },
          },
          {
            text: 'Reset and recharge in our Midtown Manhattan hotel',
            type: {
              description: 'Room Header Caption',
              code: 'room-header-caption',
            },
          },
        ],
      },
    },
  },
  propertyId: 'NYCAK',
  brandCode: 'AK',
  pageProps: { render: 'server', noi18n: '1', mc: '1' },
};
