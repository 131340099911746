import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HiddenFieldsLazy from '../../molecules/HiddenFields/HiddenFieldsLazy';
import AddDates from '../../molecules/AddDates/AddDates';
import SearchSecondaryBarLazy from '../../molecules/SearchSecondaryBar/SearchSecondaryBarLazy';
import Destination from '../../molecules/Destination/Destination';
import FindBtn from '../../atoms/FindBtn/FindBtn';
import { SearchDesktopPrimary } from './SearchDesktop.styles';
import { ContentContext, FormValuesContext } from '../../../index.contexts';
import { showConfirmOnFormSubmit } from '../../../../../lib/helper';
import { DESTINATION, ROOMS_AND_GUESTS } from '../../../constants/StoreConstants';
import config from '../../../../../lib/config';
import DestinationMPO from '../../molecules/Destination/Destination-MPO';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { Text, Types } from '@marriott/mi-ui-library';
import { addSubDirectoryPrefix } from '../../../../../utils/OfferUtils';

let showSecondary = false;
const SearchDesktop: FC<{
  className?: string;
  isHideDestinationProps?: boolean;
  isPublishUserSelection?: boolean;
  isHideFlexibleDates?: boolean;
  isMPO?: boolean;
  isSPO?: boolean;
  previewMode?: boolean;
  acceptLanguage: string;
  colorScheme?: string;
}> = ({
  className,
  isHideDestinationProps = false,
  isPublishUserSelection = false,
  isHideFlexibleDates = false,
  isMPO,
  isSPO,
  previewMode,
  acceptLanguage,
  colorScheme,
}) => {
  const { formValues } = useContext(FormValuesContext);
  const { t } = useTranslation();
  const { roomsCount = '1' } = formValues?.[ROOMS_AND_GUESTS] ?? {};
  const { searchFormDefaultActionURL, searchFormMeetingURL, searchFormDefaultActionURLSPO } = config;
  const { title } = useContext(ContentContext);

  // Don't render hidden fields on Server sie rendering.
  const [showLazyLoadedComponents, setShowLazyLoadedComponents] = useState(false);
  //flag to show secondary Search fields.
  const [showSecondarySearch, setShowSecondarySearch] = useState(true);

  // if initial value exists then show secondary form field only during client side rendering
  if (!showSecondary && typeof window !== 'undefined') {
    showSecondary = !!formValues?.[DESTINATION]?.displayText || isHideDestinationProps;
  }

  const { flexible } = formValues?.dates ?? {};

  const timestamp = new Date().getTime();
  const getRooms = formValues?.roomsAndGuests?.roomsCount;

  useEffect(() => {
    if (isMPO) {
      setShowSecondarySearch(false);
    }
  }, [isMPO]);

  useEffect(() => {
    // On Client side, render the hidden fields
    setShowLazyLoadedComponents(true);
  }, []);

  useEffect(() => {
    const searchFormData = {
      date: '123',
    };
    eventUtil.dispatch('DefaultSearchDataDesk', { searchFormData });
  });

  // const isValidRoom = getRooms!== '4-9'
  // const newRoom = getRooms!=='10-25';
  // const maxRooms = getRooms !== '+26';

  const getFormAction = (): string => {
    if (isSPO) {
      return addSubDirectoryPrefix(searchFormDefaultActionURLSPO);
    } else if (getRooms === '26+' && !isSPO) {
      return searchFormMeetingURL;
    } else {
      return searchFormDefaultActionURL;
    }
  };

  return (
    <>
      <div className="mpo-search-title">
        {isMPO && <Text copyText={title} fontSize={Types.size.medium} element={Types.tags.paragraph} />}
      </div>
      <form
        action={getFormAction()}
        id={timestamp.toString() + '-find-a-offer-homePage-form'}
        noValidate
        method="get"
        autoComplete="off"
        className={`container m-hide-mobile ${colorScheme} ${className} ${previewMode ? 'preview-cta' : ''}`}
        role="search"
        onSubmit={(event: React.SyntheticEvent): void =>
          showConfirmOnFormSubmit(event, roomsCount, flexible, t('flexibleAlertMessage'))
        }
        onClick={(): void => {
          if (isMPO) {
            setShowSecondarySearch(true);
          }
        }}
      >
        {showLazyLoadedComponents ? (
          <HiddenFieldsLazy
            isPublishUserSelection={isPublishUserSelection}
            acceptLanguage={acceptLanguage}
            isMPO={isMPO}
          />
        ) : (
          ''
        )}

        <div className="search-primary-container container brand-css">
          <SearchDesktopPrimary className="row search-primary">
            {!isHideDestinationProps && (
              <div className="col-md-4 search-primary-destination">
                {!isMPO && <Destination isMPO={isMPO} />}
                {isMPO && <DestinationMPO />}
              </div>
            )}
            <div className="col-md-6">
              <AddDates isHideFlexibleDates={isHideFlexibleDates} acceptLanguage={acceptLanguage} isMPO={isMPO} />
            </div>
          </SearchDesktopPrimary>
        </div>
        {/* {showSecondary && showLazyLoadedComponents ? ( */}

        {showSecondarySearch && <SearchSecondaryBarLazy isPublishUserSelection={isPublishUserSelection} />}
        {/* // ) : null} */}
        <FindBtn isPublishUserSelection={isPublishUserSelection} isMpo={isMPO} isSPO={isSPO} />
      </form>
    </>
  );
};

export default SearchDesktop;
