import React, { FC } from 'react';
import { TertiaryCtaProps } from './TertiaryCta.types';
import { StyledTertiaryCta } from './TertiaryCta.styles';

const TertiaryCta: FC<TertiaryCtaProps> = ({ className, clickHandler, keyHandler, label }) => {
  return (
    <StyledTertiaryCta type="button" className={className} onClick={clickHandler} onKeyDown={keyHandler}>
      {label}
    </StyledTertiaryCta>
  );
};

export default TertiaryCta;
