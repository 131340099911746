import { Types } from '@marriott/mi-ui-library';

export const trackingDetailArr: Array<Types.TrackingDetailsProps> = [
  { text: 'trackingContentPosition', pre: '' },
  { text: 'trackingDescription', pre: 'msg=' },
  { text: 'trackingOfferType', pre: 'type=' },
  { text: 'trackingTag', pre: 'tag=' },
];
export const constants = {
  COBRAND_NON_POINTS_MATH: 'cobrandNonPointsMath',
  TARGET_BLANK: '_blank',
};
export const config = {
  gcv: 'AMCV_664516D751E565010A490D4C@AdobeOrg',
  cookie: 'MCMID',
  ctaURL: '/loyalty/offers/chase.mi?returnURL=/loyalty/myAccount/profile.mi&destinationURL=',
  cname: 'merchViewed',
  code1: '0513',
  code2: '0532',
  HIO: 'HIO',
};
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY'; // 01/20/2021
export const RECENT_SEARCH_LIST_LIMIT = 5;
export const CALENDAR_MAX_ALLOWED_DAYS = 355;
export const DATE_FORMAT_DEFAULT = 'ddd, MMM DD'; // Wed, Jan 20
export const VARIATION_DESKTOP = 'DESKTOP';
export const DAY_DATE_MONTH = 'ddd, DD MMM'; // sat, 26 nov
export const JAPNESE_DATE_FORMAT = 'MMM D日 (ddd)';
export const KOREAN_DATE_FORMAT = 'MMM D일, ddd';
export const CHN_DATE_FORMAT = 'MMM D日, ddd';
export const GERMAN_DATE_FORMAT = 'ddd, DD MMM'; // sat, 26 nov
export const BREAKPOINT_TABLET = 767;
export const BREAKPOINT_DESKTOP = 991;
export const VARIATION_VERTICAL = 'VERTICAL';
export const VARIATION_TABLET = 'TABLET';
export const DAY_MONTH_YEAR_FORMAT = 'DD/MM/YYYY'; // 20/01/2021
export const SEARCH_SCROLL_TOP_POSITION = 131;
export const COOKIE_SESSION_ID = 'sessionID';
export const DEFAULT_API_ACCEPT_LANGUAGE = 'en-US';
export const OFFERS_SEARCH_FORM_OPERATION_NAME = 'phoenixOffersSearchForm';
export const OFFERS_SEARCH_FORM_OPERATION_SIGNATURE =
  '25c7f2224b03bff1f4c97d8832baf5ce4053ced99b5774bdb147fec0d3fb1fe1';
export const OFFER_SEARCH_CLIENT_NAME = 'phoenix_offers';
export const OFFERS_SEARCH_FORM_PREVIEW_OPERATION_NAME = 'phoenixOffersSearchFormPreview';
export const OFFERS_SEARCH_FORM_PREVIEW_OPERATION_SIGNATURE =
  'a473a506b7b33f324b957a0d999a941be1caf2375e5286ac18b7b8819f9e31db';
export const domain = process.env['APP_SERVER'] ?? 'https://www.marriott.com';
export const SEARCH_AUTOSUGEESTION_OPERATION_NAME = 'phoenixOfferSuggestedPlacesQuery';
export const SEARCH_AUTOSUGEESTION_OPERATION_SIGNATURE = '';
export const SEARCH_SUGGESTED_PLACES_DETAILS_OPERATION_NAME = 'phoenixOfferSuggestedPlacesDetailsQuery';
export const SEARCH_SUGGESTED_PLACES_DETAILS_OPERATION_SIGNATURE = '';
export const MILESTOMETERS = 1609.344;

export const LOCALES = {
  GB_PATTERN_HYPHEN: 'en-GB',
  GB_PATTERN_UNDER_SCORE: 'en_GB',
  US: 'en-US',
  JP: 'ja-JP',
  FR: 'fr-FR',
  ES: 'es-ES',
  IT: 'it-IT',
  PT_BR: 'pt-BR',
  KR: 'ko-KR',
  RU: 'ru-RU',
  DE: 'de-DE',
  TW: 'zh-TW',
  CN: 'zh-CN',
};
