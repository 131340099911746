/**
 * This code is based on SmoothScroll plugin
 * https://github.com/iamdustan/smoothscroll
 */
export default class ScrollUtils {
  // define timing method
  static now = (): number => (window?.performance?.now ? window.performance.now() : Date.now());

  /**
   * returns result of applying ease math function to a number
   * @method ease
   * @returns {Number}
   */
  static ease(k: number): number {
    return 0.5 * (1 - Math.cos(Math.PI * k));
  }

  /**
   * self invoked function that, given a context, steps through scrolling
   * @method step
   * @param {Object} context
   * @returns {undefined}
   */
  static step(context: { startX: number; startY: number; x: number; y: number; startTime: number }): void {
    const time = ScrollUtils.now();
    let elapsed = (time - context.startTime) / 400;

    // avoid elapsed times higher than one
    elapsed = elapsed > 1 ? 1 : elapsed;

    // apply easing to elapsed time
    const value = ScrollUtils.ease(elapsed);
    const xVal = (context.x - context.startX) * value;
    const yVal = (context.y - context.startY) * value;
    const currentX = context.startX + xVal;
    const currentY = context.startY + yVal;

    window.scroll(currentX, currentY);

    // scroll more if we have not reached our destination
    if (currentX !== context.x || currentY !== context.y) {
      window.requestAnimationFrame(ScrollUtils.step.bind(window, context));
    }
  }

  /**
   * scrolls window with a smooth behavior
   * x is the amount for horizontal scroll from current position
   * y is the amount for vertical scroll from current position
   * @method smoothScroll
   * @returns {undefined}
   */
  static smoothScroll(x: number, y: number): void {
    const startY = window.scrollY || window.pageYOffset;
    const startX = window.scrollX || window.pageXOffset;
    // scroll looping over a frame
    ScrollUtils.step({
      startTime: ScrollUtils.now(),
      startX: startX,
      startY: startY,
      x: x + startX,
      y: y + startY,
    });
  }
}
