import dynamic from 'next/dynamic';

export const AppSmallBanner = dynamic(() => import('./organisms/AppSmallBanner/index').then(mod => mod.AppSmallBanner));
export const AppHomepageHero = dynamic(() =>
  import('./organisms/AppHomepageHero/index').then(mod => mod.AppHomepageHero)
);
export const OffersAppContainer = dynamic(() =>
  import('./organisms/OffersAppContainer/index').then(mod => mod.OffersAppContainer)
);
export const AppTileSquare = dynamic(() => import('./organisms/AppTileSquare/index').then(mod => mod.AppTileSquare));
//export * from './organisms/OffersAppContainer';
