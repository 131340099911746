import React from 'react';
import { StyledSpecialRatesDiv } from './SpecialRates.styles';
import { SpecialRatesProps } from './SpecialRates.types';
// import { setSpecialRates, setUsePoints } from '../../../store/store.actions';

const SpecialRates: React.FC<SpecialRatesProps> = () => {
  // const compRef = useRef<HTMLDivElement>(null);
  // const { t } = useTranslation();
  // const isMobileView = useContext(ViewportContext);
  // const { formValues, setFormValues } = useContext(FormValuesContext);
  // // const splRatesHiddenFields = formValues?.[SPECIAL_RATES];
  // const [collapseTextField, setCollapseTextField] = React.useState(false);
  // const [popupOpenState, setPopupOpenState] = React.useState(false);
  // const [clusterCode, setClusterCode] = React.useState<string>('splRatesHiddenFields?.clusterCode' ?? '');
  // const [corpCode, setCorpCode] = React.useState<string>('splRatesHiddenFields?.corporateCode' ?? '');
  // const { specialRatesListItems } = useContext(ContentContext);
  // const specialRatesList = specialRatesListItems as Array<Record<string, string>>;
  // const { specialRateslabel: lowestRatesLabel } =
  //   specialRatesList?.find(({ specialRatesCode }) => specialRatesCode === 'none') ?? {};
  // let specialRateDefaultLabel = '';
  // specialRatesList?.forEach((obj) => {
  //   if (obj.specialRatesCode === 'splRatesHiddenFields?.clusterCode') {
  //     specialRateDefaultLabel = obj.specialRateslabel;
  //   }
  // });
  // const [specialRateLabel, setSpecialRateLabel] = React.useState<string>(specialRateDefaultLabel);

  // useEffect(() => {
  //   if (popupOpenState) {
  //     setFocusOnFirstBtn();
  //   }else {
  //     //set form values and label
  //     if (!isMobileView) {
  //     setValues();
  //     }
  //   }
  //   if (clusterCode !== 'corp') {
  //     setValues();
  //   }

  //   if (clusterCode === 'corp' && corpCode) {
  //     setCollapseTextField(true);
  //   }
  // }, [popupOpenState, clusterCode]);

  // // update local state if any change in formValues
  // useEffect(() => {
  //   // const splRates = formValues?.[SPECIAL_RATES];
  //   setClusterCode(splRates?.clusterCode ?? '');
  //   setCorpCode(splRates?.corporateCode ?? '');
  //   setSpecialRateLabel(specialRateDefaultLabel);
  // }, [formValues]);

  // const applyBtnHandler = (): void => {
  //   //set form values and label
  //   setValues();
  //   // when Apply button is clicked, update mobile state
  //   if (changeMobileState) {
  //     changeMobileState();
  //   }
  // };

  // // to set form values and label
  // const setValues = (): void => {
  //   if (clusterCode === 'corp' && !corpCode) {
  //     if (splRatesHiddenFields?.clusterCode === 'corp') {
  //       setClusterCode('none');
  //       specialRatesList.forEach((obj) => {
  //         if (obj.specialRatesCode === 'none') {
  //           setSpecialRateLabel(obj.specialRateslabel);
  //         }
  //       });
  //     } else {
  //       setClusterCode(splRatesHiddenFields?.clusterCode ?? 'none');
  //       setSpecialRateLabel(splRatesHiddenFields?.displayText ?? specialRateDefaultLabel);
  //     }
  //   }
  //   if (clusterCode !== 'corp' && corpCode) {
  //     setCorpCode('');
  //   }

  //   setFormValues(
  //     setSpecialRates({
  //       displayText:
  //         clusterCode === 'corp' && corpCode === ''
  //           ? splRatesHiddenFields?.clusterCode === 'none' ||
  //             !splRatesHiddenFields ||
  //             splRatesHiddenFields.clusterCode === 'corp'
  //             ? lowestRatesLabel
  //             : splRatesHiddenFields.displayText
  //           : specialRateLabel,
  //       clusterCode:
  //         clusterCode === 'corp' && corpCode === ''
  //           ? splRatesHiddenFields?.clusterCode === 'none' ||
  //             !splRatesHiddenFields ||
  //             splRatesHiddenFields.clusterCode === 'corp'
  //             ? 'none'
  //             : splRatesHiddenFields.clusterCode
  //           : clusterCode,
  //       corporateCode: clusterCode === 'corp' ? corpCode : '',
  //     }),
  //   );

  //   // when cluster code is set to some value other than none then use points will become false
  //   if (clusterCode !== 'none') {
  //     setFormValues(
  //       setUsePoints({
  //         value: false,
  //       }),
  //     );
  //   }
  // };

  // const setFocusOnFirstBtn = (): void => {
  //   const popupElemBtn = compRef.current?.querySelector('.special-rates-popup button') as HTMLElement;
  //   popupElemBtn?.focus();
  // };

  // // to show popup on desktop on button click
  // const showPopup = (): void => {
  //   setPopupOpenState(true);
  // };

  // // to collapse Text field when key is enter or done
  // const keyHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  //   if (e.key === 'Enter') {
  //     if (!corpCode) {
  //       const textField = compRef.current?.querySelector('[name="corporateCode"]') as HTMLElement;
  //       textField?.focus();
  //     } else {
  //       setCollapseTextField(true);
  //     }
  //   }
  // };

  // // to clear text input field
  // const resetHandler = (): void => {
  //   const textField = compRef.current?.querySelector('[name="corporateCode"]') as HTMLElement;
  //   textField?.focus();
  //   setCorpCode('');
  // };

  // // to show input text field on button click
  // const editHandler = (): void => {
  //   setCollapseTextField(false);
  //   setClusterCode('corp');
  // };

  // // to close popup on desktop on button click
  // const collapseField = (): void => {
  //   setCollapseTextField(true);
  //   const corpFiled = compRef.current?.querySelector('[value="corp"]') as HTMLElement;
  //   corpFiled?.focus();
  // };

  // // to handle radioButton selections
  // const changeHandler = (value: string, label: string): void => {
  //   setClusterCode(value);
  //   setSpecialRateLabel(label);
  //   if (!corpCode) {
  //     setCollapseTextField(false);
  //   } else {
  //     setCollapseTextField(true);
  //   }
  // };

  return (
    <StyledSpecialRatesDiv className="blue-outline">
      {/* {isMobileView ? (
        ''
      ) : (
        <>
          <button type="button" className="t-font-s" onClick={showPopup} aria-expanded={popupOpenState}>
            {specialRateLabel}
            {clusterCode === 'corp' ? (corpCode ? ' - ' + corpCode : '') : ''}
            <span className="icon-dropdown-down"></span>
          </button>
        </>
      )}
      {isMobileView || popupOpenState ? (
        <Popup
          className="special-rates-popup custom-scrollbar"
          show={true}
          handleBlur={!isMobileView}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
        >
          <fieldset>
            {!isMobileView ? (
              <legend className="t-label-s">
                <PopupHeader labelIcon="" labelText={t('selectOneRate')} cancelLabel="" />
              </legend>
            ) : (
              <PopupHeader
                cancelLabel={t('cancel')}
                cancelClickHandler={onCancel}
                labelIcon=""
                labelText={t('specialRatesPoints')}
                mainText={`${specialRateLabel}${clusterCode === 'corp' ? (corpCode ? ' - ' + corpCode : '') : ''}`}
              />
            )}
            <PopupMain>
              {isMobileView ? (
                <legend className="t-overline-normal h-r-label-form-field spl-rates-heading">
                  {t('selectOneRate')}
                </legend>
              ) : (
                ''
              )}
              {specialRatesList.map((element: Record<string, string>, index: number) => (
                <React.Fragment key={element.specialRatesCode}>
                  <RadioButton
                    radiobuttonLabel={element.specialRateslabel}
                    className={
                      element.specialRatesCode === 'corp' && collapseTextField && corpCode ? 'corp-code-edit' : ''
                    }
                    radiobuttonName="clusterCode"
                    radiobuttonId={`'clusterCode' + ${index}`}
                    checked={clusterCode === element.specialRatesCode}
                    value={element.specialRatesCode}
                    onChange={({ target: { value } }): void => changeHandler(value, element.specialRateslabel)}
                  />
                  {element.specialRatesCode === 'corp' && collapseTextField && corpCode ? (
                    <>
                      <div className="corp-code-container">
                        <span>{corpCode}</span>
                        <button type="button" className="cta-link" onClick={editHandler}>
                          {t('edit')}
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              ))}
              {clusterCode === 'corp' && !collapseTextField && (
                <div className="crop-promo-code">
                  <TextField
                    className="t-overline-normal"
                    textfieldLabel={t('addCorpOrPromoCode')}
                    textfieldName="corporateCode"
                    textfieldId={CommonUtils.getElementId()}
                    value={corpCode}
                    autoFocus={true}
                    onChange={({ target: { value } }): void => setCorpCode(value)}
                    onKeyPress={keyHandler}
                    maxLength={9}
                  />
                  {corpCode ? (
                    <button type="button" className="link-white cta-reset icon-cancel" onClick={resetHandler}>
                      <span className="sr-only">{t('clear')}</span>
                    </button>
                  ) : (
                    ''
                  )}
                  {!isMobileView && corpCode && (
                    <button type="button" onClick={collapseField} className="m-button-secondary m-button-s">
                      {t('done')}
                    </button>
                  )}
                </div>
              )}
            </PopupMain>
            {isMobileView ? <PopupFooter primaryBtnLabel={t('done')} primaryBtnClickHandler={applyBtnHandler} /> : ''}
          </fieldset>
        </Popup>
      ) : (
        ''
      )} */}
    </StyledSpecialRatesDiv>
  );
};

export default SpecialRates;
