// Imports for external libraries go here.
import React from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { NearByDestinationsInput } from './NearByDestinations.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { NearByDestinationsContainer } from './NearByDestinationsContainer';
import { StyledNearByDestinations } from './NearByDestinations.styles';

export const NearByDestinationsWrapperConfig = {
  emptyLabel: 'NearByDestinations',
  isEmpty: false,
  resourceType: 'mi-aem-offers-spa/components/nearbydestinations',
};

export const NearByDestinationsWrapper: React.FC<NearByDestinationsInput> = props => {
  return (
    <StyledNearByDestinations>
      <div className="near-by-destinations-wrapper">
        <NearByDestinationsContainer {...props} />
      </div>
    </StyledNearByDestinations>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NearByDestinations = (props: any) => (
  <div data-testid="near-by-destinations" data-component-name="o-offers-nearbydestinations">
    <EditableComponent config={NearByDestinationsWrapperConfig} {...props}>
      <NearByDestinationsWrapper {...props} />
    </EditableComponent>
  </div>
);
