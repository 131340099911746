/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const OffersHeroBannerReactWrapper = dynamic(() =>
  import('./OffersHeroBanner').then(mod => mod.OffersHeroBannerReactWrapper)
);

export const OffersHeroBannerWrapperConfig = {
  emptyLabel: 'Offers Hero Banner',
  isEmpty: false,
  resourceType: 'mi-aem-homepage-spa/components/content/herobanner',
};

export const OffersHeroBanner = (props: any) => (
  <EditableComponent config={OffersHeroBannerWrapperConfig} {...props}>
    <div className="m-container-fullbleed" data-testid="offers-hero-banner">
      <OffersHeroBannerReactWrapper {...props} />
    </div>
  </EditableComponent>
);
