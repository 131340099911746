import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  .item-label {
    font-size: ${theme.fonts.fontXs};
    font-weight: ${theme.fonts.fontWeightMedium};
    line-height: ${theme.fonts.fontM};
    margin-bottom: ${toRem(6)};
    color: ${theme.colors['dark-gray-3']};
  }
  .room-pref {
    display: block;
  }
  p {
    .item-label {
      display: inline-block !important;
    }
  }
  .custom-border {
    flex: 100%;
    margin-bottom: ${toRem(30)};
  }
  .flex-full-width {
    flex: 100%;
    margin-bottom: ${toRem(16)};
  }
  .flex-item-left {
    padding-right: ${toRem(12)};
    flex: 50%;
    margin-bottom: ${toRem(8)};
  }
  .flex-item-right {
    padding-left: ${toRem(15)};
    flex: 50%;
    margin-bottom: ${toRem(8)};
    @media ${theme.mediaQuery.allMobiles} {
      padding-left: 0;
    }
  }
  .xl-margin-right {
    margin-right: ${toRem(42)};
  }
  .currency {
    line-height: ${toRem(14)};
    text-transform: uppercase;
  }
  .t-capitalize {
    text-transform: capitalize;
  }
  @media ${theme.mediaQuery.allMobiles} {
    .flex-item-right,
    .flex-item-left {
      flex: 100%;
    }
  }
  .font-m {
    font-size: ${toRem(18)};
  }

  .font-xs {
    font-size: ${toRem(12)};
  }

  .room-label {
    margin-top: ${toRem(20)};
  }
`;
