// Styles for OffersCobrandCardHorizontalFlexible go here.
import styled from 'styled-components';

export const StyledOffersMerchandisingHeroBannerWrapper = styled.div`
  .offersherobanner-fullbleed {
    width: 100vw !important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`;
