import React from 'react';
import { FormValuesContextType, FocusContextType, PageProps } from './index.types';

export const ViewportContext = React.createContext(false);

// FocusContext is to check if curent component should be on focus
export const FocusContext = React.createContext<FocusContextType>({
  focusComp: '',
  setFocusComp: () => {
    // added comment for lint error : empty method
  },
});

export const FormValuesContext = React.createContext<FormValuesContextType>({
  UUID: '',
  setFormValues: () => {
    // added comment for lint error : empty method
  },
  setUUID: () => {
    // added comment for lint error : empty method
  },
  lastActiveState: '',
  setIsPopupOpen: () => {
    // added comment for lint error : empty method
  },
});

export const ContentContext = React.createContext<PageProps>({
  ctaLabel: '',
  placeholderTextDesktop: '',
  placeholderTextMobile: '',
  isItForOffersPage: '',
  hideDestination: '',
  submitAction: '',
  showDatesOnMobile: '',
  ratesShopPageHeader: '',
  enableTwoBoxForHeaderDates: '',
  maxGuestPerRoom: '',
  disableRecentlyViewed: '',
  topDestinationslistItems: [],
  dateFormat: '',
  enableFlexibleSearch: '',
  maxChildAge: '',
  hpBulkSearchEndPoint: '',
  disableTopDestination: '',
  enableRedeemPoints: '',
  localDateFormat: '',
  searchViewType: '',
  disableFloatingBehavior: '',
  orderingRecentlySearchedAndViewed: '',
  specialRatesListItems: [],
  hideFlexibleDatesCheckbox: '',
  enablePadding: '',
  src: '',
  placeholderTextPopularDestination: '',
  domesticDestinationsListItems: [],
  enableMarriottShopPage: '',
  enableOverlap: '',
  maxNumberOfNights: '',
  enableNewSearch: '',
  hpSearchEndPoint: '',
  roomListItems: [],
  submitActioPrefix: '',
  firstDayOfWeek: '',
  featuredDestinationsListItems: [],
  disableRecentlySearched: '',
  destination: '',
  checkInLabel: '',
  checkOutLabel: '',
  currentLocationLabel: '',
  exactDatesTabText: '',
  flexibleTabText: '',
  howManyNights: '',
  roomsGuestsHead: '',
  rooms: '',
  room: '',
  guests: '',
  guest: '',
  adults: '',
  children: '',
  dates: '',
  ageBasedRatesLabel: '',
  childAgeRequired: '',
  reset: '',
  selectOneRateLabel: '',
  usePointsCertificates: '',
  datesPlaceholderText: '',
  clear: '',
  flexibleDates: '',
  myDatesFlexibleLabel: '',
  currentLocationText: '',
  add: '',
  remove: '',
  cancel: '',
  night: '',
  nights: '',
  done: '',
  clearLabel: '',
  mobilePlaceholderText: '',
  destinationSearchResult: '',
  flexible: '',
  recentSearchesHeading: '',
  recentlyViewedHeading: '',
  clearRecentLabel: '',
  lessThanLabel: '',
  ageLabel: '',
  applyLabel: '',
  specialRatesPoints: '',
  edit: '',
  addCorpOrPromoCode: '',
  usePointsConfirmMessage: '',
  flexibleAlertMessage: '',
  stayDates: '',
  noResultFound: '',
  weekdays: '',
  weekdaysLong: '',
  months: '',
  selectAMonth: '',
  storefrontPropertySearchWidgetErrorMessageRooms: '',
  collapseLabel: '',
  newSearchLabel: '',
  weekdaysShort: '',
  monthsShort: '',
  domesticLabel: '',
  featuredLabel: '',
  collapseOnPageLoad: false,
  collapseWhenUserUpdates: false,
  marshaCode: '',
  offerId: '',
  title: '',
  maxGuestsMsg: '',
  previewMode: '',
  variation: '',
  desktopPlaceholderText: '',
  requestId: '',
  acceptLang: '',
  id: '',
  trackingProperties: {
    additionalTrackingVariables: '',
    enableScrollingBehavior: false,
    trackingDescription: '',
    impressionTrack: false,
    trackingContentPosition: '',
    atCCeVar48: '',
    clickTrack: false,
    location: '',
    merchandisingCategory: '',
    impressionCount: false,
    trackingTag: '',
    trackingOfferType: '',
  },
});
