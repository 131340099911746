export const envVarKeys = {
  NEXT_PUBLIC_ENABLE_WEBSDK: 'NEXT_PUBLIC_ENABLE_WEBSDK',
  NEXT_PUBLIC_GET_SESSION_ENDPOINT: 'NEXT_PUBLIC_GET_SESSION_ENDPOINT',
  NEXT_PUBLIC_UPDATE_SESSION_ENDPOINT: 'NEXT_PUBLIC_UPDATE_SESSION_ENDPOINT',
  NEXT_PUBLIC_GET_MVPOFFERS_ENDPOINT: 'NEXT_PUBLIC_GET_MVPOFFERS_ENDPOINT',
  NEXT_PUBLIC_SUBMIT_OSERP_URL: 'NEXT_PUBLIC_SUBMIT_OSERP_URL',
  NEXT_PUBLIC_SUBMIT_SEARCH_URL: 'NEXT_PUBLIC_SUBMIT_SEARCH_URL',
  SESSION_GET_CALL_URL_CLIENT: 'SESSION_GET_CALL_URL_CLIENT',
  NEXT_PUBLIC_AVAILABILITY_SEARCH_URL: 'NEXT_PUBLIC_AVAILABILITY_SEARCH_URL',
  NEXT_PUBLIC_ENABLE_PREQUAL: 'NEXT_PUBLIC_ENABLE_PREQUAL',
};
