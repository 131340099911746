/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, useState, FC, useEffect, useRef, useContext } from 'react';
import { LocaleUtils } from 'react-day-picker';
import { StyledCalendarWrapperDiv } from './CalendarWrapper.styles';
import {
  Button,
  Calendar,
  DateObjectType,
  getLocalizedDate,
  getValueFromDate,
  Messages,
  useWindowSize,
} from '@marriott/mi-ui-library';
import { PageContext, usePageContext } from '../../context';
import {
  CALENDAR_MAX_ALLOWED_DAYS,
  VARIATION_DESKTOP,
  SHORT_WEEK_DAYS,
  LONG_WEEK_DAYS_NAME,
  SHORT_MONTH_NAME,
  NUMBER_OF_MONTH,
  DATE_FORMAT_YEAR_MONTH_DAY,
  NO_CHECK_IN_DATE_ERROR,
  DATE_FORMAT_MM_DD_VALUE,
  POINTS_PER_QUANTITY,
  LOWEST_AVERAGE_RATE,
  ADF_CONTINUE_CLICK_TRACK,
  hardCodedAllLocale,
} from '../../constants';
import { FocusedInputType, CalendarWrapperProps } from './CalendarWrapper.types';
import { setLanguageLocalization, CustomizeRenderDay, fetchSpecificRates } from './CalendarWrapper.helper';
import { useCheckBreakpoint } from '../../hooks';
import clsx from 'clsx';
import moment from 'moment';
import { validateAvailabilityRequest } from '../../lib/organisms/SearchForm/utils/Helpers';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { formatNumberByLocale, tranformADFEdgeToDTT } from '../../utils/src/utils';
import { getFormattedDateByLocale, updateDataLayerOnInteractions } from '../../modules/utils';
import { fetchCurrencyToLocale, getRateConversionFactor } from '../../utils/src/helper';
import { JP_LOCALE, KR_LOCALE } from '../../modules/constants';
import { AlertMessageBodyProps } from './../../organisms/AvailabilityCalendar/AvailabilityCalendar.types';
export const CalendarWrapper: FC<CalendarWrapperProps> = props => {
  const {
    weekdays,
    weekdaysLong,
    monthsShort,
    adfButtonText,
    months,
    notAvailableForCheckInText,
    rateUnavailableText,
    totalPerRoom,
    forLabel,
    night,
    nights,
    firstDayOfWeek,
    ratesUnavailableMobile,
    lowPriceIndicator,
    thousandLabel,
    millionLabel,
    pointsLabel,
    yearSearchLabel,
    adfSelectionSummaryFormat,
    currencyLabel,
    resortLabel,
    feeLabel,
    destinationAmenityLabel,
    surchargeTooltipHeadingLabel,
    allRatesIncludeLabel,
    includesLabel,
    perNight,
    totalLabel,
    localDateFormat,
  } = props.jsonContent;
  const [monthChange, setMonthChange] = useState(true);
  let isLACountyFee = false;
  const [feeTitle, setFeeTitle] = useState('');
  let isResort: boolean | string = '';
  const {
    headersData,
    currentLocale,
    NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
    resolvedUrl,
    query,
    datalayerParams,
    sessionData,
    isDTT,
  } = usePageContext();
  let smallerDevicesCheck = false;
  const windowSize = useWindowSize().width;
  if (windowSize && windowSize < 767) {
    smallerDevicesCheck = true;
  } else {
    smallerDevicesCheck = false;
  }
  const returnApplicableAlert = props?.returnApplicableAlert;
  const formData = props.formData;
  const adfData = props.adfData;

  const selectedTab = props.selectedTab;
  const onMonthChange = (e: any) => {
    setMonthChange(true);
    props.onMonthChange(e);
  };
  const defaultMonthDate = new Date(moment(props.defaultMonthDate).format());
  const inventoryData = props.inventoryData;
  const isPointsSelected = props.isPointsSelected;
  const [calendarFromDate, setCalendarFromDate] = useState<any>();
  const [calendarEndDate, setCalendarEndDate] = useState<any>();
  const currentCurrency = props.currentCurrency;
  const numberOfDays = props.numberOfDays;
  const pageContext = useContext(PageContext);
  const sessionDataObj = pageContext?.sessionData?.data
    ? pageContext?.sessionData?.data
    : pageContext?.sessionData?.cacheData?.data;
  const sessionID =
    sessionData?.sessionToken ??
    sessionData?.data?.sessionToken ??
    sessionData?.cacheData?.key ??
    sessionData?.cacheData?.data?.sessionToken;

  const currentConverSionRateArr = sessionDataObj?.AriesSearch?.currentConversionRate;
  const conversionFactorRate = getRateConversionFactor(currentConverSionRateArr, props.fromCurrency) ?? 1;
  const availabilityRequestVO = sessionDataObj?.AriesSearch?.searchCriteria?.availabilityRequestVO;
  const propertyId = sessionDataObj?.AriesSearch?.searchCriteria?.propertyId;
  const [useRewardsPoints, setUseRewardsPoints] = useState(availabilityRequestVO?.rewardsRedemption);
  const [roomsAndGuests, setRoomsAndGuests] = useState({
    numberOfRooms: availabilityRequestVO?.numRooms,
    numberOfAdults: availabilityRequestVO?.numAdultsPerRoom,
    numberOfChildren: availabilityRequestVO?.numChildrenPerRoom,
    childrenAges: availabilityRequestVO?.childrenAges,
  });
  const [specialRates, setSpecialRates] = useState({
    clusterCode: availabilityRequestVO?.clusterCode,
    corporateCode: availabilityRequestVO?.corporateCode,
    groupCode: availabilityRequestVO?.groupCode,
  });

  const totalMandatoryFeesvalue =
    props?.isErsProperty || isDTT
      ? Math.floor((props?.ersManFees || 0) * (conversionFactorRate ? conversionFactorRate : 1))
      : !isDTT
      ? Math.floor(
          (props?.adfData?.calendarSearchByProperty?.edges[0]?.node?.totalPricing?.rateAmountsByMode
            ?.totalMandatoryFeesPerQuantity?.amount?.origin?.amount /
            Math.pow(
              10,
              props?.adfData?.calendarSearchByProperty?.edges[0]?.node?.totalPricing?.rateAmountsByMode
                ?.totalMandatoryFeesPerQuantity?.amount?.origin?.valueDecimalPoint
            )) *
            (conversionFactorRate ? conversionFactorRate : 1)
        )
      : 0;
  const mandatoryFeesValue =
    props?.isErsProperty || isDTT
      ? Math.floor(totalMandatoryFeesvalue) ?? 0
      : Math.floor(Math.floor(totalMandatoryFeesvalue / numberOfDays) / roomsAndGuests?.numberOfRooms);

  const dateRange = props.dateRange;
  const [alertMessage, setAlertMessage] = useState<AlertMessageBodyProps>({});
  /*
   * converting the currentLocale value (EX:'en_US' to 'en-US')
   * to meet the exact locale of commaSeperator func.
   */
  const currentLocal = currentLocale?.replace('_', '-');
  const disabledDatesVal = useRef<Date[]>([]);
  const [totalAmountAsPerSelection, setTotalAmountAsPerSelection] = useState(0);
  const START_DATE = 'startDate';
  const [focusedState, setFocusedState] = useState<FocusedInputType>(START_DATE);
  const isMobile = useCheckBreakpoint('viewportM');
  const isViewportL = useCheckBreakpoint('viewportL');
  const TOTAL_WEEK_DAYS_COUNT = 7;
  const TOTAL_MONTH_COUNT = 12;
  /*
   * Setting up the toCurrency Value based on selectedCurrency
   */

  eventUtil.on('availabilityCalendarSearchFormData', data => {
    const formValues = data?.formValues;
    setUseRewardsPoints(formValues?.usePoints?.value);
    setSpecialRates({
      clusterCode: formValues?.specialRates?.clusterCode,
      corporateCode:
        formValues?.specialRates?.clusterCode?.toLowerCase() === 'corp' ? formValues?.specialRates?.corporateCode : '',
      groupCode:
        formValues?.specialRates?.clusterCode?.toLowerCase() === 'group' ? formValues?.specialRates?.corporateCode : '',
    });
    setRoomsAndGuests({
      numberOfRooms: formValues?.roomsAndGuests?.roomsCount,
      numberOfAdults: formValues?.roomsAndGuests?.adultsCount,
      numberOfChildren: formValues?.roomsAndGuests?.childrenCount,
      childrenAges: formValues?.roomsAndGuests?.childrenAges,
    });
  });

  let shortWeekdays = weekdays?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (shortWeekdays?.length !== TOTAL_WEEK_DAYS_COUNT) {
    shortWeekdays = SHORT_WEEK_DAYS;
  }
  let weeksdaysShort = weekdays?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (weeksdaysShort?.length !== TOTAL_WEEK_DAYS_COUNT) {
    weeksdaysShort = SHORT_WEEK_DAYS;
  }

  let weekdaysLongName = weekdaysLong?.split(',');
  if (weekdaysLongName?.length !== TOTAL_WEEK_DAYS_COUNT) {
    weekdaysLongName = LONG_WEEK_DAYS_NAME;
  }

  let monthsValue = months?.split(',');
  // set default month if i18 months value are invalid
  if (monthsValue?.length !== TOTAL_MONTH_COUNT) {
    monthsValue = LocaleUtils.getMonths();
  }

  let monthsShortValue = monthsShort?.split(',');
  // set default month if i18 months value are invalid
  if (monthsShortValue?.length !== TOTAL_MONTH_COUNT) {
    monthsShortValue = SHORT_MONTH_NAME;
  }

  const clearDateSelection = (): void => {
    //clear the date selection if user double click on checkin date
    setCalendarFromDate(null);
    setCalendarEndDate(null);
  };

  // handler to set fromDate in the formValues
  const setDateHandler = (startDate: DateObjectType | null): void => {
    const from = startDate ?? undefined;

    const end = startDate?.clone()?.add(Number(numberOfDays), 'days');

    setCalendarFromDate(from);
    setCalendarEndDate(end);
    let ratesPerSelection = 0;
    adfData?.calendarSearchByProperty?.edges?.forEach((respEdge: any) => {
      const edge = isDTT ? respEdge : tranformADFEdgeToDTT(respEdge);
      const startDateFromResponse = moment(edge?.node?.startDate);
      //TODO:Commented for future purpose
      // if (startDateFromResponse.format(DATE_FORMAT_YEAR_MONTH_DAY) === from?.format(DATE_FORMAT_YEAR_MONTH_DAY)) {
      //   const ratesArray = edge?.node?.rates?.rateAmounts;
      //   ratesArray?.forEach((rate: any) => {
      //     if (!isPointsSelected && selectedTab === AVG_TAB && rate?.rateMode?.code === AVERAGE_NIGHTLY_RATE_PER_UNIT) {
      //       const amountToAdd = rate?.amount?.origin?.value / Math.pow(10, rate?.amount?.origin?.valueDecimalPoint);
      //       ratesPerSelection = amountToAdd * Number(end?.diff(from, 'days') || 1);
      //     }
      //   });
      // }

      if (
        (isPointsSelected || props.isRedemption) &&
        startDateFromResponse.format(DATE_FORMAT_YEAR_MONTH_DAY) === from?.format(DATE_FORMAT_YEAR_MONTH_DAY)
      ) {
        ratesPerSelection = edge?.node?.rateModes?.pointsPerQuantity?.points;
      }

      if (
        !isPointsSelected &&
        !props.isRedemption &&
        startDateFromResponse.format(DATE_FORMAT_YEAR_MONTH_DAY) === from?.format(DATE_FORMAT_YEAR_MONTH_DAY)
      ) {
        const ratesArray = edge?.node?.rateModes?.totalRate?.amount;
        ratesPerSelection = ratesArray?.amount / Math.pow(10, ratesArray?.valueDecimalPoint);
      }
    });
    if (disabledDatesVal.current?.toString()?.includes(new Date(moment(from)?.format()).toDateString()?.toString())) {
      setTotalAmountAsPerSelection(0);
    } else {
      if (isPointsSelected || props.isRedemption) {
        const ratesSelected = Math.round(ratesPerSelection);
        setTotalAmountAsPerSelection(ratesSelected);
      } else {
        const ratesSelected = Math.round(ratesPerSelection * conversionFactorRate);
        const finalRatesPostConversion = ratesSelected + (totalMandatoryFeesvalue > 0 ? totalMandatoryFeesvalue : 0);
        setTotalAmountAsPerSelection(finalRatesPostConversion);
      }
    }
    // update date in page context

    // if from and end date are same then remove end date and keep focus on end date
    if (from?.isSame(calendarFromDate)) {
      // if user click on checkin date while focus on end date input
      setFocusedState(START_DATE);
      clearDateSelection();
    }
  };
  useEffect(() => {
    setCalendarFromDate(null);
    setCalendarEndDate(null);
  }, [
    selectedTab,
    JSON.stringify(props.formData?.dates),
    JSON.stringify(props.formData?.roomsAndGuests),
    JSON.stringify(props.formData?.specialRates),
    JSON.stringify(props.formData?.usePoints?.value),
  ]);

  useEffect(() => {
    fetchDisabledDates();
  }, [props.adfData]);
  useEffect(() => {
    /**
     * Event to capture the basic information from hotel header
     */
    eventUtil.on('hotelHeaderDataBasicInformation', data => {
      //to display resort or destination fee label after checking description type
      if (data?.descriptions) {
        // eslint-disable-next-line array-callback-return
        data?.descriptions.filter((item: { type: { code: string } }) => {
          if (item?.type?.code === 'resortFeeDescription') {
            isResort = true;
            isLACountyFee = false;
          } else if (item?.type?.code === 'surchargeOrdinanceCostDescription') {
            isResort = false;
            isLACountyFee = true;
          } else if (item?.type?.code === 'destinationFeeDescription') {
            isResort = false;
            isLACountyFee = false;
          }
        });
      }
      //if still isResort flag is empty then use resort Flag from uxl
      if (isResort === '') {
        isResort = data?.resort;
      }
      const toolTipTitle = isResort
        ? resortLabel + ' ' + feeLabel
        : isLACountyFee
        ? surchargeTooltipHeadingLabel
        : destinationAmenityLabel + ' ' + feeLabel;
      setFeeTitle(toolTipTitle);
    });
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      eventUtil.remove('hotelHeaderDataBasicInformation', () => {});
    };
  }, []);

  // set locale for dates
  setLanguageLocalization(monthsShortValue, monthsValue, weekdaysLongName, weeksdaysShort);

  const isCurrentDateEdge = (startDate: string, endDate: string, formattedDay: string): boolean => {
    if (isDTT) {
      return moment(formattedDay).isBetween(startDate, endDate, undefined, '[]'); //IF DTT, check if the current date is with in the range of start and end dates of current edge
    } else {
      return startDate === formattedDay;
    }
  };

  // Function to calculate the last day of the Calendar month
  const getLastDayOfMonth = () => {
    const firstDayOfMonth = new Date(defaultMonthDate.getFullYear(), defaultMonthDate.getMonth(), 1);
    const firstDayOfCalendar = new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay()));

    return new Date(firstDayOfCalendar.setDate(firstDayOfCalendar.getDate() + 34));
  };

  const renderDay: FC<Date> = day => {
    let renderData = {
      formData: formData,
      adfData: {},
      selectedTab: selectedTab,
      rateUnavailableText: rateUnavailableText,
      notAvailableForCheckInText: notAvailableForCheckInText,
      inventoryDateData: inventoryData,
      isLowest: false,
    };
    const rateBlock = isPointsSelected || props.isRedemption ? POINTS_PER_QUANTITY : LOWEST_AVERAGE_RATE;
    let MIN_RATE = Number.MAX_SAFE_INTEGER;
    const lastDayOfMonth = getLastDayOfMonth();
    adfData?.calendarSearchByProperty?.edges?.forEach((edgeResp: any) => {
      const edge = isDTT ? edgeResp : tranformADFEdgeToDTT(edgeResp);
      const rateAmount = fetchSpecificRates(edge?.node?.rateModes, rateBlock, conversionFactorRate);
      const startDate = new Date(edgeResp?.node?.basicInformation?.startDate);
      const startDateUTC = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
      /**
       * check if rate is less than the minimum rate value
       * and the start date should also be less than the last day of the calendar month
       */
      if (rateAmount < MIN_RATE && startDateUTC <= lastDayOfMonth) {
        MIN_RATE = rateAmount;
      }
    });

    adfData?.calendarSearchByProperty?.edges?.forEach((edgeResp: any) => {
      const edge = isDTT ? edgeResp : tranformADFEdgeToDTT(edgeResp);
      const newRateAmount = fetchSpecificRates(edge?.node?.rateModes, rateBlock, conversionFactorRate);
      const isLowest = newRateAmount === MIN_RATE;
      const startDate = edge?.node?.startDate;
      const endDate = edge?.node?.endDate;
      const formattedDay = getValueFromDate(moment(day), DATE_FORMAT_YEAR_MONTH_DAY);
      if (isCurrentDateEdge(startDate, endDate, formattedDay)) {
        renderData = {
          formData: formData,
          adfData: edge,
          selectedTab: selectedTab,
          rateUnavailableText: rateUnavailableText,
          notAvailableForCheckInText: notAvailableForCheckInText,
          inventoryDateData: inventoryData,
          isLowest: isLowest,
        };
      }
    });

    const compactFormatLiteral = {
      millionLabel,
      thousandLabel,
    };

    return CustomizeRenderDay(
      day,
      isMobile,
      renderData,
      props?.isDataLoading,
      conversionFactorRate,
      isViewportL,
      currentLocal,
      smallerDevicesCheck,
      isPointsSelected,
      props.isRedemption,
      lowPriceIndicator,
      compactFormatLiteral,
      currentCurrency,
      totalMandatoryFeesvalue,
      numberOfDays
    );
  };
  // function to fetch disabled dates
  const fetchDisabledDates = () => {
    const startDate = new Date(moment(dateRange[0]).subtract(1, 'days').format(DATE_FORMAT_YEAR_MONTH_DAY));

    const endDate = new Date(moment(dateRange[1]).add(1, 'days').format(DATE_FORMAT_YEAR_MONTH_DAY));

    const filteredDates = [];
    for (
      let currentDate = new Date(startDate);
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      let dateFound = false;
      // Check if the current date is in the dataArray
      if (adfData && adfData.calendarSearchByProperty && adfData.calendarSearchByProperty.edges) {
        for (const data of adfData.calendarSearchByProperty.edges) {
          const startDate = isDTT ? data?.node?.startDate : data?.node?.basicInformation?.startDate;
          const endDate = isDTT ? data?.node?.endDate : data?.node?.basicInformation?.endDate;
          const formattedDate = moment(currentDate).format(DATE_FORMAT_YEAR_MONTH_DAY);
          if (isCurrentDateEdge(startDate, endDate, formattedDate)) {
            dateFound = true;
            break;
          }
        }
      }

      // If the date is not in the dataArray and falls within the range, add it to missingDates
      if (!dateFound && isDateInRange(currentDate, startDate, endDate)) {
        filteredDates.push(new Date(currentDate));
      }
    }
    disabledDatesVal.current = filteredDates;
    if (smallerDevicesCheck) {
      return [];
    }
    return filteredDates;
  };
  // added this useEffect to make aria-disabled false for the dates outside the month
  useEffect(() => {
    if (monthChange) {
      //since this element is from react-day-picker, need to handle it with query selector.
      const disableddays = document.querySelectorAll('.DayPicker-Day--outside');
      for (let i = 0; i < disableddays.length; i++) {
        if (disableddays[i].ariaDisabled === 'true' && !moment(disableddays[i].ariaLabel).isBefore(moment())) {
          disableddays[i].ariaDisabled = 'false';
        }
      }
      setMonthChange(false);
    }
  }, [monthChange]);
  const isDateInRange = (date: Date, startDate: Date, endDate: Date) => {
    return date >= startDate && date <= endDate;
  };

  const onSinlgeClickDateRangeSelect = (
    day: Date,
    setEndFromDatesForcefully: (startDay: Date, endDay: Date) => void
  ) => {
    setFocusedState(START_DATE);
    const fDate = moment(day).add(Number(numberOfDays), 'days');
    setEndFromDatesForcefully(day, fDate.toDate());
    setAlertMessage({});
    // }
  };

  // function to handle the continue button click and trigger an alert, if necessary
  const handleCalendarContinueButtonAction = (): any => {
    if (!calendarFromDate || calendarFromDate === null || calendarFromDate?.toString() === '') {
      const applicableAlert = returnApplicableAlert(NO_CHECK_IN_DATE_ERROR);
      if (applicableAlert) {
        setAlertMessage(applicableAlert);
      }
    } else {
      onContinueClick();
    }
  };

  const onContinueClick = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    let newSearchParams = searchParams.toString();
    if (
      adfData?.calendarSearchByProperty?.edges?.findIndex((edge: any) => {
        const startDate = isDTT ? edge?.node?.startDate : edge?.node?.basicInformation?.startDate;
        return startDate === calendarFromDate?.format(DATE_FORMAT_YEAR_MONTH_DAY);
      }) === -1
    ) {
      newSearchParams = `${searchParams.toString()}${'&unsuccessfulSell=true'}`;
      window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${newSearchParams}`));
    }
    const dates = { fromDate: calendarFromDate, toDate: calendarEndDate };
    const availabilitySearchParams = {
      isFromFlexibleDateCalendar: true,
      numberOfRooms: roomsAndGuests?.numberOfRooms,
      numberOfAdults: roomsAndGuests?.numberOfAdults,
      numberOfChildren: roomsAndGuests?.numberOfChildren,
      childrenAges: roomsAndGuests?.childrenAges,
      useRewardsPoints,
      clusterCode: specialRates?.clusterCode,
      corporateCode: specialRates?.corporateCode,
      groupCode: specialRates?.groupCode,
      fromDate: getFormattedDateByLocale(currentLocal, dates, localDateFormat ?? DATE_FORMAT_MM_DD_VALUE).fromDateValue,
      toDate: getFormattedDateByLocale(currentLocal, dates, localDateFormat ?? DATE_FORMAT_MM_DD_VALUE).toDateValue,
    };
    const params = new URLSearchParams(Object(availabilitySearchParams));
    params.forEach((value, key) => {
      if (
        value?.trim() === '' ||
        value === null ||
        value === undefined ||
        value?.trim() === 'undefined' ||
        value?.trim() === 'null'
      ) {
        params.delete(key);
      }
    });
    const availabilitySearchQueryString = `${params.toString()}`;

    //calling the shop app to update session on continue clicking
    const response = await validateAvailabilityRequest(
      false,
      NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
      props.propertyId,
      currentCurrency,
      false,
      props.interceptorResponse,
      props.preHeader,
      true,
      availabilitySearchQueryString,
      true,
      currentLocal,
      headersData,
      query
    );

    if (response) {
      updateDataLayerOnInteractions(
        resolvedUrl,
        headersData,
        query,
        datalayerParams,
        currentLocale,
        sessionID,
        propertyId
      );
    }
  };

  useEffect(() => {
    //Can't access this element without this selector.
    const dataPickerCaption = document.getElementsByClassName('DayPicker-Caption')[0];
    if (dataPickerCaption && dataPickerCaption.getAttribute('role') === 'heading') {
      dataPickerCaption.removeAttribute('role');
    }
  });

  return (
    <StyledCalendarWrapperDiv data-component-name="m-shop-CalendarWrapper" data-testid="shop-CalendarWrapper">
      <Suspense fallback={<div>Loading...</div>}>
        <Calendar
          startDate={calendarFromDate}
          endDate={calendarEndDate}
          focusedInput={focusedState}
          setDateHandler={setDateHandler}
          setFocusedInputHandler={(newState: FocusedInputType): void => setFocusedState(newState)}
          nightLabel={night}
          nightsLabel={nights}
          variation={VARIATION_DESKTOP}
          maxAllowedDays={CALENDAR_MAX_ALLOWED_DAYS}
          weekdays={shortWeekdays}
          months={monthsValue}
          weekdaysLong={weekdaysLongName}
          monthsShort={monthsShortValue}
          weekdaysShort={weeksdaysShort}
          firstDayOfWeek={firstDayOfWeek}
          numberOfMonths={NUMBER_OF_MONTH}
          customRenderDayCell={renderDay}
          disabledDays={fetchDisabledDates()}
          showOutsideDays={true}
          onSinlgeClickDateRangeSelect={onSinlgeClickDateRangeSelect}
          defaultMonth={defaultMonthDate}
          onMonthChange={onMonthChange}
          disableHoverToolTip={true}
          advanceMonth={true}
          enableOutsideDaysClick={false}
          locale={currentLocale}
          yearLabel={yearSearchLabel}
        />
      </Suspense>
      <div
        className={clsx(
          'd-flex',
          'justify-content-md-end',
          'mr-5',
          'px-md-3',
          'px-4',
          'price-section-wrapper',
          'align-items-center',
          'justify-content-between'
        )}
      >
        <div>
          {calendarFromDate && calendarEndDate && (
            <div className="price-section text-md-right mr-5">
              {totalAmountAsPerSelection < 1 ? (
                <div className="a-text-body t-font-s">{ratesUnavailableMobile}</div>
              ) : (
                <>
                  <div className="t-subtitle-xl">
                    {formatNumberByLocale(Math.round(totalAmountAsPerSelection), currentLocal)}
                    <span className="currency t-subtitle-m adf-currency-value">
                      {isPointsSelected || props.isRedemption
                        ? pointsLabel
                        : fetchCurrencyToLocale(currentLocal, currencyLabel, currentCurrency)}
                      {` ${totalLabel || ''}`}
                    </span>
                  </div>
                  <div className={clsx(isMobile ? 't-font-m' : 't-font-xs')}>
                    {isPointsSelected || props.isRedemption ? forLabel : totalPerRoom}{' '}
                    {getLocalizedDate(calendarFromDate, adfSelectionSummaryFormat, currentLocal)}
                    {'-'}
                    {calendarFromDate?.format('MMM') !== calendarEndDate?.format('MMM') && (
                      <span>{getLocalizedDate(calendarEndDate, 'MMM', currentLocal)} </span>
                    )}
                    {getLocalizedDate(
                      calendarEndDate,
                      currentLocal === JP_LOCALE || currentLocal === KR_LOCALE ? 'Do' : 'D',
                      currentLocal
                    )}{' '}
                    ({calendarEndDate?.diff(calendarFromDate, 'days')}{' '}
                    {calendarEndDate?.diff(calendarFromDate, 'days') < 2 ? `${night}` : `${nights}`})
                  </div>
                </>
              )}
            </div>
          )}
          {currentLocal === hardCodedAllLocale.en &&
            mandatoryFeesValue > 0 &&
            !(isPointsSelected || props.isRedemption) && (
              <div className={clsx(isMobile ? 't-font-m' : 't-font-xs', 'mandatory-fees-text-selected')}>
                {calendarEndDate && calendarFromDate ? (
                  <div>
                    {`${includesLabel} ${formatNumberByLocale(
                      mandatoryFeesValue,
                      currentLocal
                    )} ${currentCurrency} ${perNight} ${feeTitle ? feeTitle : ''}`}
                  </div>
                ) : (
                  <div>
                    {`${allRatesIncludeLabel}
                 ${formatNumberByLocale(mandatoryFeesValue, currentLocal)} ${currentCurrency}
                  ${perNight}
                  ${feeTitle ? feeTitle : ''}`}
                  </div>
                )}
              </div>
            )}
        </div>

        {alertMessage?.alertMessage && (
          <Messages
            className={clsx('adf-error-messages', 'pt-4')}
            messageType={alertMessage?.alertType}
            messageHeading={alertMessage?.alertHeading}
            messageText={alertMessage?.alertMessage}
          />
        )}
        <Button
          className={clsx(
            isMobile ? 'm-button' : 'm-button-s',
            calendarFromDate && calendarEndDate && totalAmountAsPerSelection > 1 ? '' : 'disable-continue'
          )}
          callback={handleCalendarContinueButtonAction}
          testId="con-cta"
          custom_click_track_value={ADF_CONTINUE_CLICK_TRACK}
        >
          {adfButtonText}
        </Button>
      </div>
    </StyledCalendarWrapperDiv>
  );
};
