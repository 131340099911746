import React, { FC } from 'react';
import RoomsAndGuests from '../RoomsAndGuests/RoomsAndGuests';
// import SpecialRates from '../../molecules/specialRates/index';
// import UsePoints from '../../molecules/usePoints/usePoints';
import { SearchDesktopSecondary } from './SearchSecondaryBar.styles';

const SearchSecondaryBar: FC<{ isPublishUserSelection: boolean }> = ({ isPublishUserSelection = false }) => (
  <SearchDesktopSecondary className="container search-secondary">
    <RoomsAndGuests isPublishUserSelection={isPublishUserSelection} />
    {/* <SpecialRates />
    <UsePoints value={'true'} /> */}
  </SearchDesktopSecondary>
);

export default SearchSecondaryBar;
